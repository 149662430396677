import { SelectItem } from 'components/inputs/select/select';

export const NAICS: SelectItem[] = [
  { label: 'Soybean Farming', key: '111110' },
  { label: 'Oilseed Except Soybean Farming', key: '111120' },
  { label: 'Dry Pea And Bean Farming', key: '111130' },
  { label: 'Wheat Farming', key: '111140' },
  { label: 'Corn Farming', key: '111150' },
  { label: 'Rice Farming', key: '111160' },
  { label: 'Oilseed And Grain Combination Farming', key: '111191' },
  { label: 'All Other Grain Farming', key: '111199' },
  { label: 'Potato Farming', key: '111211' },
  { label: 'Other Vegetable Except Potato And Melon Farming', key: '111219' },
  { label: 'Orange Groves', key: '111310' },
  { label: 'Citrus Except Orange Groves', key: '111320' },
  { label: 'Apple Orchards', key: '111331' },
  { label: 'Grape Vineyards', key: '111332' },
  { label: 'Strawberry Farming', key: '111333' },
  { label: 'Berry Except Strawberry Farming', key: '111334' },
  { label: 'Tree Nut Farming', key: '111335' },
  { label: 'Fruit And Tree Nut Combination Farming', key: '111336' },
  { label: 'Other Noncitrus Fruit Farming', key: '111339' },
  { label: 'Mushroom Production', key: '111411' },
  { label: 'Other Food Crops Grown Under Cover', key: '111419' },
  { label: 'Nursery And Tree Production', key: '111421' },
  { label: 'Floriculture Production', key: '111422' },
  { label: 'Tobacco Farming', key: '111910' },
  { label: 'Cotton Farming', key: '111920' },
  { label: 'Sugarcane Farming', key: '111930' },
  { label: 'Hay Farming', key: '111940' },
  { label: 'Sugar Beet Farming', key: '111991' },
  { label: 'Peanut Farming', key: '111992' },
  { label: 'All Other Miscellaneous Crop Farming', key: '111998' },
  { label: 'Beef Cattle Ranching And Farming', key: '112111' },
  { label: 'Cattle Feedlots', key: '112112' },
  { label: 'Dairy Cattle And Milk Production', key: '112120' },
  { label: 'Hog And Pig Farming', key: '112210' },
  { label: 'Chicken Egg Production', key: '112310' },
  { label: 'Broilers And Other Meat Type Chicken Production', key: '112320' },
  { label: 'Turkey Production', key: '112330' },
  { label: 'Poultry Hatcheries', key: '112340' },
  { label: 'Other Poultry Production', key: '112390' },
  { label: 'Sheep Farming', key: '112410' },
  { label: 'Goat Farming', key: '112420' },
  { label: 'Finfish Farming And Fish Hatcheries', key: '112511' },
  { label: 'Shellfish Farming', key: '112512' },
  { label: 'Other Aquaculture', key: '112519' },
  { label: 'Apiculture', key: '112910' },
  { label: 'Horses And Other Equine Production', key: '112920' },
  { label: 'Fur Bearing Animal And Rabbit Production', key: '112930' },
  { label: 'All Other Animal Production', key: '112990' },
  { label: 'Timber Tract Operations', key: '113110' },
  { label: 'Forest Nurseries And Gathering Of Forest Products', key: '113210' },
  { label: 'Logging', key: '113310' },
  { label: 'Finfish Fishing', key: '114111' },
  { label: 'Shellfish Fishing', key: '114112' },
  { label: 'Other Marine Fishing', key: '114119' },
  { label: 'Hunting And Trapping', key: '114210' },
  { label: 'Cotton Ginning', key: '115111' },
  { label: 'Soil Preparation Planting And Cultivating', key: '115112' },
  { label: 'Crop Harvesting Primarilyby Machine', key: '115113' },
  { label: 'Postharvest Crop Activities Except Cotton Ginning', key: '115114' },
  { label: 'Farm Labor Contractors And Crew Leaders', key: '115115' },
  { label: 'Farm Management Services', key: '115116' },
  { label: 'Support Activities For Animal Production', key: '115210' },
  { label: 'Support Activities For Forestry', key: '115310' },
  { label: 'Crude Petroleum Extraction', key: '211120' },
  { label: 'Natural Gas Extraction', key: '211130' },
  { label: 'Surface Coal Mining', key: '212114' },
  { label: 'Underground Coal Mining', key: '212115' },
  { label: 'Iron Ore Mining', key: '212210' },
  { label: 'Gold Ore And Silver Ore Mining', key: '212220' },
  { label: 'Copper Nickel Lead And Zinc Mining', key: '212230' },
  { label: 'Other Metal Ore Mining', key: '212290' },
  { label: 'Dimension Stone Mining And Quarrying', key: '212311' },
  { label: 'Crushed And Broken Limestone Mining And Quarrying', key: '212312' },
  { label: 'Crushed And Broken Granite Mining And Quarrying', key: '212313' },
  { label: 'Other Crushed And Broken Stone Mining And Quarrying', key: '212319' },
  { label: 'Construction Sand And Gravel Mining', key: '212321' },
  { label: 'Industrial Sand Mining', key: '212322' },
  { label: 'Kaolin Clay And Ceramic And Refractory Minerals Mining', key: '212323' },
  { label: 'Other Nonmetallic Mineral Mining And Quarrying', key: '212390' },
  { label: 'Drilling Oil And Gas Wells', key: '213111' },
  { label: 'Support Activities For Oil And Gas Operations', key: '213112' },
  { label: 'Support Activities For Coal Mining', key: '213113' },
  { label: 'Support Activities For Metal Mining', key: '213114' },
  { label: 'Support Activities For Nonmetallic Minerals Except Fuels Mining', key: '213115' },
  { label: 'Hydroelectric Power Generation', key: '221111' },
  { label: 'Fossil Fuel Electric Power Generation', key: '221112' },
  { label: 'Nuclear Electric Power Generation', key: '221113' },
  { label: 'Solar Electric Power Generation', key: '221114' },
  { label: 'Wind Electric Power Generation', key: '221115' },
  { label: 'Geothermal Electric Power Generation', key: '221116' },
  { label: 'Biomass Electric Power Generation', key: '221117' },
  { label: 'Other Electric Power Generation', key: '221118' },
  { label: 'Electric Bulk Power Transmission And Control', key: '221121' },
  { label: 'Electric Power Distribution', key: '221122' },
  { label: 'Natural Gas Distribution', key: '221210' },
  { label: 'Water Supply And Irrigation Systems', key: '221310' },
  { label: 'Sewage Treatment Facilities', key: '221320' },
  { label: 'Steam And Air Conditioning Supply', key: '221330' },
  { label: 'New Single Family Housing Construction Except For Sale Builders', key: '236115' },
  { label: 'New Multifamily Housing Construction Except For Sale Builders', key: '236116' },
  { label: 'New Housing For Sale Builders', key: '236117' },
  { label: 'Residential Remodelers', key: '236118' },
  { label: 'Industrial Building Construction', key: '236210' },
  { label: 'Commercial And Institutional Building Construction', key: '236220' },
  { label: 'Water And Sewer Line And Related Structures Construction', key: '237110' },
  { label: 'Oil And Gas Pipeline And Related Structures Construction', key: '237120' },
  { label: 'Power And Communication Line And Related Structures Construction', key: '237130' },
  { label: 'Land Subdivision', key: '237210' },
  { label: 'Highway Street And Bridge Construction', key: '237310' },
  { label: 'Other Heavy And Civil Engineering Construction', key: '237990' },
  { label: 'Residential Poured Concrete Foundation And Structure Contractors', key: '238111' },
  { label: 'Non Residential Poured Concrete Foundation And Structure Contractors', key: '238112' },
  { label: 'Residential Structural Steel And Precast Concrete Contractors', key: '238121' },
  { label: 'Non Residential Structural Steel And Precast Concrete Contractors', key: '238122' },
  { label: 'Residential Framing Contractors', key: '238131' },
  { label: 'Non Residential Framing Contractors', key: '238132' },
  { label: 'Residential Masonry Contractors', key: '238141' },
  { label: 'Non Residential Masonry Contractors', key: '238142' },
  { label: 'Residential Glass And Glazing Contractors', key: '238151' },
  { label: 'Non Residential Glass And Glazing Contractors', key: '238152' },
  { label: 'Residential Roofing Contractors', key: '238161' },
  { label: 'Non Residential Roofing Contractors', key: '238162' },
  { label: 'Residential Siding Contractors', key: '238171' },
  { label: 'Non Residential Siding Contractors', key: '238172' },
  { label: 'Residential Other Foundation Structure And Building Exterior Contractors', key: '238191' },
  { label: 'Non Residential Other Foundation Structure And Building Exterior Contractors', key: '238192' },
  { label: 'Residential Electrical Contractors And Other Wiring Installation Contractors', key: '238211' },
  { label: 'Non Residential Electrical Contractors And Other Wiring Installation Contractors', key: '238212' },
  { label: 'Residential Plumbing Heating And Air Conditioning Contractors', key: '238221' },
  { label: 'Non Residential Plumbing Heating And Air Conditioning Contractors', key: '238222' },
  { label: 'Residential Other Building Equipment Contractors', key: '238291' },
  { label: 'Non Residential Other Building Equipment Contractors', key: '238292' },
  { label: 'Residential Drywall And Insulation Contractors', key: '238311' },
  { label: 'Non Residential Drywall And Insulation Contractors', key: '238312' },
  { label: 'Residential Painting And Wall Covering Contractors', key: '238321' },
  { label: 'Non Residential Painting And Wall Covering Contractors', key: '238322' },
  { label: 'Residential Flooring Contractors', key: '238331' },
  { label: 'Non Residential Flooring Contractors', key: '238332' },
  { label: 'Residential Tile And Terrazzo Contractors', key: '238341' },
  { label: 'Non Residential Tile And Terrazzo Contractors', key: '238342' },
  { label: 'Residential Finish Carpentry Contractors', key: '238351' },
  { label: 'Non Residential Finish Carpentry Contractors', key: '238352' },
  { label: 'Residential Other Building Finishing Contractors', key: '238391' },
  { label: 'Non Residential Other Building Finishing Contractors', key: '238392' },
  { label: 'Residential Site Preparation Contractors', key: '238911' },
  { label: 'Non Residential Site Preparation Contractors', key: '238912' },
  { label: 'Residential All Other Specialty Trade Contractors', key: '238991' },
  { label: 'Non Residential All Other Specialty Trade Contractors', key: '238992' },
  { label: 'Dog And Cat Food Manufacturing', key: '311111' },
  { label: 'Other Animal Food Manufacturing', key: '311119' },
  { label: 'Flour Milling', key: '311211' },
  { label: 'Rice Milling', key: '311212' },
  { label: 'Malt Manufacturing', key: '311213' },
  { label: 'Wet Corn Milling And Starch Manufacturing', key: '311221' },
  { label: 'Soybean And Other Oilseed Processing', key: '311224' },
  { label: 'Fats And Oils Refining And Blending', key: '311225' },
  { label: 'Breakfast Cereal Manufacturing', key: '311230' },
  { label: 'Beet Sugar Manufacturing', key: '311313' },
  { label: 'Cane Sugar Manufacturing', key: '311314' },
  { label: 'Nonchocolate Confectionery Manufacturing', key: '311340' },
  { label: 'Chocolate And Confectionery Manufacturing From Cacao Beans', key: '311351' },
  { label: 'Confectionery Manufacturing From Purchased Chocolate', key: '311352' },
  { label: 'Frozen Fruit Juice And Vegetable Manufacturing', key: '311411' },
  { label: 'Frozen Specialty Food Manufacturing', key: '311412' },
  { label: 'Fruit And Vegetable Canning', key: '311421' },
  { label: 'Specialty Canning', key: '311422' },
  { label: 'Dried And Dehydrated Food Manufacturing', key: '311423' },
  { label: 'Fluid Milk Manufacturing', key: '311511' },
  { label: 'Creamery Butter Manufacturing', key: '311512' },
  { label: 'Cheese Manufacturing', key: '311513' },
  { label: 'Dry Condensed And Evaporated Dairy Product Manufacturing', key: '311514' },
  { label: 'Ice Cream And Frozen Dessert Manufacturing', key: '311520' },
  { label: 'Animal Except Poultry Slaughtering', key: '311611' },
  { label: 'Meat Processed From Carcasses', key: '311612' },
  { label: 'Rendering And Meat Byproduct Processing', key: '311613' },
  { label: 'Poultry Processing', key: '311615' },
  { label: 'Seafood Product Preparation And Packaging', key: '311710' },
  { label: 'Retail Bakeries', key: '311811' },
  { label: 'Commercial Bakeries', key: '311812' },
  { label: 'Frozen Cakes Pies And Other Pastries Manufacturing', key: '311813' },
  { label: 'Cookie And Cracker Manufacturing', key: '311821' },
  { label: 'Dry Pasta Dough And Flour Mixes Manufacturing From Purchased Flour', key: '311824' },
  { label: 'Tortilla Manufacturing', key: '311830' },
  { label: 'Roasted Nuts And Peanut Butter Manufacturing', key: '311911' },
  { label: 'Other Snack Food Manufacturing', key: '311919' },
  { label: 'Coffee And Tea Manufacturing', key: '311920' },
  { label: 'Flavoring Syrup And Concentrate Manufacturing', key: '311930' },
  { label: 'Mayonnaise Dressing And Other Prepared Sauce Manufacturing', key: '311941' },
  { label: 'Spice And Extract Manufacturing', key: '311942' },
  { label: 'Perishable Prepared Food Manufacturing', key: '311991' },
  { label: 'All Other Miscellaneous Food Manufacturing', key: '311999' },
  { label: 'Soft Drink Manufacturing', key: '312111' },
  { label: 'Bottled Water Manufacturing', key: '312112' },
  { label: 'Ice Manufacturing', key: '312113' },
  { label: 'Breweries', key: '312120' },
  { label: 'Wineries', key: '312130' },
  { label: 'Distilleries', key: '312140' },
  { label: 'Tobacco Manufacturing', key: '312230' },
  { label: 'Fiber Yarn And Thread Mills', key: '313110' },
  { label: 'Broadwoven Fabric Mills', key: '313210' },
  { label: 'Narrow Fabric Mills And Schiffli Machine Embroidery', key: '313220' },
  { label: 'Nonwoven Fabric Mills', key: '313230' },
  { label: 'Knit Fabric Mills', key: '313240' },
  { label: 'Textile And Fabric Finishing Mills', key: '313310' },
  { label: 'Fabric Coating Mills', key: '313320' },
  { label: 'Carpet And Rug Mills', key: '314110' },
  { label: 'Curtain And Linen Mills', key: '314120' },
  { label: 'Textile Bag And Canvas Mills', key: '314910' },
  { label: 'Rope Cordage Twine Tire Cord And Tire Fabric Mills', key: '314994' },
  { label: 'All Other Miscellaneous Textile Product Mills', key: '314999' },
  { label: 'Apparel Knitting Mills', key: '315120' },
  { label: 'Cut And Sew Apparel Contractors', key: '315210' },
  { label: 'Cut And Sew Apparel Manufacturing Except Contractors', key: '315250' },
  { label: 'Apparel Accessories And Other Apparel Manufacturing', key: '315990' },
  { label: 'Leather And Hide Tanning And Finishing', key: '316110' },
  { label: 'Footwear Manufacturing', key: '316210' },
  { label: 'Other Leather And Allied Product Manufacturing', key: '316990' },
  { label: 'Sawmills', key: '321113' },
  { label: 'Wood Preservation', key: '321114' },
  { label: 'Hardwood Veneer And Plywood Manufacturing', key: '321211' },
  { label: 'Softwood Veneer And Plywood Manufacturing', key: '321212' },
  { label: 'Engineered Wood Member Manufacturing', key: '321215' },
  { label: 'Reconstituted Wood Product Manufacturing', key: '321219' },
  { label: 'Wood Window And Door Manufacturing', key: '321911' },
  { label: 'Cut Stock Resawing Lumber And Planing', key: '321912' },
  { label: 'Other Millwork Including Flooring', key: '321918' },
  { label: 'Wood Container And Pallet Manufacturing', key: '321920' },
  { label: 'Manufactured Home Mobile Home Manufacturing', key: '321991' },
  { label: 'Prefabricated Wood Building Manufacturing', key: '321992' },
  { label: 'All Other Miscellaneous Wood Product Manufacturing', key: '321999' },
  { label: 'Pulp Mills', key: '322110' },
  { label: 'Paper Mills', key: '322120' },
  { label: 'Paperboard Mills', key: '322130' },
  { label: 'Corrugated And Solid Fiber Box Manufacturing', key: '322211' },
  { label: 'Folding Paperboard Box Manufacturing', key: '322212' },
  { label: 'Other Paperboard Container Manufacturing', key: '322219' },
  { label: 'Paper Bag And Coated And Treated Paper Manufacturing', key: '322220' },
  { label: 'Stationery Product Manufacturing', key: '322230' },
  { label: 'Sanitary Paper Product Manufacturing', key: '322291' },
  { label: 'All Other Converted Paper Product Manufacturing', key: '322299' },
  { label: 'Commercial Printing Except Screen And Books', key: '323111' },
  { label: 'Commercial Screen Printing', key: '323113' },
  { label: 'Books Printing', key: '323117' },
  { label: 'Support Activities For Printing', key: '323120' },
  { label: 'Petroleum Refineries', key: '324110' },
  { label: 'Asphalt Paving Mixture And Block Manufacturing', key: '324121' },
  { label: 'Asphalt Shingle And Coating Materials Manufacturing', key: '324122' },
  { label: 'Petroleum Lubricating Oil And Grease Manufacturing', key: '324191' },
  { label: 'All Other Petroleum And Coal Products Manufacturing', key: '324199' },
  { label: 'Petrochemical Manufacturing', key: '325110' },
  { label: 'Industrial Gas Manufacturing', key: '325120' },
  { label: 'Synthetic Dye And Pigment Manufacturing', key: '325130' },
  { label: 'Other Basic Inorganic Chemical Manufacturing', key: '325180' },
  { label: 'Ethyl Alcohol Manufacturing', key: '325193' },
  { label: 'Cyclic Crude Intermediate And Gum And Wood Chemical Manufacturing', key: '325194' },
  { label: 'All Other Basic Organic Chemical Manufacturing', key: '325199' },
  { label: 'Plastics Material And Resin Manufacturing', key: '325211' },
  { label: 'Synthetic Rubber Manufacturing', key: '325212' },
  { label: 'Artificial And Synthetic Fibers And Filaments Manufacturing', key: '325220' },
  { label: 'Nitrogenous Fertilizer Manufacturing', key: '325311' },
  { label: 'Phosphatic Fertilizer Manufacturing', key: '325312' },
  { label: 'Fertilizer Mixing Only Manufacturing', key: '325314' },
  { label: 'Compost Manufacturing', key: '325315' },
  { label: 'Pesticide And Other Agricultural Chemical Manufacturing', key: '325320' },
  { label: 'Medicinal And Botanical Manufacturing', key: '325411' },
  { label: 'Pharmaceutical Preparation Manufacturing', key: '325412' },
  { label: 'In Vitro Diagnostic Substance Manufacturing', key: '325413' },
  { label: 'Biological Product Except Diagnostic Manufacturing', key: '325414' },
  { label: 'Paint And Coating Manufacturing', key: '325510' },
  { label: 'Adhesive Manufacturing', key: '325520' },
  { label: 'Soap And Other Detergent Manufacturing', key: '325611' },
  { label: 'Polish And Other Sanitation Good Manufacturing', key: '325612' },
  { label: 'Surface Active Agent Manufacturing', key: '325613' },
  { label: 'Toilet Preparation Manufacturing', key: '325620' },
  { label: 'Printing Ink Manufacturing', key: '325910' },
  { label: 'Explosives Manufacturing', key: '325920' },
  { label: 'Custom Compounding Of Purchased Resins', key: '325991' },
  { label: 'Photographic Film Paper Plate Chemical And Copy Toner Manufacturing', key: '325992' },
  { label: 'All Other Miscellaneous Chemical Product And Preparation Manufacturing', key: '325998' },
  { label: 'Plastics Bag And Pouch Manufacturing', key: '326111' },
  { label: 'Plastics Packaging Film And Sheet Including Laminated Manufacturing', key: '326112' },
  { label: 'Unlaminated Plastics Film And Sheet Except Packaging Manufacturing', key: '326113' },
  { label: 'Unlaminated Plastics Profile Shape Manufacturing', key: '326121' },
  { label: 'Plastics Pipe And Pipe Fitting Manufacturing', key: '326122' },
  { label: 'Laminated Plastics Plate Sheet Except Packaging And Shape Manufacturing', key: '326130' },
  { label: 'Polystyrene Foam Product Manufacturing', key: '326140' },
  { label: 'Urethane And Other Foam Product Except Polystyrene Manufacturing', key: '326150' },
  { label: 'Plastics Bottle Manufacturing', key: '326160' },
  { label: 'Plastics Plumbing Fixture Manufacturing', key: '326191' },
  { label: 'All Other Plastics Product Manufacturing', key: '326199' },
  { label: 'Tire Manufacturing Except Retreading', key: '326211' },
  { label: 'Tire Retreading', key: '326212' },
  { label: 'Rubber And Plastics Hoses And Belting Manufacturing', key: '326220' },
  { label: 'Rubber Product Manufacturing For Mechanical Use', key: '326291' },
  { label: 'All Other Rubber Product Manufacturing', key: '326299' },
  { label: 'Pottery Ceramics And Plumbing Fixture Manufacturing', key: '327110' },
  { label: 'Clay Building Material And Refractories Manufacturing', key: '327120' },
  { label: 'Flat Glass Manufacturing', key: '327211' },
  { label: 'Other Pressed And Blown Glass And Glassware Manufacturing', key: '327212' },
  { label: 'Glass Container Manufacturing', key: '327213' },
  { label: 'Glass Product Manufacturing Made Of Purchased Glass', key: '327215' },
  { label: 'Cement Manufacturing', key: '327310' },
  { label: 'Ready Mix Concrete Manufacturing', key: '327320' },
  { label: 'Concrete Block And Brick Manufacturing', key: '327331' },
  { label: 'Concrete Pipe Manufacturing', key: '327332' },
  { label: 'Other Concrete Product Manufacturing', key: '327390' },
  { label: 'Lime Manufacturing', key: '327410' },
  { label: 'Gypsum Product Manufacturing', key: '327420' },
  { label: 'Abrasive Product Manufacturing', key: '327910' },
  { label: 'Cut Stone And Stone Product Manufacturing', key: '327991' },
  { label: 'Ground Or Treated Mineral And Earth Manufacturing', key: '327992' },
  { label: 'Mineral Wool Manufacturing', key: '327993' },
  { label: 'All Other Miscellaneous Nonmetallic Mineral Product Manufacturing', key: '327999' },
  { label: 'Iron And Steel Mills And Ferroalloy Manufacturing', key: '331110' },
  { label: 'Iron And Steel Pipe And Tube Manufacturing From Purchased Steel', key: '331210' },
  { label: 'Rolled Steel Shape Manufacturing', key: '331221' },
  { label: 'Steel Wire Drawing', key: '331222' },
  { label: 'Alumina Refining And Primary Aluminum Production', key: '331313' },
  { label: 'Secondary Smelting And Alloying Of Aluminum', key: '331314' },
  { label: 'Aluminum Sheet Plate And Foil Manufacturing', key: '331315' },
  { label: 'Other Aluminum Rolling Drawing And Extruding', key: '331318' },
  { label: 'Nonferrous Metal Except Aluminum Smelting And Refining', key: '331410' },
  { label: 'Copper Rolling Drawing Extruding And Alloying', key: '331420' },
  { label: 'Nonferrous Metal Except Copper And Aluminum Rolling Drawing And Extruding', key: '331491' },
  { label: 'Secondary Smelting Refining And Alloying Of Nonferrous Metal Except Copper And Aluminum', key: '331492' },
  { label: 'Iron Foundries', key: '331511' },
  { label: 'Steel Investment Foundries', key: '331512' },
  { label: 'Steel Foundries Except Investment', key: '331513' },
  { label: 'Nonferrous Metal Die Casting Foundries', key: '331523' },
  { label: 'Aluminum Foundries Except Die Casting', key: '331524' },
  { label: 'Other Nonferrous Metal Foundries Except Die Casting', key: '331529' },
  { label: 'Iron And Steel Forging', key: '332111' },
  { label: 'Nonferrous Forging', key: '332112' },
  { label: 'Custom Roll Forming', key: '332114' },
  { label: 'Powder Metallurgy Part Manufacturing', key: '332117' },
  { label: 'Metal Crown Closure And Other Metal Stamping Except Automotive', key: '332119' },
  { label: 'Metal Kitchen Cookware Utensil Cutlery And Flatware Except Precious Manufacturing', key: '332215' },
  { label: 'Saw Blade And Handtool Manufacturing', key: '332216' },
  { label: 'Prefabricated Metal Building And Component Manufacturing', key: '332311' },
  { label: 'Fabricated Structural Metal Manufacturing', key: '332312' },
  { label: 'Plate Work Manufacturing', key: '332313' },
  { label: 'Metal Window And Door Manufacturing', key: '332321' },
  { label: 'Sheet Metal Work Manufacturing', key: '332322' },
  { label: 'Orlabelntal And Architectural Metal Work Manufacturing', key: '332323' },
  { label: 'Power Boiler And Heat Exchanger Manufacturing', key: '332410' },
  { label: 'Metal Tank Heavy Gauge Manufacturing', key: '332420' },
  { label: 'Metal Can Manufacturing', key: '332431' },
  { label: 'Other Metal Container Manufacturing', key: '332439' },
  { label: 'Hardware Manufacturing', key: '332510' },
  { label: 'Spring Manufacturing', key: '332613' },
  { label: 'Other Fabricated Wire Product Manufacturing', key: '332618' },
  { label: 'Machine Shops', key: '332710' },
  { label: 'Precision Turned Product Manufacturing', key: '332721' },
  { label: 'Bolt Nut Screw Rivet And Washer Manufacturing', key: '332722' },
  { label: 'Metal Heat Treating', key: '332811' },
  { label: 'Metal Coating Engraving Except Jewelry And Silverware And Allied Servicesto Manufacturers', key: '332812' },
  { label: 'Electroplating Plating Polishing Anodizing And Coloring', key: '332813' },
  { label: 'Industrial Valve Manufacturing', key: '332911' },
  { label: 'Fluid Power Valve And Hose Fitting Manufacturing', key: '332912' },
  { label: 'Plumbing Fixture Fitting And Trim Manufacturing', key: '332913' },
  { label: 'Other Metal Valve And Pipe Fitting Manufacturing', key: '332919' },
  { label: 'Ball And Roller Bearing Manufacturing', key: '332991' },
  { label: 'Small Arms Ammunition Manufacturing', key: '332992' },
  { label: 'Ammunition Except Small Arms Manufacturing', key: '332993' },
  { label: 'Small Arms Ordnance And Ordnance Accessories Manufacturing', key: '332994' },
  { label: 'Fabricated Pipe And Pipe Fitting Manufacturing', key: '332996' },
  { label: 'All Other Miscellaneous Fabricated Metal Product Manufacturing', key: '332999' },
  { label: 'Farm Machinery And Equipment Manufacturing', key: '333111' },
  { label: 'Lawn And Garden Tractor And Home Lawn And Garden Equipment Manufacturing', key: '333112' },
  { label: 'Construction Machinery Manufacturing', key: '333120' },
  { label: 'Mining Machinery And Equipment Manufacturing', key: '333131' },
  { label: 'Oil And Gas Field Machinery And Equipment Manufacturing', key: '333132' },
  { label: 'Food Product Machinery Manufacturing', key: '333241' },
  { label: 'Semiconductor Machinery Manufacturing', key: '333242' },
  { label: 'Sawmill Woodworking And Paper Machinery Manufacturing', key: '333243' },
  { label: 'All Other Industrial Machinery Manufacturing', key: '333248' },
  { label: 'Commercial And Service Industry Machinery Manufacturing', key: '333310' },
  { label: 'Industrial And Commercial Fan And Blower And Air Purification Equipment Manufacturing', key: '333413' },
  { label: 'Heating Equipment Except Warm Air Furnaces Manufacturing', key: '333414' },
  {
    label: 'Air Conditioning And Warm Air Heating Equipment And Commercial And Industrial Refrigeration Equipment Manufacturing',
    key: '333415',
  },
  { label: 'Industrial Mold Manufacturing', key: '333511' },
  { label: 'Special Die And Tool Die Set Jig And Fixture Manufacturing', key: '333514' },
  { label: 'Cutting Tool And Machine Tool Accessory Manufacturing', key: '333515' },
  { label: 'Machine Tool Manufacturing', key: '333517' },
  { label: 'Rolling Mill And Other Metalworking Machinery Manufacturing', key: '333519' },
  { label: 'Turbine And Turbine Generator Set Units Manufacturing', key: '333611' },
  { label: 'Speed Changer Industrial High Speed Drive And Gear Manufacturing', key: '333612' },
  { label: 'Mechanical Power Transmission Equipment Manufacturing', key: '333613' },
  { label: 'Other Engine Equipment Manufacturing', key: '333618' },
  { label: 'Air And Gas Compressor Manufacturing', key: '333912' },
  { label: 'Measuring Dispensing And Other Pumping Equipment Manufacturing', key: '333914' },
  { label: 'Elevator And Moving Stairway Manufacturing', key: '333921' },
  { label: 'Conveyor And Conveying Equipment Manufacturing', key: '333922' },
  { label: 'Overhead Traveling Crane Hoist And Monorail System Manufacturing', key: '333923' },
  { label: 'Industrial Truck Tractor Trailer And Stacker Machinery Manufacturing', key: '333924' },
  { label: 'Power Driven Handtool Manufacturing', key: '333991' },
  { label: 'Welding And Soldering Equipment Manufacturing', key: '333992' },
  { label: 'Packaging Machinery Manufacturing', key: '333993' },
  { label: 'Industrial Process Furnace And Oven Manufacturing', key: '333994' },
  { label: 'Fluid Power Cylinder And Actuator Manufacturing', key: '333995' },
  { label: 'Fluid Power Pump And Motor Manufacturing', key: '333996' },
  { label: 'All Other Miscellaneous General Purpose Machinery Manufacturing', key: '333998' },
  { label: 'Electronic Computer Manufacturing', key: '334111' },
  { label: 'Computer Storage Device Manufacturing', key: '334112' },
  { label: 'Computer Terminal And Other Computer Peripheral Equipment Manufacturing', key: '334118' },
  { label: 'Telephone Apparatus Manufacturing', key: '334210' },
  { label: 'Radio And Television Broadcasting And Wireless Communications Equipment Manufacturing', key: '334220' },
  { label: 'Other Communications Equipment Manufacturing', key: '334290' },
  { label: 'Audio And Video Equipment Manufacturing', key: '334310' },
  { label: 'Bare Printed Circuit Board Manufacturing', key: '334412' },
  { label: 'Semiconductor And Related Device Manufacturing', key: '334413' },
  { label: 'Capacitor Resistor Coil Transformer And Other Inductor Manufacturing', key: '334416' },
  { label: 'Electronic Connector Manufacturing', key: '334417' },
  { label: 'Printed Circuit Assembly Electronic Assembly Manufacturing', key: '334418' },
  { label: 'Other Electronic Component Manufacturing', key: '334419' },
  { label: 'Electromedical And Electrotherapeutic Apparatus Manufacturing', key: '334510' },
  {
    label: 'Search Detection Navigation Guidance Aeronautical And Nautical System And Instrument Manufacturing',
    key: '334511',
  },
  { label: 'Automatic Environmental Control Manufacturing For Residential Commercial And Appliance Use', key: '334512' },
  {
    label: 'Instruments And Related Products Manufacturing For Measuring Displaying And Controlling Industrial Process Variables',
    key: '334513',
  },
  { label: 'Totalizing Fluid Meter And Counting Device Manufacturing', key: '334514' },
  { label: 'Instrument Manufacturing For Measuring And Testing Electricity And Electrical Signals', key: '334515' },
  { label: 'Analytical Laboratory Instrument Manufacturing', key: '334516' },
  { label: 'Irradiation Apparatus Manufacturing', key: '334517' },
  { label: 'Other Measuring And Controlling Device Manufacturing', key: '334519' },
  { label: 'Manufacturing And Reproducing Magnetic And Optical Media', key: '334610' },
  { label: 'Residential Electric Lighting Fixture Manufacturing', key: '335131' },
  { label: 'Commercial Industrial And Institutional Electric Lighting Fixture Manufacturing', key: '335132' },
  { label: 'Electric Lamp Bulb And Other Lighting Equipment Manufacturing', key: '335139' },
  { label: 'Small Electrical Appliance Manufacturing', key: '335210' },
  { label: 'Major Household Appliance Manufacturing', key: '335220' },
  { label: 'Power Distribution And Specialty Transformer Manufacturing', key: '335311' },
  { label: 'Motor And Generator Manufacturing', key: '335312' },
  { label: 'Switchgear And Switchboard Apparatus Manufacturing', key: '335313' },
  { label: 'Relay And Industrial Control Manufacturing', key: '335314' },
  { label: 'Battery Manufacturing', key: '335910' },
  { label: 'Fiber Optic Cable Manufacturing', key: '335921' },
  { label: 'Other Communication And Energy Wire Manufacturing', key: '335929' },
  { label: 'Current Carrying Wiring Device Manufacturing', key: '335931' },
  { label: 'Noncurrent Carrying Wiring Device Manufacturing', key: '335932' },
  { label: 'Carbon And Graphite Product Manufacturing', key: '335991' },
  { label: 'All Other Miscellaneous Electrical Equipment And Component Manufacturing', key: '335999' },
  { label: 'Automobile And Light Duty Motor Vehicle Manufacturing', key: '336110' },
  { label: 'Heavy Duty Truck Manufacturing', key: '336120' },
  { label: 'Motor Vehicle Body Manufacturing', key: '336211' },
  { label: 'Truck Trailer Manufacturing', key: '336212' },
  { label: 'Motor Home Manufacturing', key: '336213' },
  { label: 'Travel Trailer And Camper Manufacturing', key: '336214' },
  { label: 'Motor Vehicle Gasoline Engine And Engine Parts Manufacturing', key: '336310' },
  { label: 'Motor Vehicle Electrical And Electronic Equipment Manufacturing', key: '336320' },
  { label: 'Motor Vehicle Steering And Suspension Components Except Spring Manufacturing', key: '336330' },
  { label: 'Motor Vehicle Brake System Manufacturing', key: '336340' },
  { label: 'Motor Vehicle Transmission And Power Train Parts Manufacturing', key: '336350' },
  { label: 'Motor Vehicle Seating And Interior Trim Manufacturing', key: '336360' },
  { label: 'Motor Vehicle Metal Stamping', key: '336370' },
  { label: 'Other Motor Vehicle Parts Manufacturing', key: '336390' },
  { label: 'Aircraft Manufacturing', key: '336411' },
  { label: 'Aircraft Engine And Engine Parts Manufacturing', key: '336412' },
  { label: 'Other Aircraft Parts And Auxiliary Equipment Manufacturing', key: '336413' },
  { label: 'Guided Missile And Space Vehicle Manufacturing', key: '336414' },
  { label: 'Guided Missile And Space Vehicle Propulsion Unit And Propulsion Unit Parts Manufacturing', key: '336415' },
  { label: 'Other Guided Missile And Space Vehicle Parts And Auxiliary Equipment Manufacturing', key: '336419' },
  { label: 'Railroad Rolling Stock Manufacturing', key: '336510' },
  { label: 'Ship Building And Repairing', key: '336611' },
  { label: 'Boat Building', key: '336612' },
  { label: 'Motorcycle Bicycle And Parts Manufacturing', key: '336991' },
  { label: 'Military Armored Vehicle Tank And Tank Component Manufacturing', key: '336992' },
  { label: 'All Other Transportation Equipment Manufacturing', key: '336999' },
  { label: 'Wood Kitchen Cabinet And Countertop Manufacturing', key: '337110' },
  { label: 'Upholstered Household Furniture Manufacturing', key: '337121' },
  { label: 'Nonupholstered Wood Household Furniture Manufacturing', key: '337122' },
  { label: 'Household Furniture Except Wood And Upholstered Manufacturing', key: '337126' },
  { label: 'Institutional Furniture Manufacturing', key: '337127' },
  { label: 'Wood Office Furniture Manufacturing', key: '337211' },
  { label: 'Custom Architectural Woodwork And Millwork Manufacturing', key: '337212' },
  { label: 'Office Furniture Except Wood Manufacturing', key: '337214' },
  { label: 'Showcase Partition Shelving And Locker Manufacturing', key: '337215' },
  { label: 'Mattress Manufacturing', key: '337910' },
  { label: 'Blind And Shade Manufacturing', key: '337920' },
  { label: 'Surgical And Medical Instrument Manufacturing', key: '339112' },
  { label: 'Surgical Appliance And Supplies Manufacturing', key: '339113' },
  { label: 'Dental Equipment And Supplies Manufacturing', key: '339114' },
  { label: 'Ophthalmic Goods Manufacturing', key: '339115' },
  { label: 'Dental Laboratories', key: '339116' },
  { label: 'Jewelry And Silverware Manufacturing', key: '339910' },
  { label: 'Sporting And Athletic Goods Manufacturing', key: '339920' },
  { label: 'Doll Toy And Game Manufacturing', key: '339930' },
  { label: 'Office Supplies Except Paper Manufacturing', key: '339940' },
  { label: 'Sign Manufacturing', key: '339950' },
  { label: 'Gasket Packing And Sealing Device Manufacturing', key: '339991' },
  { label: 'Musical Instrument Manufacturing', key: '339992' },
  { label: 'Fastener Button Needle And Pin Manufacturing', key: '339993' },
  { label: 'Broom Brush And Mop Manufacturing', key: '339994' },
  { label: 'Burial Casket Manufacturing', key: '339995' },
  { label: 'All Other Miscellaneous Manufacturing', key: '339999' },
  { label: 'Automobile And Other Motor Vehicle Merchant Wholesalers', key: '423110' },
  { label: 'Motor Vehicle Supplies And New Parts Merchant Wholesalers', key: '423120' },
  { label: 'Tire And Tube Merchant Wholesalers', key: '423130' },
  { label: 'Motor Vehicle Parts Used Merchant Wholesalers', key: '423140' },
  { label: 'Furniture Merchant Wholesalers', key: '423210' },
  { label: 'Home Furnishing Merchant Wholesalers', key: '423220' },
  { label: 'Lumber Plywood Millwork And Wood Panel Merchant Wholesalers', key: '423310' },
  { label: 'Brick Stone And Related Construction Material Merchant Wholesalers', key: '423320' },
  { label: 'Roofing Siding And Insulation Material Merchant Wholesalers', key: '423330' },
  { label: 'Other Construction Material Merchant Wholesalers', key: '423390' },
  { label: 'Photographic Equipment And Supplies Merchant Wholesalers', key: '423410' },
  { label: 'Office Equipment Merchant Wholesalers', key: '423420' },
  { label: 'Computer And Computer Peripheral Equipment And Software Merchant Wholesalers', key: '423430' },
  { label: 'Other Commercial Equipment Merchant Wholesalers', key: '423440' },
  { label: 'Medical Dental And Hospital Equipment And Supplies Merchant Wholesalers', key: '423450' },
  { label: 'Ophthalmic Goods Merchant Wholesalers', key: '423460' },
  { label: 'Other Professional Equipment And Supplies Merchant Wholesalers', key: '423490' },
  { label: 'Metal Service Centers And Other Metal Merchant Wholesalers', key: '423510' },
  { label: 'Coal And Other Mineral And Ore Merchant Wholesalers', key: '423520' },
  { label: 'Electrical Apparatus And Equipment Wiring Supplies And Related Equipment Merchant Wholesalers', key: '423610' },
  { label: 'Household Appliances Electric Housewares And Consumer Electronics Merchant Wholesalers', key: '423620' },
  { label: 'Other Electronic Parts And Equipment Merchant Wholesalers', key: '423690' },
  { label: 'Hardware Merchant Wholesalers', key: '423710' },
  { label: 'Plumbing And Heating Equipment And Supplies Hydronics Merchant Wholesalers', key: '423720' },
  { label: 'Warm Air Heating And Air Conditioning Equipment And Supplies Merchant Wholesalers', key: '423730' },
  { label: 'Refrigeration Equipment And Supplies Merchant Wholesalers', key: '423740' },
  { label: 'Construction And Mining Except Oil Well Machinery And Equipment Merchant Wholesalers', key: '423810' },
  { label: 'Farm And Garden Machinery And Equipment Merchant Wholesalers', key: '423820' },
  { label: 'Industrial Machinery And Equipment Merchant Wholesalers', key: '423830' },
  { label: 'Industrial Supplies Merchant Wholesalers', key: '423840' },
  { label: 'Service Establishment Equipment And Supplies Merchant Wholesalers', key: '423850' },
  { label: 'Transportation Equipment And Supplies Except Motor Vehicle Merchant Wholesalers', key: '423860' },
  { label: 'Sporting And Recreational Goods And Supplies Merchant Wholesalers', key: '423910' },
  { label: 'Toy And Hobby Goods And Supplies Merchant Wholesalers', key: '423920' },
  { label: 'Recyclable Material Merchant Wholesalers', key: '423930' },
  { label: 'Jewelry Watch Precious Stone And Precious Metal Merchant Wholesalers', key: '423940' },
  { label: 'Other Miscellaneous Durable Goods Merchant Wholesalers', key: '423990' },
  { label: 'Printing And Writing Paper Merchant Wholesalers', key: '424110' },
  { label: 'Stationery And Office Supplies Merchant Wholesalers', key: '424120' },
  { label: 'Industrial And Personal Service Paper Merchant Wholesalers', key: '424130' },
  { label: 'Drugs And Druggists Sundries Merchant Wholesalers', key: '424210' },
  { label: 'Piece Goods Notions And Other Dry Goods Merchant Wholesalers', key: '424310' },
  { label: 'Footwear Merchant Wholesalers', key: '424340' },
  { label: 'Clothing And Clothing Accessories Merchant Wholesalers', key: '424350' },
  { label: 'General Line Grocery Merchant Wholesalers', key: '424410' },
  { label: 'Packaged Frozen Food Merchant Wholesalers', key: '424420' },
  { label: 'Dairy Product Except Dried Or Canned Merchant Wholesalers', key: '424430' },
  { label: 'Poultry And Poultry Product Merchant Wholesalers', key: '424440' },
  { label: 'Confectionery Merchant Wholesalers', key: '424450' },
  { label: 'Fish And Seafood Merchant Wholesalers', key: '424460' },
  { label: 'Meat And Meat Product Merchant Wholesalers', key: '424470' },
  { label: 'Fresh Fruit And Vegetable Merchant Wholesalers', key: '424480' },
  { label: 'Other Grocery And Related Products Merchant Wholesalers', key: '424490' },
  { label: 'Grain And Field Bean Merchant Wholesalers', key: '424510' },
  { label: 'Livestock Merchant Wholesalers', key: '424520' },
  { label: 'Other Farm Product Raw Material Merchant Wholesalers', key: '424590' },
  { label: 'Plastics Materials And Basic Forms And Shapes Merchant Wholesalers', key: '424610' },
  { label: 'Other Chemical And Allied Products Merchant Wholesalers', key: '424690' },
  { label: 'Petroleum Bulk Stations And Terminals', key: '424710' },
  { label: 'Petroleum And Petroleum Products Merchant Wholesalers Except Bulk Stations And Terminals', key: '424720' },
  { label: 'Beer And Ale Merchant Wholesalers', key: '424810' },
  { label: 'Wine And Distilled Alcoholic Beverage Merchant Wholesalers', key: '424820' },
  { label: 'Farm Supplies Merchant Wholesalers', key: '424910' },
  { label: 'Book Periodical And Newspaper Merchant Wholesalers', key: '424920' },
  { label: 'Flower Nursery Stock And Florists Supplies Merchant Wholesalers', key: '424930' },
  { label: 'Tobacco Product And Electronic Cigarette Merchant Wholesalers', key: '424940' },
  { label: 'Paint Varnish And Supplies Merchant Wholesalers', key: '424950' },
  { label: 'Other Miscellaneous Nondurable Goods Merchant Wholesalers', key: '424990' },
  { label: 'Wholesale Trade Agents And Brokers', key: '425120' },
  { label: 'New Car Dealers', key: '441110' },
  { label: 'Used Car Dealers', key: '441120' },
  { label: 'Recreational Vehicle Dealers', key: '441210' },
  { label: 'Boat Dealers', key: '441222' },
  { label: 'Motorcycle ATV And All Other Motor Vehicle Dealers', key: '441227' },
  { label: 'Automotive Parts And Accessories Retailers', key: '441330' },
  { label: 'Tire Dealers', key: '441340' },
  { label: 'Home Centers', key: '444110' },
  { label: 'Paint And Wallpaper Retailers', key: '444120' },
  { label: 'Hardware Retailers', key: '444140' },
  { label: 'Other Building Material Dealers', key: '444180' },
  { label: 'Outdoor Power Equipment Retailers', key: '444230' },
  { label: 'Nursery Garden Center And Farm Supply Retailers', key: '444240' },
  { label: 'Supermarkets And Other Grocery Retailers Except Convenience Retailers', key: '445110' },
  { label: 'Convenience Retailers', key: '445131' },
  { label: 'Vending Machine Operators', key: '445132' },
  { label: 'Fruit And Vegetable Retailers', key: '445230' },
  { label: 'Meat Retailers', key: '445240' },
  { label: 'Fish And Seafood Retailers', key: '445250' },
  { label: 'Baked Goods Retailers', key: '445291' },
  { label: 'Confectionery And Nut Retailers', key: '445292' },
  { label: 'All Other Specialty Food Retailers', key: '445298' },
  { label: 'Beer Wine And Liquor Retailers', key: '445320' },
  { label: 'Furniture Retailers', key: '449110' },
  { label: 'Floor Covering Retailers', key: '449121' },
  { label: 'Window Treatment Retailers', key: '449122' },
  { label: 'All Other Home Furnishings Retailers', key: '449129' },
  { label: 'Electronics And Appliance Retailers', key: '449210' },
  { label: 'Mail Order Houses', key: '454113' },
  { label: 'Other Direct Selling Establishments', key: '454390' },
  { label: 'Department Stores', key: '455110' },
  { label: 'Warehouse Clubs And Supercenters', key: '455211' },
  { label: 'All Other General Merchandise Retailers', key: '455219' },
  { label: 'Pharmacies And Drug Retailers', key: '456110' },
  { label: 'Cosmetics Beauty Supplies And Perfume Retailers', key: '456120' },
  { label: 'Optical Goods Retailers', key: '456130' },
  { label: 'Food Health Supplement Retailers', key: '456191' },
  { label: 'All Other Health And Personal Care Retailers', key: '456199' },
  { label: 'Gasoline Stations With Convenience Stores', key: '457110' },
  { label: 'Other Gasoline Stations', key: '457120' },
  { label: 'Fuel Dealers', key: '457210' },
  { label: 'Clothing And Clothing Accessories Retailers', key: '458110' },
  { label: 'Shoe Retailers', key: '458210' },
  { label: 'Jewelry Retailers', key: '458310' },
  { label: 'Luggage And Leather Goods Retailers', key: '458320' },
  { label: 'Sporting Goods Retailers', key: '459110' },
  { label: 'Hobby Toy And Game Retailers', key: '459120' },
  { label: 'Sewing Needlework And Piece Goods Retailers', key: '459130' },
  { label: 'Musical Instrument And Supplies Retailers', key: '459140' },
  { label: 'Book Retailers And News Dealers', key: '459210' },
  { label: 'Florists', key: '459310' },
  { label: 'Office Supplies And Stationery Retailers', key: '459410' },
  { label: 'Gift Novelty And Souvenir Retailers', key: '459420' },
  { label: 'Used Merchandise Retailers', key: '459510' },
  { label: 'Pet And Pet Supplies Retailers', key: '459910' },
  { label: 'Art Dealers', key: '459920' },
  { label: 'Manufactured Mobile Home Dealers', key: '459930' },
  { label: 'Tobacco Electronic Cigarette And Other Smoking Supplies Retailers', key: '459991' },
  { label: 'All Other Miscellaneous Retailers', key: '459999' },
  { label: 'Scheduled Passenger Air Transportation', key: '481111' },
  { label: 'Scheduled Freight Air Transportation', key: '481112' },
  { label: 'Nonscheduled Chartered Passenger Air Transportation', key: '481211' },
  { label: 'Nonscheduled Chartered Freight Air Transportation', key: '481212' },
  { label: 'Other Nonscheduled Air Transportation', key: '481219' },
  { label: 'Line Haul Railroads', key: '482111' },
  { label: 'Short Line Railroads', key: '482112' },
  { label: 'Deep Sea Freight Transportation', key: '483111' },
  { label: 'Deep Sea Passenger Transportation', key: '483112' },
  { label: 'Coastal And Great Lakes Freight Transportation', key: '483113' },
  { label: 'Coastal And Great Lakes Passenger Transportation', key: '483114' },
  { label: 'Inland Water Freight Transportation', key: '483211' },
  { label: 'Inland Water Passenger Transportation', key: '483212' },
  { label: 'General Freight Trucking Local', key: '484110' },
  { label: 'General Freight Trucking Long Distance Truckload', key: '484121' },
  { label: 'General Freight Trucking Long Distance Less Than Truckload', key: '484122' },
  { label: 'Used Household And Office Goods Moving', key: '484210' },
  { label: 'Specialized Freight Except Used Goods Trucking Local', key: '484220' },
  { label: 'Specialized Freight Except Used Goods Trucking Long Distance', key: '484230' },
  { label: 'Mixed Mode Transit Systems', key: '485111' },
  { label: 'Commuter Rail Systems', key: '485112' },
  { label: 'Bus And Other Motor Vehicle Transit Systems', key: '485113' },
  { label: 'Other Urban Transit Systems', key: '485119' },
  { label: 'Interurban And Rural Bus Transportation', key: '485210' },
  { label: 'Taxi And Ridesharing Services', key: '485310' },
  { label: 'Limousine Service', key: '485320' },
  { label: 'School And Employee Bus Transportation', key: '485410' },
  { label: 'Charter Bus Industry', key: '485510' },
  { label: 'Special Needs Transportation', key: '485991' },
  { label: 'All Other Transit And Ground Passenger Transportation', key: '485999' },
  { label: 'Pipeline Transportation Of Crude Oil', key: '486110' },
  { label: 'Pipeline Transportation Of Natural Gas', key: '486210' },
  { label: 'Pipeline Transportation Of Refined Petroleum Products', key: '486910' },
  { label: 'All Other Pipeline Transportation', key: '486990' },
  { label: 'Scenic And Sightseeing Transportation Land', key: '487110' },
  { label: 'Scenic And Sightseeing Transportation Water', key: '487210' },
  { label: 'Scenic And Sightseeing Transportation Other', key: '487990' },
  { label: 'Air Traffic Control', key: '488111' },
  { label: 'Other Airport Operations', key: '488119' },
  { label: 'Other Support Activities For Air Transportation', key: '488190' },
  { label: 'Support Activities For Rail Transportation', key: '488210' },
  { label: 'Port And Harbor Operations', key: '488310' },
  { label: 'Marine Cargo Handling', key: '488320' },
  { label: 'Navigational Services To Shipping', key: '488330' },
  { label: 'Other Support Activities For Water Transportation', key: '488390' },
  { label: 'Motor Vehicle Towing', key: '488410' },
  { label: 'Other Support Activities For Road Transportation', key: '488490' },
  { label: 'Freight Transportation Arrangement', key: '488510' },
  { label: 'Packing And Crating', key: '488991' },
  { label: 'All Other Support Activities For Transportation', key: '488999' },
  { label: 'Postal Service', key: '491110' },
  { label: 'Couriers And Express Delivery Services', key: '492110' },
  { label: 'Local Messengers And Local Delivery', key: '492210' },
  { label: 'General Warehousing And Storage', key: '493110' },
  { label: 'Refrigerated Warehousing And Storage', key: '493120' },
  { label: 'Farm Product Warehousing And Storage', key: '493130' },
  { label: 'Other Warehousing And Storage', key: '493190' },
  { label: 'Motion Picture And Video Production', key: '512110' },
  { label: 'Motion Picture And Video Distribution', key: '512120' },
  { label: 'Motion Picture Theaters Except Drive Ins', key: '512131' },
  { label: 'Drive In Motion Picture Theaters', key: '512132' },
  { label: 'Teleproduction And Other Postproduction Services', key: '512191' },
  { label: 'Other Motion Picture And Video Industries', key: '512199' },
  { label: 'Music Publishers', key: '512230' },
  { label: 'Sound Recording Studios', key: '512240' },
  { label: 'Record Production And Distribution', key: '512250' },
  { label: 'Other Sound Recording Industries', key: '512290' },
  { label: 'Newspaper Publishers', key: '513110' },
  { label: 'Periodical Publishers', key: '513120' },
  { label: 'Book Publishers', key: '513130' },
  { label: 'Directory And Mailing List Publishers', key: '513140' },
  { label: 'Greeting Card Publishers', key: '513191' },
  { label: 'All Other Publishers', key: '513199' },
  { label: 'Software Publishers', key: '513210' },
  { label: 'Cable And Other Subscription Programming', key: '515210' },
  { label: 'Radio Broadcasting Stations', key: '516110' },
  { label: 'Television Broadcasting Stations', key: '516120' },
  {
    label: 'Media Streaming Distribution Services Social Networks And Other Media Networks And Content Providers',
    key: '516210',
  },
  { label: 'Wired Telecommunications Carriers', key: '517111' },
  { label: 'Wireless Telecommunications Carriers Except Satellite', key: '517112' },
  { label: 'Telecommunications Resellers', key: '517121' },
  { label: 'Satellite Telecommunications', key: '517410' },
  { label: 'All Other Telecommunications', key: '517810' },
  { label: 'Computing Infrastructure Providers Data Processing Web Hosting And Related Services', key: '518210' },
  { label: 'Libraries And Archives', key: '519210' },
  { label: 'Web Search Portals And All Other Information Services', key: '519290' },
  { label: 'Monetary Authorities Central Bank', key: '521110' },
  { label: 'Commercial Banking', key: '522110' },
  { label: 'Credit Unions', key: '522130' },
  { label: 'Savings Institutions And Other Depository Credit Intermediation', key: '522180' },
  { label: 'Credit Card Issuing', key: '522210' },
  { label: 'Sales Financing', key: '522220' },
  { label: 'Consumer Lending', key: '522291' },
  { label: 'Real Estate Credit', key: '522292' },
  { label: 'All Other Nondepository Credit Intermediation', key: '522298' },
  { label: 'International Secondary Market And All Other Nondepository Credit Intermediation', key: '522299' },
  { label: 'Mortgage And Nonmortgage Loan Brokers', key: '522310' },
  { label: 'Financial Transactions Processing Reserve And Clearinghouse Activities', key: '522320' },
  { label: 'Other Activities Related To Credit Intermediation', key: '522390' },
  { label: 'Investment Banking And Securities Intermediation', key: '523150' },
  { label: 'Commodity Contracts Intermediation', key: '523160' },
  { label: 'Securities And Commodity Exchanges', key: '523210' },
  { label: 'Miscellaneous Intermediation', key: '523910' },
  { label: 'Portfolio Management And Investment Advice', key: '523940' },
  { label: 'Trust Fiduciary And Custody Activities', key: '523991' },
  { label: 'Miscellaneous Financial Investment Activities', key: '523999' },
  { label: 'Direct Life Insurance Carriers', key: '524113' },
  { label: 'Direct Health And Medical Insurance Carriers', key: '524114' },
  { label: 'Direct Property And Casualty Insurance Carriers', key: '524126' },
  { label: 'Direct Title Insurance Carriers', key: '524127' },
  { label: 'Other Direct Insurance Except Life Health And Medical Carriers', key: '524128' },
  { label: 'Reinsurance Carriers', key: '524130' },
  { label: 'Insurance Agencies And Brokerages', key: '524210' },
  { label: 'Claims Adjusting', key: '524291' },
  { label: 'Pharmacy Benefit Management And Other Third Party Administration Of Insurance And Pension Funds', key: '524292' },
  { label: 'All Other Insurance Related Activities', key: '524298' },
  { label: 'Pension Funds', key: '525110' },
  { label: 'Health And Welfare Funds', key: '525120' },
  { label: 'Other Insurance Funds', key: '525190' },
  { label: 'Open End Investment Funds', key: '525910' },
  { label: 'Trusts Estates And Agency Accounts', key: '525920' },
  { label: 'Other Financial Vehicles', key: '525990' },
  { label: 'Lessors Of Residential Buildings And Dwellings', key: '531110' },
  { label: 'Lessors Of Nonresidential Buildings Except Miniwarehouses', key: '531120' },
  { label: 'Lessors Of Miniwarehouses And Self Storage Units', key: '531130' },
  { label: 'Lessors Of Other Real Estate Property', key: '531190' },
  { label: 'Offices Of Real Estate Agents And Brokers', key: '531210' },
  { label: 'Residential Property Managers', key: '531311' },
  { label: 'Nonresidential Property Managers', key: '531312' },
  { label: 'Offices Of Real Estate Appraisers', key: '531320' },
  { label: 'Other Activities Related To Real Estate', key: '531390' },
  { label: 'Passenger Car Rental', key: '532111' },
  { label: 'Passenger Car Leasing', key: '532112' },
  { label: 'Truck Utility Trailer And RV Recreational Vehicle Rental And Leasing', key: '532120' },
  { label: 'Consumer Electronics And Appliances Rental', key: '532210' },
  { label: 'Formal Wear And Costume Rental', key: '532281' },
  { label: 'Video Tape And Disc Rental', key: '532282' },
  { label: 'Home Health Equipment Rental', key: '532283' },
  { label: 'Recreational Goods Rental', key: '532284' },
  { label: 'All Other Consumer Goods Rental', key: '532289' },
  { label: 'General Rental Centers', key: '532310' },
  { label: 'Commercial Air Rail And Water Transportation Equipment Rental And Leasing', key: '532411' },
  { label: 'Construction Mining And Forestry Machinery And Equipment Rental And Leasing', key: '532412' },
  { label: 'Office Machinery And Equipment Rental And Leasing', key: '532420' },
  { label: 'Other Commercial And Industrial Machinery And Equipment Rental And Leasing', key: '532490' },
  { label: 'Lessors Of Nonfinancial Intangible Assets Except Copyrighted Works', key: '533110' },
  { label: 'Offices Of Lawyers', key: '541110' },
  { label: 'Title Abstract And Settlement Offices', key: '541191' },
  { label: 'All Other Legal Services', key: '541199' },
  { label: 'Offices Of Certified Public Accountants', key: '541211' },
  { label: 'Tax Preparation Services', key: '541213' },
  { label: 'Payroll Services', key: '541214' },
  { label: 'Other Accounting Services', key: '541219' },
  { label: 'Architectural Services', key: '541310' },
  { label: 'Landscape Architectural Services', key: '541320' },
  { label: 'Engineering Services', key: '541330' },
  { label: 'Drafting Services', key: '541340' },
  { label: 'Building Inspection Services', key: '541350' },
  { label: 'Geophysical Surveying And Mapping Services', key: '541360' },
  { label: 'Surveying And Mapping Except Geophysical Services', key: '541370' },
  { label: 'Testing Laboratories And Services', key: '541380' },
  { label: 'Interior Design Services', key: '541410' },
  { label: 'Industrial Design Services', key: '541420' },
  { label: 'Graphic Design Services', key: '541430' },
  { label: 'Other Specialized Design Services', key: '541490' },
  { label: 'Custom Computer Programming Services', key: '541511' },
  { label: 'Computer Systems Design Services', key: '541512' },
  { label: 'Computer Facilities Management Services', key: '541513' },
  { label: 'Other Computer Related Services', key: '541519' },
  { label: 'Administrative Management And General Management Consulting Services', key: '541611' },
  { label: 'Human Resources Consulting Services', key: '541612' },
  { label: 'Marketing Consulting Services', key: '541613' },
  { label: 'Process Physical Distribution And Logistics Consulting Services', key: '541614' },
  { label: 'Other Management Consulting Services', key: '541618' },
  { label: 'Environmental Consulting Services', key: '541620' },
  { label: 'Other Scientific And Technical Consulting Services', key: '541690' },
  { label: 'Research And Development In Nanotechnology', key: '541713' },
  { label: 'Research And Development In Biotechnology Except Nanobiotechnology', key: '541714' },
  {
    label: 'Research And Development In The Physical Engineering And Life Sciences Except Nanotechnology And Biotechnology',
    key: '541715',
  },
  { label: 'Research And Development In The Social Sciences And Humanities', key: '541720' },
  { label: 'Advertising Agencies', key: '541810' },
  { label: 'Public Relations Agencies', key: '541820' },
  { label: 'Media Buying Agencies', key: '541830' },
  { label: 'Media Representatives', key: '541840' },
  { label: 'Indoor And Outdoor Display Advertising', key: '541850' },
  { label: 'Direct Mail Advertising', key: '541860' },
  { label: 'Advertising Material Distribution Services', key: '541870' },
  { label: 'Other Services Related To Advertising', key: '541890' },
  { label: 'Marketing Research And Public Opinion Polling', key: '541910' },
  { label: 'Photography Studios Portrait', key: '541921' },
  { label: 'Commercial Photography', key: '541922' },
  { label: 'Translation And Interpretation Services', key: '541930' },
  { label: 'Veterinary Services', key: '541940' },
  { label: 'All Other Professional Scientific And Technical Services', key: '541990' },
  { label: 'Offices Of Bank Holding Companies', key: '551111' },
  { label: 'Offices Of Other Holding Companies', key: '551112' },
  { label: 'Corporate Subsidiary And Regional Managing Offices', key: '551114' },
  { label: 'Office Administrative Services', key: '561110' },
  { label: 'Facilities Support Services', key: '561210' },
  { label: 'Employment Placement Agencies', key: '561311' },
  { label: 'Executive Search Services', key: '561312' },
  { label: 'Temporary Help Services', key: '561320' },
  { label: 'Professional Employer Organizations', key: '561330' },
  { label: 'Document Preparation Services', key: '561410' },
  { label: 'Telephone Answering Services', key: '561421' },
  { label: 'Telemarketing Bureaus And Other Contact Centers', key: '561422' },
  { label: 'Private Mail Centers', key: '561431' },
  { label: 'Other Business Service Centers Including Copy Shops', key: '561439' },
  { label: 'Collection Agencies', key: '<wtf>561440</wtf>' },
  { label: 'Credit Bureaus', key: '561450' },
  { label: 'Repossession Services', key: '561491' },
  { label: 'Court Reporting And Stenotype Services', key: '561492' },
  { label: 'All Other Business Support Services', key: '561499' },
  { label: 'Travel Agencies', key: '561510' },
  { label: 'Tour Operators', key: '561520' },
  { label: 'Convention And Visitors Bureaus', key: '561591' },
  { label: 'All Other Travel Arrangement And Reservation Services', key: '561599' },
  { label: 'Investigation And Personal Background Check Services', key: '561611' },
  { label: 'Security Guards And Patrol Services', key: '561612' },
  { label: 'Armored Car Services', key: '561613' },
  { label: 'Security Systems Services Except Locksmiths', key: '561621' },
  { label: 'Locksmiths', key: '561622' },
  { label: 'Exterminating And Pest Control Services', key: '561710' },
  { label: 'Janitorial Services', key: '561720' },
  { label: 'Landscaping Services', key: '561730' },
  { label: 'Carpet And Upholstery Cleaning Services', key: '561740' },
  { label: 'Other Services To Buildings And Dwellings', key: '561790' },
  { label: 'Packaging And Labeling Services', key: '561910' },
  { label: 'Convention And Trade Show Organizers', key: '561920' },
  { label: 'All Other Support Services', key: '561990' },
  { label: 'Solid Waste Collection', key: '562111' },
  { label: 'Hazardous Waste Collection', key: '562112' },
  { label: 'Other Waste Collection', key: '562119' },
  { label: 'Hazardous Waste Treatment And Disposal', key: '562211' },
  { label: 'Solid Waste Landfill', key: '562212' },
  { label: 'Solid Waste Combustors And Incinerators', key: '562213' },
  { label: 'Other Nonhazardous Waste Treatment And Disposal', key: '562219' },
  { label: 'Remediation Services', key: '562910' },
  { label: 'Materials Recovery Facilities', key: '562920' },
  { label: 'Septic Tank And Related Services', key: '562991' },
  { label: 'All Other Miscellaneous Waste Management Services', key: '562998' },
  { label: 'Elementary And Secondary Schools', key: '611110' },
  { label: 'Junior Colleges', key: '611210' },
  { label: 'Colleges Universities And Professional Schools', key: '611310' },
  { label: 'Business And Secretarial Schools', key: '611410' },
  { label: 'Computer Training', key: '611420' },
  { label: 'Professional And Management Development Training', key: '611430' },
  { label: 'Cosmetology And Barber Schools', key: '611511' },
  { label: 'Flight Training', key: '611512' },
  { label: 'Apprenticeship Training', key: '611513' },
  { label: 'Other Technical And Trade Schools', key: '611519' },
  { label: 'Fine Arts Schools', key: '611610' },
  { label: 'Sports And Recreation Instruction', key: '611620' },
  { label: 'Language Schools', key: '611630' },
  { label: 'Exam Preparation And Tutoring', key: '611691' },
  { label: 'Automobile Driving Schools', key: '611692' },
  { label: 'All Other Miscellaneous Schools And Instruction', key: '611699' },
  { label: 'Educational Support Services', key: '611710' },
  { label: 'Offices Of Physicians Except Mental Health Specialists', key: '621111' },
  { label: 'Offices Of Physicians Mental Health Specialists', key: '621112' },
  { label: 'Offices Of Dentists', key: '621210' },
  { label: 'Offices Of Chiropractors', key: '621310' },
  { label: 'Offices Of Optometrists', key: '621320' },
  { label: 'Offices Of Mental Health Practitioners Except Physicians', key: '621330' },
  { label: 'Offices Of Physical Occupational And Speech Therapists And Audiologists', key: '621340' },
  { label: 'Offices Of Podiatrists', key: '621391' },
  { label: 'Offices Of All Other Miscellaneous Health Practitioners', key: '621399' },
  { label: 'Family Planning Centers', key: '621410' },
  { label: 'Outpatient Mental Health And Substance Abuse Centers', key: '621420' },
  { label: 'HMO Medical Centers', key: '621491' },
  { label: 'Kidney Dialysis Centers', key: '621492' },
  { label: 'Freestanding Ambulatory Surgical And Emergency Centers', key: '621493' },
  { label: 'All Other Outpatient Care Centers', key: '621498' },
  { label: 'Medical Laboratories', key: '621511' },
  { label: 'Diagnostic Imaging Centers', key: '621512' },
  { label: 'Home Health Care Services', key: '621610' },
  { label: 'Ambulance Services', key: '621910' },
  { label: 'Blood And Organ Banks', key: '621991' },
  { label: 'All Other Miscellaneous Ambulatory Health Care Services', key: '621999' },
  { label: 'General Medical And Surgical Hospitals', key: '622110' },
  { label: 'Psychiatric And Substance Abuse Hospitals', key: '622210' },
  { label: 'Specialty Except Psychiatric And Substance Abuse Hospitals', key: '622310' },
  { label: 'Nursing Care Facilities Skilled Nursing Facilities', key: '623110' },
  { label: 'Residential Intellectual And Developmental Disability Facilities', key: '623210' },
  { label: 'Residential Mental Health And Substance Abuse Facilities', key: '623220' },
  { label: 'Continuing Care Retirement Communities', key: '623311' },
  { label: 'Assisted Living Facilities For The Elderly', key: '623312' },
  { label: 'Other Residential Care Facilities', key: '623990' },
  { label: 'Child And Youth Services', key: '624110' },
  { label: 'Services For The Elderly And Persons With Disabilities', key: '624120' },
  { label: 'Other Individual And Family Services', key: '624190' },
  { label: 'Community Food Services', key: '624210' },
  { label: 'Temporary Shelters', key: '624221' },
  { label: 'Other Community Housing Services', key: '624229' },
  { label: 'Emergency And Other Relief Services', key: '624230' },
  { label: 'Vocational Rehabilitation Services', key: '624310' },
  { label: 'Child Care Services', key: '624410' },
  { label: 'Theater Companies And Dinner Theaters', key: '711110' },
  { label: 'Dance Companies', key: '711120' },
  { label: 'Musical Groups And Artists', key: '711130' },
  { label: 'Other Performing Arts Companies', key: '711190' },
  { label: 'Sports Teams And Clubs', key: '711211' },
  { label: 'Racetracks', key: '711212' },
  { label: 'Other Spectator Sports', key: '711219' },
  { label: 'Promoters Of Performing Arts Sports And Similar Events With Facilities', key: '711310' },
  { label: 'Promoters Of Performing Arts Sports And Similar Events Without Facilities', key: '711320' },
  { label: 'Agents And Managers For Artists Athletes Entertainers And Other Public Figures', key: '711410' },
  { label: 'Independent Artists Writers And Performers', key: '711510' },
  { label: 'Museums', key: '712110' },
  { label: 'Historical Sites', key: '712120' },
  { label: 'Zoos And Botanical Gardens', key: '712130' },
  { label: 'Nature Parks And Other Similar Institutions', key: '712190' },
  { label: 'Amusement And Theme Parks', key: '713110' },
  { label: 'Amusement Arcades', key: '713120' },
  { label: 'Casinos Except Casino Hotels', key: '713210' },
  { label: 'Other Gambling Industries', key: '713290' },
  { label: 'Golf Courses And Country Clubs', key: '713910' },
  { label: 'Skiing Facilities', key: '713920' },
  { label: 'Marinas', key: '713930' },
  { label: 'Fitness And Recreational Sports Centers', key: '713940' },
  { label: 'Bowling Centers', key: '713950' },
  { label: 'All Other Amusement And Recreation Industries', key: '713990' },
  { label: 'Hotels Except Casino Hotels And Motels', key: '721110' },
  { label: 'Casino Hotels', key: '721120' },
  { label: 'Bedand Breakfast Inns', key: '721191' },
  { label: 'All Other Traveler Accommodation', key: '721199' },
  { label: 'RV Recreational Vehicle Parks And Campgrounds', key: '721211' },
  { label: 'Recreational And Vacation Camps Except Campgrounds', key: '721214' },
  { label: 'Rooming And Boarding Houses Dormitories And Workers Camps', key: '721310' },
  { label: 'Food Service Contractors', key: '722310' },
  { label: 'Caterers', key: '722320' },
  { label: 'Mobile Food Services', key: '722330' },
  { label: 'Drinking Places Alcoholic Beverages', key: '722410' },
  { label: 'Full Service Restaurants', key: '722511' },
  { label: 'Limited Service Restaurants', key: '722513' },
  { label: 'Cafeterias Grill Buffets And Buffets', key: '722514' },
  { label: 'Snack And Nonalcoholic Beverage Bars', key: '722515' },
  { label: 'General Automotive Repair', key: '811111' },
  { label: 'Specialized Automotive Repair', key: '811114' },
  { label: 'Automotive Body Paint And Interior Repair And Maintenance', key: '811121' },
  { label: 'Automotive Glass Replacement Shops', key: '811122' },
  { label: 'Automotive Oil Change And Lubrication Shops', key: '811191' },
  { label: 'Car Washes', key: '811192' },
  { label: 'All Other Automotive Repair And Maintenance', key: '811198' },
  { label: 'Electronic And Precision Equipment Repair And Maintenance', key: '811210' },
  {
    label: 'Commercial And Industrial Machinery And Equipment Except Automotive And Electronic Repair And Maintenance',
    key: '811310',
  },
  { label: 'Home And Garden Equipment Repair And Maintenance', key: '811411' },
  { label: 'Appliance Repair And Maintenance', key: '811412' },
  { label: 'Reupholstery And Furniture Repair', key: '811420' },
  { label: 'Footwear And Leather Goods Repair', key: '811430' },
  { label: 'Other Personal And Household Goods Repair And Maintenance', key: '811490' },
  { label: 'Barber Shops', key: '812111' },
  { label: 'Beauty Salons', key: '812112' },
  { label: 'Nail Salons', key: '812113' },
  { label: 'Diet And Weight Reducing Centers', key: '812191' },
  { label: 'Other Personal Care Services', key: '812199' },
  { label: 'Funeral Homes And Funeral Services', key: '812210' },
  { label: 'Cemeteries And Crematories', key: '812220' },
  { label: 'Coin Operated Laundries And Drycleaners', key: '812310' },
  { label: 'Drycleaning And Laundry Services Except Coin Operated', key: '812320' },
  { label: 'Linen Supply', key: '812331' },
  { label: 'Industrial Launderers', key: '812332' },
  { label: 'Pet Care Except Veterinary Services', key: '812910' },
  { label: 'Photofinishing Laboratories Except One Hour', key: '812921' },
  { label: 'One Hour Photofinishing', key: '812922' },
  { label: 'Parking Lots And Garages', key: '812930' },
  { label: 'All Other Personal Services', key: '812990' },
  { label: 'Religious Organizations', key: '813110' },
  { label: 'Grantmaking Foundations', key: '813211' },
  { label: 'Voluntary Health Organizations', key: '813212' },
  { label: 'Other Grantmaking And Giving Services', key: '813219' },
  { label: 'Human Rights Organizations', key: '813311' },
  { label: 'Environment Conservation And Wildlife Organizations', key: '813312' },
  { label: 'Other Social Advocacy Organizations', key: '813319' },
  { label: 'Civic And Social Organizations', key: '813410' },
  { label: 'Business Associations', key: '813910' },
  { label: 'Professional Organizations', key: '813920' },
  { label: 'Labor Unions And Similar Labor Organizations', key: '813930' },
  { label: 'Political Organizations', key: '813940' },
  { label: 'Other Similar Organizations Except Business Professional Labor And Political Organizations', key: '813990' },
  { label: 'Private Households', key: '814110' },
  { label: 'Executive Offices', key: '921110' },
  { label: 'Legislative Bodies', key: '921120' },
  { label: 'Public Finance Activities', key: '921130' },
  { label: 'Executive And Legislative Offices Combined', key: '921140' },
  { label: 'American Indian And Alaska Native Tribal Governments', key: '921150' },
  { label: 'Other General Government Support', key: '921190' },
  { label: 'Courts', key: '922110' },
  { label: 'Police Protection', key: '922120' },
  { label: 'Legal Counsel And Prosecution', key: '922130' },
  { label: 'Correctional Institutions', key: '922140' },
  { label: 'Parole Offices And Probation Offices', key: '922150' },
  { label: 'Fire Protection', key: '922160' },
  { label: 'Other Justice Public Order And Safety Activities', key: '922190' },
  { label: 'Administration Of Education Programs', key: '923110' },
  { label: 'Administration Of Public Health Programs', key: '923120' },
  {
    label: 'Administration Of Human Resource Programs Except Education Public Health And Veterans Affairs Programs',
    key: '923130',
  },
  { label: 'Administration Of Veterans Affairs', key: '923140' },
  { label: 'Administration Of Air And Water Resource And Solid Waste Management Programs', key: '924110' },
  { label: 'Administration Of Conservation Programs', key: '924120' },
  { label: 'Administration Of Housing Programs', key: '925110' },
  { label: 'Administration Of Urban Planning And Community And Rural Development', key: '925120' },
  { label: 'Administration Of General Economic Programs', key: '926110' },
  { label: 'Regulation And Administration Of Transportation Programs', key: '926120' },
  { label: 'Regulation And Administration Of Communications Electric Gas And Other Utilities', key: '926130' },
  { label: 'Regulation Of Agricultural Marketing And Commodities', key: '926140' },
  { label: 'Regulation Licensing And Inspection Of Miscellaneous Commercial Sectors', key: '926150' },
  { label: 'Space Research And Technology', key: '927110' },
  { label: 'National Security', key: '928110' },
  { label: 'International Affairs', key: '928120' },
  { label: 'Unclassified', key: '999999' },
];
