import { getIn, useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormField } from 'components/form/formField/formField';
import { SearchableSelect } from 'components/inputs/select/searchableSelect';
import { SelectItem } from 'components/inputs/select/select';

import { FORMIK_INITIAL_VALUES, LABELS } from '../../../../keys';
import { BridgeSourceOfFunds } from '../../../../types';
import replaceLabelsUnderscore from '../../../../utils/replaceLabelsUnderscore';
import { Container, Label } from './styles';

interface Props {
  // path to the field in the formik values, must point to a field of an array type: BridgeSourceOfFunds
  fieldPath: string;
  //  for errors coming from backend
  error?: Record<string, string>;
}

const SOURCE_OF_FUNDS: SelectItem[] = Object.entries(BridgeSourceOfFunds).map(([key, value]) => ({
  key: value,
  label: key,
}));

const formattedSourceOfFunds = replaceLabelsUnderscore(SOURCE_OF_FUNDS);

const SourceOfFunds = ({ fieldPath, error }: Props) => {
  const { t } = useTranslation();
  const { values, touched, errors, setFieldValue } = useFormikContext<typeof FORMIK_INITIAL_VALUES>();

  const sourceOfFunds = `${fieldPath}`;
  const sourceOfFundsError = getIn(errors, fieldPath);
  const sourceOfFundsTouched = getIn(touched, fieldPath);

  const sourceOfFundsInformationValue = getIn(values, fieldPath) as BridgeSourceOfFunds;

  return (
    <Container>
      <Label>{t(LABELS.SECTIONS.SOURCE_OF_FUNDS.TITLE)}</Label>

      <FormField error={error?.[fieldPath]}>
        {/* showing BE errors at the top, move it? */}
        {null}
      </FormField>

      <FormField error={sourceOfFundsError && sourceOfFundsTouched ? sourceOfFundsError : undefined}>
        <SearchableSelect<SelectItem, false>
          fullWidth
          value={formattedSourceOfFunds.find((item) => item.key === sourceOfFundsInformationValue)}
          items={formattedSourceOfFunds}
          placeholder={t(LABELS.SECTIONS.SOURCE_OF_FUNDS.PLACEHOLDER)}
          onChange={(selectItem) => {
            setFieldValue(sourceOfFunds, selectItem?.key);
          }}
          getItemLabel={(item) => item.label}
          multiple={false}
          closeDropdownOnSelect
          error={error?.[fieldPath] || (sourceOfFundsError && sourceOfFundsTouched) ? sourceOfFundsError : undefined}
        />
      </FormField>
    </Container>
  );
};

export default SourceOfFunds;
