import { SelectItem } from 'components/inputs/select/select';

export const subdivisions: Record<string, SelectItem[]> = {
  AFG: [
    {
      label: 'Balkh',
      key: 'BAL',
    },
    {
      label: 'Bamian',
      key: 'BAM',
    },
    {
      label: 'Badghis',
      key: 'BDG',
    },
    {
      label: 'Badakhshan',
      key: 'BDS',
    },
    {
      label: 'Baghlan',
      key: 'BGL',
    },
    {
      label: 'Daykondi',
      key: 'DAY',
    },
    {
      label: 'Farah',
      key: 'FRA',
    },
    {
      label: 'Faryab',
      key: 'FYB',
    },
    {
      label: 'Ghazni',
      key: 'GHA',
    },
    {
      label: 'Ghowr',
      key: 'GHO',
    },
    {
      label: 'Helmand',
      key: 'HEL',
    },
    {
      label: 'Herat',
      key: 'HER',
    },
    {
      label: 'Jowzjan',
      key: 'JOW',
    },
    {
      label: 'Kabul [Kabol]',
      key: 'KAB',
    },
    {
      label: 'Kandahar',
      key: 'KAN',
    },
    {
      label: 'Kapisa',
      key: 'KAP',
    },
    {
      label: 'Kondoz [Kunduz]',
      key: 'KDZ',
    },
    {
      label: 'Khowst',
      key: 'KHO',
    },
    {
      label: 'Konar [Kunar]',
      key: 'KNR',
    },
    {
      label: 'Laghman',
      key: 'LAG',
    },
    {
      label: 'لوګر ولايت',
      key: 'LOG',
    },
    {
      label: 'Nangrahar [Nangarhar]',
      key: 'NAN',
    },
    {
      label: 'Nimruz',
      key: 'NIM',
    },
    {
      label: 'Nurestan',
      key: 'NUR',
    },
    {
      label: 'Panjshir',
      key: 'PAN',
    },
    {
      label: 'Parwan',
      key: 'PAR',
    },
    {
      label: 'Paktia',
      key: 'PIA',
    },
    {
      label: 'Paktika',
      key: 'PKA',
    },
    {
      label: 'Samangan',
      key: 'SAM',
    },
    {
      label: 'Sar-e Pol',
      key: 'SAR',
    },
    {
      label: 'Takhar',
      key: 'TAK',
    },
    {
      label: 'روزګان ولايت',
      key: 'URU',
    },
    {
      label: 'Wardak [Wardag]',
      key: 'WAR',
    },
    {
      label: 'Zabol [Zabul]',
      key: 'ZAB',
    },
  ],
  ALA: [],
  ALB: [
    {
      label: 'Berat',
      key: '01',
    },
    {
      label: 'Durrës',
      key: '02',
    },
    {
      label: 'Elbasan',
      key: '03',
    },
    {
      label: 'Fier',
      key: '04',
    },
    {
      label: 'Gjirokastër',
      key: '05',
    },
    {
      label: 'Korçë',
      key: '06',
    },
    {
      label: 'Kukës',
      key: '07',
    },
    {
      label: 'Lezhë',
      key: '08',
    },
    {
      label: 'Dibër',
      key: '09',
    },
    {
      label: 'Shkodër',
      key: '10',
    },
    {
      label: 'Tiranë',
      key: '11',
    },
    {
      label: 'Vlorë',
      key: '12',
    },
  ],
  DZA: [
    {
      label: 'Adrar',
      key: '01',
    },
    {
      label: 'Chlef',
      key: '02',
    },
    {
      label: 'Laghouat',
      key: '03',
    },
    {
      label: 'Oum el Bouaghi',
      key: '04',
    },
    {
      label: 'Batna',
      key: '05',
    },
    {
      label: 'Béjaïa',
      key: '06',
    },
    {
      label: 'Biskra',
      key: '07',
    },
    {
      label: 'Béchar',
      key: '08',
    },
    {
      label: 'Blida',
      key: '09',
    },
    {
      label: 'Bouira',
      key: '10',
    },
    {
      label: 'Tamanghasset',
      key: '11',
    },
    {
      label: 'Tébessa',
      key: '12',
    },
    {
      label: 'Tlemcen',
      key: '13',
    },
    {
      label: 'Tiaret',
      key: '14',
    },
    {
      label: 'Tizi Ouzou',
      key: '15',
    },
    {
      label: 'Alger',
      key: '16',
    },
    {
      label: 'Djelfa',
      key: '17',
    },
    {
      label: 'Jijel',
      key: '18',
    },
    {
      label: 'Sétif',
      key: '19',
    },
    {
      label: 'Saïda',
      key: '20',
    },
    {
      label: 'Skikda',
      key: '21',
    },
    {
      label: 'Sidi Bel Abbès',
      key: '22',
    },
    {
      label: 'Annaba',
      key: '23',
    },
    {
      label: 'Guelma',
      key: '24',
    },
    {
      label: 'Constantine',
      key: '25',
    },
    {
      label: 'Médéa',
      key: '26',
    },
    {
      label: 'Mostaganem',
      key: '27',
    },
    {
      label: 'Msila',
      key: '28',
    },
    {
      label: 'Mascara',
      key: '29',
    },
    {
      label: 'Ouargla',
      key: '30',
    },
    {
      label: 'Oran',
      key: '31',
    },
    {
      label: 'El Bayadh',
      key: '32',
    },
    {
      label: 'Illizi',
      key: '33',
    },
    {
      label: 'Bordj Bou Arréridj',
      key: '34',
    },
    {
      label: 'Boumerdès',
      key: '35',
    },
    {
      label: 'El Tarf',
      key: '36',
    },
    {
      label: 'Tindouf',
      key: '37',
    },
    {
      label: 'Tissemsilt',
      key: '38',
    },
    {
      label: 'El Oued',
      key: '39',
    },
    {
      label: 'Khenchela',
      key: '40',
    },
    {
      label: 'Souk Ahras',
      key: '41',
    },
    {
      label: 'Tipaza',
      key: '42',
    },
    {
      label: 'Mila',
      key: '43',
    },
    {
      label: 'Aïn Defla',
      key: '44',
    },
    {
      label: 'Naama',
      key: '45',
    },
    {
      label: 'Aïn Témouchent',
      key: '46',
    },
    {
      label: 'Ghardaïa',
      key: '47',
    },
    {
      label: 'Relizane',
      key: '48',
    },
  ],
  ASM: [],
  AND: [
    {
      label: 'Canillo',
      key: '02',
    },
    {
      label: 'Encamp',
      key: '03',
    },
    {
      label: 'La Massana',
      key: '04',
    },
    {
      label: 'Ordino',
      key: '05',
    },
    {
      label: 'Sant Julià de Lòria',
      key: '06',
    },
    {
      label: 'Andorra la Vella',
      key: '07',
    },
    {
      label: 'Escaldes-Engordany',
      key: '08',
    },
  ],
  AGO: [
    {
      label: 'Bengo',
      key: 'BGO',
    },
    {
      label: 'Benguela',
      key: 'BGU',
    },
    {
      label: 'Bié',
      key: 'BIE',
    },
    {
      label: 'Cabinda',
      key: 'CAB',
    },
    {
      label: 'Cuando-Cubango',
      key: 'CCU',
    },
    {
      label: 'Cunene',
      key: 'CNN',
    },
    {
      label: 'Cuanza Norte',
      key: 'CNO',
    },
    {
      label: 'Cuanza Sul',
      key: 'CUS',
    },
    {
      label: 'Huambo',
      key: 'HUA',
    },
    {
      label: 'Huíla',
      key: 'HUI',
    },
    {
      label: 'Lunda Norte',
      key: 'LNO',
    },
    {
      label: 'Lunda Sul',
      key: 'LSU',
    },
    {
      label: 'Luanda',
      key: 'LUA',
    },
    {
      label: 'Malange',
      key: 'MAL',
    },
    {
      label: 'Moxico',
      key: 'MOX',
    },
    {
      label: 'Namibe',
      key: 'NAM',
    },
    {
      label: 'Uíge',
      key: 'UIG',
    },
    {
      label: 'Zaire',
      key: 'ZAI',
    },
  ],
  AIA: [],
  ATA: [],
  ATG: [
    {
      label: 'Saint George',
      key: '03',
    },
    {
      label: 'Saint John’s',
      key: '04',
    },
    {
      label: 'Saint Mary',
      key: '05',
    },
    {
      label: 'Saint Paul',
      key: '06',
    },
    {
      label: 'Saint Peter',
      key: '07',
    },
    {
      label: 'Saint Philip',
      key: '08',
    },
    {
      label: 'Barbuda',
      key: '10',
    },
    {
      label: 'Redonda',
      key: '11',
    },
  ],
  ARG: [
    {
      label: 'Salta',
      key: 'A',
    },
    {
      label: 'Buenos Aires',
      key: 'B',
    },
    {
      label: 'Capital federal',
      key: 'C',
    },
    {
      label: 'San Luis',
      key: 'D',
    },
    {
      label: 'Entre Ríos',
      key: 'E',
    },
    {
      label: 'La Rioja',
      key: 'F',
    },
    {
      label: 'Santiago del Estero',
      key: 'G',
    },
    {
      label: 'Chaco',
      key: 'H',
    },
    {
      label: 'San Juan',
      key: 'J',
    },
    {
      label: 'Catamarca',
      key: 'K',
    },
    {
      label: 'La Pampa',
      key: 'L',
    },
    {
      label: 'Mendoza',
      key: 'M',
    },
    {
      label: 'Misiones',
      key: 'N',
    },
    {
      label: 'Formosa',
      key: 'P',
    },
    {
      label: 'Neuquén',
      key: 'Q',
    },
    {
      label: 'Río Negro',
      key: 'R',
    },
    {
      label: 'Santa Fe',
      key: 'S',
    },
    {
      label: 'Tucumán',
      key: 'T',
    },
    {
      label: 'Chubut',
      key: 'U',
    },
    {
      label: 'Tierra del Fuego',
      key: 'V',
    },
    {
      label: 'Corrientes',
      key: 'W',
    },
    {
      label: 'Córdoba',
      key: 'X',
    },
    {
      label: 'Jujuy',
      key: 'Y',
    },
    {
      label: 'Santa Cruz',
      key: 'Z',
    },
  ],
  ARM: [
    {
      label: 'Aragacotn',
      key: 'AG',
    },
    {
      label: 'Ararat',
      key: 'AR',
    },
    {
      label: 'Armavir',
      key: 'AV',
    },
    {
      label: 'Erevan',
      key: 'ER',
    },
    {
      label: "Gegark'unik'",
      key: 'GR',
    },
    {
      label: "Kotayk'",
      key: 'KT',
    },
    {
      label: 'Lory',
      key: 'LO',
    },
    {
      label: 'Širak',
      key: 'SH',
    },
    {
      label: "Syunik'",
      key: 'SU',
    },
    {
      label: 'Tavuš',
      key: 'TV',
    },
    {
      label: 'Vayoc Jor',
      key: 'VD',
    },
  ],
  ABW: [],
  AUS: [
    {
      label: 'Australian Capital Territory',
      key: 'ACT',
    },
    {
      label: 'New South Wales',
      key: 'NSW',
    },
    {
      label: 'Northern Territory',
      key: 'NT',
    },
    {
      label: 'Queensland',
      key: 'QLD',
    },
    {
      label: 'South Australia',
      key: 'SA',
    },
    {
      label: 'Tasmania',
      key: 'TAS',
    },
    {
      label: 'Victoria',
      key: 'VIC',
    },
    {
      label: 'Western Australia',
      key: 'WA',
    },
  ],
  AUT: [
    {
      label: 'Burgenland',
      key: '1',
    },
    {
      label: 'Kärnten',
      key: '2',
    },
    {
      label: 'Niederösterreich',
      key: '3',
    },
    {
      label: 'Oberösterreich',
      key: '4',
    },
    {
      label: 'Salzburg',
      key: '5',
    },
    {
      label: 'Steiermark',
      key: '6',
    },
    {
      label: 'Tirol',
      key: '7',
    },
    {
      label: 'Vorarlberg',
      key: '8',
    },
    {
      label: 'Wien',
      key: '9',
    },
  ],
  AZE: [
    {
      label: 'Abseron',
      key: 'ABS',
    },
    {
      label: 'Agstafa',
      key: 'AGA',
    },
    {
      label: 'Agcabädi',
      key: 'AGC',
    },
    {
      label: 'Agdam',
      key: 'AGM',
    },
    {
      label: 'Agdas',
      key: 'AGS',
    },
    {
      label: 'Agsu',
      key: 'AGU',
    },
    {
      label: 'Astara',
      key: 'AST',
    },
    {
      label: 'Baki',
      key: 'BA',
    },
    {
      label: 'Babäk',
      key: 'BAB',
    },
    {
      label: 'Balakän',
      key: 'BAL',
    },
    {
      label: 'Bärdä',
      key: 'BAR',
    },
    {
      label: 'Beyläqan',
      key: 'BEY',
    },
    {
      label: 'Biläsuvar',
      key: 'BIL',
    },
    {
      label: 'Cäbrayil',
      key: 'CAB',
    },
    {
      label: 'Cälilabab',
      key: 'CAL',
    },
    {
      label: 'Culfa',
      key: 'CUL',
    },
    {
      label: 'Daskäsän',
      key: 'DAS',
    },
    {
      label: 'Füzuli',
      key: 'FUZ',
    },
    {
      label: 'Gäncä',
      key: 'GA',
    },
    {
      label: 'Gädäbäy',
      key: 'GAD',
    },
    {
      label: 'Goranboy',
      key: 'GOR',
    },
    {
      label: 'Göyçay',
      key: 'GOY',
    },
    {
      label: 'Göygöl',
      key: 'GYG',
    },
    {
      label: 'Haciqabul',
      key: 'HAC',
    },
    {
      label: 'Imisli',
      key: 'IMI',
    },
    {
      label: 'Ismayilli',
      key: 'ISM',
    },
    {
      label: 'Kälbäcär',
      key: 'KAL',
    },
    {
      label: 'Kəngərli',
      key: 'KAN',
    },
    {
      label: 'Kürdämir',
      key: 'KUR',
    },
    {
      label: 'Länkäran City',
      key: 'LA',
    },
    {
      label: 'Laçin',
      key: 'LAC',
    },
    {
      label: 'Länkäran',
      key: 'LAN',
    },
    {
      label: 'Lerik',
      key: 'LER',
    },
    {
      label: 'Masalli',
      key: 'MAS',
    },
    {
      label: 'Mingäçevir',
      key: 'MI',
    },
    {
      label: 'Naftalan',
      key: 'NA',
    },
    {
      label: 'Neftçala',
      key: 'NEF',
    },
    {
      label: 'Naxçıvan',
      key: 'NV',
    },
    {
      label: 'Naxçivan',
      key: 'NX',
    },
    {
      label: 'Oguz',
      key: 'OGU',
    },
    {
      label: 'Ordubad',
      key: 'ORD',
    },
    {
      label: 'Qäbälä',
      key: 'QAB',
    },
    {
      label: 'Qax',
      key: 'QAX',
    },
    {
      label: 'Qazax',
      key: 'QAZ',
    },
    {
      label: 'Quba',
      key: 'QBA',
    },
    {
      label: 'Qubadli',
      key: 'QBI',
    },
    {
      label: 'Qobustan',
      key: 'QOB',
    },
    {
      label: 'Qusar',
      key: 'QUS',
    },
    {
      label: 'Säki City',
      key: 'SA',
    },
    {
      label: 'Sabirabad',
      key: 'SAB',
    },
    {
      label: 'Sädäräk',
      key: 'SAD',
    },
    {
      label: 'Sahbuz',
      key: 'SAH',
    },
    {
      label: 'Säki',
      key: 'SAK',
    },
    {
      label: 'Salyan',
      key: 'SAL',
    },
    {
      label: 'Särur',
      key: 'SAR',
    },
    {
      label: 'Saatli',
      key: 'SAT',
    },
    {
      label: 'Şabran (rayon)',
      key: 'SBN',
    },
    {
      label: 'Siyäzän',
      key: 'SIY',
    },
    {
      label: 'Sämkir',
      key: 'SKR',
    },
    {
      label: 'Sumqayit',
      key: 'SM',
    },
    {
      label: 'Samaxi',
      key: 'SMI',
    },
    {
      label: 'Samux',
      key: 'SMX',
    },
    {
      label: 'Şirvan',
      key: 'SR',
    },
    {
      label: 'Susa',
      key: 'SUS',
    },
    {
      label: 'Tärtär',
      key: 'TAR',
    },
    {
      label: 'Tovuz',
      key: 'TOV',
    },
    {
      label: 'Ucar',
      key: 'UCA',
    },
    {
      label: 'Xankändi',
      key: 'XA',
    },
    {
      label: 'Xaçmaz',
      key: 'XAC',
    },
    {
      label: 'Xocali',
      key: 'XCI',
    },
    {
      label: 'Xizi',
      key: 'XIZ',
    },
    {
      label: 'Xocavänd',
      key: 'XVD',
    },
    {
      label: 'Yardimli',
      key: 'YAR',
    },
    {
      label: 'Yevlax City',
      key: 'YE',
    },
    {
      label: 'Yevlax',
      key: 'YEV',
    },
    {
      label: 'Zängilan',
      key: 'ZAN',
    },
    {
      label: 'Zaqatala',
      key: 'ZAQ',
    },
    {
      label: 'Zärdab',
      key: 'ZAR',
    },
  ],
  BHS: [
    {
      label: 'Acklins',
      key: 'AK',
    },
    {
      label: 'Bimini',
      key: 'BI',
    },
    {
      label: 'Black Point',
      key: 'BP',
    },
    {
      label: 'Berry Islands',
      key: 'BY',
    },
    {
      label: 'Central Eleuthera',
      key: 'CE',
    },
    {
      label: 'Cat Island',
      key: 'CI',
    },
    {
      label: 'Crooked Island',
      key: 'CK',
    },
    {
      label: 'Central Abaco',
      key: 'CO',
    },
    {
      label: 'Central Andros',
      key: 'CS',
    },
    {
      label: 'East Grand Bahama',
      key: 'EG',
    },
    {
      label: 'Exuma',
      key: 'EX',
    },
    {
      label: 'Freeport',
      key: 'FP',
    },
    {
      label: 'Grand Cay',
      key: 'GC',
    },
    {
      label: 'Harbour Island',
      key: 'HI',
    },
    {
      label: 'Hope Town',
      key: 'HT',
    },
    {
      label: 'Inagua',
      key: 'IN',
    },
    {
      label: 'Long Island',
      key: 'LI',
    },
    {
      label: 'Mangrove Cay',
      key: 'MC',
    },
    {
      label: 'Mayaguana',
      key: 'MG',
    },
    {
      label: 'Moore’s Island',
      key: 'MI',
    },
    {
      label: 'North Eleuthera',
      key: 'NE',
    },
    {
      label: 'North Abaco',
      key: 'NO',
    },
    {
      label: 'New Providence',
      key: 'NP',
    },
    {
      label: 'North Andros',
      key: 'NS',
    },
    {
      label: 'Rum Cay',
      key: 'RC',
    },
    {
      label: 'Ragged Island',
      key: 'RI',
    },
    {
      label: 'South Andros',
      key: 'SA',
    },
    {
      label: 'South Eleuthera',
      key: 'SE',
    },
    {
      label: 'South Abaco',
      key: 'SO',
    },
    {
      label: 'San Salvador',
      key: 'SS',
    },
    {
      label: 'Spanish Wells',
      key: 'SW',
    },
    {
      label: 'West Grand Bahama',
      key: 'WG',
    },
  ],
  BHR: [
    {
      label: 'Al Manamah (Al ‘Asimah)',
      key: '13',
    },
    {
      label: 'Al Janubiyah',
      key: '14',
    },
    {
      label: 'Al Muharraq',
      key: '15',
    },
    {
      label: 'Ash Shamaliyah',
      key: '17',
    },
  ],
  BGD: [
    {
      label: 'Bandarban zila',
      key: '01',
    },
    {
      label: 'Barguna zila',
      key: '02',
    },
    {
      label: 'Bogra zila',
      key: '03',
    },
    {
      label: 'Brahmanbaria zila',
      key: '04',
    },
    {
      label: 'Bagerhat zila',
      key: '05',
    },
    {
      label: 'Barisal zila',
      key: '06',
    },
    {
      label: 'Bhola zila',
      key: '07',
    },
    {
      label: 'Comilla zila',
      key: '08',
    },
    {
      label: 'Chandpur zila',
      key: '09',
    },
    {
      label: 'Chittagong zila',
      key: '10',
    },
    {
      label: "Cox's Bazar zila",
      key: '11',
    },
    {
      label: 'Chuadanga zila',
      key: '12',
    },
    {
      label: 'Dhaka zila',
      key: '13',
    },
    {
      label: 'Dinajpur zila',
      key: '14',
    },
    {
      label: 'Faridpur zila',
      key: '15',
    },
    {
      label: 'Feni zila',
      key: '16',
    },
    {
      label: 'Gopalganj zila',
      key: '17',
    },
    {
      label: 'Gazipur zila',
      key: '18',
    },
    {
      label: 'Gaibandha zila',
      key: '19',
    },
    {
      label: 'Habiganj zila',
      key: '20',
    },
    {
      label: 'Jamalpur zila',
      key: '21',
    },
    {
      label: 'Jessore zila',
      key: '22',
    },
    {
      label: 'Jhenaidah zila',
      key: '23',
    },
    {
      label: 'Jaipurhat zila',
      key: '24',
    },
    {
      label: 'Jhalakati zila',
      key: '25',
    },
    {
      label: 'Kishoreganj zila',
      key: '26',
    },
    {
      label: 'Khulna zila',
      key: '27',
    },
    {
      label: 'Kurigram zila',
      key: '28',
    },
    {
      label: 'Khagrachari zila',
      key: '29',
    },
    {
      label: 'Kushtia zila',
      key: '30',
    },
    {
      label: 'Lakshmipur zila',
      key: '31',
    },
    {
      label: 'Lalmonirhat zila',
      key: '32',
    },
    {
      label: 'Manikganj zila',
      key: '33',
    },
    {
      label: 'Mymensingh zila',
      key: '34',
    },
    {
      label: 'Munshiganj zila',
      key: '35',
    },
    {
      label: 'Madaripur zila',
      key: '36',
    },
    {
      label: 'Magura zila',
      key: '37',
    },
    {
      label: 'Moulvibazar zila',
      key: '38',
    },
    {
      label: 'Meherpur zila',
      key: '39',
    },
    {
      label: 'Narayanganj zila',
      key: '40',
    },
    {
      label: 'Netrakona zila',
      key: '41',
    },
    {
      label: 'Narsingdi zila',
      key: '42',
    },
    {
      label: 'Narail zila',
      key: '43',
    },
    {
      label: 'Natore zila',
      key: '44',
    },
    {
      label: 'Nawabganj zila',
      key: '45',
    },
    {
      label: 'Nilphamari zila',
      key: '46',
    },
    {
      label: 'Noakhali zila',
      key: '47',
    },
    {
      label: 'Naogaon zila',
      key: '48',
    },
    {
      label: 'Pabna zila',
      key: '49',
    },
    {
      label: 'Pirojpur zila',
      key: '50',
    },
    {
      label: 'Patuakhali zila',
      key: '51',
    },
    {
      label: 'Panchagarh zila',
      key: '52',
    },
    {
      label: 'Rajbari zila',
      key: '53',
    },
    {
      label: 'Rajshahi zila',
      key: '54',
    },
    {
      label: 'Rangpur zila',
      key: '55',
    },
    {
      label: 'Rangamati zila',
      key: '56',
    },
    {
      label: 'Sherpur zila',
      key: '57',
    },
    {
      label: 'Satkhira zila',
      key: '58',
    },
    {
      label: 'Sirajganj zila',
      key: '59',
    },
    {
      label: 'Sylhet zila',
      key: '60',
    },
    {
      label: 'Sunamganj zila',
      key: '61',
    },
    {
      label: 'Shariatpur zila',
      key: '62',
    },
    {
      label: 'Tangail zila',
      key: '63',
    },
    {
      label: 'Thakurgaon zila',
      key: '64',
    },
    {
      label: 'বরিশাল বিভাগ',
      key: 'A',
    },
    {
      label: 'চট্টগ্রাম বিভাগ',
      key: 'B',
    },
    {
      label: 'ঢাকা বিভাগ',
      key: 'C',
    },
    {
      label: 'খুলনা বিভাগ',
      key: 'D',
    },
    {
      label: 'রাজশাহী বিভাগ',
      key: 'E',
    },
    {
      label: 'রংপুর বিভাগ',
      key: 'F',
    },
    {
      label: 'সিলেট বিভাগ',
      key: 'G',
    },
    {
      label: 'ময়মনসিংহ বিভাগ',
      key: 'H',
    },
  ],
  BRB: [
    {
      label: 'Christ Church',
      key: '01',
    },
    {
      label: 'Saint Andrew',
      key: '02',
    },
    {
      label: 'Saint George',
      key: '03',
    },
    {
      label: 'Saint James',
      key: '04',
    },
    {
      label: 'Saint John',
      key: '05',
    },
    {
      label: 'Saint Joseph',
      key: '06',
    },
    {
      label: 'Saint Lucy',
      key: '07',
    },
    {
      label: 'Saint Michael',
      key: '08',
    },
    {
      label: 'Saint Peter',
      key: '09',
    },
    {
      label: 'Saint Philip',
      key: '10',
    },
    {
      label: 'Saint Thomas',
      key: '11',
    },
  ],
  BLR: [
    {
      label: "Brestskaya voblasts' (be) Brestskaya oblast' (ru)",
      key: 'BR',
    },
    {
      label: 'Мінск',
      key: 'HM',
    },
    {
      label: "Homyel'skaya voblasts' (be) Gomel'skaya oblast' (ru)",
      key: 'HO',
    },
    {
      label: "Hrodzenskaya voblasts' (be) Grodnenskaya oblast' (ru)",
      key: 'HR',
    },
    {
      label: "Mahilyowskaya voblasts' (be) Mogilevskaya oblast' (ru)",
      key: 'MA',
    },
    {
      label: "Minskaya voblasts' (be) Minskaya oblast' (ru)",
      key: 'MI',
    },
    {
      label: "Vitsyebskaya voblasts' (be) Vitebskaya oblast' (ru)",
      key: 'VI',
    },
  ],
  BEL: [
    {
      label: 'Brussels',
      key: 'BRU',
    },
    {
      label: 'Antwerpen (nl)',
      key: 'VAN',
    },
    {
      label: 'Vlaams Brabant (nl)',
      key: 'VBR',
    },
    {
      label: 'Vlaams Gewest',
      key: 'VLG',
    },
    {
      label: 'Limburg (nl)',
      key: 'VLI',
    },
    {
      label: 'Oost-Vlaanderen (nl)',
      key: 'VOV',
    },
    {
      label: 'West-Vlaanderen (nl)',
      key: 'VWV',
    },
    {
      label: 'Wallonië',
      key: 'WAL',
    },
    {
      label: 'Brabant Wallon (fr)',
      key: 'WBR',
    },
    {
      label: 'Hainaut (fr)',
      key: 'WHT',
    },
    {
      label: 'Liège (fr)',
      key: 'WLG',
    },
    {
      label: 'Luxembourg (fr)',
      key: 'WLX',
    },
    {
      label: 'Namur (fr)',
      key: 'WNA',
    },
  ],
  BLZ: [
    {
      label: 'Belize',
      key: 'BZ',
    },
    {
      label: 'Cayo',
      key: 'CY',
    },
    {
      label: 'Corozal',
      key: 'CZL',
    },
    {
      label: 'Orange Walk',
      key: 'OW',
    },
    {
      label: 'Stann Creek',
      key: 'SC',
    },
    {
      label: 'Toledo',
      key: 'TOL',
    },
  ],
  BEN: [
    {
      label: 'Atakora',
      key: 'AK',
    },
    {
      label: 'Alibori',
      key: 'AL',
    },
    {
      label: 'Atlantique',
      key: 'AQ',
    },
    {
      label: 'Borgou',
      key: 'BO',
    },
    {
      label: 'Collines',
      key: 'CO',
    },
    {
      label: 'Donga',
      key: 'DO',
    },
    {
      label: 'Kouffo',
      key: 'KO',
    },
    {
      label: 'Littoral',
      key: 'LI',
    },
    {
      label: 'Mono',
      key: 'MO',
    },
    {
      label: 'Ouémé',
      key: 'OU',
    },
    {
      label: 'Plateau',
      key: 'PL',
    },
    {
      label: 'Zou',
      key: 'ZO',
    },
  ],
  BMU: [],
  BTN: [
    {
      label: 'Paro',
      key: '11',
    },
    {
      label: 'Chhukha',
      key: '12',
    },
    {
      label: 'Ha',
      key: '13',
    },
    {
      label: 'Samtse',
      key: '14',
    },
    {
      label: 'Thimphu',
      key: '15',
    },
    {
      label: 'Tsirang',
      key: '21',
    },
    {
      label: 'Dagana',
      key: '22',
    },
    {
      label: 'Punakha',
      key: '23',
    },
    {
      label: 'Wangdue Phodrang',
      key: '24',
    },
    {
      label: 'Sarpang',
      key: '31',
    },
    {
      label: 'Trongsa',
      key: '32',
    },
    {
      label: 'Bumthang',
      key: '33',
    },
    {
      label: 'Zhemgang',
      key: '34',
    },
    {
      label: 'Trashigang',
      key: '41',
    },
    {
      label: 'Monggar',
      key: '42',
    },
    {
      label: 'Pemagatshel',
      key: '43',
    },
    {
      label: 'Lhuentse',
      key: '44',
    },
    {
      label: 'Samdrup Jongkha',
      key: '45',
    },
    {
      label: 'Gasa',
      key: 'GA',
    },
    {
      label: 'Trashi Yangtse',
      key: 'TY',
    },
  ],
  BOL: [
    {
      label: 'El Beni',
      key: 'B',
    },
    {
      label: 'Cochabamba',
      key: 'C',
    },
    {
      label: 'Chuquisaca',
      key: 'H',
    },
    {
      label: 'La Paz',
      key: 'L',
    },
    {
      label: 'Pando',
      key: 'N',
    },
    {
      label: 'Oruro',
      key: 'O',
    },
    {
      label: 'Potosí',
      key: 'P',
    },
    {
      label: 'Santa Cruz',
      key: 'S',
    },
    {
      label: 'Tarija',
      key: 'T',
    },
  ],
  BES: [
    {
      label: 'Bonaire',
      key: 'BO',
    },
    {
      label: 'Saba',
      key: 'SA',
    },
    {
      label: 'Sint Eustatius',
      key: 'SE',
    },
  ],
  BIH: [
    {
      label: 'Federacija Bosna i Hercegovina',
      key: 'BIH',
    },
    {
      label: 'Brčko Distrikt',
      key: 'BRC',
    },
    {
      label: 'Republika Srpska',
      key: 'SRP',
    },
  ],
  BWA: [
    {
      label: 'Central',
      key: 'CE',
    },
    {
      label: 'Chobe',
      key: 'CH',
    },
    {
      label: 'Francistown',
      key: 'FR',
    },
    {
      label: 'Gaborone',
      key: 'GA',
    },
    {
      label: 'Ghanzi',
      key: 'GH',
    },
    {
      label: 'Jwaneng',
      key: 'JW',
    },
    {
      label: 'Kgalagadi',
      key: 'KG',
    },
    {
      label: 'Kgatleng',
      key: 'KL',
    },
    {
      label: 'Kweneng',
      key: 'KW',
    },
    {
      label: 'Lobatse',
      key: 'LO',
    },
    {
      label: 'North-East',
      key: 'NE',
    },
    {
      label: 'North-West',
      key: 'NW',
    },
    {
      label: 'South-East',
      key: 'SE',
    },
    {
      label: 'Southern',
      key: 'SO',
    },
    {
      label: 'Selibe Phikwe',
      key: 'SP',
    },
    {
      label: 'Sowa Town',
      key: 'ST',
    },
  ],
  BVT: [],
  BRA: [
    {
      label: 'Acre',
      key: 'AC',
    },
    {
      label: 'Alagoas',
      key: 'AL',
    },
    {
      label: 'Amazonas',
      key: 'AM',
    },
    {
      label: 'Amapá',
      key: 'AP',
    },
    {
      label: 'Bahia',
      key: 'BA',
    },
    {
      label: 'Ceará',
      key: 'CE',
    },
    {
      label: 'Distrito Federal',
      key: 'DF',
    },
    {
      label: 'Espírito Santo',
      key: 'ES',
    },
    {
      label: 'Goiás',
      key: 'GO',
    },
    {
      label: 'Maranhão',
      key: 'MA',
    },
    {
      label: 'Minas Gerais',
      key: 'MG',
    },
    {
      label: 'Mato Grosso do Sul',
      key: 'MS',
    },
    {
      label: 'Mato Grosso',
      key: 'MT',
    },
    {
      label: 'Pará',
      key: 'PA',
    },
    {
      label: 'Paraíba',
      key: 'PB',
    },
    {
      label: 'Pernambuco',
      key: 'PE',
    },
    {
      label: 'Piauí',
      key: 'PI',
    },
    {
      label: 'Paraná',
      key: 'PR',
    },
    {
      label: 'Rio de Janeiro',
      key: 'RJ',
    },
    {
      label: 'Rio Grande do Norte',
      key: 'RN',
    },
    {
      label: 'Rondônia',
      key: 'RO',
    },
    {
      label: 'Roraima',
      key: 'RR',
    },
    {
      label: 'Rio Grande do Sul',
      key: 'RS',
    },
    {
      label: 'Santa Catarina',
      key: 'SC',
    },
    {
      label: 'Sergipe',
      key: 'SE',
    },
    {
      label: 'São Paulo',
      key: 'SP',
    },
    {
      label: 'Tocantins',
      key: 'TO',
    },
  ],
  IOT: [],
  BRN: [
    {
      label: 'Belait',
      key: 'BE',
    },
    {
      label: 'Brunei-Muara',
      key: 'BM',
    },
    {
      label: 'Temburong',
      key: 'TE',
    },
    {
      label: 'Tutong',
      key: 'TU',
    },
  ],
  BGR: [
    {
      label: 'Blagoevgrad',
      key: '01',
    },
    {
      label: 'Burgas',
      key: '02',
    },
    {
      label: 'Varna',
      key: '03',
    },
    {
      label: 'Veliko Tarnovo',
      key: '04',
    },
    {
      label: 'Vidin',
      key: '05',
    },
    {
      label: 'Vratsa',
      key: '06',
    },
    {
      label: 'Gabrovo',
      key: '07',
    },
    {
      label: 'Dobrich',
      key: '08',
    },
    {
      label: 'Kardzhali',
      key: '09',
    },
    {
      label: 'Kjustendil',
      key: '10',
    },
    {
      label: 'Lovech',
      key: '11',
    },
    {
      label: 'Montana',
      key: '12',
    },
    {
      label: 'Pazardzhik',
      key: '13',
    },
    {
      label: 'Pernik',
      key: '14',
    },
    {
      label: 'Pleven',
      key: '15',
    },
    {
      label: 'Plovdiv',
      key: '16',
    },
    {
      label: 'Razgrad',
      key: '17',
    },
    {
      label: 'Ruse',
      key: '18',
    },
    {
      label: 'Silistra',
      key: '19',
    },
    {
      label: 'Sliven',
      key: '20',
    },
    {
      label: 'Smolyan',
      key: '21',
    },
    {
      label: 'Sofia-Grad',
      key: '22',
    },
    {
      label: 'Sofia',
      key: '23',
    },
    {
      label: 'Stara Zagora',
      key: '24',
    },
    {
      label: 'Targovishte',
      key: '25',
    },
    {
      label: 'Haskovo',
      key: '26',
    },
    {
      label: 'Šumen',
      key: '27',
    },
    {
      label: 'Yambol',
      key: '28',
    },
  ],
  BFA: [
    {
      label: 'Boucle du Mouhoun',
      key: '01',
    },
    {
      label: 'Cascades',
      key: '02',
    },
    {
      label: 'Centre',
      key: '03',
    },
    {
      label: 'Centre-Est',
      key: '04',
    },
    {
      label: 'Centre-Nord',
      key: '05',
    },
    {
      label: 'Centre-Ouest',
      key: '06',
    },
    {
      label: 'Centre-Sud',
      key: '07',
    },
    {
      label: 'Est',
      key: '08',
    },
    {
      label: 'Hauts-Bassins',
      key: '09',
    },
    {
      label: 'Nord',
      key: '10',
    },
    {
      label: 'Plateau-Central',
      key: '11',
    },
    {
      label: 'Sahel',
      key: '12',
    },
    {
      label: 'Sud-Ouest',
      key: '13',
    },
    {
      label: 'Balé',
      key: 'BAL',
    },
    {
      label: 'Bam',
      key: 'BAM',
    },
    {
      label: 'Banwa',
      key: 'BAN',
    },
    {
      label: 'Bazèga',
      key: 'BAZ',
    },
    {
      label: 'Bougouriba',
      key: 'BGR',
    },
    {
      label: 'Boulgou',
      key: 'BLG',
    },
    {
      label: 'Boulkiemdé',
      key: 'BLK',
    },
    {
      label: 'Comoé',
      key: 'COM',
    },
    {
      label: 'Ganzourgou',
      key: 'GAN',
    },
    {
      label: 'Gnagna',
      key: 'GNA',
    },
    {
      label: 'Gourma',
      key: 'GOU',
    },
    {
      label: 'Houet',
      key: 'HOU',
    },
    {
      label: 'Ioba',
      key: 'IOB',
    },
    {
      label: 'Kadiogo',
      key: 'KAD',
    },
    {
      label: 'Kénédougou',
      key: 'KEN',
    },
    {
      label: 'Komondjari',
      key: 'KMD',
    },
    {
      label: 'Kompienga',
      key: 'KMP',
    },
    {
      label: 'Koulpélogo',
      key: 'KOP',
    },
    {
      label: 'Kossi',
      key: 'KOS',
    },
    {
      label: 'Kouritenga',
      key: 'KOT',
    },
    {
      label: 'Kourwéogo',
      key: 'KOW',
    },
    {
      label: 'Léraba',
      key: 'LER',
    },
    {
      label: 'Loroum',
      key: 'LOR',
    },
    {
      label: 'Mouhoun',
      key: 'MOU',
    },
    {
      label: 'labelntenga',
      key: 'NAM',
    },
    {
      label: 'Nahouri',
      key: 'NAO',
    },
    {
      label: 'Nayala',
      key: 'NAY',
    },
    {
      label: 'Noumbiel',
      key: 'NOU',
    },
    {
      label: 'Oubritenga',
      key: 'OUB',
    },
    {
      label: 'Oudalan',
      key: 'OUD',
    },
    {
      label: 'Passoré',
      key: 'PAS',
    },
    {
      label: 'Poni',
      key: 'PON',
    },
    {
      label: 'Séno',
      key: 'SEN',
    },
    {
      label: 'Sissili',
      key: 'SIS',
    },
    {
      label: 'Sanmatenga',
      key: 'SMT',
    },
    {
      label: 'Sanguié',
      key: 'SNG',
    },
    {
      label: 'Soum',
      key: 'SOM',
    },
    {
      label: 'Sourou',
      key: 'SOR',
    },
    {
      label: 'Tapoa',
      key: 'TAP',
    },
    {
      label: 'Tui',
      key: 'TUI',
    },
    {
      label: 'Yagha',
      key: 'YAG',
    },
    {
      label: 'Yatenga',
      key: 'YAT',
    },
    {
      label: 'Ziro',
      key: 'ZIR',
    },
    {
      label: 'Zondoma',
      key: 'ZON',
    },
    {
      label: 'Zoundwéogo',
      key: 'ZOU',
    },
  ],
  BDI: [
    {
      label: 'Bubanza',
      key: 'BB',
    },
    {
      label: 'Bujumbura',
      key: 'BJ',
    },
    {
      label: 'province de Bujumbura rural',
      key: 'BL',
    },
    {
      label: 'province de Bujumbura Mairie',
      key: 'BM',
    },
    {
      label: 'Bururi',
      key: 'BR',
    },
    {
      label: 'Cankuzo',
      key: 'CA',
    },
    {
      label: 'Cibitoke',
      key: 'CI',
    },
    {
      label: 'Gitega',
      key: 'GI',
    },
    {
      label: 'Kirundo',
      key: 'KI',
    },
    {
      label: 'Karuzi',
      key: 'KR',
    },
    {
      label: 'Kayanza',
      key: 'KY',
    },
    {
      label: 'Makamba',
      key: 'MA',
    },
    {
      label: 'Muramvya',
      key: 'MU',
    },
    {
      label: 'Mwaro',
      key: 'MW',
    },
    {
      label: 'Muyinga',
      key: 'MY',
    },
    {
      label: 'Ngozi',
      key: 'NG',
    },
    {
      label: 'Province de Rumonge',
      key: 'RM',
    },
    {
      label: 'Rutana',
      key: 'RT',
    },
    {
      label: 'Ruyigi',
      key: 'RY',
    },
  ],
  CPV: [
    {
      label: 'Ilhas de Barlavento',
      key: 'B',
    },
    {
      label: 'Brava',
      key: 'BR',
    },
    {
      label: 'Boa Vista',
      key: 'BV',
    },
    {
      label: 'Santa Catarina',
      key: 'CA',
    },
    {
      label: 'Santa Catarina do Fogo',
      key: 'CF',
    },
    {
      label: 'Santa Cruz',
      key: 'CR',
    },
    {
      label: 'Calheta de São Miguel',
      key: 'CS',
    },
    {
      label: 'Maio',
      key: 'MA',
    },
    {
      label: 'Mosteiros',
      key: 'MO',
    },
    {
      label: 'Paúl',
      key: 'PA',
    },
    {
      label: 'Porto Novo',
      key: 'PN',
    },
    {
      label: 'Praia',
      key: 'PR',
    },
    {
      label: 'Ribeira Brava',
      key: 'RB',
    },
    {
      label: 'Ribeira Grande',
      key: 'RG',
    },
    {
      label: 'Ribeira Grande de Santiago',
      key: 'RS',
    },
    {
      label: 'Ilhas de Sotavento',
      key: 'S',
    },
    {
      label: 'São Domingos',
      key: 'SD',
    },
    {
      label: 'São Filipe',
      key: 'SF',
    },
    {
      label: 'Sal',
      key: 'SL',
    },
    {
      label: 'São Miguel',
      key: 'SM',
    },
    {
      label: 'São Nicolau',
      key: 'SN',
    },
    {
      label: 'São Lourenço dos Órgãos',
      key: 'SO',
    },
    {
      label: 'São Salvador do Mundo',
      key: 'SS',
    },
    {
      label: 'São Vicente',
      key: 'SV',
    },
    {
      label: 'Tarrafal',
      key: 'TA',
    },
    {
      label: 'Tarrafal de São Nicolau',
      key: 'TS',
    },
  ],
  KHM: [
    {
      label: 'Banteay Mean Chey [Bântéay Méanchey]',
      key: '1',
    },
    {
      label: 'Kracheh [Krâchéh]',
      key: '10',
    },
    {
      label: 'Mondol Kiri [Môndól Kiri]',
      key: '11',
    },
    {
      label: 'Phnom Penh [Phnum Pénh]',
      key: '12',
    },
    {
      label: 'Preah Vihear [Preah Vihéar]',
      key: '13',
    },
    {
      label: 'Prey Veaeng [Prey Vêng]',
      key: '14',
    },
    {
      label: 'Pousaat [Pouthisat]',
      key: '15',
    },
    {
      label: 'Rotanak Kiri [Rôtânôkiri]',
      key: '16',
    },
    {
      label: 'Siem Reab [Siemréab]',
      key: '17',
    },
    {
      label: 'Krong Preah Sihanouk [Krong Preah Sihanouk]',
      key: '18',
    },
    {
      label: 'Stueng Traeng',
      key: '19',
    },
    {
      label: 'Baat Dambang [Batdâmbâng]',
      key: '2',
    },
    {
      label: 'Svaay Rieng [Svay Rieng]',
      key: '20',
    },
    {
      label: 'Taakaev [Takêv]',
      key: '21',
    },
    {
      label: 'Otdar Mean Chey [Otdâr Méanchey] ',
      key: '22',
    },
    {
      label: 'Krong Kep [Krong Kêb]',
      key: '23',
    },
    {
      label: 'Krong Pailin [Krong Pailin]',
      key: '24',
    },
    {
      label: 'Tbong Khmum',
      key: '25',
    },
    {
      label: 'Kampong Chaam [Kâmpóng Cham]',
      key: '3',
    },
    {
      label: 'Kampong Chhnang [Kâmpóng Chhnang]',
      key: '4',
    },
    {
      label: 'Kampong Spueu [Kâmpóng Spœ]',
      key: '5',
    },
    {
      label: 'Kampong Thum [Kâmpóng Thum]',
      key: '6',
    },
    {
      label: 'Kampot [Kâmpôt]',
      key: '7',
    },
    {
      label: 'Kandaal [Kândal]',
      key: '8',
    },
    {
      label: 'Kaoh Kong [Kaôh Kong]',
      key: '9',
    },
  ],
  CMR: [
    {
      label: 'Adamaoua',
      key: 'AD',
    },
    {
      label: 'Centre',
      key: 'CE',
    },
    {
      label: 'Far North',
      key: 'EN',
    },
    {
      label: 'East',
      key: 'ES',
    },
    {
      label: 'Littoral',
      key: 'LT',
    },
    {
      label: 'North',
      key: 'NO',
    },
    {
      label: 'North-West',
      key: 'NW',
    },
    {
      label: 'West',
      key: 'OU',
    },
    {
      label: 'South',
      key: 'SU',
    },
    {
      label: 'South-West',
      key: 'SW',
    },
  ],
  CAN: [
    {
      label: 'Alberta',
      key: 'AB',
    },
    {
      label: 'British Columbia',
      key: 'BC',
    },
    {
      label: 'Manitoba',
      key: 'MB',
    },
    {
      label: 'New Brunswick',
      key: 'NB',
    },
    {
      label: 'Newfoundland and Labrador',
      key: 'NL',
    },
    {
      label: 'Nova Scotia',
      key: 'NS',
    },
    {
      label: 'Northwest Territories',
      key: 'NT',
    },
    {
      label: 'Nunavut',
      key: 'NU',
    },
    {
      label: 'Ontario',
      key: 'ON',
    },
    {
      label: 'Prince Edward Island',
      key: 'PE',
    },
    {
      label: 'Quebec',
      key: 'QC',
    },
    {
      label: 'Saskatchewan',
      key: 'SK',
    },
    {
      label: 'Yukon',
      key: 'YT',
    },
  ],
  CYM: [],
  CAF: [
    {
      label: 'Ouham',
      key: 'AC',
    },
    {
      label: 'Bamingui-Bangoran',
      key: 'BB',
    },
    {
      label: 'Bangui',
      key: 'BGF',
    },
    {
      label: 'Basse-Kotto',
      key: 'BK',
    },
    {
      label: 'Haute-Kotto',
      key: 'HK',
    },
    {
      label: 'Haut-Mbomou',
      key: 'HM',
    },
    {
      label: 'Mambéré-Kadéï',
      key: 'HS',
    },
    {
      label: 'Nana-Grébizi',
      key: 'KB',
    },
    {
      label: 'Kémo',
      key: 'KG',
    },
    {
      label: 'Lobaye',
      key: 'LB',
    },
    {
      label: 'Mbomou',
      key: 'MB',
    },
    {
      label: 'Ombella-Mpoko',
      key: 'MP',
    },
    {
      label: 'Nana-Mambéré',
      key: 'NM',
    },
    {
      label: 'Ouham-Pendé',
      key: 'OP',
    },
    {
      label: 'Sangha-Mbaéré',
      key: 'SE',
    },
    {
      label: 'Ouaka',
      key: 'UK',
    },
    {
      label: 'Vakaga',
      key: 'VK',
    },
  ],
  TCD: [
    {
      label: 'Batha',
      key: 'BA',
    },
    {
      label: 'بحر الغزال',
      key: 'BG',
    },
    {
      label: 'منطقة بوركو',
      key: 'BO',
    },
    {
      label: 'Chari-Baguirmi',
      key: 'CB',
    },
    {
      label: 'إنيدي الشرقية',
      key: 'EE',
    },
    {
      label: 'إنيدي الغربية',
      key: 'EO',
    },
    {
      label: 'Guéra',
      key: 'GR',
    },
    {
      label: 'Hadjer Lamis',
      key: 'HL',
    },
    {
      label: 'Kanem',
      key: 'KA',
    },
    {
      label: 'Lac',
      key: 'LC',
    },
    {
      label: 'Logone-Occidental',
      key: 'LO',
    },
    {
      label: 'Logone-Oriental',
      key: 'LR',
    },
    {
      label: 'Mandoul',
      key: 'MA',
    },
    {
      label: 'Moyen-Chari',
      key: 'MC',
    },
    {
      label: 'Mayo-Kébbi-Est',
      key: 'ME',
    },
    {
      label: 'Mayo-Kébbi-Ouest',
      key: 'MO',
    },
    {
      label: 'Ndjamena',
      key: 'ND',
    },
    {
      label: 'Ouaddaï',
      key: 'OD',
    },
    {
      label: 'Salamat',
      key: 'SA',
    },
    {
      label: 'منطقة سيلا',
      key: 'SI',
    },
    {
      label: 'Tandjilé',
      key: 'TA',
    },
    {
      label: 'منطقة تبستي',
      key: 'TI',
    },
    {
      label: 'Wadi Fira',
      key: 'WF',
    },
  ],
  CHL: [
    {
      label: 'Aisén del General Carlos Ibáñez del Campo',
      key: 'AI',
    },
    {
      label: 'Antofagasta',
      key: 'AN',
    },
    {
      label: 'Arica y Parinacota',
      key: 'AP',
    },
    {
      label: 'Araucanía',
      key: 'AR',
    },
    {
      label: 'Atacama',
      key: 'AT',
    },
    {
      label: 'Bío-Bío',
      key: 'BI',
    },
    {
      label: 'Coquimbo',
      key: 'CO',
    },
    {
      label: "Libertador General Bernardo O'Higgins",
      key: 'LI',
    },
    {
      label: 'Los Lagos',
      key: 'LL',
    },
    {
      label: 'Los Ríos',
      key: 'LR',
    },
    {
      label: 'Magallanes',
      key: 'MA',
    },
    {
      label: 'Maule',
      key: 'ML',
    },
    {
      label: 'Región de Ñuble',
      key: 'NB',
    },
    {
      label: 'Región Metropolitana de Santiago',
      key: 'RM',
    },
    {
      label: 'Tarapacá',
      key: 'TA',
    },
    {
      label: 'Valparaíso',
      key: 'VS',
    },
  ],
  CHN: [
    {
      label: 'Anhui',
      key: 'AH',
    },
    {
      label: 'Beijing',
      key: 'BJ',
    },
    {
      label: 'Chongqing',
      key: 'CQ',
    },
    {
      label: 'Fujian',
      key: 'FJ',
    },
    {
      label: 'Guangdong',
      key: 'GD',
    },
    {
      label: 'Gansu',
      key: 'GS',
    },
    {
      label: 'Guangxi',
      key: 'GX',
    },
    {
      label: 'Guizhou',
      key: 'GZ',
    },
    {
      label: 'Henan',
      key: 'HA',
    },
    {
      label: 'Hubei',
      key: 'HB',
    },
    {
      label: 'Hebei',
      key: 'HE',
    },
    {
      label: 'Hainan',
      key: 'HI',
    },
    {
      label: 'Xianggang (zh) **',
      key: 'HK',
    },
    {
      label: 'Heilongjiang',
      key: 'HL',
    },
    {
      label: 'Hunan',
      key: 'HN',
    },
    {
      label: 'Jilin',
      key: 'JL',
    },
    {
      label: 'Jiangsu',
      key: 'JS',
    },
    {
      label: 'Jiangxi',
      key: 'JX',
    },
    {
      label: 'Liaoning',
      key: 'LN',
    },
    {
      label: 'Aomen (zh) ***',
      key: 'MO',
    },
    {
      label: 'Nei Mongol (mn)',
      key: 'NM',
    },
    {
      label: 'Ningxia',
      key: 'NX',
    },
    {
      label: 'Qinghai',
      key: 'QH',
    },
    {
      label: 'Sichuan',
      key: 'SC',
    },
    {
      label: 'Shandong',
      key: 'SD',
    },
    {
      label: 'Shanghai',
      key: 'SH',
    },
    {
      label: 'Shaanxi',
      key: 'SN',
    },
    {
      label: 'Shanxi',
      key: 'SX',
    },
    {
      label: 'Tianjin',
      key: 'TJ',
    },
    {
      label: 'Taiwan *',
      key: 'TW',
    },
    {
      label: 'Xinjiang',
      key: 'XJ',
    },
    {
      label: 'Xizang',
      key: 'XZ',
    },
    {
      label: 'Yunnan',
      key: 'YN',
    },
    {
      label: 'Zhejiang',
      key: 'ZJ',
    },
  ],
  CXR: [],
  CCK: [],
  COL: [
    {
      label: 'Amazonas',
      key: 'AMA',
    },
    {
      label: 'Antioquia',
      key: 'ANT',
    },
    {
      label: 'Arauca',
      key: 'ARA',
    },
    {
      label: 'Atlántico',
      key: 'ATL',
    },
    {
      label: 'Bolívar',
      key: 'BOL',
    },
    {
      label: 'Boyacá',
      key: 'BOY',
    },
    {
      label: 'Caldas',
      key: 'CAL',
    },
    {
      label: 'Caquetá',
      key: 'CAQ',
    },
    {
      label: 'Casanare',
      key: 'CAS',
    },
    {
      label: 'Cauca',
      key: 'CAU',
    },
    {
      label: 'Cesar',
      key: 'CES',
    },
    {
      label: 'Chocó',
      key: 'CHO',
    },
    {
      label: 'Córdoba',
      key: 'COR',
    },
    {
      label: 'Cundinamarca',
      key: 'CUN',
    },
    {
      label: 'Distrito Capital de Bogotá',
      key: 'DC',
    },
    {
      label: 'Guainía',
      key: 'GUA',
    },
    {
      label: 'Guaviare',
      key: 'GUV',
    },
    {
      label: 'Huila',
      key: 'HUI',
    },
    {
      label: 'La Guajira',
      key: 'LAG',
    },
    {
      label: 'Magdalena',
      key: 'MAG',
    },
    {
      label: 'Meta',
      key: 'MET',
    },
    {
      label: 'Nariño',
      key: 'NAR',
    },
    {
      label: 'Norte de Santander',
      key: 'NSA',
    },
    {
      label: 'Putumayo',
      key: 'PUT',
    },
    {
      label: 'Quindío',
      key: 'QUI',
    },
    {
      label: 'Risaralda',
      key: 'RIS',
    },
    {
      label: 'Santander',
      key: 'SAN',
    },
    {
      label: 'San Andrés, Providencia y Santa Catalina',
      key: 'SAP',
    },
    {
      label: 'Sucre',
      key: 'SUC',
    },
    {
      label: 'Tolima',
      key: 'TOL',
    },
    {
      label: 'Valle del Cauca',
      key: 'VAC',
    },
    {
      label: 'Vaupés',
      key: 'VAU',
    },
    {
      label: 'Vichada',
      key: 'VID',
    },
  ],
  COM: [
    {
      label: 'Anjouan',
      key: 'A',
    },
    {
      label: 'Grande Comore',
      key: 'G',
    },
    {
      label: 'Mohéli',
      key: 'M',
    },
  ],
  COG: [
    {
      label: 'Bouenza',
      key: '11',
    },
    {
      label: 'Pool',
      key: '12',
    },
    {
      label: 'Sangha',
      key: '13',
    },
    {
      label: 'Plateaux',
      key: '14',
    },
    {
      label: 'Cuvette-Ouest',
      key: '15',
    },
    {
      label: 'Pointe-Noire',
      key: '16',
    },
    {
      label: 'Lékoumou',
      key: '2',
    },
    {
      label: 'Kouilou',
      key: '5',
    },
    {
      label: 'Likouala',
      key: '7',
    },
    {
      label: 'Cuvette',
      key: '8',
    },
    {
      label: 'Niari',
      key: '9',
    },
    {
      label: 'Brazzaville',
      key: 'BZV',
    },
  ],
  COD: [
    {
      label: 'Bas-Congo',
      key: 'BC',
    },
    {
      label: 'Bas-Uele',
      key: 'BU',
    },
    {
      label: 'Équateur',
      key: 'EQ',
    },
    {
      label: 'Haut-Katanga',
      key: 'HK',
    },
    {
      label: 'Haut-Lomami',
      key: 'HL',
    },
    {
      label: 'Haut-Uele',
      key: 'HU',
    },
    {
      label: 'Ituri',
      key: 'IT',
    },
    {
      label: 'Lulua',
      key: 'KC',
    },
    {
      label: 'Kasai-Oriental',
      key: 'KE',
    },
    {
      label: 'Kwango',
      key: 'KG',
    },
    {
      label: 'Kwilu',
      key: 'KL',
    },
    {
      label: 'Kinshasa',
      key: 'KN',
    },
    {
      label: 'Kasaï',
      key: 'KS',
    },
    {
      label: 'Kabinda',
      key: 'LO',
    },
    {
      label: 'Lualaba',
      key: 'LU',
    },
    {
      label: 'Maniema',
      key: 'MA',
    },
    {
      label: 'Mai-Ndombe',
      key: 'MN',
    },
    {
      label: 'Mongala',
      key: 'MO',
    },
    {
      label: 'Nord-Kivu',
      key: 'NK',
    },
    {
      label: 'Nord-Ubangi',
      key: 'NU',
    },
    {
      label: 'Sankuru',
      key: 'SA',
    },
    {
      label: 'Sud-Kivu',
      key: 'SK',
    },
    {
      label: 'Sud-Ubangi',
      key: 'SU',
    },
    {
      label: 'Tanganyika',
      key: 'TA',
    },
    {
      label: 'Tshopo',
      key: 'TO',
    },
    {
      label: 'Tshuapa',
      key: 'TU',
    },
  ],
  COK: [],
  CRI: [
    {
      label: 'Alajuela',
      key: 'A',
    },
    {
      label: 'Cartago',
      key: 'C',
    },
    {
      label: 'Guanacaste',
      key: 'G',
    },
    {
      label: 'Heredia',
      key: 'H',
    },
    {
      label: 'Limón',
      key: 'L',
    },
    {
      label: 'Puntarenas',
      key: 'P',
    },
    {
      label: 'San José',
      key: 'SJ',
    },
  ],
  CIV: [
    {
      label: 'Abidjan',
      key: 'AB',
    },
    {
      label: 'District du Bas-Sassandra',
      key: 'BS',
    },
    {
      label: 'District de la Comoé',
      key: 'CM',
    },
    {
      label: 'Denguélé',
      key: 'DN',
    },
    {
      label: 'Gôh-Djiboua',
      key: 'GD',
    },
    {
      label: 'District des Lacs',
      key: 'LC',
    },
    {
      label: 'District des Lagunes',
      key: 'LG',
    },
    {
      label: 'District des Montagnes',
      key: 'MG',
    },
    {
      label: 'District du Sassandra-Marahoué',
      key: 'SM',
    },
    {
      label: 'Savanes',
      key: 'SV',
    },
    {
      label: 'Vallée du Bandama',
      key: 'VB',
    },
    {
      label: 'District du Woroba',
      key: 'WR',
    },
    {
      label: 'Yamoussoukro',
      key: 'YM',
    },
    {
      label: 'Zanzan',
      key: 'ZZ',
    },
  ],
  HRV: [
    {
      label: 'Zagrebačka županija',
      key: '01',
    },
    {
      label: 'Krapinsko-zagorska županija',
      key: '02',
    },
    {
      label: 'Sisačko-moslavačka županija',
      key: '03',
    },
    {
      label: 'Karlovačka županija',
      key: '04',
    },
    {
      label: 'Varaždinska županija',
      key: '05',
    },
    {
      label: 'Koprivničko-križevačka županija',
      key: '06',
    },
    {
      label: 'Bjelovarsko-bilogorska županija',
      key: '07',
    },
    {
      label: 'Primorsko-goranska županija',
      key: '08',
    },
    {
      label: 'Ličko-senjska županija',
      key: '09',
    },
    {
      label: 'Virovitičko-podravska županija',
      key: '10',
    },
    {
      label: 'Požeško-slavonska županija',
      key: '11',
    },
    {
      label: 'Brodsko-posavska županija',
      key: '12',
    },
    {
      label: 'Zadarska županija',
      key: '13',
    },
    {
      label: 'Osječko-baranjska županija',
      key: '14',
    },
    {
      label: 'Šibensko-kninska županija',
      key: '15',
    },
    {
      label: 'Vukovarsko-srijemska županija',
      key: '16',
    },
    {
      label: 'Splitsko-dalmatinska županija',
      key: '17',
    },
    {
      label: 'Istarska županija',
      key: '18',
    },
    {
      label: 'Dubrovačko-neretvanska županija',
      key: '19',
    },
    {
      label: 'Međimurska županija',
      key: '20',
    },
    {
      label: 'Grad Zagreb',
      key: '21',
    },
  ],
  CUB: [
    {
      label: 'Pinar del Río',
      key: '01',
    },
    {
      label: 'La Habana',
      key: '03',
    },
    {
      label: 'Matanzas',
      key: '04',
    },
    {
      label: 'Villa Clara',
      key: '05',
    },
    {
      label: 'Cienfuegos',
      key: '06',
    },
    {
      label: 'Sancti Spíritus',
      key: '07',
    },
    {
      label: 'Ciego de Ávila',
      key: '08',
    },
    {
      label: 'Camagüey',
      key: '09',
    },
    {
      label: 'Las Tunas',
      key: '10',
    },
    {
      label: 'Holguín',
      key: '11',
    },
    {
      label: 'Granma',
      key: '12',
    },
    {
      label: 'Santiago de Cuba',
      key: '13',
    },
    {
      label: 'Guantánamo',
      key: '14',
    },
    {
      label: 'Artemisa',
      key: '15',
    },
    {
      label: 'Mayabeque',
      key: '16',
    },
    {
      label: 'Isla de la Juventud',
      key: '99',
    },
  ],
  CUW: [],
  CYP: [
    {
      label: 'Lefkosia',
      key: '01',
    },
    {
      label: 'Lemesos',
      key: '02',
    },
    {
      label: 'Larnaka',
      key: '03',
    },
    {
      label: 'Ammochostos',
      key: '04',
    },
    {
      label: 'Pafos',
      key: '05',
    },
    {
      label: 'Keryneia',
      key: '06',
    },
  ],
  CZE: [
    {
      label: 'Praha, Hlavní město',
      key: '10',
    },
    {
      label: 'Středočeský kraj',
      key: '20',
    },
    {
      label: 'Benešov',
      key: '201',
    },
    {
      label: 'Beroun',
      key: '202',
    },
    {
      label: 'Kladno',
      key: '203',
    },
    {
      label: 'Kolín',
      key: '204',
    },
    {
      label: 'Kutná Hora',
      key: '205',
    },
    {
      label: 'Mělník',
      key: '206',
    },
    {
      label: 'Mladá Boleslav',
      key: '207',
    },
    {
      label: 'Nymburk',
      key: '208',
    },
    {
      label: 'Praha-východ',
      key: '209',
    },
    {
      label: 'Praha-západ',
      key: '20A',
    },
    {
      label: 'Příbram',
      key: '20B',
    },
    {
      label: 'Rakovník',
      key: '20C',
    },
    {
      label: 'Jihočeský kraj',
      key: '31',
    },
    {
      label: 'České Budějovice',
      key: '311',
    },
    {
      label: 'Český Krumlov',
      key: '312',
    },
    {
      label: 'Jindřichův Hradec',
      key: '313',
    },
    {
      label: 'Písek',
      key: '314',
    },
    {
      label: 'Prachatice',
      key: '315',
    },
    {
      label: 'Strakonice',
      key: '316',
    },
    {
      label: 'Tábor',
      key: '317',
    },
    {
      label: 'Plzeňský kraj',
      key: '32',
    },
    {
      label: 'Domažlice',
      key: '321',
    },
    {
      label: 'Klatovy',
      key: '322',
    },
    {
      label: 'Plzeň-město',
      key: '323',
    },
    {
      label: 'Plzeň-jih',
      key: '324',
    },
    {
      label: 'Plzeň-sever',
      key: '325',
    },
    {
      label: 'Rokycany',
      key: '326',
    },
    {
      label: 'Tachov',
      key: '327',
    },
    {
      label: 'Karlovarský kraj',
      key: '41',
    },
    {
      label: 'Cheb',
      key: '411',
    },
    {
      label: 'Karlovy Vary',
      key: '412',
    },
    {
      label: 'Sokolov',
      key: '413',
    },
    {
      label: 'Ústecký kraj',
      key: '42',
    },
    {
      label: 'Děčín',
      key: '421',
    },
    {
      label: 'Chomutov',
      key: '422',
    },
    {
      label: 'Litoměřice',
      key: '423',
    },
    {
      label: 'Louny',
      key: '424',
    },
    {
      label: 'Most',
      key: '425',
    },
    {
      label: 'Teplice',
      key: '426',
    },
    {
      label: 'Ústí nad Labem',
      key: '427',
    },
    {
      label: 'Liberecký kraj',
      key: '51',
    },
    {
      label: 'Česká Lípa',
      key: '511',
    },
    {
      label: 'Jablonec nad Nisou',
      key: '512',
    },
    {
      label: 'Liberec',
      key: '513',
    },
    {
      label: 'Semily',
      key: '514',
    },
    {
      label: 'Královéhradecký kraj',
      key: '52',
    },
    {
      label: 'Hradec Králové',
      key: '521',
    },
    {
      label: 'Jičín',
      key: '522',
    },
    {
      label: 'Náchod',
      key: '523',
    },
    {
      label: 'Rychnov nad Kněžnou',
      key: '524',
    },
    {
      label: 'Trutnov',
      key: '525',
    },
    {
      label: 'Pardubický kraj',
      key: '53',
    },
    {
      label: 'Chrudim',
      key: '531',
    },
    {
      label: 'Pardubice',
      key: '532',
    },
    {
      label: 'Svitavy',
      key: '533',
    },
    {
      label: 'Ústí nad Orlicí',
      key: '534',
    },
    {
      label: 'Kraj Vysočina',
      key: '63',
    },
    {
      label: 'Havlíčkův Brod',
      key: '631',
    },
    {
      label: 'Jihlava',
      key: '632',
    },
    {
      label: 'Pelhřimov',
      key: '633',
    },
    {
      label: 'Třebíč',
      key: '634',
    },
    {
      label: 'Žďár nad Sázavou',
      key: '635',
    },
    {
      label: 'Jihomoravský kraj',
      key: '64',
    },
    {
      label: 'Blansko',
      key: '641',
    },
    {
      label: 'Brno-město',
      key: '642',
    },
    {
      label: 'Brno-venkov',
      key: '643',
    },
    {
      label: 'Břeclav',
      key: '644',
    },
    {
      label: 'Hodonín',
      key: '645',
    },
    {
      label: 'Vyškov',
      key: '646',
    },
    {
      label: 'Znojmo',
      key: '647',
    },
    {
      label: 'Olomoucký kraj',
      key: '71',
    },
    {
      label: 'Jeseník',
      key: '711',
    },
    {
      label: 'Olomouc',
      key: '712',
    },
    {
      label: 'Prostějov',
      key: '713',
    },
    {
      label: 'Přerov',
      key: '714',
    },
    {
      label: 'Šumperk',
      key: '715',
    },
    {
      label: 'Zlínský kraj',
      key: '72',
    },
    {
      label: 'Kroměříž',
      key: '721',
    },
    {
      label: 'Uherské Hradiště',
      key: '722',
    },
    {
      label: 'Vsetín',
      key: '723',
    },
    {
      label: 'Zlín',
      key: '724',
    },
    {
      label: 'Moravskoslezský kraj',
      key: '80',
    },
    {
      label: 'Bruntál',
      key: '801',
    },
    {
      label: 'Frýdek-Místek',
      key: '802',
    },
    {
      label: 'Karviná',
      key: '803',
    },
    {
      label: 'Nový Jičín',
      key: '804',
    },
    {
      label: 'Opava',
      key: '805',
    },
    {
      label: 'Ostrava-město',
      key: '806',
    },
  ],
  DNK: [
    {
      label: 'Nordjylland',
      key: '81',
    },
    {
      label: 'Midtjylland',
      key: '82',
    },
    {
      label: 'Syddanmark',
      key: '83',
    },
    {
      label: 'Hovedstaden',
      key: '84',
    },
    {
      label: 'Sjælland',
      key: '85',
    },
  ],
  DJI: [
    {
      label: 'Arta',
      key: 'AR',
    },
    {
      label: 'Ali Sabieh',
      key: 'AS',
    },
    {
      label: 'Dikhil',
      key: 'DI',
    },
    {
      label: 'Djibouti',
      key: 'DJ',
    },
    {
      label: 'Obock',
      key: 'OB',
    },
    {
      label: 'Tadjourah',
      key: 'TA',
    },
  ],
  DMA: [
    {
      label: 'Saint Andrew',
      key: '02',
    },
    {
      label: 'Saint David',
      key: '03',
    },
    {
      label: 'Saint George',
      key: '04',
    },
    {
      label: 'Saint John',
      key: '05',
    },
    {
      label: 'Saint Joseph',
      key: '06',
    },
    {
      label: 'Saint Luke',
      key: '07',
    },
    {
      label: 'Saint Mark',
      key: '08',
    },
    {
      label: 'Saint Patrick',
      key: '09',
    },
    {
      label: 'Saint Paul',
      key: '10',
    },
    {
      label: 'Saint Peter',
      key: '11',
    },
  ],
  DOM: [
    {
      label: 'Distrito Nacional (Santo Domingo)',
      key: '01',
    },
    {
      label: 'Azua',
      key: '02',
    },
    {
      label: 'Bahoruco',
      key: '03',
    },
    {
      label: 'Barahona',
      key: '04',
    },
    {
      label: 'Dajabón',
      key: '05',
    },
    {
      label: 'Duarte',
      key: '06',
    },
    {
      label: 'La Estrelleta [Elías Piña]',
      key: '07',
    },
    {
      label: 'El Seybo [El Seibo]',
      key: '08',
    },
    {
      label: 'Espaillat',
      key: '09',
    },
    {
      label: 'Independencia',
      key: '10',
    },
    {
      label: 'La Altagracia',
      key: '11',
    },
    {
      label: 'La Romana',
      key: '12',
    },
    {
      label: 'La Vega',
      key: '13',
    },
    {
      label: 'María Trinidad Sánchez',
      key: '14',
    },
    {
      label: 'Monte Cristi',
      key: '15',
    },
    {
      label: 'Pedernales',
      key: '16',
    },
    {
      label: 'Peravia',
      key: '17',
    },
    {
      label: 'Puerto Plata',
      key: '18',
    },
    {
      label: 'Salcedo',
      key: '19',
    },
    {
      label: 'Samaná',
      key: '20',
    },
    {
      label: 'San Cristóbal',
      key: '21',
    },
    {
      label: 'San Juan',
      key: '22',
    },
    {
      label: 'San Pedro de Macorís',
      key: '23',
    },
    {
      label: 'Sánchez Ramírez',
      key: '24',
    },
    {
      label: 'Santiago',
      key: '25',
    },
    {
      label: 'Santiago Rodríguez',
      key: '26',
    },
    {
      label: 'Valverde',
      key: '27',
    },
    {
      label: 'Monseñor Nouel',
      key: '28',
    },
    {
      label: 'Monte Plata',
      key: '29',
    },
    {
      label: 'Hato Mayor',
      key: '30',
    },
    {
      label: 'San Jose de Ocoa',
      key: '31',
    },
    {
      label: 'Santo Domingo',
      key: '32',
    },
    {
      label: 'Cibao Nordeste',
      key: '33',
    },
    {
      label: 'Cibao Noroeste',
      key: '34',
    },
    {
      label: 'Cibao Norte',
      key: '35',
    },
    {
      label: 'Cibao Sur',
      key: '36',
    },
    {
      label: 'El Valle',
      key: '37',
    },
    {
      label: 'Enriquillo',
      key: '38',
    },
    {
      label: 'Higüamo',
      key: '39',
    },
    {
      label: 'Ozama',
      key: '40',
    },
    {
      label: 'Valdesia',
      key: '41',
    },
    {
      label: 'Yuma',
      key: '42',
    },
  ],
  ECU: [
    {
      label: 'Azuay',
      key: 'A',
    },
    {
      label: 'Bolívar',
      key: 'B',
    },
    {
      label: 'Carchi',
      key: 'C',
    },
    {
      label: 'Orellana',
      key: 'D',
    },
    {
      label: 'Esmeraldas',
      key: 'E',
    },
    {
      label: 'Cañar',
      key: 'F',
    },
    {
      label: 'Guayas',
      key: 'G',
    },
    {
      label: 'Chimborazo',
      key: 'H',
    },
    {
      label: 'Imbabura',
      key: 'I',
    },
    {
      label: 'Loja',
      key: 'L',
    },
    {
      label: 'Manabí',
      key: 'M',
    },
    {
      label: 'Napo',
      key: 'N',
    },
    {
      label: 'El Oro',
      key: 'O',
    },
    {
      label: 'Pichincha',
      key: 'P',
    },
    {
      label: 'Los Ríos',
      key: 'R',
    },
    {
      label: 'Morona-Santiago',
      key: 'S',
    },
    {
      label: 'Santo Domingo de los Tsachilas',
      key: 'SD',
    },
    {
      label: 'Santa Elena',
      key: 'SE',
    },
    {
      label: 'Tungurahua',
      key: 'T',
    },
    {
      label: 'Sucumbíos',
      key: 'U',
    },
    {
      label: 'Galápagos',
      key: 'W',
    },
    {
      label: 'Cotopaxi',
      key: 'X',
    },
    {
      label: 'Pastaza',
      key: 'Y',
    },
    {
      label: 'Zamora-Chinchipe',
      key: 'Z',
    },
  ],
  EGY: [
    {
      label: 'Al Iskandariyah',
      key: 'ALX',
    },
    {
      label: 'Aswan',
      key: 'ASN',
    },
    {
      label: 'Asyut',
      key: 'AST',
    },
    {
      label: 'Al Bahr al Ahmar',
      key: 'BA',
    },
    {
      label: 'Al Buhayrah',
      key: 'BH',
    },
    {
      label: 'Bani Suwayf',
      key: 'BNS',
    },
    {
      label: 'Al Qahirah',
      key: 'C',
    },
    {
      label: 'Ad Daqahliyah',
      key: 'DK',
    },
    {
      label: 'Dumyat',
      key: 'DT',
    },
    {
      label: 'Al Fayyum',
      key: 'FYM',
    },
    {
      label: 'Al Gharbiyah',
      key: 'GH',
    },
    {
      label: 'Al Jizah',
      key: 'GZ',
    },
    {
      label: 'Al Ismā`īlīyah',
      key: 'IS',
    },
    {
      label: "Janub Sina'",
      key: 'JS',
    },
    {
      label: 'Al Qalyubiyah',
      key: 'KB',
    },
    {
      label: 'Kafr ash Shaykh',
      key: 'KFS',
    },
    {
      label: 'Qina',
      key: 'KN',
    },
    {
      label: 'al-Uqsur',
      key: 'LX',
    },
    {
      label: 'Al Minya',
      key: 'MN',
    },
    {
      label: 'Al Minufiyah',
      key: 'MNF',
    },
    {
      label: 'Matrūh',
      key: 'MT',
    },
    {
      label: 'Būr Sa`īd',
      key: 'PTS',
    },
    {
      label: 'Suhaj',
      key: 'SHG',
    },
    {
      label: 'Ash Sharqiyah',
      key: 'SHR',
    },
    {
      label: "Shamal Sina'",
      key: 'SIN',
    },
    {
      label: 'As Suways',
      key: 'SUZ',
    },
    {
      label: 'Al Wadi al Jadid',
      key: 'WAD',
    },
  ],
  SLV: [
    {
      label: 'Ahuachapán',
      key: 'AH',
    },
    {
      label: 'Cabañas',
      key: 'CA',
    },
    {
      label: 'Chalatenango',
      key: 'CH',
    },
    {
      label: 'Cuscatlán',
      key: 'CU',
    },
    {
      label: 'La Libertad',
      key: 'LI',
    },
    {
      label: 'Morazán',
      key: 'MO',
    },
    {
      label: 'La Paz',
      key: 'PA',
    },
    {
      label: 'Santa Ana',
      key: 'SA',
    },
    {
      label: 'San Miguel',
      key: 'SM',
    },
    {
      label: 'Sonsonate',
      key: 'SO',
    },
    {
      label: 'San Salvador',
      key: 'SS',
    },
    {
      label: 'San Vicente',
      key: 'SV',
    },
    {
      label: 'La Unión',
      key: 'UN',
    },
    {
      label: 'Usulután',
      key: 'US',
    },
  ],
  GNQ: [
    {
      label: 'Annobón',
      key: 'AN',
    },
    {
      label: 'Bioko Norte',
      key: 'BN',
    },
    {
      label: 'Bioko Sur',
      key: 'BS',
    },
    {
      label: 'Región Continental',
      key: 'C',
    },
    {
      label: 'Centro Sur',
      key: 'CS',
    },
    {
      label: 'Djibloho',
      key: 'DJ',
    },
    {
      label: 'Región Insular',
      key: 'I',
    },
    {
      label: 'Kie-Ntem',
      key: 'KN',
    },
    {
      label: 'Litoral',
      key: 'LI',
    },
    {
      label: 'Wele-Nzás',
      key: 'WN',
    },
  ],
  ERI: [
    {
      label: 'Anseba',
      key: 'AN',
    },
    {
      label: 'Debubawi Keyih Bahri [Debub-Keih-Bahri]',
      key: 'DK',
    },
    {
      label: 'Debub',
      key: 'DU',
    },
    {
      label: 'Gash-Barka',
      key: 'GB',
    },
    {
      label: 'Maakel [Maekel]',
      key: 'MA',
    },
    {
      label: 'Semenawi Keyih Bahri [Semien-Keih-Bahri]',
      key: 'SK',
    },
  ],
  EST: [
    {
      label: 'Alutaguse',
      key: '130',
    },
    {
      label: 'Anija',
      key: '141',
    },
    {
      label: 'Antsla',
      key: '142',
    },
    {
      label: 'Elva',
      key: '171',
    },
    {
      label: 'Haapsalu',
      key: '184',
    },
    {
      label: 'Haljala',
      key: '191',
    },
    {
      label: 'Harku',
      key: '198',
    },
    {
      label: 'Hiiumaa',
      key: '205',
    },
    {
      label: 'Häädemeeste',
      key: '214',
    },
    {
      label: 'Jõelähtme',
      key: '245',
    },
    {
      label: 'Jõgeva',
      key: '247',
    },
    {
      label: 'Jõhvi',
      key: '251',
    },
    {
      label: 'Järva',
      key: '255',
    },
    {
      label: 'Kadrina',
      key: '272',
    },
    {
      label: 'Kambja',
      key: '283',
    },
    {
      label: 'Kanepi',
      key: '284',
    },
    {
      label: 'Kastre',
      key: '291',
    },
    {
      label: 'Kehtna',
      key: '293',
    },
    {
      label: 'Keila',
      key: '296',
    },
    {
      label: 'Kihnu',
      key: '303',
    },
    {
      label: 'Kiili',
      key: '305',
    },
    {
      label: 'Kohila',
      key: '317',
    },
    {
      label: 'Kohtla-Järve',
      key: '321',
    },
    {
      label: 'Kose',
      key: '338',
    },
    {
      label: 'Kuusalu',
      key: '353',
    },
    {
      label: 'Harjumaa',
      key: '37',
    },
    {
      label: 'Hiiumaa',
      key: '39',
    },
    {
      label: 'Loksa',
      key: '424',
    },
    {
      label: 'Lääneranna',
      key: '430',
    },
    {
      label: 'Lääne-Harju',
      key: '431',
    },
    {
      label: 'Luunja',
      key: '432',
    },
    {
      label: 'Lääne-Nigula',
      key: '441',
    },
    {
      label: 'Lüganuse',
      key: '442',
    },
    {
      label: 'Maardu',
      key: '446',
    },
    {
      label: 'Ida-Virumaa',
      key: '45',
    },
    {
      label: 'Muhu',
      key: '478',
    },
    {
      label: 'Mulgi',
      key: '480',
    },
    {
      label: 'Mustvee',
      key: '486',
    },
    {
      label: 'Jõgevamaa',
      key: '50',
    },
    {
      label: 'Märjamaa',
      key: '503',
    },
    {
      label: 'Narva',
      key: '511',
    },
    {
      label: 'Narva-Jõesuu',
      key: '514',
    },
    {
      label: 'Järvamaa',
      key: '52',
    },
    {
      label: 'Nõo',
      key: '528',
    },
    {
      label: 'Otepää',
      key: '557',
    },
    {
      label: 'Läänemaa',
      key: '56',
    },
    {
      label: 'Paide',
      key: '567',
    },
    {
      label: 'Peipsiääre',
      key: '586',
    },
    {
      label: 'Lääne-Virumaa',
      key: '60',
    },
    {
      label: 'Põhja-Sakala',
      key: '615',
    },
    {
      label: 'Põltsamaa',
      key: '618',
    },
    {
      label: 'Põlva',
      key: '622',
    },
    {
      label: 'Pärnu',
      key: '624',
    },
    {
      label: 'Põhja-Pärnumaa',
      key: '638',
    },
    {
      label: 'Põlvamaa',
      key: '64',
    },
    {
      label: 'Raasiku',
      key: '651',
    },
    {
      label: 'Rae',
      key: '653',
    },
    {
      label: 'Rakvere',
      key: '661',
    },
    {
      label: 'Rakvere',
      key: '663',
    },
    {
      label: 'Rapla',
      key: '668',
    },
    {
      label: 'Pärnumaa',
      key: '68',
    },
    {
      label: 'Ruhnu',
      key: '689',
    },
    {
      label: 'Rõuge',
      key: '698',
    },
    {
      label: 'Räpina',
      key: '708',
    },
    {
      label: 'Raplamaa',
      key: '71',
    },
    {
      label: 'Saarde',
      key: '712',
    },
    {
      label: 'Saaremaa',
      key: '714',
    },
    {
      label: 'Saku',
      key: '719',
    },
    {
      label: 'Saue',
      key: '726',
    },
    {
      label: 'Setomaa',
      key: '732',
    },
    {
      label: 'Sillamäe',
      key: '735',
    },
    {
      label: 'Saaremaa',
      key: '74',
    },
    {
      label: 'Tallinn',
      key: '784',
    },
    {
      label: 'Tartumaa',
      key: '79',
    },
    {
      label: 'Tapa',
      key: '792',
    },
    {
      label: 'Tartu',
      key: '793',
    },
    {
      label: 'Tartu',
      key: '796',
    },
    {
      label: 'Toila',
      key: '803',
    },
    {
      label: 'Tori',
      key: '809',
    },
    {
      label: 'Valgamaa',
      key: '81',
    },
    {
      label: 'Tõrva',
      key: '824',
    },
    {
      label: 'Türi',
      key: '834',
    },
    {
      label: 'Viljandimaa',
      key: '84',
    },
    {
      label: 'Valga',
      key: '855',
    },
    {
      label: 'Võrumaa',
      key: '87',
    },
    {
      label: 'Viimsi',
      key: '890',
    },
    {
      label: 'Viljandi',
      key: '897',
    },
    {
      label: 'Viljandi',
      key: '899',
    },
    {
      label: 'Vinni',
      key: '901',
    },
    {
      label: 'Viru-Nigula',
      key: '903',
    },
    {
      label: 'Vormsi',
      key: '907',
    },
    {
      label: 'Võru',
      key: '917',
    },
    {
      label: 'Võru',
      key: '919',
    },
    {
      label: 'Väike-Maarja',
      key: '928',
    },
  ],
  SWZ: [
    {
      label: 'Hhohho',
      key: 'HH',
    },
    {
      label: 'Lubombo',
      key: 'LU',
    },
    {
      label: 'Manzini',
      key: 'MA',
    },
    {
      label: 'Shiselweni',
      key: 'SH',
    },
  ],
  ETH: [
    {
      label: 'Adis Abeba',
      key: 'AA',
    },
    {
      label: 'Afar',
      key: 'AF',
    },
    {
      label: 'Amara',
      key: 'AM',
    },
    {
      label: 'Binshangul Gumuz',
      key: 'BE',
    },
    {
      label: 'Dire Dawa',
      key: 'DD',
    },
    {
      label: 'Gambela Hizboch',
      key: 'GA',
    },
    {
      label: 'Hareri Hizb',
      key: 'HA',
    },
    {
      label: 'Oromiya',
      key: 'OR',
    },
    {
      label: 'YeDebub Biheroch Bihereseboch na Hizboch',
      key: 'SN',
    },
    {
      label: 'Sumale',
      key: 'SO',
    },
    {
      label: 'Tigray',
      key: 'TI',
    },
  ],
  FLK: [],
  FRO: [],
  FJI: [
    {
      label: 'Ba',
      key: '01',
    },
    {
      label: 'Bua',
      key: '02',
    },
    {
      label: 'Cakaudrove',
      key: '03',
    },
    {
      label: 'Kadavu',
      key: '04',
    },
    {
      label: 'Lau',
      key: '05',
    },
    {
      label: 'Lomaiviti',
      key: '06',
    },
    {
      label: 'Macuata',
      key: '07',
    },
    {
      label: 'Nadroga-Navosa',
      key: '08',
    },
    {
      label: 'Naitasiri',
      key: '09',
    },
    {
      label: 'Namosi',
      key: '10',
    },
    {
      label: 'Ra',
      key: '11',
    },
    {
      label: 'Rewa',
      key: '12',
    },
    {
      label: 'Serua',
      key: '13',
    },
    {
      label: 'Tailevu',
      key: '14',
    },
    {
      label: 'Central',
      key: 'C',
    },
    {
      label: 'Eastern',
      key: 'E',
    },
    {
      label: 'Northern',
      key: 'N',
    },
    {
      label: 'Rotuma',
      key: 'R',
    },
    {
      label: 'Western',
      key: 'W',
    },
  ],
  FIN: [
    {
      label: 'Ahvenanmaan maakunta',
      key: '01',
    },
    {
      label: 'Etelä-Karjala',
      key: '02',
    },
    {
      label: 'Etelä-Pohjanmaa',
      key: '03',
    },
    {
      label: 'Etelä-Savo',
      key: '04',
    },
    {
      label: 'Kainuu',
      key: '05',
    },
    {
      label: 'Kanta-Häme',
      key: '06',
    },
    {
      label: 'Keski-Pohjanmaa',
      key: '07',
    },
    {
      label: 'Keski-Suomi',
      key: '08',
    },
    {
      label: 'Kymenlaakso',
      key: '09',
    },
    {
      label: 'Lappi',
      key: '10',
    },
    {
      label: 'Pirkanmaa',
      key: '11',
    },
    {
      label: 'Pohjanmaa',
      key: '12',
    },
    {
      label: 'Pohjois-Karjala',
      key: '13',
    },
    {
      label: 'Pohjois-Pohjanmaa',
      key: '14',
    },
    {
      label: 'Pohjois-Savo',
      key: '15',
    },
    {
      label: 'Päijät-Häme',
      key: '16',
    },
    {
      label: 'Satakunta',
      key: '17',
    },
    {
      label: 'Uusimaa',
      key: '18',
    },
    {
      label: 'Varsinais-Suomi',
      key: '19',
    },
  ],
  FRA: [
    {
      label: 'Ain',
      key: '01',
    },
    {
      label: 'Aisne',
      key: '02',
    },
    {
      label: 'Allier',
      key: '03',
    },
    {
      label: 'Alpes-de-Haute-Provence',
      key: '04',
    },
    {
      label: 'Hautes-Alpes',
      key: '05',
    },
    {
      label: 'Alpes-Maritimes',
      key: '06',
    },
    {
      label: 'Ardèche',
      key: '07',
    },
    {
      label: 'Ardennes',
      key: '08',
    },
    {
      label: 'Ariège',
      key: '09',
    },
    {
      label: 'Aube',
      key: '10',
    },
    {
      label: 'Aude',
      key: '11',
    },
    {
      label: 'Aveyron',
      key: '12',
    },
    {
      label: 'Bouches-du-Rhône',
      key: '13',
    },
    {
      label: 'Calvados',
      key: '14',
    },
    {
      label: 'Cantal',
      key: '15',
    },
    {
      label: 'Charente',
      key: '16',
    },
    {
      label: 'Charente-Maritime',
      key: '17',
    },
    {
      label: 'Cher',
      key: '18',
    },
    {
      label: 'Corrèze',
      key: '19',
    },
    {
      label: 'Corse',
      key: '20R',
    },
    {
      label: "Côte-d'Or",
      key: '21',
    },
    {
      label: "Côtes-d'Armor",
      key: '22',
    },
    {
      label: 'Creuse',
      key: '23',
    },
    {
      label: 'Dordogne',
      key: '24',
    },
    {
      label: 'Doubs',
      key: '25',
    },
    {
      label: 'Drôme',
      key: '26',
    },
    {
      label: 'Eure',
      key: '27',
    },
    {
      label: 'Eure-et-Loir',
      key: '28',
    },
    {
      label: 'Finistère',
      key: '29',
    },
    {
      label: 'Corse-du-Sud',
      key: '2A',
    },
    {
      label: 'Haute-Corse',
      key: '2B',
    },
    {
      label: 'Gard',
      key: '30',
    },
    {
      label: 'Haute-Garonne',
      key: '31',
    },
    {
      label: 'Gers',
      key: '32',
    },
    {
      label: 'Gironde',
      key: '33',
    },
    {
      label: 'Hérault',
      key: '34',
    },
    {
      label: 'Ille-et-Vilaine',
      key: '35',
    },
    {
      label: 'Indre',
      key: '36',
    },
    {
      label: 'Indre-et-Loire',
      key: '37',
    },
    {
      label: 'Isère',
      key: '38',
    },
    {
      label: 'Jura',
      key: '39',
    },
    {
      label: 'Landes',
      key: '40',
    },
    {
      label: 'Loir-et-Cher',
      key: '41',
    },
    {
      label: 'Loire',
      key: '42',
    },
    {
      label: 'Haute-Loire',
      key: '43',
    },
    {
      label: 'Loire-Atlantique',
      key: '44',
    },
    {
      label: 'Loiret',
      key: '45',
    },
    {
      label: 'Lot',
      key: '46',
    },
    {
      label: 'Lot-et-Garonne',
      key: '47',
    },
    {
      label: 'Lozère',
      key: '48',
    },
    {
      label: 'Maine-et-Loire',
      key: '49',
    },
    {
      label: 'Manche',
      key: '50',
    },
    {
      label: 'Marne',
      key: '51',
    },
    {
      label: 'Haute-Marne',
      key: '52',
    },
    {
      label: 'Mayenne',
      key: '53',
    },
    {
      label: 'Meurthe-et-Moselle',
      key: '54',
    },
    {
      label: 'Meuse',
      key: '55',
    },
    {
      label: 'Morbihan',
      key: '56',
    },
    {
      label: 'Moselle',
      key: '57',
    },
    {
      label: 'Nièvre',
      key: '58',
    },
    {
      label: 'Nord',
      key: '59',
    },
    {
      label: 'Oise',
      key: '60',
    },
    {
      label: 'Orne',
      key: '61',
    },
    {
      label: 'Pas-de-Calais',
      key: '62',
    },
    {
      label: 'Puy-de-Dôme',
      key: '63',
    },
    {
      label: 'Pyrénées-Atlantiques',
      key: '64',
    },
    {
      label: 'Hautes-Pyrénées',
      key: '65',
    },
    {
      label: 'Pyrénées-Orientales',
      key: '66',
    },
    {
      label: 'Bas-Rhin',
      key: '67',
    },
    {
      label: 'Haut-Rhin',
      key: '68',
    },
    {
      label: 'Rhône',
      key: '69',
    },
    {
      label: 'Métropole de Lyon',
      key: '69M',
    },
    {
      label: 'Alsace',
      key: '6AE',
    },
    {
      label: 'Haute-Saône',
      key: '70',
    },
    {
      label: 'Saône-et-Loire',
      key: '71',
    },
    {
      label: 'Sarthe',
      key: '72',
    },
    {
      label: 'Savoie',
      key: '73',
    },
    {
      label: 'Haute-Savoie',
      key: '74',
    },
    {
      label: 'Paris',
      key: '75C',
    },
    {
      label: 'Seine-Maritime',
      key: '76',
    },
    {
      label: 'Seine-et-Marne',
      key: '77',
    },
    {
      label: 'Yvelines',
      key: '78',
    },
    {
      label: 'Deux-Sèvres',
      key: '79',
    },
    {
      label: 'Somme',
      key: '80',
    },
    {
      label: 'Tarn',
      key: '81',
    },
    {
      label: 'Tarn-et-Garonne',
      key: '82',
    },
    {
      label: 'Var',
      key: '83',
    },
    {
      label: 'Vaucluse',
      key: '84',
    },
    {
      label: 'Vendée',
      key: '85',
    },
    {
      label: 'Vienne',
      key: '86',
    },
    {
      label: 'Haute-Vienne',
      key: '87',
    },
    {
      label: 'Vosges',
      key: '88',
    },
    {
      label: 'Yonne',
      key: '89',
    },
    {
      label: 'Territoire de Belfort',
      key: '90',
    },
    {
      label: 'Essonne',
      key: '91',
    },
    {
      label: 'Hauts-de-Seine',
      key: '92',
    },
    {
      label: 'Seine-Saint-Denis',
      key: '93',
    },
    {
      label: 'Val-de-Marne',
      key: '94',
    },
    {
      label: "Val-d'Oise",
      key: '95',
    },
    {
      label: 'Guadeloupe',
      key: '971',
    },
    {
      label: 'Martinique',
      key: '972',
    },
    {
      label: 'Guyane (française)',
      key: '973',
    },
    {
      label: 'La Réunion',
      key: '974',
    },
    {
      label: 'Mayotte',
      key: '976',
    },
    {
      label: 'Auvergne-Rhône-Alpes',
      key: 'ARA',
    },
    {
      label: 'Bourgogne-Franche-Comté',
      key: 'BFC',
    },
    {
      label: 'Saint-Barthélemy',
      key: 'BL',
    },
    {
      label: 'Bretagne',
      key: 'BRE',
    },
    {
      label: 'Clipperton',
      key: 'CP',
    },
    {
      label: 'Centre-Val de Loire',
      key: 'CVL',
    },
    {
      label: 'Grand Est',
      key: 'GES',
    },
    {
      label: 'Hauts-de-France',
      key: 'HDF',
    },
    {
      label: 'Île-de-France',
      key: 'IDF',
    },
    {
      label: 'Saint-Martin',
      key: 'MF',
    },
    {
      label: 'Nouvelle-Aquitaine',
      key: 'NAQ',
    },
    {
      label: 'New Caledonia',
      key: 'NC',
    },
    {
      label: 'Normandie',
      key: 'NOR',
    },
    {
      label: 'Occitanie',
      key: 'OCC',
    },
    {
      label: "Provence-Alpes-Côte d'Azur",
      key: 'PAC',
    },
    {
      label: 'Pays de la Loire',
      key: 'PDL',
    },
    {
      label: 'Polynésie française',
      key: 'PF',
    },
    {
      label: 'Saint-Pierre-et-Miquelon',
      key: 'PM',
    },
    {
      label: 'Terres Australes Françaises',
      key: 'TF',
    },
    {
      label: 'Wallis-et-Futuna',
      key: 'WF',
    },
  ],
  GUF: [],
  PYF: [],
  ATF: [],
  GAB: [
    {
      label: 'Estuaire',
      key: '1',
    },
    {
      label: 'Haut-Ogooué',
      key: '2',
    },
    {
      label: 'Moyen-Ogooué',
      key: '3',
    },
    {
      label: 'Ngounié',
      key: '4',
    },
    {
      label: 'Nyanga',
      key: '5',
    },
    {
      label: 'Ogooué-Ivindo',
      key: '6',
    },
    {
      label: 'Ogooué-Lolo',
      key: '7',
    },
    {
      label: 'Ogooué-Maritime',
      key: '8',
    },
    {
      label: 'Woleu-Ntem',
      key: '9',
    },
  ],
  GMB: [
    {
      label: 'Banjul',
      key: 'B',
    },
    {
      label: 'Lower River',
      key: 'L',
    },
    {
      label: 'MacCarthy Island',
      key: 'M',
    },
    {
      label: 'North Bank',
      key: 'N',
    },
    {
      label: 'Upper River',
      key: 'U',
    },
    {
      label: 'Western',
      key: 'W',
    },
  ],
  GEO: [
    {
      label: 'Abkhazia',
      key: 'AB',
    },
    {
      label: 'Ajaria',
      key: 'AJ',
    },
    {
      label: 'Guria',
      key: 'GU',
    },
    {
      label: 'Imereti',
      key: 'IM',
    },
    {
      label: 'Kakheti',
      key: 'KA',
    },
    {
      label: 'Kvemo Kartli',
      key: 'KK',
    },
    {
      label: 'Mtskheta-Mtianeti',
      key: 'MM',
    },
    {
      label: 'Racha-Lechkhumi [and] Kvemo Svaneti',
      key: 'RL',
    },
    {
      label: 'Samtskhe-Javakheti',
      key: 'SJ',
    },
    {
      label: 'Shida Kartli',
      key: 'SK',
    },
    {
      label: 'Samegrelo-Zemo Svaneti',
      key: 'SZ',
    },
    {
      label: 'Tbilisi',
      key: 'TB',
    },
  ],
  DEU: [
    {
      label: 'Brandenburg',
      key: 'BB',
    },
    {
      label: 'Berlin',
      key: 'BE',
    },
    {
      label: 'Baden-Württemberg',
      key: 'BW',
    },
    {
      label: 'Bayern',
      key: 'BY',
    },
    {
      label: 'Bremen',
      key: 'HB',
    },
    {
      label: 'Hessen',
      key: 'HE',
    },
    {
      label: 'Hamburg',
      key: 'HH',
    },
    {
      label: 'Mecklenburg-Vorpommern',
      key: 'MV',
    },
    {
      label: 'Niedersachsen',
      key: 'NI',
    },
    {
      label: 'Nordrhein-Westfalen',
      key: 'NW',
    },
    {
      label: 'Rheinland-Pfalz',
      key: 'RP',
    },
    {
      label: 'Schleswig-Holstein',
      key: 'SH',
    },
    {
      label: 'Saarland',
      key: 'SL',
    },
    {
      label: 'Sachsen',
      key: 'SN',
    },
    {
      label: 'Sachsen-Anhalt',
      key: 'ST',
    },
    {
      label: 'Thüringen',
      key: 'TH',
    },
  ],
  GHA: [
    {
      label: 'Greater Accra',
      key: 'AA',
    },
    {
      label: 'Ahafo',
      key: 'AF',
    },
    {
      label: 'Ashanti',
      key: 'AH',
    },
    {
      label: 'Bono East',
      key: 'BE',
    },
    {
      label: 'Bono',
      key: 'BO',
    },
    {
      label: 'Central',
      key: 'CP',
    },
    {
      label: 'Eastern',
      key: 'EP',
    },
    {
      label: 'North East',
      key: 'NE',
    },
    {
      label: 'Northern',
      key: 'NP',
    },
    {
      label: 'Oti',
      key: 'OT',
    },
    {
      label: 'Savannah',
      key: 'SV',
    },
    {
      label: 'Volta',
      key: 'TV',
    },
    {
      label: 'Upper East',
      key: 'UE',
    },
    {
      label: 'Upper West',
      key: 'UW',
    },
    {
      label: 'Western North',
      key: 'WN',
    },
    {
      label: 'Western',
      key: 'WP',
    },
  ],
  GIB: [],
  GRC: [
    {
      label: 'Ágion Óros',
      key: '69',
    },
    {
      label: 'Anatolikí Makedonía kai Thráki',
      key: 'A',
    },
    {
      label: 'Kentrikí Makedonía',
      key: 'B',
    },
    {
      label: 'Dytikí Makedonía',
      key: 'C',
    },
    {
      label: 'Ípeiros',
      key: 'D',
    },
    {
      label: 'Thessalía',
      key: 'E',
    },
    {
      label: 'Ionía Nísia',
      key: 'F',
    },
    {
      label: 'Dytikí Elláda',
      key: 'G',
    },
    {
      label: 'Stereá Elláda',
      key: 'H',
    },
    {
      label: 'Attikí',
      key: 'I',
    },
    {
      label: 'Pelopónnisos',
      key: 'J',
    },
    {
      label: 'Vóreio Aigaío',
      key: 'K',
    },
    {
      label: 'Nótio Aigaío',
      key: 'L',
    },
    {
      label: 'Kríti',
      key: 'M',
    },
  ],
  GRL: [
    {
      label: 'Avannaata Kommunia',
      key: 'AV',
    },
    {
      label: 'Kujalleq',
      key: 'KU',
    },
    {
      label: 'Qeqqata',
      key: 'QE',
    },
    {
      label: 'Kommune Qeqertalik',
      key: 'QT',
    },
    {
      label: 'Sermersooq',
      key: 'SM',
    },
  ],
  GRD: [
    {
      label: 'Saint Andrew',
      key: '01',
    },
    {
      label: 'Saint David',
      key: '02',
    },
    {
      label: 'Saint George',
      key: '03',
    },
    {
      label: 'Saint John',
      key: '04',
    },
    {
      label: 'Saint Mark',
      key: '05',
    },
    {
      label: 'Saint Patrick',
      key: '06',
    },
    {
      label: 'Southern Grenadine Islands',
      key: '10',
    },
  ],
  GLP: [],
  GUM: [],
  GTM: [
    {
      label: 'Alta Verapaz',
      key: 'AV',
    },
    {
      label: 'Baja Verapaz',
      key: 'BV',
    },
    {
      label: 'Chimaltenango',
      key: 'CM',
    },
    {
      label: 'Chiquimula',
      key: 'CQ',
    },
    {
      label: 'Escuintla',
      key: 'ES',
    },
    {
      label: 'Guatemala',
      key: 'GU',
    },
    {
      label: 'Huehuetenango',
      key: 'HU',
    },
    {
      label: 'Izabal',
      key: 'IZ',
    },
    {
      label: 'Jalapa',
      key: 'JA',
    },
    {
      label: 'Jutiapa',
      key: 'JU',
    },
    {
      label: 'Petén',
      key: 'PE',
    },
    {
      label: 'El Progreso',
      key: 'PR',
    },
    {
      label: 'Quiché',
      key: 'QC',
    },
    {
      label: 'Quetzaltenango',
      key: 'QZ',
    },
    {
      label: 'Retalhuleu',
      key: 'RE',
    },
    {
      label: 'Sacatepéquez',
      key: 'SA',
    },
    {
      label: 'San Marcos',
      key: 'SM',
    },
    {
      label: 'Sololá',
      key: 'SO',
    },
    {
      label: 'Santa Rosa',
      key: 'SR',
    },
    {
      label: 'Suchitepéquez',
      key: 'SU',
    },
    {
      label: 'Totonicapán',
      key: 'TO',
    },
    {
      label: 'Zacapa',
      key: 'ZA',
    },
  ],
  GGY: [],
  GIN: [
    {
      label: 'Région de Boké',
      key: 'B',
    },
    {
      label: 'Beyla',
      key: 'BE',
    },
    {
      label: 'Boffa',
      key: 'BF',
    },
    {
      label: 'Boké',
      key: 'BK',
    },
    {
      label: 'Conakry',
      key: 'C',
    },
    {
      label: 'Coyah',
      key: 'CO',
    },
    {
      label: 'Région de Kindia',
      key: 'D',
    },
    {
      label: 'Dabola',
      key: 'DB',
    },
    {
      label: 'Dinguiraye',
      key: 'DI',
    },
    {
      label: 'Dalaba',
      key: 'DL',
    },
    {
      label: 'Dubréka',
      key: 'DU',
    },
    {
      label: 'Région de Faranah',
      key: 'F',
    },
    {
      label: 'Faranah',
      key: 'FA',
    },
    {
      label: 'Forécariah',
      key: 'FO',
    },
    {
      label: 'Fria',
      key: 'FR',
    },
    {
      label: 'Gaoual',
      key: 'GA',
    },
    {
      label: 'Guékédou',
      key: 'GU',
    },
    {
      label: 'Région de Kankan',
      key: 'K',
    },
    {
      label: 'Kankan',
      key: 'KA',
    },
    {
      label: 'Koubia',
      key: 'KB',
    },
    {
      label: 'Kindia',
      key: 'KD',
    },
    {
      label: 'Kérouané',
      key: 'KE',
    },
    {
      label: 'Koundara',
      key: 'KN',
    },
    {
      label: 'Kouroussa',
      key: 'KO',
    },
    {
      label: 'Kissidougou',
      key: 'KS',
    },
    {
      label: 'Région de Labé',
      key: 'L',
    },
    {
      label: 'Labé',
      key: 'LA',
    },
    {
      label: 'Lélouma',
      key: 'LE',
    },
    {
      label: 'Lola',
      key: 'LO',
    },
    {
      label: 'Région de Mamou',
      key: 'M',
    },
    {
      label: 'Macenta',
      key: 'MC',
    },
    {
      label: 'Mandiana',
      key: 'MD',
    },
    {
      label: 'Mali',
      key: 'ML',
    },
    {
      label: 'Mamou',
      key: 'MM',
    },
    {
      label: 'Région de Nzérékoré',
      key: 'N',
    },
    {
      label: 'Nzérékoré',
      key: 'NZ',
    },
    {
      label: 'Pita',
      key: 'PI',
    },
    {
      label: 'Siguiri',
      key: 'SI',
    },
    {
      label: 'Télimélé',
      key: 'TE',
    },
    {
      label: 'Tougué',
      key: 'TO',
    },
    {
      label: 'Yomou',
      key: 'YO',
    },
  ],
  GNB: [
    {
      label: 'Bafatá',
      key: 'BA',
    },
    {
      label: 'Bolama',
      key: 'BL',
    },
    {
      label: 'Biombo',
      key: 'BM',
    },
    {
      label: 'Bissau',
      key: 'BS',
    },
    {
      label: 'Cacheu',
      key: 'CA',
    },
    {
      label: 'Gabú',
      key: 'GA',
    },
    {
      label: 'Leste',
      key: 'L',
    },
    {
      label: 'Norte',
      key: 'N',
    },
    {
      label: 'Oio',
      key: 'OI',
    },
    {
      label: 'Quinara',
      key: 'QU',
    },
    {
      label: 'Sul',
      key: 'S',
    },
    {
      label: 'Tombali',
      key: 'TO',
    },
  ],
  GUY: [
    {
      label: 'Barima-Waini',
      key: 'BA',
    },
    {
      label: 'Cuyuni-Mazaruni',
      key: 'CU',
    },
    {
      label: 'Demerara-Mahaica',
      key: 'DE',
    },
    {
      label: 'East Berbice-Corentyne',
      key: 'EB',
    },
    {
      label: 'Essequibo Islands-West Demerara',
      key: 'ES',
    },
    {
      label: 'Mahaica-Berbice',
      key: 'MA',
    },
    {
      label: 'Pomeroon-Supenaam',
      key: 'PM',
    },
    {
      label: 'Potaro-Siparuni',
      key: 'PT',
    },
    {
      label: 'Upper Demerara-Berbice',
      key: 'UD',
    },
    {
      label: 'Upper Takutu-Upper Essequibo',
      key: 'UT',
    },
  ],
  HTI: [
    {
      label: 'Artibonite',
      key: 'AR',
    },
    {
      label: 'Centre',
      key: 'CE',
    },
    {
      label: 'Grande-Anse',
      key: 'GA',
    },
    {
      label: 'Nord',
      key: 'ND',
    },
    {
      label: 'Nord-Est',
      key: 'NE',
    },
    {
      label: 'Nippes',
      key: 'NI',
    },
    {
      label: 'Nord-Ouest',
      key: 'NO',
    },
    {
      label: 'Ouest',
      key: 'OU',
    },
    {
      label: 'Sud',
      key: 'SD',
    },
    {
      label: 'Sud-Est',
      key: 'SE',
    },
  ],
  HMD: [],
  VAT: [],
  HND: [
    {
      label: 'Atlántida',
      key: 'AT',
    },
    {
      label: 'Choluteca',
      key: 'CH',
    },
    {
      label: 'Colón',
      key: 'CL',
    },
    {
      label: 'Comayagua',
      key: 'CM',
    },
    {
      label: 'Copán',
      key: 'CP',
    },
    {
      label: 'Cortés',
      key: 'CR',
    },
    {
      label: 'El Paraíso',
      key: 'EP',
    },
    {
      label: 'Francisco Morazán',
      key: 'FM',
    },
    {
      label: 'Gracias a Dios',
      key: 'GD',
    },
    {
      label: 'Islas de la Bahía',
      key: 'IB',
    },
    {
      label: 'Intibucá',
      key: 'IN',
    },
    {
      label: 'Lempira',
      key: 'LE',
    },
    {
      label: 'La Paz',
      key: 'LP',
    },
    {
      label: 'Ocotepeque',
      key: 'OC',
    },
    {
      label: 'Olancho',
      key: 'OL',
    },
    {
      label: 'Santa Bárbara',
      key: 'SB',
    },
    {
      label: 'Valle',
      key: 'VA',
    },
    {
      label: 'Yoro',
      key: 'YO',
    },
  ],
  HKG: [],
  HUN: [
    {
      label: 'Baranya',
      key: 'BA',
    },
    {
      label: 'Békéscsaba',
      key: 'BC',
    },
    {
      label: 'Békés',
      key: 'BE',
    },
    {
      label: 'Bács-Kiskun',
      key: 'BK',
    },
    {
      label: 'Budapest',
      key: 'BU',
    },
    {
      label: 'Borsod-Abaúj-Zemplén',
      key: 'BZ',
    },
    {
      label: 'Csongrád',
      key: 'CS',
    },
    {
      label: 'Debrecen',
      key: 'DE',
    },
    {
      label: 'Dunaújváros',
      key: 'DU',
    },
    {
      label: 'Eger',
      key: 'EG',
    },
    {
      label: 'Érd',
      key: 'ER',
    },
    {
      label: 'Fejér',
      key: 'FE',
    },
    {
      label: 'Győr-Moson-Sopron',
      key: 'GS',
    },
    {
      label: 'Győr',
      key: 'GY',
    },
    {
      label: 'Hajdú-Bihar',
      key: 'HB',
    },
    {
      label: 'Heves',
      key: 'HE',
    },
    {
      label: 'Hódmezővásárhely',
      key: 'HV',
    },
    {
      label: 'Jász-Nagykun-Szolnok',
      key: 'JN',
    },
    {
      label: 'Komárom-Esztergom',
      key: 'KE',
    },
    {
      label: 'Kecskemét',
      key: 'KM',
    },
    {
      label: 'Kaposvár',
      key: 'KV',
    },
    {
      label: 'Miskolc',
      key: 'MI',
    },
    {
      label: 'Nagykanizsa',
      key: 'NK',
    },
    {
      label: 'Nógrád',
      key: 'NO',
    },
    {
      label: 'Nyíregyháza',
      key: 'NY',
    },
    {
      label: 'Pest',
      key: 'PE',
    },
    {
      label: 'Pécs',
      key: 'PS',
    },
    {
      label: 'Szeged',
      key: 'SD',
    },
    {
      label: 'Székesfehérvár',
      key: 'SF',
    },
    {
      label: 'Szombathely',
      key: 'SH',
    },
    {
      label: 'Szolnok',
      key: 'SK',
    },
    {
      label: 'Sopron',
      key: 'SN',
    },
    {
      label: 'Somogy',
      key: 'SO',
    },
    {
      label: 'Szekszárd',
      key: 'SS',
    },
    {
      label: 'Salgótarján',
      key: 'ST',
    },
    {
      label: 'Szabolcs-Szatmár-Bereg',
      key: 'SZ',
    },
    {
      label: 'Tatabánya',
      key: 'TB',
    },
    {
      label: 'Tolna',
      key: 'TO',
    },
    {
      label: 'Vas',
      key: 'VA',
    },
    {
      label: 'Veszprém',
      key: 'VE',
    },
    {
      label: 'Veszprém',
      key: 'VM',
    },
    {
      label: 'Zala',
      key: 'ZA',
    },
    {
      label: 'Zalaegerszeg',
      key: 'ZE',
    },
  ],
  ISL: [
    {
      label: 'Reykjavík',
      key: '0',
    },
    {
      label: 'Höfuðborgarsvæði utan Reykjavíkur',
      key: '1',
    },
    {
      label: 'Suðurnes',
      key: '2',
    },
    {
      label: 'Vesturland',
      key: '3',
    },
    {
      label: 'Vestfirðir',
      key: '4',
    },
    {
      label: 'Norðurland vestra',
      key: '5',
    },
    {
      label: 'Norðurland eystra',
      key: '6',
    },
    {
      label: 'Austurland',
      key: '7',
    },
    {
      label: 'Suðurland',
      key: '8',
    },
    {
      label: 'Akrahreppur',
      key: 'AKH',
    },
    {
      label: 'Akraneskaupstaður',
      key: 'AKN',
    },
    {
      label: 'Akureyrarbær',
      key: 'AKU',
    },
    {
      label: 'Árneshreppur',
      key: 'ARN',
    },
    {
      label: 'Ásahreppur',
      key: 'ASA',
    },
    {
      label: 'Bláskógabyggð',
      key: 'BLA',
    },
    {
      label: 'Blönduósbær',
      key: 'BLO',
    },
    {
      label: 'Borgarbyggð',
      key: 'BOG',
    },
    {
      label: 'Bolungarvíkurkaupstaður',
      key: 'BOL',
    },
    {
      label: 'Dalabyggð',
      key: 'DAB',
    },
    {
      label: 'Dalvíkurbyggð',
      key: 'DAV',
    },
    {
      label: 'Eyja- og Miklaholtshreppur',
      key: 'EOM',
    },
    {
      label: 'Eyjafjarðarsveit',
      key: 'EYF',
    },
    {
      label: 'Fjarðabyggð',
      key: 'FJD',
    },
    {
      label: 'Fjallabyggð',
      key: 'FJL',
    },
    {
      label: 'Flóahreppur',
      key: 'FLA',
    },
    {
      label: 'Fljótsdalshreppur',
      key: 'FLR',
    },
    {
      label: 'Garðabær',
      key: 'GAR',
    },
    {
      label: 'Grímsnes- og Grafningshreppur',
      key: 'GOG',
    },
    {
      label: 'Grindavíkurbær',
      key: 'GRN',
    },
    {
      label: 'Grundarfjarðarbær',
      key: 'GRU',
    },
    {
      label: 'Grýtubakkahreppur',
      key: 'GRY',
    },
    {
      label: 'Hafnarfjarðarkaupstaður',
      key: 'HAF',
    },
    {
      label: 'Helgafellssveit',
      key: 'HEL',
    },
    {
      label: 'Hörgársveit',
      key: 'HRG',
    },
    {
      label: 'Hrunamannahreppur',
      key: 'HRU',
    },
    {
      label: 'Húnavatnshreppur',
      key: 'HUT',
    },
    {
      label: 'Húnaþing vestra',
      key: 'HUV',
    },
    {
      label: 'Hvalfjarðarsveit',
      key: 'HVA',
    },
    {
      label: 'Hveragerðisbær',
      key: 'HVE',
    },
    {
      label: 'Ísafjarðarbær',
      key: 'ISA',
    },
    {
      label: 'Kaldrananeshreppur',
      key: 'KAL',
    },
    {
      label: 'Kjósarhreppur',
      key: 'KJO',
    },
    {
      label: 'Kópavogsbær',
      key: 'KOP',
    },
    {
      label: 'Langanesbyggð',
      key: 'LAN',
    },
    {
      label: 'Mosfellsbær',
      key: 'MOS',
    },
    {
      label: 'Múlaþing',
      key: 'MUL',
    },
    {
      label: 'Mýrdalshreppur',
      key: 'MYR',
    },
    {
      label: 'Norðurþing',
      key: 'NOR',
    },
    {
      label: 'Rangárþing eystra',
      key: 'RGE',
    },
    {
      label: 'Rangárþing ytra',
      key: 'RGY',
    },
    {
      label: 'Reykhólahreppur',
      key: 'RHH',
    },
    {
      label: 'Reykjanesbær',
      key: 'RKN',
    },
    {
      label: 'Reykjavíkurborg',
      key: 'RKV',
    },
    {
      label: 'Svalbarðshreppur',
      key: 'SBH',
    },
    {
      label: 'Svalbarðsstrandarhreppur',
      key: 'SBT',
    },
    {
      label: 'Suðurnesjabær',
      key: 'SDN',
    },
    {
      label: 'Súðavíkurhreppur',
      key: 'SDV',
    },
    {
      label: 'Seltjarnarnesbær',
      key: 'SEL',
    },
    {
      label: 'Sveitarfélagið Árborg',
      key: 'SFA',
    },
    {
      label: 'Sveitarfélagið Hornafjörður',
      key: 'SHF',
    },
    {
      label: 'Skaftárhreppur',
      key: 'SKF',
    },
    {
      label: 'Skagabyggð',
      key: 'SKG',
    },
    {
      label: 'Skorradalshreppur',
      key: 'SKO',
    },
    {
      label: 'Skútustaðahreppur',
      key: 'SKU',
    },
    {
      label: 'Snæfellsbær',
      key: 'SNF',
    },
    {
      label: 'Skeiða- og Gnúpverjahreppur',
      key: 'SOG',
    },
    {
      label: 'Sveitarfélagið Ölfus',
      key: 'SOL',
    },
    {
      label: 'Sveitarfélagið Skagafjörður',
      key: 'SSF',
    },
    {
      label: 'Sveitarfélagið Skagaströnd',
      key: 'SSS',
    },
    {
      label: 'Strandabyggð',
      key: 'STR',
    },
    {
      label: 'Stykkishólmsbær',
      key: 'STY',
    },
    {
      label: 'Sveitarfélagið Vogar',
      key: 'SVG',
    },
    {
      label: 'Tálknafjarðarhreppur',
      key: 'TAL',
    },
    {
      label: 'Þingeyjarsveit',
      key: 'THG',
    },
    {
      label: 'Tjörneshreppur',
      key: 'TJO',
    },
    {
      label: 'Vestmannaeyjabær',
      key: 'VEM',
    },
    {
      label: 'Vesturbyggð',
      key: 'VER',
    },
    {
      label: 'Vopnafjarðarhreppur',
      key: 'VOP',
    },
  ],
  IND: [
    {
      label: 'Andaman and Nicobar Islands',
      key: 'AN',
    },
    {
      label: 'Andhra Pradesh',
      key: 'AP',
    },
    {
      label: 'Arunachal Pradesh',
      key: 'AR',
    },
    {
      label: 'Assam',
      key: 'AS',
    },
    {
      label: 'Bihar',
      key: 'BR',
    },
    {
      label: 'Chandigarh',
      key: 'CH',
    },
    {
      label: 'Chhattisgarh',
      key: 'CG',
    },
    {
      label: 'Dādra and Nagar Haveli and Damān and Diu',
      key: 'DH',
    },
    {
      label: 'Delhi',
      key: 'DL',
    },
    {
      label: 'Goa',
      key: 'GA',
    },
    {
      label: 'Gujarat',
      key: 'GJ',
    },
    {
      label: 'Himachal Pradesh',
      key: 'HP',
    },
    {
      label: 'Haryana',
      key: 'HR',
    },
    {
      label: 'Jharkhand',
      key: 'JH',
    },
    {
      label: 'Jammu and Kashmir',
      key: 'JK',
    },
    {
      label: 'Karnataka',
      key: 'KA',
    },
    {
      label: 'Kerala',
      key: 'KL',
    },
    {
      label: 'Ladakh',
      key: 'LA',
    },
    {
      label: 'Lakshadweep',
      key: 'LD',
    },
    {
      label: 'Maharashtra',
      key: 'MH',
    },
    {
      label: 'Meghalaya',
      key: 'ML',
    },
    {
      label: 'Manipur',
      key: 'MN',
    },
    {
      label: 'Madhya Pradesh',
      key: 'MP',
    },
    {
      label: 'Mizoram',
      key: 'MZ',
    },
    {
      label: 'Nagaland',
      key: 'NL',
    },
    {
      label: 'Odisha',
      key: 'OD',
    },
    {
      label: 'Punjab',
      key: 'PB',
    },
    {
      label: 'Pondicherry',
      key: 'PY',
    },
    {
      label: 'Rajasthan',
      key: 'RJ',
    },
    {
      label: 'Sikkim',
      key: 'SK',
    },
    {
      label: 'तेलंगाना',
      key: 'TS',
    },
    {
      label: 'Tamil Nadu',
      key: 'TN',
    },
    {
      label: 'Tripura',
      key: 'TR',
    },
    {
      label: 'Uttar Pradesh',
      key: 'UP',
    },
    {
      label: 'उत्तराखण्ड',
      key: 'UK',
    },
    {
      label: 'West Bengal',
      key: 'WB',
    },
  ],
  IDN: [
    {
      label: 'Aceh',
      key: 'AC',
    },
    {
      label: 'Bali',
      key: 'BA',
    },
    {
      label: 'Kepulauan Bangka Belitung',
      key: 'BB',
    },
    {
      label: 'Bengkulu',
      key: 'BE',
    },
    {
      label: 'Banten',
      key: 'BT',
    },
    {
      label: 'Gorontalo',
      key: 'GO',
    },
    {
      label: 'Jambi',
      key: 'JA',
    },
    {
      label: 'Jawa Barat',
      key: 'JB',
    },
    {
      label: 'Jawa Timur',
      key: 'JI',
    },
    {
      label: 'Daerah Khusus Ibukota Jakarta',
      key: 'JK',
    },
    {
      label: 'Jawa Tengah',
      key: 'JT',
    },
    {
      label: 'Jawa',
      key: 'JW',
    },
    {
      label: 'Kalimantan',
      key: 'KA',
    },
    {
      label: 'Kalimantan Barat',
      key: 'KB',
    },
    {
      label: 'Kalimantan Timur',
      key: 'KI',
    },
    {
      label: 'Kepulauan Riau',
      key: 'KR',
    },
    {
      label: 'Kalimantan Selatan',
      key: 'KS',
    },
    {
      label: 'Kalimantan Tengah',
      key: 'KT',
    },
    {
      label: 'Kalimantan Utara',
      key: 'KU',
    },
    {
      label: 'Lampung',
      key: 'LA',
    },
    {
      label: 'Maluku',
      key: 'MA',
    },
    {
      label: 'Kepulauan Maluku',
      key: 'ML',
    },
    {
      label: 'Maluku Utara',
      key: 'MU',
    },
    {
      label: 'Nusa Tenggara Barat',
      key: 'NB',
    },
    {
      label: 'Nusa Tenggara Timur',
      key: 'NT',
    },
    {
      label: 'Kepulauan Nusa Tenggara',
      key: 'NU',
    },
    {
      label: 'Papua',
      key: 'PA',
    },
    {
      label: 'Papua Barat',
      key: 'PB',
    },
    {
      label: 'Papua bagian barat',
      key: 'PP',
    },
    {
      label: 'Riau',
      key: 'RI',
    },
    {
      label: 'Sulawesi Utara',
      key: 'SA',
    },
    {
      label: 'Sumatera Barat',
      key: 'SB',
    },
    {
      label: 'Sulawesi Tenggara',
      key: 'SG',
    },
    {
      label: 'Sulawesi',
      key: 'SL',
    },
    {
      label: 'Sumatera',
      key: 'SM',
    },
    {
      label: 'Sulawesi Selatan',
      key: 'SN',
    },
    {
      label: 'Sulawesi Barat',
      key: 'SR',
    },
    {
      label: 'Sumatera Selatan',
      key: 'SS',
    },
    {
      label: 'Sulawesi Tengah',
      key: 'ST',
    },
    {
      label: 'Sumatera Utara',
      key: 'SU',
    },
    {
      label: 'Yogyakarta',
      key: 'YO',
    },
  ],
  IRN: [
    {
      label: 'Markazi',
      key: '00',
    },
    {
      label: 'Gilan',
      key: '01',
    },
    {
      label: 'Mazandaran',
      key: '02',
    },
    {
      label: 'Az¯arbayjan-e Sharqi',
      key: '03',
    },
    {
      label: 'Az¯arbayjan-e Gharbi',
      key: '04',
    },
    {
      label: 'Kermanshah',
      key: '05',
    },
    {
      label: 'Khuzestan',
      key: '06',
    },
    {
      label: 'Fars',
      key: '07',
    },
    {
      label: 'Kerman',
      key: '08',
    },
    {
      label: 'Khorasan-e Razavi',
      key: '09',
    },
    {
      label: 'Esfahan',
      key: '10',
    },
    {
      label: 'Sistan va Baluchestan',
      key: '11',
    },
    {
      label: 'Kordestan',
      key: '12',
    },
    {
      label: 'Hamadan',
      key: '13',
    },
    {
      label: 'Chahar Mah¸all va Bakhtiari',
      key: '14',
    },
    {
      label: 'Lorestan',
      key: '15',
    },
    {
      label: 'Ilam',
      key: '16',
    },
    {
      label: 'Kohkiluyeh va Buyer Ahmad',
      key: '17',
    },
    {
      label: 'Bushehr',
      key: '18',
    },
    {
      label: 'Zanjan',
      key: '19',
    },
    {
      label: 'Semnan',
      key: '20',
    },
    {
      label: 'Yazd',
      key: '21',
    },
    {
      label: 'Hormozgan',
      key: '22',
    },
    {
      label: 'Tehran',
      key: '23',
    },
    {
      label: 'Ardabil',
      key: '24',
    },
    {
      label: 'Qom',
      key: '25',
    },
    {
      label: 'Qazvin',
      key: '26',
    },
    {
      label: 'Golestan',
      key: '27',
    },
    {
      label: 'Khorasan-e Shemali',
      key: '28',
    },
    {
      label: 'Khorasan-e Janubi',
      key: '29',
    },
    {
      label: 'استان البرز',
      key: '30',
    },
  ],
  IRQ: [
    {
      label: 'Al Anbar',
      key: 'AN',
    },
    {
      label: 'Arbil',
      key: 'AR',
    },
    {
      label: 'Al Basrah',
      key: 'BA',
    },
    {
      label: 'Babil',
      key: 'BB',
    },
    {
      label: 'Baghdad',
      key: 'BG',
    },
    {
      label: 'Dahuk',
      key: 'DA',
    },
    {
      label: 'Diyalá',
      key: 'DI',
    },
    {
      label: 'Dhi Qar',
      key: 'DQ',
    },
    {
      label: "Karbala'",
      key: 'KA',
    },
    {
      label: 'كركوك',
      key: 'KI',
    },
    {
      label: 'إقليم كردستان',
      key: 'KR',
    },
    {
      label: 'Maysan',
      key: 'MA',
    },
    {
      label: 'Al Muthanná',
      key: 'MU',
    },
    {
      label: 'An Najaf',
      key: 'NA',
    },
    {
      label: 'Ninawá',
      key: 'NI',
    },
    {
      label: 'Al Qadisiyah',
      key: 'QA',
    },
    {
      label: 'Salah ad Din',
      key: 'SD',
    },
    {
      label: 'As Sulaymaniyah',
      key: 'SU',
    },
    {
      label: 'Wasit',
      key: 'WA',
    },
  ],
  IRL: [
    {
      label: 'Connacht',
      key: 'C',
    },
    {
      label: 'Clare',
      key: 'CE',
    },
    {
      label: 'Cavan',
      key: 'CN',
    },
    {
      label: 'Cork',
      key: 'CO',
    },
    {
      label: 'Carlow',
      key: 'CW',
    },
    {
      label: 'Dublin',
      key: 'D',
    },
    {
      label: 'Donegal',
      key: 'DL',
    },
    {
      label: 'Galway',
      key: 'G',
    },
    {
      label: 'Kildare',
      key: 'KE',
    },
    {
      label: 'Kilkenny',
      key: 'KK',
    },
    {
      label: 'Kerry',
      key: 'KY',
    },
    {
      label: 'Leinster',
      key: 'L',
    },
    {
      label: 'Longford',
      key: 'LD',
    },
    {
      label: 'Louth',
      key: 'LH',
    },
    {
      label: 'Limerick',
      key: 'LK',
    },
    {
      label: 'Leitrim',
      key: 'LM',
    },
    {
      label: 'Laois',
      key: 'LS',
    },
    {
      label: 'Munster',
      key: 'M',
    },
    {
      label: 'Meath',
      key: 'MH',
    },
    {
      label: 'Monaghan',
      key: 'MN',
    },
    {
      label: 'Mayo',
      key: 'MO',
    },
    {
      label: 'Offaly',
      key: 'OY',
    },
    {
      label: 'Roscommon',
      key: 'RN',
    },
    {
      label: 'Sligo',
      key: 'SO',
    },
    {
      label: 'Tipperary',
      key: 'TA',
    },
    {
      label: 'Ulster',
      key: 'U',
    },
    {
      label: 'Waterford',
      key: 'WD',
    },
    {
      label: 'Westmeath',
      key: 'WH',
    },
    {
      label: 'Wicklow',
      key: 'WW',
    },
    {
      label: 'Wexford',
      key: 'WX',
    },
  ],
  IMN: [],
  ISR: [
    {
      label: 'HaDarom',
      key: 'D',
    },
    {
      label: 'Haifa',
      key: 'HA',
    },
    {
      label: 'Yerushalayim',
      key: 'JM',
    },
    {
      label: 'HaMerkaz',
      key: 'M',
    },
    {
      label: 'Tel-Aviv',
      key: 'TA',
    },
    {
      label: 'HaZafon',
      key: 'Z',
    },
  ],
  ITA: [
    {
      label: 'Piemonte',
      key: '21',
    },
    {
      label: "Valle d'Aosta",
      key: '23',
    },
    {
      label: 'Lombardia',
      key: '25',
    },
    {
      label: 'Trentino-Alto Adige',
      key: '32',
    },
    {
      label: 'Veneto',
      key: '34',
    },
    {
      label: 'Friuli Venezia Giulia',
      key: '36',
    },
    {
      label: 'Liguria',
      key: '42',
    },
    {
      label: 'Emilia-Romagna',
      key: '45',
    },
    {
      label: 'Toscana',
      key: '52',
    },
    {
      label: 'Umbria',
      key: '55',
    },
    {
      label: 'Marche',
      key: '57',
    },
    {
      label: 'Lazio',
      key: '62',
    },
    {
      label: 'Abruzzo',
      key: '65',
    },
    {
      label: 'Molise',
      key: '67',
    },
    {
      label: 'Campania',
      key: '72',
    },
    {
      label: 'Puglia',
      key: '75',
    },
    {
      label: 'Basilicata',
      key: '77',
    },
    {
      label: 'Calabria',
      key: '78',
    },
    {
      label: 'Sicilia',
      key: '82',
    },
    {
      label: 'Sardegna',
      key: '88',
    },
    {
      label: 'Agrigento',
      key: 'AG',
    },
    {
      label: 'Alessandria',
      key: 'AL',
    },
    {
      label: 'Ancona',
      key: 'AN',
    },
    {
      label: 'Ascoli Piceno',
      key: 'AP',
    },
    {
      label: "L'Aquila",
      key: 'AQ',
    },
    {
      label: 'Arezzo',
      key: 'AR',
    },
    {
      label: 'Asti',
      key: 'AT',
    },
    {
      label: 'Avellino',
      key: 'AV',
    },
    {
      label: 'Bari',
      key: 'BA',
    },
    {
      label: 'Bergamo',
      key: 'BG',
    },
    {
      label: 'Biella',
      key: 'BI',
    },
    {
      label: 'Belluno',
      key: 'BL',
    },
    {
      label: 'Benevento',
      key: 'BN',
    },
    {
      label: 'Bologna',
      key: 'BO',
    },
    {
      label: 'Brindisi',
      key: 'BR',
    },
    {
      label: 'Brescia',
      key: 'BS',
    },
    {
      label: 'Barletta-Andria-Trani',
      key: 'BT',
    },
    {
      label: 'Bolzano',
      key: 'BZ',
    },
    {
      label: 'Cagliari',
      key: 'CA',
    },
    {
      label: 'Campobasso',
      key: 'CB',
    },
    {
      label: 'Caserta',
      key: 'CE',
    },
    {
      label: 'Chieti',
      key: 'CH',
    },
    {
      label: 'Caltanissetta',
      key: 'CL',
    },
    {
      label: 'Cuneo',
      key: 'CN',
    },
    {
      label: 'Como',
      key: 'CO',
    },
    {
      label: 'Cremona',
      key: 'CR',
    },
    {
      label: 'Cosenza',
      key: 'CS',
    },
    {
      label: 'Catania',
      key: 'CT',
    },
    {
      label: 'Catanzaro',
      key: 'CZ',
    },
    {
      label: 'Enna',
      key: 'EN',
    },
    {
      label: 'Forlì-Cesena',
      key: 'FC',
    },
    {
      label: 'Ferrara',
      key: 'FE',
    },
    {
      label: 'Foggia',
      key: 'FG',
    },
    {
      label: 'Firenze',
      key: 'FI',
    },
    {
      label: 'Fermo',
      key: 'FM',
    },
    {
      label: 'Frosinone',
      key: 'FR',
    },
    {
      label: 'Genova',
      key: 'GE',
    },
    {
      label: 'Gorizia',
      key: 'GO',
    },
    {
      label: 'Grosseto',
      key: 'GR',
    },
    {
      label: 'Imperia',
      key: 'IM',
    },
    {
      label: 'Isernia',
      key: 'IS',
    },
    {
      label: 'Crotone',
      key: 'KR',
    },
    {
      label: 'Lecco',
      key: 'LC',
    },
    {
      label: 'Lecce',
      key: 'LE',
    },
    {
      label: 'Livorno',
      key: 'LI',
    },
    {
      label: 'Lodi',
      key: 'LO',
    },
    {
      label: 'Latina',
      key: 'LT',
    },
    {
      label: 'Lucca',
      key: 'LU',
    },
    {
      label: 'Monza e Brianza',
      key: 'MB',
    },
    {
      label: 'Macerata',
      key: 'MC',
    },
    {
      label: 'Messina',
      key: 'ME',
    },
    {
      label: 'Milano',
      key: 'MI',
    },
    {
      label: 'Mantova',
      key: 'MN',
    },
    {
      label: 'Modena',
      key: 'MO',
    },
    {
      label: 'Massa-Carrara',
      key: 'MS',
    },
    {
      label: 'Matera',
      key: 'MT',
    },
    {
      label: 'Napoli',
      key: 'NA',
    },
    {
      label: 'Novara',
      key: 'NO',
    },
    {
      label: 'Nuoro',
      key: 'NU',
    },
    {
      label: 'Oristano',
      key: 'OR',
    },
    {
      label: 'Palermo',
      key: 'PA',
    },
    {
      label: 'Piacenza',
      key: 'PC',
    },
    {
      label: 'Padova',
      key: 'PD',
    },
    {
      label: 'Pescara',
      key: 'PE',
    },
    {
      label: 'Perugia',
      key: 'PG',
    },
    {
      label: 'Pisa',
      key: 'PI',
    },
    {
      label: 'Pordenone',
      key: 'PN',
    },
    {
      label: 'Prato',
      key: 'PO',
    },
    {
      label: 'Parma',
      key: 'PR',
    },
    {
      label: 'Pistoia',
      key: 'PT',
    },
    {
      label: 'Pesaro e Urbino',
      key: 'PU',
    },
    {
      label: 'Pavia',
      key: 'PV',
    },
    {
      label: 'Potenza',
      key: 'PZ',
    },
    {
      label: 'Ravenna',
      key: 'RA',
    },
    {
      label: 'Reggio Calabria',
      key: 'RC',
    },
    {
      label: 'Reggio Emilia',
      key: 'RE',
    },
    {
      label: 'Ragusa',
      key: 'RG',
    },
    {
      label: 'Rieti',
      key: 'RI',
    },
    {
      label: 'Roma',
      key: 'RM',
    },
    {
      label: 'Rimini',
      key: 'RN',
    },
    {
      label: 'Rovigo',
      key: 'RO',
    },
    {
      label: 'Salerno',
      key: 'SA',
    },
    {
      label: 'Siena',
      key: 'SI',
    },
    {
      label: 'Sondrio',
      key: 'SO',
    },
    {
      label: 'La Spezia',
      key: 'SP',
    },
    {
      label: 'Siracusa',
      key: 'SR',
    },
    {
      label: 'Sassari',
      key: 'SS',
    },
    {
      label: 'Sud Sardegna',
      key: 'SU',
    },
    {
      label: 'Savona',
      key: 'SV',
    },
    {
      label: 'Taranto',
      key: 'TA',
    },
    {
      label: 'Teramo',
      key: 'TE',
    },
    {
      label: 'Trento',
      key: 'TN',
    },
    {
      label: 'Torino',
      key: 'TO',
    },
    {
      label: 'Trapani',
      key: 'TP',
    },
    {
      label: 'Terni',
      key: 'TR',
    },
    {
      label: 'Trieste',
      key: 'TS',
    },
    {
      label: 'Treviso',
      key: 'TV',
    },
    {
      label: 'Udine',
      key: 'UD',
    },
    {
      label: 'Varese',
      key: 'VA',
    },
    {
      label: 'Verbano-Cusio-Ossola',
      key: 'VB',
    },
    {
      label: 'Vercelli',
      key: 'VC',
    },
    {
      label: 'Venezia',
      key: 'VE',
    },
    {
      label: 'Vicenza',
      key: 'VI',
    },
    {
      label: 'Verona',
      key: 'VR',
    },
    {
      label: 'Viterbo',
      key: 'VT',
    },
    {
      label: 'Vibo Valentia',
      key: 'VV',
    },
  ],
  JAM: [
    {
      label: 'Kingston',
      key: '01',
    },
    {
      label: 'Saint Andrew',
      key: '02',
    },
    {
      label: 'Saint Thomas',
      key: '03',
    },
    {
      label: 'Portland',
      key: '04',
    },
    {
      label: 'Saint Mary',
      key: '05',
    },
    {
      label: 'Saint Ann',
      key: '06',
    },
    {
      label: 'Trelawny',
      key: '07',
    },
    {
      label: 'Saint James',
      key: '08',
    },
    {
      label: 'Hanover',
      key: '09',
    },
    {
      label: 'Westmoreland',
      key: '10',
    },
    {
      label: 'Saint Elizabeth',
      key: '11',
    },
    {
      label: 'Manchester',
      key: '12',
    },
    {
      label: 'Clarendon',
      key: '13',
    },
    {
      label: 'Saint Catherine',
      key: '14',
    },
  ],
  JPN: [
    {
      label: 'Hokkaido',
      key: '01',
    },
    {
      label: 'Aomori',
      key: '02',
    },
    {
      label: 'Iwate',
      key: '03',
    },
    {
      label: 'Miyagi',
      key: '04',
    },
    {
      label: 'Akita',
      key: '05',
    },
    {
      label: 'Yamagata',
      key: '06',
    },
    {
      label: 'Fukushima',
      key: '07',
    },
    {
      label: 'Ibaraki',
      key: '08',
    },
    {
      label: 'Tochigi',
      key: '09',
    },
    {
      label: 'Gunma',
      key: '10',
    },
    {
      label: 'Saitama',
      key: '11',
    },
    {
      label: 'Chiba',
      key: '12',
    },
    {
      label: 'Tokyo',
      key: '13',
    },
    {
      label: 'Kanagawa',
      key: '14',
    },
    {
      label: 'Niigata',
      key: '15',
    },
    {
      label: 'Toyama',
      key: '16',
    },
    {
      label: 'Ishikawa',
      key: '17',
    },
    {
      label: 'Fukui',
      key: '18',
    },
    {
      label: 'Yamanashi',
      key: '19',
    },
    {
      label: 'Nagano',
      key: '20',
    },
    {
      label: 'Gifu',
      key: '21',
    },
    {
      label: 'Shizuoka',
      key: '22',
    },
    {
      label: 'Aichi',
      key: '23',
    },
    {
      label: 'Mie',
      key: '24',
    },
    {
      label: 'Shiga',
      key: '25',
    },
    {
      label: 'Kyoto',
      key: '26',
    },
    {
      label: 'Osaka',
      key: '27',
    },
    {
      label: 'Hyogo',
      key: '28',
    },
    {
      label: 'Nara',
      key: '29',
    },
    {
      label: 'Wakayama',
      key: '30',
    },
    {
      label: 'Tottori',
      key: '31',
    },
    {
      label: 'Shimane',
      key: '32',
    },
    {
      label: 'Okayama',
      key: '33',
    },
    {
      label: 'Hiroshima',
      key: '34',
    },
    {
      label: 'Yamaguchi',
      key: '35',
    },
    {
      label: 'Tokushima',
      key: '36',
    },
    {
      label: 'Kagawa',
      key: '37',
    },
    {
      label: 'Ehime',
      key: '38',
    },
    {
      label: 'Kochi',
      key: '39',
    },
    {
      label: 'Fukuoka',
      key: '40',
    },
    {
      label: 'Saga',
      key: '41',
    },
    {
      label: 'Nagasaki',
      key: '42',
    },
    {
      label: 'Kumamoto',
      key: '43',
    },
    {
      label: 'Oita',
      key: '44',
    },
    {
      label: 'Miyazaki',
      key: '45',
    },
    {
      label: 'Kagoshima',
      key: '46',
    },
    {
      label: 'Okinawa',
      key: '47',
    },
  ],
  JEY: [],
  JOR: [
    {
      label: 'Ajlun',
      key: 'AJ',
    },
    {
      label: 'Amman',
      key: 'AM',
    },
    {
      label: 'Aqaba',
      key: 'AQ',
    },
    {
      label: 'At Tafilah',
      key: 'AT',
    },
    {
      label: "Az Zarqa'",
      key: 'AZ',
    },
    {
      label: "Al Balqa'",
      key: 'BA',
    },
    {
      label: 'Irbid',
      key: 'IR',
    },
    {
      label: 'Jarash',
      key: 'JA',
    },
    {
      label: 'Al Karak',
      key: 'KA',
    },
    {
      label: 'Al Mafraq',
      key: 'MA',
    },
    {
      label: 'Madaba',
      key: 'MD',
    },
    {
      label: 'Ma`an',
      key: 'MN',
    },
  ],
  KAZ: [
    {
      label: 'Aqmola oblysy',
      key: 'AKM',
    },
    {
      label: 'Aqtöbe oblysy',
      key: 'AKT',
    },
    {
      label: 'Almaty',
      key: 'ALA',
    },
    {
      label: 'Almaty oblysy',
      key: 'ALM',
    },
    {
      label: 'Astana',
      key: 'AST',
    },
    {
      label: 'Atyrau oblysy',
      key: 'ATY',
    },
    {
      label: 'Qaraghandy oblysy',
      key: 'KAR',
    },
    {
      label: 'Qostanay oblysy',
      key: 'KUS',
    },
    {
      label: 'Qyzylorda oblysy',
      key: 'KZY',
    },
    {
      label: 'Mangghystau oblysy',
      key: 'MAN',
    },
    {
      label: 'Pavlodar oblysy',
      key: 'PAV',
    },
    {
      label: 'Soltüstik Qazaqstan oblysy',
      key: 'SEV',
    },
    {
      label: 'Shymkent',
      key: 'SHY',
    },
    {
      label: 'Shyghys Qazaqstan oblysy',
      key: 'VOS',
    },
    {
      label: 'Ongtüstik Qazaqstan oblysy',
      key: 'YUZ',
    },
    {
      label: 'Batys Qazaqstan oblysy',
      key: 'ZAP',
    },
    {
      label: 'Zhambyl oblysy',
      key: 'ZHA',
    },
  ],
  KEN: [
    {
      label: 'Baringo',
      key: '01',
    },
    {
      label: 'Bomet',
      key: '02',
    },
    {
      label: 'Bungoma',
      key: '03',
    },
    {
      label: 'Busia',
      key: '04',
    },
    {
      label: 'Elgeyo/Marakwet',
      key: '05',
    },
    {
      label: 'Embu',
      key: '06',
    },
    {
      label: 'Garissa',
      key: '07',
    },
    {
      label: 'Homa Bay',
      key: '08',
    },
    {
      label: 'Isiolo',
      key: '09',
    },
    {
      label: 'Kajiado',
      key: '10',
    },
    {
      label: 'Kakamega',
      key: '11',
    },
    {
      label: 'Kericho',
      key: '12',
    },
    {
      label: 'Kiambu',
      key: '13',
    },
    {
      label: 'Kilifi',
      key: '14',
    },
    {
      label: 'Kirinyaga',
      key: '15',
    },
    {
      label: 'Kisii',
      key: '16',
    },
    {
      label: 'Kisumu',
      key: '17',
    },
    {
      label: 'Kitui',
      key: '18',
    },
    {
      label: 'Kwale',
      key: '19',
    },
    {
      label: 'Laikipia',
      key: '20',
    },
    {
      label: 'Lamu',
      key: '21',
    },
    {
      label: 'Machakos',
      key: '22',
    },
    {
      label: 'Makueni',
      key: '23',
    },
    {
      label: 'Mandera',
      key: '24',
    },
    {
      label: 'Marsabit',
      key: '25',
    },
    {
      label: 'Meru',
      key: '26',
    },
    {
      label: 'Migori',
      key: '27',
    },
    {
      label: 'Mombasa',
      key: '28',
    },
    {
      label: 'Murang’a',
      key: '29',
    },
    {
      label: 'Nairobi City',
      key: '30',
    },
    {
      label: 'Nakuru',
      key: '31',
    },
    {
      label: 'Nandi',
      key: '32',
    },
    {
      label: 'Narok',
      key: '33',
    },
    {
      label: 'Nyamira',
      key: '34',
    },
    {
      label: 'Nyandarua',
      key: '35',
    },
    {
      label: 'Nyeri',
      key: '36',
    },
    {
      label: 'Samburu',
      key: '37',
    },
    {
      label: 'Siaya',
      key: '38',
    },
    {
      label: 'Taita-Taveta',
      key: '39',
    },
    {
      label: 'Tana River',
      key: '40',
    },
    {
      label: 'Tharaka-Nithi',
      key: '41',
    },
    {
      label: 'Trans-Nzoia',
      key: '42',
    },
    {
      label: 'Turkana',
      key: '43',
    },
    {
      label: 'Uasin Gishu',
      key: '44',
    },
    {
      label: 'Vihiga',
      key: '45',
    },
    {
      label: 'Wajir',
      key: '46',
    },
    {
      label: 'West Pokot',
      key: '47',
    },
  ],
  KIR: [
    {
      label: 'Gilbert Islands',
      key: 'G',
    },
    {
      label: 'Line Islands',
      key: 'L',
    },
    {
      label: 'Phoenix Islands',
      key: 'P',
    },
  ],
  PRK: [
    {
      label: '평양직할시',
      key: '01',
    },
    {
      label: '평안남도',
      key: '02',
    },
    {
      label: '평안북도',
      key: '03',
    },
    {
      label: '자강도',
      key: '04',
    },
    {
      label: '황해남도',
      key: '05',
    },
    {
      label: '황해북도',
      key: '06',
    },
    {
      label: '강원도',
      key: '07',
    },
    {
      label: '함경남도',
      key: '08',
    },
    {
      label: '함경북도',
      key: '09',
    },
    {
      label: '량강도',
      key: '10',
    },
    {
      label: '라선특별시',
      key: '13',
    },
    {
      label: '남포특별시',
      key: '14',
    },
  ],
  KOR: [
    {
      label: "Seoul Teugbyeolsi [Seoul-T'ukpyolshi]",
      key: '11',
    },
    {
      label: "Busan Gwang'yeogsi [Pusan-Kwangyokshi]",
      key: '26',
    },
    {
      label: "Daegu Gwang'yeogsi [Taegu-Kwangyokshi]",
      key: '27',
    },
    {
      label: "Incheon Gwang'yeogsi [Inch'n-Kwangyokshi]",
      key: '28',
    },
    {
      label: "Gwangju Gwang'yeogsi [Kwangju-Kwangyokshi]",
      key: '29',
    },
    {
      label: "Daejeon Gwang'yeogsi [Taejon-Kwangyokshi]",
      key: '30',
    },
    {
      label: "Ulsan Gwang'yeogsi [Ulsan-Kwangyokshi]",
      key: '31',
    },
    {
      label: 'Gyeonggido [Kyonggi-do]',
      key: '41',
    },
    {
      label: "Gang'weondo [Kang-won-do]",
      key: '42',
    },
    {
      label: "Chungcheongbugdo [Ch'ungch'ongbuk-do]",
      key: '43',
    },
    {
      label: "Chungcheongnamdo [Ch'ungch'ongnam-do]",
      key: '44',
    },
    {
      label: 'Jeonrabugdo[Chollabuk-do]',
      key: '45',
    },
    {
      label: 'Jeonranamdo [Chollanam-do]',
      key: '46',
    },
    {
      label: 'Gyeongsangbugdo [Kyongsangbuk-do]',
      key: '47',
    },
    {
      label: 'Gyeongsangnamdo [Kyongsangnam-do]',
      key: '48',
    },
    {
      label: 'Jejudo [Cheju-do]',
      key: '49',
    },
    {
      label: '세종특별자치시',
      key: '50',
    },
  ],
  KWT: [
    {
      label: 'Al Ahmadi',
      key: 'AH',
    },
    {
      label: 'Al Farwaniyah',
      key: 'FA',
    },
    {
      label: 'Hawalli',
      key: 'HA',
    },
    {
      label: 'Al Jahrah',
      key: 'JA',
    },
    {
      label: 'Al Kuwayt',
      key: 'KU',
    },
    {
      label: 'Mubarak al-Kabir',
      key: 'MU',
    },
  ],
  KGZ: [
    {
      label: 'Batken',
      key: 'B',
    },
    {
      label: 'Chü',
      key: 'C',
    },
    {
      label: 'Bishkek',
      key: 'GB',
    },
    {
      label: 'Ош',
      key: 'GO',
    },
    {
      label: 'Jalal-Abad',
      key: 'J',
    },
    {
      label: 'Naryn',
      key: 'N',
    },
    {
      label: 'Osh',
      key: 'O',
    },
    {
      label: 'Talas',
      key: 'T',
    },
    {
      label: 'Ysyk-Köl',
      key: 'Y',
    },
  ],
  LAO: [
    {
      label: 'Attapu [Attopeu]',
      key: 'AT',
    },
    {
      label: 'Bokèo',
      key: 'BK',
    },
    {
      label: 'Bolikhamxai [Borikhane]',
      key: 'BL',
    },
    {
      label: 'Champasak [Champassak]',
      key: 'CH',
    },
    {
      label: 'Houaphan',
      key: 'HO',
    },
    {
      label: 'Khammouan',
      key: 'KH',
    },
    {
      label: 'Louang Namtha',
      key: 'LM',
    },
    {
      label: 'Louangphabang [Louang Prabang]',
      key: 'LP',
    },
    {
      label: 'Oudômxai [Oudomsai]',
      key: 'OU',
    },
    {
      label: 'Phôngsali [Phong Saly]',
      key: 'PH',
    },
    {
      label: 'Salavan [Saravane]',
      key: 'SL',
    },
    {
      label: 'Savannakhét',
      key: 'SV',
    },
    {
      label: 'Vientiane',
      key: 'VI',
    },
    {
      label: 'Vientiane Prefecture',
      key: 'VT',
    },
    {
      label: 'Xaignabouli [Sayaboury]',
      key: 'XA',
    },
    {
      label: 'Xékong [Sékong]',
      key: 'XE',
    },
    {
      label: 'Xiangkhoang [Xieng Khouang]',
      key: 'XI',
    },
    {
      label: 'ແຂວງໄຊສົມບູນ',
      key: 'XS',
    },
  ],
  LVA: [
    {
      label: 'Aizkraukles novads',
      key: '002',
    },
    {
      label: 'Alūksnes novads',
      key: '007',
    },
    {
      label: 'Ādažu novads',
      key: '011',
    },
    {
      label: 'Balvu novads',
      key: '015',
    },
    {
      label: 'Bauskas novads',
      key: '016',
    },
    {
      label: 'Cēsu novads',
      key: '022',
    },
    {
      label: 'Dobeles novads',
      key: '026',
    },
    {
      label: 'Gulbenes novads',
      key: '033',
    },
    {
      label: 'Jelgavas novads',
      key: '041',
    },
    {
      label: 'Jēkabpils novads',
      key: '042',
    },
    {
      label: 'Krāslavas novads',
      key: '047',
    },
    {
      label: 'Kuldīgas novads',
      key: '050',
    },
    {
      label: 'Ķekavas novads',
      key: '052',
    },
    {
      label: 'Limbažu novads',
      key: '054',
    },
    {
      label: 'Līvānu novads',
      key: '056',
    },
    {
      label: 'Ludzas novads',
      key: '058',
    },
    {
      label: 'Madonas novads',
      key: '059',
    },
    {
      label: 'Mārupes novads',
      key: '062',
    },
    {
      label: 'Ogres novads',
      key: '067',
    },
    {
      label: 'Olaines novads',
      key: '068',
    },
    {
      label: 'Preiļu novads',
      key: '073',
    },
    {
      label: 'Rēzeknes novads',
      key: '077',
    },
    {
      label: 'Ropažu novads',
      key: '080',
    },
    {
      label: 'Salaspils novads',
      key: '087',
    },
    {
      label: 'Saldus novads',
      key: '088',
    },
    {
      label: 'Saulkrastu novads',
      key: '089',
    },
    {
      label: 'Siguldas novads',
      key: '091',
    },
    {
      label: 'Smiltenes novads',
      key: '094',
    },
    {
      label: 'Talsu novads',
      key: '097',
    },
    {
      label: 'Tukuma novads',
      key: '099',
    },
    {
      label: 'Valkas novads',
      key: '101',
    },
    {
      label: 'Varakļānu novads',
      key: '102',
    },
    {
      label: 'Ventspils novads',
      key: '106',
    },
    {
      label: 'Augšdaugavas novads',
      key: '111',
    },
    {
      label: 'Dienvidkurzemes Novads',
      key: '112',
    },
    {
      label: 'Valmieras Novads',
      key: '113',
    },
    {
      label: 'Daugavpils',
      key: 'DGV',
    },
    {
      label: 'Jelgava',
      key: 'JEL',
    },
    {
      label: 'Jurmala',
      key: 'JUR',
    },
    {
      label: 'Liepaja',
      key: 'LPX',
    },
    {
      label: 'Rezekne',
      key: 'REZ',
    },
    {
      label: 'Riga',
      key: 'RIX',
    },
    {
      label: 'Ventspils',
      key: 'VEN',
    },
  ],
  LBN: [
    {
      label: 'محافظة عكار',
      key: 'AK',
    },
    {
      label: 'Loubnâne ech Chemâli',
      key: 'AS',
    },
    {
      label: 'Beirut',
      key: 'BA',
    },
    {
      label: 'محافظة بعلبك الهرمل',
      key: 'BH',
    },
    {
      label: 'El Béqaa',
      key: 'BI',
    },
    {
      label: 'Loubnâne ej Jnoûbi',
      key: 'JA',
    },
    {
      label: 'Jabal Loubnâne',
      key: 'JL',
    },
    {
      label: 'Nabatîyé',
      key: 'NA',
    },
  ],
  LSO: [
    {
      label: 'Maseru',
      key: 'A',
    },
    {
      label: 'Butha-Buthe',
      key: 'B',
    },
    {
      label: 'Leribe',
      key: 'C',
    },
    {
      label: 'Berea',
      key: 'D',
    },
    {
      label: 'Mafeteng',
      key: 'E',
    },
    {
      label: "Mohale's Hoek",
      key: 'F',
    },
    {
      label: 'Quthing',
      key: 'G',
    },
    {
      label: "Qacha's Nek",
      key: 'H',
    },
    {
      label: 'Mokhotlong',
      key: 'J',
    },
    {
      label: 'Thaba-Tseka',
      key: 'K',
    },
  ],
  LBR: [
    {
      label: 'Bong',
      key: 'BG',
    },
    {
      label: 'Bomi',
      key: 'BM',
    },
    {
      label: 'Grand Cape Mount',
      key: 'CM',
    },
    {
      label: 'Grand Bassa',
      key: 'GB',
    },
    {
      label: 'Grand Gedeh',
      key: 'GG',
    },
    {
      label: 'Grand Kru',
      key: 'GK',
    },
    {
      label: 'Gbarpolu',
      key: 'GP',
    },
    {
      label: 'Lofa',
      key: 'LO',
    },
    {
      label: 'Margibi',
      key: 'MG',
    },
    {
      label: 'Montserrado',
      key: 'MO',
    },
    {
      label: 'Maryland',
      key: 'MY',
    },
    {
      label: 'Nimba',
      key: 'NI',
    },
    {
      label: 'River Gee',
      key: 'RG',
    },
    {
      label: 'Rivercess',
      key: 'RI',
    },
    {
      label: 'Sinoe',
      key: 'SI',
    },
    {
      label: 'Gbarpolu',
      key: 'X1~',
    },
    {
      label: 'River Gee',
      key: 'X2~',
    },
  ],
  LBY: [
    {
      label: 'Banghazi',
      key: 'BA',
    },
    {
      label: 'Al Butnan',
      key: 'BU',
    },
    {
      label: 'Darnah',
      key: 'DR',
    },
    {
      label: 'Ghat',
      key: 'GT',
    },
    {
      label: 'Al Jabal al Akhḑar',
      key: 'JA',
    },
    {
      label: 'Al Jabal al Gharbī',
      key: 'JG',
    },
    {
      label: 'Al Jifarah',
      key: 'JI',
    },
    {
      label: 'Al Jufrah',
      key: 'JU',
    },
    {
      label: 'Al Kufrah',
      key: 'KF',
    },
    {
      label: 'Al Murqub',
      key: 'MB',
    },
    {
      label: 'Mişrātah',
      key: 'MI',
    },
    {
      label: 'Al Marj',
      key: 'MJ',
    },
    {
      label: 'Murzuq',
      key: 'MQ',
    },
    {
      label: 'Nālūt',
      key: 'NL',
    },
    {
      label: 'An Nuqaţ al Khams',
      key: 'NQ',
    },
    {
      label: 'Sabhā',
      key: 'SB',
    },
    {
      label: 'Sirte',
      key: 'SR',
    },
    {
      label: 'Tarabulus',
      key: 'TB',
    },
    {
      label: 'Al Wahat',
      key: 'WA',
    },
    {
      label: 'Wādī al Ḩayāt',
      key: 'WD',
    },
    {
      label: 'Wādī ash Shāţiʾ',
      key: 'WS',
    },
    {
      label: 'Az Zawiyah',
      key: 'ZA',
    },
  ],
  LIE: [
    {
      label: 'Balzers',
      key: '01',
    },
    {
      label: 'Eschen',
      key: '02',
    },
    {
      label: 'Gamprin',
      key: '03',
    },
    {
      label: 'Mauren',
      key: '04',
    },
    {
      label: 'Planken',
      key: '05',
    },
    {
      label: 'Ruggell',
      key: '06',
    },
    {
      label: 'Schaan',
      key: '07',
    },
    {
      label: 'Schellenberg',
      key: '08',
    },
    {
      label: 'Triesen',
      key: '09',
    },
    {
      label: 'Triesenberg',
      key: '10',
    },
    {
      label: 'Vaduz',
      key: '11',
    },
  ],
  LTU: [
    {
      label: 'Akmenės rajono savivaldybė',
      key: '01',
    },
    {
      label: 'Alytaus miesto savivaldybė',
      key: '02',
    },
    {
      label: 'Alytaus rajono savivaldybė',
      key: '03',
    },
    {
      label: 'Anykščių rajono savivaldybė',
      key: '04',
    },
    {
      label: 'Birštono savivaldybė',
      key: '05',
    },
    {
      label: 'Biržų rajono savivaldybė',
      key: '06',
    },
    {
      label: 'Druskininkų savivaldybė',
      key: '07',
    },
    {
      label: 'Elektrėnų savivaldybė',
      key: '08',
    },
    {
      label: 'Ignalinos rajono savivaldybė',
      key: '09',
    },
    {
      label: 'Jonavos rajono savivaldybė',
      key: '10',
    },
    {
      label: 'Joniškio rajono savivaldybė',
      key: '11',
    },
    {
      label: 'Jurbarko rajono savivaldybė',
      key: '12',
    },
    {
      label: 'Kaišiadorių rajono savivaldybė',
      key: '13',
    },
    {
      label: 'Kalvarijos savivaldybė',
      key: '14',
    },
    {
      label: 'Kauno miesto savivaldybė',
      key: '15',
    },
    {
      label: 'Kauno rajono savivaldybė',
      key: '16',
    },
    {
      label: 'Kazlų Rūdos savivaldybė',
      key: '17',
    },
    {
      label: 'Kėdainių rajono savivaldybė',
      key: '18',
    },
    {
      label: 'Kelmės rajono savivaldybė',
      key: '19',
    },
    {
      label: 'Klaipėdos miesto savivaldybė',
      key: '20',
    },
    {
      label: 'Klaipėdos rajono savivaldybė',
      key: '21',
    },
    {
      label: 'Kretingos rajono savivaldybė',
      key: '22',
    },
    {
      label: 'Kupiškio rajono savivaldybė',
      key: '23',
    },
    {
      label: 'Lazdijų rajono savivaldybė',
      key: '24',
    },
    {
      label: 'Marijampolės savivaldybė',
      key: '25',
    },
    {
      label: 'Mažeikių rajono savivaldybė',
      key: '26',
    },
    {
      label: 'Molėtų rajono savivaldybė',
      key: '27',
    },
    {
      label: 'Neringos savivaldybė',
      key: '28',
    },
    {
      label: 'Pagėgių savivaldybė',
      key: '29',
    },
    {
      label: 'Pakruojo rajono savivaldybė',
      key: '30',
    },
    {
      label: 'Palangos miesto savivaldybė',
      key: '31',
    },
    {
      label: 'Panevėžio miesto savivaldybė',
      key: '32',
    },
    {
      label: 'Panevėžio rajono savivaldybė',
      key: '33',
    },
    {
      label: 'Pasvalio rajono savivaldybė',
      key: '34',
    },
    {
      label: 'Plungės rajono savivaldybė',
      key: '35',
    },
    {
      label: 'Prienų rajono savivaldybė',
      key: '36',
    },
    {
      label: 'Radviliškio rajono savivaldybė',
      key: '37',
    },
    {
      label: 'Raseinių rajono savivaldybė',
      key: '38',
    },
    {
      label: 'Rietavo savivaldybė',
      key: '39',
    },
    {
      label: 'Rokiškio rajono savivaldybė',
      key: '40',
    },
    {
      label: 'Šakių rajono savivaldybė',
      key: '41',
    },
    {
      label: 'Šalčininkų rajono savivaldybė',
      key: '42',
    },
    {
      label: 'Šiaulių miesto savivaldybė',
      key: '43',
    },
    {
      label: 'Šiaulių rajono savivaldybė',
      key: '44',
    },
    {
      label: 'Šilalės rajono savivaldybė',
      key: '45',
    },
    {
      label: 'Šilutės rajono savivaldybė',
      key: '46',
    },
    {
      label: 'Širvintų rajono savivaldybė',
      key: '47',
    },
    {
      label: 'Skuodo rajono savivaldybė',
      key: '48',
    },
    {
      label: 'Švenčionių rajono savivaldybė',
      key: '49',
    },
    {
      label: 'Tauragės rajono savivaldybė',
      key: '50',
    },
    {
      label: 'Telšių rajono savivaldybė',
      key: '51',
    },
    {
      label: 'Trakų rajono savivaldybė',
      key: '52',
    },
    {
      label: 'Ukmergės rajono savivaldybė',
      key: '53',
    },
    {
      label: 'Utenos rajono savivaldybė',
      key: '54',
    },
    {
      label: 'Varėnos rajono savivaldybė',
      key: '55',
    },
    {
      label: 'Vilkaviškio rajono savivaldybė',
      key: '56',
    },
    {
      label: 'Vilniaus miesto savivaldybė',
      key: '57',
    },
    {
      label: 'Vilniaus rajono savivaldybė',
      key: '58',
    },
    {
      label: 'Visagino savivaldybė',
      key: '59',
    },
    {
      label: 'Zarasų rajono savivaldybė',
      key: '60',
    },
    {
      label: 'Alytaus Apskritis',
      key: 'AL',
    },
    {
      label: 'Klaipedos Apskritis',
      key: 'KL',
    },
    {
      label: 'Kauno Apskritis',
      key: 'KU',
    },
    {
      label: 'Marijampoles Apskritis',
      key: 'MR',
    },
    {
      label: 'Panevežio Apskritis',
      key: 'PN',
    },
    {
      label: 'Šiauliu Apskritis',
      key: 'SA',
    },
    {
      label: 'Taurages Apskritis',
      key: 'TA',
    },
    {
      label: 'Telšiu Apskritis',
      key: 'TE',
    },
    {
      label: 'Utenos Apskritis',
      key: 'UT',
    },
    {
      label: 'Vilniaus Apskritis',
      key: 'VL',
    },
  ],
  LUX: [
    {
      label: 'Kapellen',
      key: 'CA',
    },
    {
      label: 'Klierf',
      key: 'CL',
    },
    {
      label: 'Diekrech',
      key: 'DI',
    },
    {
      label: 'Iechternach',
      key: 'EC',
    },
    {
      label: 'Esch-Uelzecht',
      key: 'ES',
    },
    {
      label: 'Gréivemaacher',
      key: 'GR',
    },
    {
      label: 'Lëtzebuerg',
      key: 'LU',
    },
    {
      label: 'Miersch',
      key: 'ME',
    },
    {
      label: 'Réiden-Atert',
      key: 'RD',
    },
    {
      label: 'Réimech',
      key: 'RM',
    },
    {
      label: 'Veianen',
      key: 'VD',
    },
    {
      label: 'Wolz',
      key: 'WI',
    },
  ],
  MAC: [],
  MDG: [
    {
      label: 'Toamasina',
      key: 'A',
    },
    {
      label: 'Antsiranana',
      key: 'D',
    },
    {
      label: 'Fianarantsoa',
      key: 'F',
    },
    {
      label: 'Mahajanga',
      key: 'M',
    },
    {
      label: 'Antananarivo',
      key: 'T',
    },
    {
      label: 'Toliara',
      key: 'U',
    },
  ],
  MWI: [
    {
      label: 'Balaka',
      key: 'BA',
    },
    {
      label: 'Blantyre',
      key: 'BL',
    },
    {
      label: 'Central',
      key: 'C',
    },
    {
      label: 'Chikwawa',
      key: 'CK',
    },
    {
      label: 'Chiradzulu',
      key: 'CR',
    },
    {
      label: 'Chitipa',
      key: 'CT',
    },
    {
      label: 'Dedza',
      key: 'DE',
    },
    {
      label: 'Dowa',
      key: 'DO',
    },
    {
      label: 'Karonga',
      key: 'KR',
    },
    {
      label: 'Kasungu',
      key: 'KS',
    },
    {
      label: 'Lilongwe',
      key: 'LI',
    },
    {
      label: 'Likoma Island',
      key: 'LK',
    },
    {
      label: 'Mchinji',
      key: 'MC',
    },
    {
      label: 'Mangochi',
      key: 'MG',
    },
    {
      label: 'Machinga',
      key: 'MH',
    },
    {
      label: 'Mulanje',
      key: 'MU',
    },
    {
      label: 'Mwanza',
      key: 'MW',
    },
    {
      label: 'Mzimba',
      key: 'MZ',
    },
    {
      label: 'Northern',
      key: 'N',
    },
    {
      label: 'Nkhata Bay',
      key: 'NB',
    },
    {
      label: 'Neno',
      key: 'NE',
    },
    {
      label: 'Ntchisi',
      key: 'NI',
    },
    {
      label: 'Nkhotakota',
      key: 'NK',
    },
    {
      label: 'Nsanje',
      key: 'NS',
    },
    {
      label: 'Ntcheu',
      key: 'NU',
    },
    {
      label: 'Phalombe',
      key: 'PH',
    },
    {
      label: 'Rumphi',
      key: 'RU',
    },
    {
      label: 'Southern',
      key: 'S',
    },
    {
      label: 'Salima',
      key: 'SA',
    },
    {
      label: 'Thyolo',
      key: 'TH',
    },
    {
      label: 'Zomba',
      key: 'ZO',
    },
  ],
  MYS: [
    {
      label: 'Johor',
      key: '01',
    },
    {
      label: 'Kedah',
      key: '02',
    },
    {
      label: 'Kelantan',
      key: '03',
    },
    {
      label: 'Melaka',
      key: '04',
    },
    {
      label: 'Negeri Sembilan',
      key: '05',
    },
    {
      label: 'Pahang',
      key: '06',
    },
    {
      label: 'Pulau Pinang',
      key: '07',
    },
    {
      label: 'Perak',
      key: '08',
    },
    {
      label: 'Perlis',
      key: '09',
    },
    {
      label: 'Selangor',
      key: '10',
    },
    {
      label: 'Terengganu',
      key: '11',
    },
    {
      label: 'Sabah',
      key: '12',
    },
    {
      label: 'Sarawak',
      key: '13',
    },
    {
      label: 'Wilayah Persekutuan Kuala Lumpur',
      key: '14',
    },
    {
      label: 'Wilayah Persekutuan Labuan',
      key: '15',
    },
    {
      label: 'Wilayah Persekutuan Putrajaya',
      key: '16',
    },
  ],
  MDV: [
    {
      label: 'Ariatholhu Dhekunuburi',
      key: '00',
    },
    {
      label: 'Addu',
      key: '01',
    },
    {
      label: 'Ariatholhu Uthuruburi',
      key: '02',
    },
    {
      label: 'Faadhippolhu',
      key: '03',
    },
    {
      label: 'Felidheatholhu',
      key: '04',
    },
    {
      label: 'Hahdhunmathi',
      key: '05',
    },
    {
      label: 'Thiladhunmathee Uthuruburi',
      key: '07',
    },
    {
      label: 'Kolhumadulu',
      key: '08',
    },
    {
      label: 'Mulakatholhu',
      key: '12',
    },
    {
      label: 'Maalhosmadulu Uthuruburi',
      key: '13',
    },
    {
      label: 'Nilandheatholhu Uthuruburi',
      key: '14',
    },
    {
      label: 'Nilandheatholhu Dhekunuburi',
      key: '17',
    },
    {
      label: 'Maalhosmadulu Dhekunuburi',
      key: '20',
    },
    {
      label: 'Thiladhunmathee Dhekunuburi',
      key: '23',
    },
    {
      label: 'Miladhunmadulu Uthuruburi',
      key: '24',
    },
    {
      label: 'Miladhunmadulu Dhekunuburi',
      key: '25',
    },
    {
      label: 'Maaleatholhu',
      key: '26',
    },
    {
      label: 'Huvadhuatholhu Uthuruburi',
      key: '27',
    },
    {
      label: 'Huvadhuatholhu Dhekunuburi',
      key: '28',
    },
    {
      label: 'Fuvammulah',
      key: '29',
    },
    {
      label: 'Male',
      key: 'MLE',
    },
  ],
  MLI: [
    {
      label: 'Kayes',
      key: '1',
    },
    {
      label: 'Région de Taoudénit',
      key: '10',
    },
    {
      label: 'Koulikoro',
      key: '2',
    },
    {
      label: 'Sikasso',
      key: '3',
    },
    {
      label: 'Ségou',
      key: '4',
    },
    {
      label: 'Mopti',
      key: '5',
    },
    {
      label: 'Tombouctou',
      key: '6',
    },
    {
      label: 'Gao',
      key: '7',
    },
    {
      label: 'Kidal',
      key: '8',
    },
    {
      label: 'Région de Ménaka',
      key: '9',
    },
    {
      label: 'Bamako',
      key: 'BKO',
    },
  ],
  MLT: [
    {
      label: 'Attard',
      key: '01',
    },
    {
      label: 'Balzan',
      key: '02',
    },
    {
      label: 'Birgu',
      key: '03',
    },
    {
      label: 'Birkirkara',
      key: '04',
    },
    {
      label: 'Birżebbuġa',
      key: '05',
    },
    {
      label: 'Bormla',
      key: '06',
    },
    {
      label: 'Dingli',
      key: '07',
    },
    {
      label: 'Fgura',
      key: '08',
    },
    {
      label: 'Floriana',
      key: '09',
    },
    {
      label: 'Fontana',
      key: '10',
    },
    {
      label: 'Gudja',
      key: '11',
    },
    {
      label: 'Gżira',
      key: '12',
    },
    {
      label: 'Għajnsielem',
      key: '13',
    },
    {
      label: 'Għarb',
      key: '14',
    },
    {
      label: 'Għargħur',
      key: '15',
    },
    {
      label: 'Għasri',
      key: '16',
    },
    {
      label: 'Għaxaq',
      key: '17',
    },
    {
      label: 'Ħamrun',
      key: '18',
    },
    {
      label: 'Iklin',
      key: '19',
    },
    {
      label: 'Isla',
      key: '20',
    },
    {
      label: 'Kalkara',
      key: '21',
    },
    {
      label: 'Kerċem',
      key: '22',
    },
    {
      label: 'Kirkop',
      key: '23',
    },
    {
      label: 'Lija',
      key: '24',
    },
    {
      label: 'Luqa',
      key: '25',
    },
    {
      label: 'Marsa',
      key: '26',
    },
    {
      label: 'Marsaskala',
      key: '27',
    },
    {
      label: 'Marsaxlokk',
      key: '28',
    },
    {
      label: 'Mdina',
      key: '29',
    },
    {
      label: 'Mellieħa',
      key: '30',
    },
    {
      label: 'Mġarr',
      key: '31',
    },
    {
      label: 'Mosta',
      key: '32',
    },
    {
      label: 'Mqabba',
      key: '33',
    },
    {
      label: 'Msida',
      key: '34',
    },
    {
      label: 'Mtarfa',
      key: '35',
    },
    {
      label: 'Munxar',
      key: '36',
    },
    {
      label: 'Nadur',
      key: '37',
    },
    {
      label: 'Naxxar',
      key: '38',
    },
    {
      label: 'Paola',
      key: '39',
    },
    {
      label: 'Pembroke',
      key: '40',
    },
    {
      label: 'Pietà',
      key: '41',
    },
    {
      label: 'Qala',
      key: '42',
    },
    {
      label: 'Qormi',
      key: '43',
    },
    {
      label: 'Qrendi',
      key: '44',
    },
    {
      label: 'Rabat Gozo',
      key: '45',
    },
    {
      label: 'Rabat Malta',
      key: '46',
    },
    {
      label: 'Safi',
      key: '47',
    },
    {
      label: "Saint Julian's",
      key: '48',
    },
    {
      label: 'Saint John',
      key: '49',
    },
    {
      label: 'Saint Lawrence',
      key: '50',
    },
    {
      label: "Saint Paul's Bay",
      key: '51',
    },
    {
      label: 'Sannat',
      key: '52',
    },
    {
      label: "Saint Lucia's",
      key: '53',
    },
    {
      label: 'Santa Venera',
      key: '54',
    },
    {
      label: 'Siġġiewi',
      key: '55',
    },
    {
      label: 'Sliema',
      key: '56',
    },
    {
      label: 'Swieqi',
      key: '57',
    },
    {
      label: "Ta' Xbiex",
      key: '58',
    },
    {
      label: 'Tarxien',
      key: '59',
    },
    {
      label: 'Valletta',
      key: '60',
    },
    {
      label: 'Xagħra',
      key: '61',
    },
    {
      label: 'Xewkija',
      key: '62',
    },
    {
      label: 'Xgħajra',
      key: '63',
    },
    {
      label: 'Żabbar',
      key: '64',
    },
    {
      label: 'Żebbuġ Gozo',
      key: '65',
    },
    {
      label: 'Żebbuġ Malta',
      key: '66',
    },
    {
      label: 'Żejtun',
      key: '67',
    },
    {
      label: 'Żurrieq',
      key: '68',
    },
  ],
  MHL: [
    {
      label: 'Ailuk',
      key: 'ALK',
    },
    {
      label: 'Ailinglapalap',
      key: 'ALL',
    },
    {
      label: 'Arno',
      key: 'ARN',
    },
    {
      label: 'Aur',
      key: 'AUR',
    },
    {
      label: 'Ebon',
      key: 'EBO',
    },
    {
      label: 'Eniwetok',
      key: 'ENI',
    },
    {
      label: 'Jabat',
      key: 'JAB',
    },
    {
      label: 'Jaluit',
      key: 'JAL',
    },
    {
      label: 'Kili',
      key: 'KIL',
    },
    {
      label: 'Kwajalein',
      key: 'KWA',
    },
    {
      label: 'Ralik Chain',
      key: 'L',
    },
    {
      label: 'Lae',
      key: 'LAE',
    },
    {
      label: 'Lib',
      key: 'LIB',
    },
    {
      label: 'Likiep',
      key: 'LIK',
    },
    {
      label: 'Majuro',
      key: 'MAJ',
    },
    {
      label: 'Maloelap',
      key: 'MAL',
    },
    {
      label: 'Mejit',
      key: 'MEJ',
    },
    {
      label: 'Mili',
      key: 'MIL',
    },
    {
      label: 'Namorik',
      key: 'NMK',
    },
    {
      label: 'Namu',
      key: 'NMU',
    },
    {
      label: 'Rongelap',
      key: 'RON',
    },
    {
      label: 'Ratak Chain',
      key: 'T',
    },
    {
      label: 'Ujae',
      key: 'UJA',
    },
    {
      label: 'Ujelang',
      key: 'UJL',
    },
    {
      label: 'Utirik',
      key: 'UTI',
    },
    {
      label: 'Wotho',
      key: 'WTH',
    },
    {
      label: 'Wotje',
      key: 'WTJ',
    },
  ],
  MTQ: [],
  MRT: [
    {
      label: 'Hodh ech Chargui',
      key: '01',
    },
    {
      label: 'Hodh el Gharbi',
      key: '02',
    },
    {
      label: 'Assaba',
      key: '03',
    },
    {
      label: 'Gorgol',
      key: '04',
    },
    {
      label: 'Brakna',
      key: '05',
    },
    {
      label: 'Trarza',
      key: '06',
    },
    {
      label: 'Adrar',
      key: '07',
    },
    {
      label: 'Dakhlet Nouâdhibou',
      key: '08',
    },
    {
      label: 'Tagant',
      key: '09',
    },
    {
      label: 'Guidimaka',
      key: '10',
    },
    {
      label: 'Tiris Zemmour',
      key: '11',
    },
    {
      label: 'Inchiri',
      key: '12',
    },
    {
      label: 'Nouakchott Ouest',
      key: '13',
    },
    {
      label: 'نواكشوط الشمالية',
      key: '14',
    },
    {
      label: 'نواكشوط الجنوبية',
      key: '15',
    },
  ],
  MUS: [
    {
      label: 'Agalega Islands',
      key: 'AG',
    },
    {
      label: 'Black River',
      key: 'BL',
    },
    {
      label: 'Cargados Carajos Shoals [Saint Brandon Islands]',
      key: 'CC',
    },
    {
      label: 'Flacq',
      key: 'FL',
    },
    {
      label: 'Grand Port',
      key: 'GP',
    },
    {
      label: 'Moka',
      key: 'MO',
    },
    {
      label: 'Pamplemousses',
      key: 'PA',
    },
    {
      label: 'Port Louis City',
      key: 'PL',
    },
    {
      label: 'Plaines Wilhems',
      key: 'PW',
    },
    {
      label: 'Rodrigues Island',
      key: 'RO',
    },
    {
      label: 'Rivière du Rempart',
      key: 'RR',
    },
    {
      label: 'Savanne',
      key: 'SA',
    },
  ],
  MYT: [],
  MEX: [
    {
      label: 'Aguascalientes',
      key: 'AGU',
    },
    {
      label: 'Baja California',
      key: 'BCN',
    },
    {
      label: 'Baja California Sur',
      key: 'BCS',
    },
    {
      label: 'Campeche',
      key: 'CAM',
    },
    {
      label: 'Chihuahua',
      key: 'CHH',
    },
    {
      label: 'Chiapas',
      key: 'CHP',
    },
    {
      label: 'Ciudad de México',
      key: 'CMX',
    },
    {
      label: 'Coahuila',
      key: 'COA',
    },
    {
      label: 'Colima',
      key: 'COL',
    },
    {
      label: 'Durango',
      key: 'DUR',
    },
    {
      label: 'Guerrero',
      key: 'GRO',
    },
    {
      label: 'Guanajuato',
      key: 'GUA',
    },
    {
      label: 'Hidalgo',
      key: 'HID',
    },
    {
      label: 'Jalisco',
      key: 'JAL',
    },
    {
      label: 'México',
      key: 'MEX',
    },
    {
      label: 'Michoacán',
      key: 'MIC',
    },
    {
      label: 'Morelos',
      key: 'MOR',
    },
    {
      label: 'Nayarit',
      key: 'NAY',
    },
    {
      label: 'Nuevo León',
      key: 'NLE',
    },
    {
      label: 'Oaxaca',
      key: 'OAX',
    },
    {
      label: 'Puebla',
      key: 'PUE',
    },
    {
      label: 'Querétaro',
      key: 'QUE',
    },
    {
      label: 'Quintana Roo',
      key: 'ROO',
    },
    {
      label: 'Sinaloa',
      key: 'SIN',
    },
    {
      label: 'San Luis Potosí',
      key: 'SLP',
    },
    {
      label: 'Sonora',
      key: 'SON',
    },
    {
      label: 'Tabasco',
      key: 'TAB',
    },
    {
      label: 'Tamaulipas',
      key: 'TAM',
    },
    {
      label: 'Tlaxcala',
      key: 'TLA',
    },
    {
      label: 'Veracruz',
      key: 'VER',
    },
    {
      label: 'Yucatán',
      key: 'YUC',
    },
    {
      label: 'Zacatecas',
      key: 'ZAC',
    },
  ],
  FSM: [
    {
      label: 'Kosrae',
      key: 'KSA',
    },
    {
      label: 'Pohnpei',
      key: 'PNI',
    },
    {
      label: 'Chuuk',
      key: 'TRK',
    },
    {
      label: 'Yap',
      key: 'YAP',
    },
  ],
  MDA: [
    {
      label: 'Anenii Noi',
      key: 'AN',
    },
    {
      label: 'Balti',
      key: 'BA',
    },
    {
      label: 'Bender [Tighina]',
      key: 'BD',
    },
    {
      label: 'Briceni',
      key: 'BR',
    },
    {
      label: 'Basarabeasca',
      key: 'BS',
    },
    {
      label: 'Cahul',
      key: 'CA',
    },
    {
      label: 'Călărași',
      key: 'CL',
    },
    {
      label: 'Cimișlia',
      key: 'CM',
    },
    {
      label: 'Criuleni',
      key: 'CR',
    },
    {
      label: 'Căușeni',
      key: 'CS',
    },
    {
      label: 'Cantemir',
      key: 'CT',
    },
    {
      label: 'Chisinau',
      key: 'CU',
    },
    {
      label: 'Dondușeni',
      key: 'DO',
    },
    {
      label: 'Drochia',
      key: 'DR',
    },
    {
      label: 'Dubăsari',
      key: 'DU',
    },
    {
      label: 'Edinet',
      key: 'ED',
    },
    {
      label: 'Fălești',
      key: 'FA',
    },
    {
      label: 'Florești',
      key: 'FL',
    },
    {
      label: 'Gagauzia, Unitate Teritoriala Autonoma (UTAG)',
      key: 'GA',
    },
    {
      label: 'Glodeni',
      key: 'GL',
    },
    {
      label: 'Hîncești',
      key: 'HI',
    },
    {
      label: 'Ialoveni',
      key: 'IA',
    },
    {
      label: 'Leova',
      key: 'LE',
    },
    {
      label: 'Nisporeni',
      key: 'NI',
    },
    {
      label: 'Ocnița',
      key: 'OC',
    },
    {
      label: 'Orhei',
      key: 'OR',
    },
    {
      label: 'Rezina',
      key: 'RE',
    },
    {
      label: 'Rîșcani',
      key: 'RI',
    },
    {
      label: 'Șoldănești',
      key: 'SD',
    },
    {
      label: 'Sîngerei',
      key: 'SI',
    },
    {
      label: 'Stînga Nistrului, unitatea teritoriala din',
      key: 'SN',
    },
    {
      label: 'Soroca',
      key: 'SO',
    },
    {
      label: 'Strășeni',
      key: 'ST',
    },
    {
      label: 'Ștefan Vodă',
      key: 'SV',
    },
    {
      label: 'Taraclia',
      key: 'TA',
    },
    {
      label: 'Telenești',
      key: 'TE',
    },
    {
      label: 'Ungheni',
      key: 'UN',
    },
  ],
  MCO: [
    {
      label: 'La Colle',
      key: 'CL',
    },
    {
      label: 'La Condamine',
      key: 'CO',
    },
    {
      label: 'Fontvieille',
      key: 'FO',
    },
    {
      label: 'La Gare',
      key: 'GA',
    },
    {
      label: 'jardin exotique de Monaco',
      key: 'JE',
    },
    {
      label: 'Larvotto/Bas Moulins',
      key: 'LA',
    },
    {
      label: 'Malbousquet',
      key: 'MA',
    },
    {
      label: 'Monte-Carlo',
      key: 'MC',
    },
    {
      label: 'Moneghetti',
      key: 'MG',
    },
    {
      label: 'Monaco-Ville',
      key: 'MO',
    },
    {
      label: 'Moulins',
      key: 'MU',
    },
    {
      label: 'Port Hercule',
      key: 'PH',
    },
    {
      label: 'église Sainte-Dévote',
      key: 'SD',
    },
    {
      label: 'La Source',
      key: 'SO',
    },
    {
      label: 'Spélugues',
      key: 'SP',
    },
    {
      label: 'La Rousse',
      key: 'SR',
    },
    {
      label: 'Vallon de la Rousse',
      key: 'VR',
    },
  ],
  MNG: [
    {
      label: 'Orhon',
      key: '035',
    },
    {
      label: 'Darhan uul',
      key: '037',
    },
    {
      label: 'Hentiy',
      key: '039',
    },
    {
      label: 'Hövsgöl',
      key: '041',
    },
    {
      label: 'Hovd',
      key: '043',
    },
    {
      label: 'Uvs',
      key: '046',
    },
    {
      label: 'Töv',
      key: '047',
    },
    {
      label: 'Selenge',
      key: '049',
    },
    {
      label: 'Sühbaatar',
      key: '051',
    },
    {
      label: 'Ömnögovi',
      key: '053',
    },
    {
      label: 'Övörhangay',
      key: '055',
    },
    {
      label: 'Dzavhan',
      key: '057',
    },
    {
      label: 'Dundgovi',
      key: '059',
    },
    {
      label: 'Dornod',
      key: '061',
    },
    {
      label: 'Dornogovi',
      key: '063',
    },
    {
      label: 'Govi-Sümber',
      key: '064',
    },
    {
      label: 'Govi-Altay',
      key: '065',
    },
    {
      label: 'Bulgan',
      key: '067',
    },
    {
      label: 'Bayanhongor',
      key: '069',
    },
    {
      label: 'Bayan-Ölgiy',
      key: '071',
    },
    {
      label: 'Arhangay',
      key: '073',
    },
    {
      label: 'Ulaanbaatar',
      key: '1',
    },
  ],
  MNE: [
    {
      label: 'Andrijevica',
      key: '01',
    },
    {
      label: 'Bar',
      key: '02',
    },
    {
      label: 'Berane',
      key: '03',
    },
    {
      label: 'Bijelo Polje',
      key: '04',
    },
    {
      label: 'Budva',
      key: '05',
    },
    {
      label: 'Cetinje',
      key: '06',
    },
    {
      label: 'Danilovgrad',
      key: '07',
    },
    {
      label: 'Herceg-Novi',
      key: '08',
    },
    {
      label: 'Kolašin',
      key: '09',
    },
    {
      label: 'Kotor',
      key: '10',
    },
    {
      label: 'Mojkovac',
      key: '11',
    },
    {
      label: 'Nikšic´',
      key: '12',
    },
    {
      label: 'Plav',
      key: '13',
    },
    {
      label: 'Pljevlja',
      key: '14',
    },
    {
      label: 'Plužine',
      key: '15',
    },
    {
      label: 'Podgorica',
      key: '16',
    },
    {
      label: 'Rožaje',
      key: '17',
    },
    {
      label: 'Šavnik',
      key: '18',
    },
    {
      label: 'Tivat',
      key: '19',
    },
    {
      label: 'Ulcinj',
      key: '20',
    },
    {
      label: 'Žabljak',
      key: '21',
    },
    {
      label: 'Општина Гусиње',
      key: '22',
    },
    {
      label: 'Општина Петњица',
      key: '23',
    },
    {
      label: 'Tuzi',
      key: '24',
    },
  ],
  MSR: [],
  MAR: [
    {
      label: 'Tanger-Tétouan-Al Hoceïma',
      key: '01',
    },
    {
      label: "L'Oriental",
      key: '02',
    },
    {
      label: 'Fès-Meknès',
      key: '03',
    },
    {
      label: 'Rabat-Salé-Kénitra',
      key: '04',
    },
    {
      label: 'Béni Mellal-Khénifra',
      key: '05',
    },
    {
      label: 'Casablanca-Settat',
      key: '06',
    },
    {
      label: 'Marrakech-Safi',
      key: '07',
    },
    {
      label: 'Drâa-Tafilalet',
      key: '08',
    },
    {
      label: 'Souss-Massa',
      key: '09',
    },
    {
      label: 'Guelmim-Oued Noun (EH-partial)',
      key: '10',
    },
    {
      label: 'Laâyoune-Sakia El Hamra (EH-partial)',
      key: '11',
    },
    {
      label: 'Dakhla-Oued Ed-Dahab (EH)',
      key: '12',
    },
    {
      label: 'Agadir*',
      key: 'AGD',
    },
    {
      label: 'Aousserd',
      key: 'AOU',
    },
    {
      label: 'Assa-Zag',
      key: 'ASZ',
    },
    {
      label: 'Azilal',
      key: 'AZI',
    },
    {
      label: 'Aït Baha',
      key: 'BAH',
    },
    {
      label: 'Beni Mellal',
      key: 'BEM',
    },
    {
      label: 'Berkane',
      key: 'BER',
    },
    {
      label: 'Ben Slimane',
      key: 'BES',
    },
    {
      label: 'Boujdour (EH)',
      key: 'BOD',
    },
    {
      label: 'Boulemane',
      key: 'BOM',
    },
    {
      label: 'Berrechid',
      key: 'BRR',
    },
    {
      label: 'Casablanca [Dar el Beïda]',
      key: 'CAS',
    },
    {
      label: 'Chefchaouene',
      key: 'CHE',
    },
    {
      label: 'Chichaoua',
      key: 'CHI',
    },
    {
      label: 'Chtouka-Ait Baha',
      key: 'CHT',
    },
    {
      label: 'Driouch',
      key: 'DRI',
    },
    {
      label: 'Errachidia',
      key: 'ERR',
    },
    {
      label: 'Essaouira',
      key: 'ESI',
    },
    {
      label: 'Es Smara (EH)',
      key: 'ESM',
    },
    {
      label: 'Fahs-Beni Makada',
      key: 'FAH',
    },
    {
      label: 'Fès',
      key: 'FES',
    },
    {
      label: 'Figuig',
      key: 'FIG',
    },
    {
      label: 'Fquih Ben Salah',
      key: 'FQH',
    },
    {
      label: 'Guelmim',
      key: 'GUE',
    },
    {
      label: 'Guercif',
      key: 'GUF',
    },
    {
      label: 'El Hajeb',
      key: 'HAJ',
    },
    {
      label: 'Al Haouz',
      key: 'HAO',
    },
    {
      label: 'Al Hoceïma',
      key: 'HOC',
    },
    {
      label: 'Ifrane',
      key: 'IFR',
    },
    {
      label: 'عمالة إنزكان آيت ملول',
      key: 'INE',
    },
    {
      label: 'El Jadida',
      key: 'JDI',
    },
    {
      label: 'Jerada',
      key: 'JRA',
    },
    {
      label: 'Kénitra',
      key: 'KEN',
    },
    {
      label: 'Kelaat Sraghna',
      key: 'KES',
    },
    {
      label: 'Khemisset',
      key: 'KHE',
    },
    {
      label: 'Khenifra',
      key: 'KHN',
    },
    {
      label: 'Khouribga',
      key: 'KHO',
    },
    {
      label: 'Laâyoune* (EH)',
      key: 'LAA',
    },
    {
      label: 'Larache',
      key: 'LAR',
    },
    {
      label: 'Marrakech',
      key: 'MAR',
    },
    {
      label: 'M’diq-Fnideq',
      key: 'MDF',
    },
    {
      label: 'Médiouna',
      key: 'MED',
    },
    {
      label: 'Meknès*',
      key: 'MEK',
    },
    {
      label: 'Aït Melloul',
      key: 'MEL',
    },
    {
      label: 'Midelt',
      key: 'MID',
    },
    {
      label: 'مراكش',
      key: 'MMD',
    },
    {
      label: 'مراكش²',
      key: 'MMN',
    },
    {
      label: 'المحمدية',
      key: 'MOH',
    },
    {
      label: 'Moulay Yacoub',
      key: 'MOU',
    },
    {
      label: 'Nador',
      key: 'NAD',
    },
    {
      label: 'Nouaceur',
      key: 'NOU',
    },
    {
      label: 'Ouarzazate',
      key: 'OUA',
    },
    {
      label: 'Oued ed Dahab (EH)',
      key: 'OUD',
    },
    {
      label: 'Oujda-Angad',
      key: 'OUJ',
    },
    {
      label: 'Ouezzane',
      key: 'OUZ',
    },
    {
      label: 'الرباط',
      key: 'RAB',
    },
    {
      label: 'Rehamna',
      key: 'REH',
    },
    {
      label: 'Safi',
      key: 'SAF',
    },
    {
      label: 'Salé',
      key: 'SAL',
    },
    {
      label: 'Sefrou',
      key: 'SEF',
    },
    {
      label: 'Settat',
      key: 'SET',
    },
    {
      label: 'Sidi Bennour',
      key: 'SIB',
    },
    {
      label: 'Sidi Ifni',
      key: 'SIF',
    },
    {
      label: 'Sidi Kacem',
      key: 'SIK',
    },
    {
      label: 'Sidi Slimane',
      key: 'SIL',
    },
    {
      label: 'Skhirate-Témara',
      key: 'SKH',
    },
    {
      label: 'Sidi Youssef Ben Ali',
      key: 'SYB',
    },
    {
      label: 'Tarfaya (EH-partial)',
      key: 'TAF',
    },
    {
      label: 'Taourirt',
      key: 'TAI',
    },
    {
      label: 'Taounate',
      key: 'TAO',
    },
    {
      label: 'Taroudannt',
      key: 'TAR',
    },
    {
      label: 'Tata',
      key: 'TAT',
    },
    {
      label: 'Taza',
      key: 'TAZ',
    },
    {
      label: 'Tétouan*',
      key: 'TET',
    },
    {
      label: 'Tinghir',
      key: 'TIN',
    },
    {
      label: 'Tiznit',
      key: 'TIZ',
    },
    {
      label: 'Tanger-Assilah',
      key: 'TNG',
    },
    {
      label: 'Tan-Tan',
      key: 'TNT',
    },
    {
      label: 'Laayoune-Boujdour-Sakia El Hamra',
      key: 'X1~',
    },
    {
      label: 'Youssoufia',
      key: 'YUS',
    },
    {
      label: 'Zagora',
      key: 'ZAG',
    },
  ],
  MOZ: [
    {
      label: 'Niassa',
      key: 'A',
    },
    {
      label: 'Manica',
      key: 'B',
    },
    {
      label: 'Gaza',
      key: 'G',
    },
    {
      label: 'Inhambane',
      key: 'I',
    },
    {
      label: 'Maputo',
      key: 'L',
    },
    {
      label: 'Maputo City',
      key: 'MPM',
    },
    {
      label: 'Nampula',
      key: 'N',
    },
    {
      label: 'Cabo Delgado',
      key: 'P',
    },
    {
      label: 'Zambézia',
      key: 'Q',
    },
    {
      label: 'Sofala',
      key: 'S',
    },
    {
      label: 'Tete',
      key: 'T',
    },
  ],
  MMR: [
    {
      label: 'Sagaing',
      key: '01',
    },
    {
      label: 'Bago',
      key: '02',
    },
    {
      label: 'Magway',
      key: '03',
    },
    {
      label: 'Mandalay',
      key: '04',
    },
    {
      label: 'Tanintharyi',
      key: '05',
    },
    {
      label: 'Yangon',
      key: '06',
    },
    {
      label: 'Ayeyarwady',
      key: '07',
    },
    {
      label: 'Kachin',
      key: '11',
    },
    {
      label: 'Kayah',
      key: '12',
    },
    {
      label: 'Kayin',
      key: '13',
    },
    {
      label: 'Chin',
      key: '14',
    },
    {
      label: 'Mon',
      key: '15',
    },
    {
      label: 'Rakhine',
      key: '16',
    },
    {
      label: 'Shan',
      key: '17',
    },
    {
      label: 'နေပြည်တော် ပြည်ထောင်စုနယ်မြေ',
      key: '18',
    },
  ],
  NAM: [
    {
      label: 'Zambezi',
      key: 'CA',
    },
    {
      label: 'Erongo',
      key: 'ER',
    },
    {
      label: 'Hardap',
      key: 'HA',
    },
    {
      label: 'Karas',
      key: 'KA',
    },
    {
      label: 'Kavango East',
      key: 'KE',
    },
    {
      label: 'Khomas',
      key: 'KH',
    },
    {
      label: 'Kunene',
      key: 'KU',
    },
    {
      label: 'Kavango West',
      key: 'KW',
    },
    {
      label: 'Otjozondjupa',
      key: 'OD',
    },
    {
      label: 'Omaheke',
      key: 'OH',
    },
    {
      label: 'Oshana',
      key: 'ON',
    },
    {
      label: 'Omusati',
      key: 'OS',
    },
    {
      label: 'Oshikoto',
      key: 'OT',
    },
    {
      label: 'Ohangwena',
      key: 'OW',
    },
  ],
  NRU: [
    {
      label: 'Aiwo',
      key: '01',
    },
    {
      label: 'Anabar',
      key: '02',
    },
    {
      label: 'Anetan',
      key: '03',
    },
    {
      label: 'Anibare',
      key: '04',
    },
    {
      label: 'Baiti',
      key: '05',
    },
    {
      label: 'Boe',
      key: '06',
    },
    {
      label: 'Buada',
      key: '07',
    },
    {
      label: 'Denigomodu',
      key: '08',
    },
    {
      label: 'Ewa',
      key: '09',
    },
    {
      label: 'Ijuw',
      key: '10',
    },
    {
      label: 'Meneng',
      key: '11',
    },
    {
      label: 'Nibok',
      key: '12',
    },
    {
      label: 'Uaboe',
      key: '13',
    },
    {
      label: 'Yaren',
      key: '14',
    },
  ],
  NPL: [
    {
      label: 'मध्यमाञ्चल विकास क्षेत्र',
      key: '1',
    },
    {
      label: 'मध्य-पश्चिमाञ्चल विकास क्षेत्र',
      key: '2',
    },
    {
      label: 'पश्चिमाञ्चल विकास क्षेत्र',
      key: '3',
    },
    {
      label: 'पूर्वाञ्चल विकास क्षेत्र',
      key: '4',
    },
    {
      label: 'सुदूर-पश्चिमाञ्चल विकास क्षेत्र',
      key: '5',
    },
    {
      label: 'Bagmati',
      key: 'BA',
    },
    {
      label: 'Bheri',
      key: 'BH',
    },
    {
      label: 'Dhawalagiri',
      key: 'DH',
    },
    {
      label: 'Gandaki',
      key: 'GA',
    },
    {
      label: 'Janakpur',
      key: 'JA',
    },
    {
      label: 'Karnali',
      key: 'KA',
    },
    {
      label: 'Kosi [Koshi]',
      key: 'KO',
    },
    {
      label: 'Lumbini',
      key: 'LU',
    },
    {
      label: 'Mahakali',
      key: 'MA',
    },
    {
      label: 'Mechi',
      key: 'ME',
    },
    {
      label: 'Narayani',
      key: 'NA',
    },
    {
      label: 'Province 1',
      key: 'P1',
    },
    {
      label: 'Province 2',
      key: 'P2',
    },
    {
      label: 'Province 3',
      key: 'P3',
    },
    {
      label: 'Gandaki²',
      key: 'P4',
    },
    {
      label: 'Province 5',
      key: 'P5',
    },
    {
      label: 'Karnali²',
      key: 'P6',
    },
    {
      label: 'Province 7',
      key: 'P7',
    },
    {
      label: 'Rapti',
      key: 'RA',
    },
    {
      label: 'Sagarmatha',
      key: 'SA',
    },
    {
      label: 'Seti',
      key: 'SE',
    },
  ],
  NLD: [
    {
      label: 'Aruba',
      key: 'AW',
    },
    {
      label: 'Bonaire',
      key: 'BQ1',
    },
    {
      label: 'Saba',
      key: 'BQ2',
    },
    {
      label: 'Sint Eustatius',
      key: 'BQ3',
    },
    {
      label: 'Curaçao',
      key: 'CW',
    },
    {
      label: 'Drenthe',
      key: 'DR',
    },
    {
      label: 'Flevoland',
      key: 'FL',
    },
    {
      label: 'Friesland',
      key: 'FR',
    },
    {
      label: 'Gelderland',
      key: 'GE',
    },
    {
      label: 'Groningen',
      key: 'GR',
    },
    {
      label: 'Limburg',
      key: 'LI',
    },
    {
      label: 'Noord-Brabant',
      key: 'NB',
    },
    {
      label: 'Noord-Holland',
      key: 'NH',
    },
    {
      label: 'Overijssel',
      key: 'OV',
    },
    {
      label: 'Sint Maarten',
      key: 'SX',
    },
    {
      label: 'Utrecht',
      key: 'UT',
    },
    {
      label: 'Zeeland',
      key: 'ZE',
    },
    {
      label: 'Zuid-Holland',
      key: 'ZH',
    },
  ],
  NCL: [],
  NZL: [
    {
      label: 'Auckland',
      key: 'AUK',
    },
    {
      label: 'Bay of Plenty',
      key: 'BOP',
    },
    {
      label: 'Canterbury',
      key: 'CAN',
    },
    {
      label: 'Chatham Islands Territory',
      key: 'CIT',
    },
    {
      label: 'Gisborne',
      key: 'GIS',
    },
    {
      label: "Hawke's Bay",
      key: 'HKB',
    },
    {
      label: 'Marlborough',
      key: 'MBH',
    },
    {
      label: 'Manawatu-Wanganui',
      key: 'MWT',
    },
    {
      label: 'Nelson',
      key: 'NSN',
    },
    {
      label: 'Northland',
      key: 'NTL',
    },
    {
      label: 'Otago',
      key: 'OTA',
    },
    {
      label: 'Southland',
      key: 'STL',
    },
    {
      label: 'Tasman',
      key: 'TAS',
    },
    {
      label: 'Taranaki',
      key: 'TKI',
    },
    {
      label: 'Wellington',
      key: 'WGN',
    },
    {
      label: 'Waikato',
      key: 'WKO',
    },
    {
      label: 'West Coast',
      key: 'WTC',
    },
  ],
  NIC: [
    {
      label: 'Atlántico Norte*',
      key: 'AN',
    },
    {
      label: 'Atlántico Sur*',
      key: 'AS',
    },
    {
      label: 'Boaco',
      key: 'BO',
    },
    {
      label: 'Carazo',
      key: 'CA',
    },
    {
      label: 'Chinandega',
      key: 'CI',
    },
    {
      label: 'Chontales',
      key: 'CO',
    },
    {
      label: 'Estelí',
      key: 'ES',
    },
    {
      label: 'Granada',
      key: 'GR',
    },
    {
      label: 'Jinotega',
      key: 'JI',
    },
    {
      label: 'León',
      key: 'LE',
    },
    {
      label: 'Madriz',
      key: 'MD',
    },
    {
      label: 'Managua',
      key: 'MN',
    },
    {
      label: 'Masaya',
      key: 'MS',
    },
    {
      label: 'Matagalpa',
      key: 'MT',
    },
    {
      label: 'Nueva Segovia',
      key: 'NS',
    },
    {
      label: 'Rivas',
      key: 'RI',
    },
    {
      label: 'Río San Juan',
      key: 'SJ',
    },
  ],
  NER: [
    {
      label: 'Agadez',
      key: '1',
    },
    {
      label: 'Diffa',
      key: '2',
    },
    {
      label: 'Dosso',
      key: '3',
    },
    {
      label: 'Maradi',
      key: '4',
    },
    {
      label: 'Tahoua',
      key: '5',
    },
    {
      label: 'Tillabéri',
      key: '6',
    },
    {
      label: 'Zinder',
      key: '7',
    },
    {
      label: 'Niamey',
      key: '8',
    },
  ],
  NGA: [
    {
      label: 'Abia',
      key: 'AB',
    },
    {
      label: 'Adamawa',
      key: 'AD',
    },
    {
      label: 'Akwa Ibom',
      key: 'AK',
    },
    {
      label: 'Anambra',
      key: 'AN',
    },
    {
      label: 'Bauchi',
      key: 'BA',
    },
    {
      label: 'Benue',
      key: 'BE',
    },
    {
      label: 'Borno',
      key: 'BO',
    },
    {
      label: 'Bayelsa',
      key: 'BY',
    },
    {
      label: 'Cross River',
      key: 'CR',
    },
    {
      label: 'Delta',
      key: 'DE',
    },
    {
      label: 'Ebonyi',
      key: 'EB',
    },
    {
      label: 'Edo',
      key: 'ED',
    },
    {
      label: 'Ekiti',
      key: 'EK',
    },
    {
      label: 'Enugu',
      key: 'EN',
    },
    {
      label: 'Abuja Capital Territory',
      key: 'FC',
    },
    {
      label: 'Gombe',
      key: 'GO',
    },
    {
      label: 'Imo',
      key: 'IM',
    },
    {
      label: 'Jigawa',
      key: 'JI',
    },
    {
      label: 'Kaduna',
      key: 'KD',
    },
    {
      label: 'Kebbi',
      key: 'KE',
    },
    {
      label: 'Kano',
      key: 'KN',
    },
    {
      label: 'Kogi',
      key: 'KO',
    },
    {
      label: 'Katsina',
      key: 'KT',
    },
    {
      label: 'Kwara',
      key: 'KW',
    },
    {
      label: 'Lagos',
      key: 'LA',
    },
    {
      label: 'Nassarawa',
      key: 'NA',
    },
    {
      label: 'Niger',
      key: 'NI',
    },
    {
      label: 'Ogun',
      key: 'OG',
    },
    {
      label: 'Ondo',
      key: 'ON',
    },
    {
      label: 'Osun',
      key: 'OS',
    },
    {
      label: 'Oyo',
      key: 'OY',
    },
    {
      label: 'Plateau',
      key: 'PL',
    },
    {
      label: 'Rivers',
      key: 'RI',
    },
    {
      label: 'Sokoto',
      key: 'SO',
    },
    {
      label: 'Taraba',
      key: 'TA',
    },
    {
      label: 'Yobe',
      key: 'YO',
    },
    {
      label: 'Zamfara',
      key: 'ZA',
    },
  ],
  NIU: [],
  NFK: [],
  MKD: [
    {
      label: 'Veles',
      key: '101',
    },
    {
      label: 'Gradsko',
      key: '102',
    },
    {
      label: 'Demir Kapija',
      key: '103',
    },
    {
      label: 'Kavadarci',
      key: '104',
    },
    {
      label: 'Lozovo',
      key: '105',
    },
    {
      label: 'Negotino',
      key: '106',
    },
    {
      label: 'Rosoman',
      key: '107',
    },
    {
      label: 'Sveti Nikole',
      key: '108',
    },
    {
      label: 'Čaška',
      key: '109',
    },
    {
      label: 'Berovo',
      key: '201',
    },
    {
      label: 'Vinica',
      key: '202',
    },
    {
      label: 'Delčevo',
      key: '203',
    },
    {
      label: 'Zrnovci',
      key: '204',
    },
    {
      label: 'Karbinci',
      key: '205',
    },
    {
      label: 'Kočani',
      key: '206',
    },
    {
      label: 'Makedonska Kamenica',
      key: '207',
    },
    {
      label: 'Pehčevo',
      key: '208',
    },
    {
      label: 'Probištip',
      key: '209',
    },
    {
      label: 'Češinovo-Obleševo',
      key: '210',
    },
    {
      label: 'Štip',
      key: '211',
    },
    {
      label: 'Vevčani',
      key: '301',
    },
    {
      label: 'Debar',
      key: '303',
    },
    {
      label: 'Debarca',
      key: '304',
    },
    {
      label: 'Kičevo',
      key: '307',
    },
    {
      label: 'Makedonski Brod',
      key: '308',
    },
    {
      label: 'Ohrid',
      key: '310',
    },
    {
      label: 'Plasnica',
      key: '311',
    },
    {
      label: 'Struga',
      key: '312',
    },
    {
      label: 'Centar Župa',
      key: '313',
    },
    {
      label: 'Bogdanci',
      key: '401',
    },
    {
      label: 'Bosilovo',
      key: '402',
    },
    {
      label: 'Valandovo',
      key: '403',
    },
    {
      label: 'Vasilevo',
      key: '404',
    },
    {
      label: 'Gevgelija',
      key: '405',
    },
    {
      label: 'Dojran',
      key: '406',
    },
    {
      label: 'Konče',
      key: '407',
    },
    {
      label: 'Novo Selo',
      key: '408',
    },
    {
      label: 'Radoviš',
      key: '409',
    },
    {
      label: 'Strumica',
      key: '410',
    },
    {
      label: 'Bitola',
      key: '501',
    },
    {
      label: 'Demir Hisar',
      key: '502',
    },
    {
      label: 'Dolneni',
      key: '503',
    },
    {
      label: 'Krivogaštani',
      key: '504',
    },
    {
      label: 'Kruševo',
      key: '505',
    },
    {
      label: 'Mogila',
      key: '506',
    },
    {
      label: 'Novaci',
      key: '507',
    },
    {
      label: 'Prilep',
      key: '508',
    },
    {
      label: 'Resen',
      key: '509',
    },
    {
      label: 'Bogovinje',
      key: '601',
    },
    {
      label: 'Brvenica',
      key: '602',
    },
    {
      label: 'Vrapčište',
      key: '603',
    },
    {
      label: 'Gostivar',
      key: '604',
    },
    {
      label: 'Želino',
      key: '605',
    },
    {
      label: 'Jegunovce',
      key: '606',
    },
    {
      label: 'Mavrovo i Rostuša',
      key: '607',
    },
    {
      label: 'Tearce',
      key: '608',
    },
    {
      label: 'Tetovo',
      key: '609',
    },
    {
      label: 'Kratovo',
      key: '701',
    },
    {
      label: 'Kriva Palanka',
      key: '702',
    },
    {
      label: 'Kumanovo',
      key: '703',
    },
    {
      label: 'Lipkovo',
      key: '704',
    },
    {
      label: 'Rankovce',
      key: '705',
    },
    {
      label: 'Staro Nagoričane',
      key: '706',
    },
    {
      label: 'Aerodrom †',
      key: '801',
    },
    {
      label: 'Aračinovo',
      key: '802',
    },
    {
      label: 'Butel †',
      key: '803',
    },
    {
      label: 'Gazi Baba †',
      key: '804',
    },
    {
      label: 'Gjorče Petrov †',
      key: '805',
    },
    {
      label: 'Zelenikovo',
      key: '806',
    },
    {
      label: 'Ilinden',
      key: '807',
    },
    {
      label: 'Karpoš †',
      key: '808',
    },
    {
      label: 'Kisela Voda †',
      key: '809',
    },
    {
      label: 'Petrovec',
      key: '810',
    },
    {
      label: 'Saraj †',
      key: '811',
    },
    {
      label: 'Sopište',
      key: '812',
    },
    {
      label: 'Studeničani',
      key: '813',
    },
    {
      label: 'Centar †',
      key: '814',
    },
    {
      label: 'Čair †',
      key: '815',
    },
    {
      label: 'Čučer-Sandevo',
      key: '816',
    },
    {
      label: 'Šuto Orizari †',
      key: '817',
    },
  ],
  MNP: [],
  NOR: [
    {
      label: 'Oslo',
      key: '03',
    },
    {
      label: 'Rogaland',
      key: '11',
    },
    {
      label: 'Møre og Romsdal',
      key: '15',
    },
    {
      label: 'Nordland',
      key: '18',
    },
    {
      label: 'Svalbard (Arctic Region)',
      key: '21',
    },
    {
      label: 'Jan Mayen (Arctic Region)',
      key: '22',
    },
    {
      label: 'Viken',
      key: '30',
    },
    {
      label: 'Innlandet',
      key: '34',
    },
    {
      label: 'Vestfold og Telemark',
      key: '38',
    },
    {
      label: 'Agder',
      key: '42',
    },
    {
      label: 'Vestland',
      key: '46',
    },
    {
      label: 'Trøndelag',
      key: '50',
    },
    {
      label: 'Troms og Finnmark',
      key: '54',
    },
  ],
  OMN: [
    {
      label: 'محافظة جنوب الباطنة',
      key: 'BJ',
    },
    {
      label: 'محافظة شمال الباطنة',
      key: 'BS',
    },
    {
      label: 'محافظة البريمي',
      key: 'BU',
    },
    {
      label: 'Ad Dakhiliyah',
      key: 'DA',
    },
    {
      label: 'Masqat',
      key: 'MA',
    },
    {
      label: 'Musandam',
      key: 'MU',
    },
    {
      label: 'محافظة جنوب الشرقية',
      key: 'SJ',
    },
    {
      label: 'محافظة شمال الشرقية',
      key: 'SS',
    },
    {
      label: 'Al Wustá',
      key: 'WU',
    },
    {
      label: 'Adh Dhahirah',
      key: 'ZA',
    },
    {
      label: 'محافظة ظفار',
      key: 'ZU',
    },
  ],
  PAK: [
    {
      label: 'Baluchistan (en)',
      key: 'BA',
    },
    {
      label: 'Gilgit-Baltistan',
      key: 'GB',
    },
    {
      label: 'Islamabad',
      key: 'IS',
    },
    {
      label: 'Azad Kashmir',
      key: 'JK',
    },
    {
      label: 'Khyber Pakhtunkhwa',
      key: 'KP',
    },
    {
      label: 'Punjab',
      key: 'PB',
    },
    {
      label: 'Sind (en)',
      key: 'SD',
    },
  ],
  PLW: [
    {
      label: 'Aimeliik',
      key: '002',
    },
    {
      label: 'Airai',
      key: '004',
    },
    {
      label: 'Angaur',
      key: '010',
    },
    {
      label: 'Hatobohei',
      key: '050',
    },
    {
      label: 'Kayangel',
      key: '100',
    },
    {
      label: 'Koror',
      key: '150',
    },
    {
      label: 'Melekeok',
      key: '212',
    },
    {
      label: 'Ngaraard',
      key: '214',
    },
    {
      label: 'Ngarchelong',
      key: '218',
    },
    {
      label: 'Ngardmau',
      key: '222',
    },
    {
      label: 'Ngatpang',
      key: '224',
    },
    {
      label: 'Ngchesar',
      key: '226',
    },
    {
      label: 'Ngeremlengui',
      key: '227',
    },
    {
      label: 'Ngiwal',
      key: '228',
    },
    {
      label: 'Peleliu',
      key: '350',
    },
    {
      label: 'Sonsorol',
      key: '370',
    },
  ],
  PSE: [
    {
      label: 'محافظة بيت لحم',
      key: 'BTH',
    },
    {
      label: 'الوسطى',
      key: 'DEB',
    },
    {
      label: 'محافظة غزة',
      key: 'GZA',
    },
    {
      label: 'محافظة الخليل',
      key: 'HBN',
    },
    {
      label: 'القدس',
      key: 'JEM',
    },
    {
      label: 'جنين',
      key: 'JEN',
    },
    {
      label: 'أريحا',
      key: 'JRH',
    },
    {
      label: 'محافظة خان يونس',
      key: 'KYS',
    },
    {
      label: 'محافظة نابلس',
      key: 'NBS',
    },
    {
      label: 'شمال غزة',
      key: 'NGZ',
    },
    {
      label: 'محافظة قلقيلية',
      key: 'QQA',
    },
    {
      label: 'رام الله والبيرة',
      key: 'RBH',
    },
    {
      label: 'محافظة رفح الفلسطينية',
      key: 'RFH',
    },
    {
      label: 'محافظة سلفيت',
      key: 'SLT',
    },
    {
      label: 'محافظة طوباس',
      key: 'TBS',
    },
    {
      label: 'محافظة طولكرم',
      key: 'TKM',
    },
  ],
  PAN: [
    {
      label: 'Bocas del Toro',
      key: '1',
    },
    {
      label: 'Panamá Oeste',
      key: '10',
    },
    {
      label: 'Coclé',
      key: '2',
    },
    {
      label: 'Colón',
      key: '3',
    },
    {
      label: 'Chiriquí',
      key: '4',
    },
    {
      label: 'Darién',
      key: '5',
    },
    {
      label: 'Herrera',
      key: '6',
    },
    {
      label: 'Los Santos',
      key: '7',
    },
    {
      label: 'Panamá',
      key: '8',
    },
    {
      label: 'Veraguas',
      key: '9',
    },
    {
      label: 'Emberá-Wounaan',
      key: 'EM',
    },
    {
      label: 'Guna Yala',
      key: 'KY',
    },
    {
      label: 'Ngäbe-Buglé',
      key: 'NB',
    },
  ],
  PNG: [
    {
      label: 'Chimbu',
      key: 'CPK',
    },
    {
      label: 'Central',
      key: 'CPM',
    },
    {
      label: 'East New Britain',
      key: 'EBR',
    },
    {
      label: 'Eastern Highlands',
      key: 'EHG',
    },
    {
      label: 'Enga',
      key: 'EPW',
    },
    {
      label: 'East Sepik',
      key: 'ESW',
    },
    {
      label: 'Gulf',
      key: 'GPK',
    },
    {
      label: 'Hela',
      key: 'HLA',
    },
    {
      label: 'Jiwaka',
      key: 'JWK',
    },
    {
      label: 'Milne Bay',
      key: 'MBA',
    },
    {
      label: 'Morobe',
      key: 'MPL',
    },
    {
      label: 'Madang',
      key: 'MPM',
    },
    {
      label: 'Manus',
      key: 'MRL',
    },
    {
      label: 'National Capital District (Port Moresby)',
      key: 'NCD',
    },
    {
      label: 'New Ireland',
      key: 'NIK',
    },
    {
      label: 'Northern',
      key: 'NPP',
    },
    {
      label: 'Bougainville',
      key: 'NSB',
    },
    {
      label: 'Sandaun [West Sepik]',
      key: 'SAN',
    },
    {
      label: 'Southern Highlands',
      key: 'SHM',
    },
    {
      label: 'West New Britain',
      key: 'WBK',
    },
    {
      label: 'Western Highlands',
      key: 'WHM',
    },
    {
      label: 'Western',
      key: 'WPD',
    },
  ],
  PRY: [
    {
      label: 'Concepción',
      key: '1',
    },
    {
      label: 'Alto Paraná',
      key: '10',
    },
    {
      label: 'Central',
      key: '11',
    },
    {
      label: 'Ñeembucú',
      key: '12',
    },
    {
      label: 'Amambay',
      key: '13',
    },
    {
      label: 'Canindeyú',
      key: '14',
    },
    {
      label: 'Presidente Hayes',
      key: '15',
    },
    {
      label: 'Alto Paraguay',
      key: '16',
    },
    {
      label: 'Boquerón',
      key: '19',
    },
    {
      label: 'San Pedro',
      key: '2',
    },
    {
      label: 'Cordillera',
      key: '3',
    },
    {
      label: 'Guairá',
      key: '4',
    },
    {
      label: 'Caaguazú',
      key: '5',
    },
    {
      label: 'Caazapá',
      key: '6',
    },
    {
      label: 'Itapúa',
      key: '7',
    },
    {
      label: 'Misiones',
      key: '8',
    },
    {
      label: 'Paraguarí',
      key: '9',
    },
    {
      label: 'Asunción',
      key: 'ASU',
    },
  ],
  PER: [
    {
      label: 'Amazonas',
      key: 'AMA',
    },
    {
      label: 'Ancash',
      key: 'ANC',
    },
    {
      label: 'Apurímac',
      key: 'APU',
    },
    {
      label: 'Arequipa',
      key: 'ARE',
    },
    {
      label: 'Ayacucho',
      key: 'AYA',
    },
    {
      label: 'Cajamarca',
      key: 'CAJ',
    },
    {
      label: 'El Callao',
      key: 'CAL',
    },
    {
      label: 'Cuzco [Cusco]',
      key: 'CUS',
    },
    {
      label: 'Huánuco',
      key: 'HUC',
    },
    {
      label: 'Huancavelica',
      key: 'HUV',
    },
    {
      label: 'Ica',
      key: 'ICA',
    },
    {
      label: 'Junín',
      key: 'JUN',
    },
    {
      label: 'La Libertad',
      key: 'LAL',
    },
    {
      label: 'Lambayeque',
      key: 'LAM',
    },
    {
      label: 'Lima',
      key: 'LIM',
    },
    {
      label: 'Lima Metropolitana',
      key: 'LMA',
    },
    {
      label: 'Loreto',
      key: 'LOR',
    },
    {
      label: 'Madre de Dios',
      key: 'MDD',
    },
    {
      label: 'Moquegua',
      key: 'MOQ',
    },
    {
      label: 'Pasco',
      key: 'PAS',
    },
    {
      label: 'Piura',
      key: 'PIU',
    },
    {
      label: 'Puno',
      key: 'PUN',
    },
    {
      label: 'San Martín',
      key: 'SAM',
    },
    {
      label: 'Tacna',
      key: 'TAC',
    },
    {
      label: 'Tumbes',
      key: 'TUM',
    },
    {
      label: 'Ucayali',
      key: 'UCA',
    },
  ],
  PHL: [
    {
      label: 'National Capital Region',
      key: '00',
    },
    {
      label: 'Ilocos',
      key: '01',
    },
    {
      label: 'Cagayan Valley',
      key: '02',
    },
    {
      label: 'Central Luzon',
      key: '03',
    },
    {
      label: 'Bicol',
      key: '05',
    },
    {
      label: 'Western Visayas',
      key: '06',
    },
    {
      label: 'Central Visayas',
      key: '07',
    },
    {
      label: 'Eastern Visayas',
      key: '08',
    },
    {
      label: 'Zamboanga Peninsula',
      key: '09',
    },
    {
      label: 'Northern Mindanao',
      key: '10',
    },
    {
      label: 'Davao',
      key: '11',
    },
    {
      label: 'Soccsksargen',
      key: '12',
    },
    {
      label: 'Caraga',
      key: '13',
    },
    {
      label: 'Muslim Mindanao',
      key: '14',
    },
    {
      label: 'Cordillera Administrative',
      key: '15',
    },
    {
      label: 'Calabarzon',
      key: '40',
    },
    {
      label: 'Mimaropa',
      key: '41',
    },
    {
      label: 'Abra',
      key: 'ABR',
    },
    {
      label: 'Agusan del Norte',
      key: 'AGN',
    },
    {
      label: 'Agusan del Sur',
      key: 'AGS',
    },
    {
      label: 'Aklan',
      key: 'AKL',
    },
    {
      label: 'Albay',
      key: 'ALB',
    },
    {
      label: 'Antique',
      key: 'ANT',
    },
    {
      label: 'Apayao',
      key: 'APA',
    },
    {
      label: 'Aurora',
      key: 'AUR',
    },
    {
      label: 'Bataan',
      key: 'BAN',
    },
    {
      label: 'Basilan',
      key: 'BAS',
    },
    {
      label: 'Benguet',
      key: 'BEN',
    },
    {
      label: 'Biliran',
      key: 'BIL',
    },
    {
      label: 'Bohol',
      key: 'BOH',
    },
    {
      label: 'Batangas',
      key: 'BTG',
    },
    {
      label: 'Batanes',
      key: 'BTN',
    },
    {
      label: 'Bukidnon',
      key: 'BUK',
    },
    {
      label: 'Bulacan',
      key: 'BUL',
    },
    {
      label: 'Cagayan',
      key: 'CAG',
    },
    {
      label: 'Camiguin',
      key: 'CAM',
    },
    {
      label: 'Camarines Norte',
      key: 'CAN',
    },
    {
      label: 'Capiz',
      key: 'CAP',
    },
    {
      label: 'Camarines Sur',
      key: 'CAS',
    },
    {
      label: 'Catanduanes',
      key: 'CAT',
    },
    {
      label: 'Cavite',
      key: 'CAV',
    },
    {
      label: 'Cebu',
      key: 'CEB',
    },
    {
      label: 'Compostela Valley',
      key: 'COM',
    },
    {
      label: 'Davao Oriental',
      key: 'DAO',
    },
    {
      label: 'Davao del Sur',
      key: 'DAS',
    },
    {
      label: 'Davao del Norte',
      key: 'DAV',
    },
    {
      label: 'Dinagat Islands',
      key: 'DIN',
    },
    {
      label: 'Davao Occidental',
      key: 'DVO',
    },
    {
      label: 'Eastern Samar',
      key: 'EAS',
    },
    {
      label: 'Guimaras',
      key: 'GUI',
    },
    {
      label: 'Ifugao',
      key: 'IFU',
    },
    {
      label: 'Iloilo',
      key: 'ILI',
    },
    {
      label: 'Ilocos Norte',
      key: 'ILN',
    },
    {
      label: 'Ilocos Sur',
      key: 'ILS',
    },
    {
      label: 'Isabela',
      key: 'ISA',
    },
    {
      label: 'Kalinga',
      key: 'KAL',
    },
    {
      label: 'Laguna',
      key: 'LAG',
    },
    {
      label: 'Lanao del Norte',
      key: 'LAN',
    },
    {
      label: 'Lanao del Sur',
      key: 'LAS',
    },
    {
      label: 'Leyte',
      key: 'LEY',
    },
    {
      label: 'La Union',
      key: 'LUN',
    },
    {
      label: 'Marinduque',
      key: 'MAD',
    },
    {
      label: 'Maguindanao',
      key: 'MAG',
    },
    {
      label: 'Masbate',
      key: 'MAS',
    },
    {
      label: 'Mindoro Occidental',
      key: 'MDC',
    },
    {
      label: 'Mindoro Oriental',
      key: 'MDR',
    },
    {
      label: 'Mountain Province',
      key: 'MOU',
    },
    {
      label: 'Misamis Occidental',
      key: 'MSC',
    },
    {
      label: 'Misamis Oriental',
      key: 'MSR',
    },
    {
      label: 'North Cotabato',
      key: 'NCO',
    },
    {
      label: 'Negros Occidental',
      key: 'NEC',
    },
    {
      label: 'Negros Oriental',
      key: 'NER',
    },
    {
      label: 'Northern Samar',
      key: 'NSA',
    },
    {
      label: 'Nueva Ecija',
      key: 'NUE',
    },
    {
      label: 'Nueva Vizcaya',
      key: 'NUV',
    },
    {
      label: 'Pampanga',
      key: 'PAM',
    },
    {
      label: 'Pangasinan',
      key: 'PAN',
    },
    {
      label: 'Palawan',
      key: 'PLW',
    },
    {
      label: 'Quezon',
      key: 'QUE',
    },
    {
      label: 'Quirino',
      key: 'QUI',
    },
    {
      label: 'Rizal',
      key: 'RIZ',
    },
    {
      label: 'Romblon',
      key: 'ROM',
    },
    {
      label: 'Sarangani',
      key: 'SAR',
    },
    {
      label: 'South Cotabato',
      key: 'SCO',
    },
    {
      label: 'Siquijor',
      key: 'SIG',
    },
    {
      label: 'Southern Leyte',
      key: 'SLE',
    },
    {
      label: 'Sulu',
      key: 'SLU',
    },
    {
      label: 'Sorsogon',
      key: 'SOR',
    },
    {
      label: 'Sultan Kudarat',
      key: 'SUK',
    },
    {
      label: 'Surigao del Norte',
      key: 'SUN',
    },
    {
      label: 'Surigao del Sur',
      key: 'SUR',
    },
    {
      label: 'Tarlac',
      key: 'TAR',
    },
    {
      label: 'Tawi-Tawi',
      key: 'TAW',
    },
    {
      label: 'Western Samar',
      key: 'WSA',
    },
    {
      label: 'Zamboanga del Norte',
      key: 'ZAN',
    },
    {
      label: 'Zamboanga del Sur',
      key: 'ZAS',
    },
    {
      label: 'Zambales',
      key: 'ZMB',
    },
    {
      label: 'Zamboanga Sibuguey [Zamboanga Sibugay]',
      key: 'ZSI',
    },
  ],
  PCN: [],
  POL: [
    {
      label: 'Dolnośląskie',
      key: '02',
    },
    {
      label: 'Kujawsko-pomorskie',
      key: '04',
    },
    {
      label: 'Lubelskie',
      key: '06',
    },
    {
      label: 'Lubuskie',
      key: '08',
    },
    {
      label: 'Łódzkie',
      key: '10',
    },
    {
      label: 'Małopolskie',
      key: '12',
    },
    {
      label: 'Mazowieckie',
      key: '14',
    },
    {
      label: 'Opolskie',
      key: '16',
    },
    {
      label: 'Podkarpackie',
      key: '18',
    },
    {
      label: 'Podlaskie',
      key: '20',
    },
    {
      label: 'Pomorskie',
      key: '22',
    },
    {
      label: 'Śląskie',
      key: '24',
    },
    {
      label: 'Świętokrzyskie',
      key: '26',
    },
    {
      label: 'Warmińsko-mazurskie',
      key: '28',
    },
    {
      label: 'Wielkopolskie',
      key: '30',
    },
    {
      label: 'Zachodniopomorskie',
      key: '32',
    },
  ],
  PRT: [
    {
      label: 'Aveiro',
      key: '01',
    },
    {
      label: 'Beja',
      key: '02',
    },
    {
      label: 'Braga',
      key: '03',
    },
    {
      label: 'Bragança',
      key: '04',
    },
    {
      label: 'Castelo Branco',
      key: '05',
    },
    {
      label: 'Coimbra',
      key: '06',
    },
    {
      label: 'Évora',
      key: '07',
    },
    {
      label: 'Faro',
      key: '08',
    },
    {
      label: 'Guarda',
      key: '09',
    },
    {
      label: 'Leiria',
      key: '10',
    },
    {
      label: 'Lisboa',
      key: '11',
    },
    {
      label: 'Portalegre',
      key: '12',
    },
    {
      label: 'Porto',
      key: '13',
    },
    {
      label: 'Santarém',
      key: '14',
    },
    {
      label: 'Setúbal',
      key: '15',
    },
    {
      label: 'Viana do Castelo',
      key: '16',
    },
    {
      label: 'Vila Real',
      key: '17',
    },
    {
      label: 'Viseu',
      key: '18',
    },
    {
      label: 'Açores',
      key: '20',
    },
    {
      label: 'Madeira',
      key: '30',
    },
  ],
  PRI: [],
  QAT: [
    {
      label: 'Ad Dawhah',
      key: 'DA',
    },
    {
      label: 'Al Khawr',
      key: 'KH',
    },
    {
      label: 'Madinat ash Shamal',
      key: 'MS',
    },
    {
      label: 'Ar Rayyan',
      key: 'RA',
    },
    {
      label: 'الشحانية',
      key: 'SH',
    },
    {
      label: 'Umm Salal',
      key: 'US',
    },
    {
      label: 'Al Wakrah',
      key: 'WA',
    },
    {
      label: 'بلدية الضعاين',
      key: 'ZA',
    },
  ],
  REU: [],
  ROU: [
    {
      label: 'Alba',
      key: 'AB',
    },
    {
      label: 'Arges',
      key: 'AG',
    },
    {
      label: 'Arad',
      key: 'AR',
    },
    {
      label: 'Bucuresti',
      key: 'B',
    },
    {
      label: 'Bacau',
      key: 'BC',
    },
    {
      label: 'Bihor',
      key: 'BH',
    },
    {
      label: 'Bistrita-Nasaud',
      key: 'BN',
    },
    {
      label: 'Braila',
      key: 'BR',
    },
    {
      label: 'Botosani',
      key: 'BT',
    },
    {
      label: 'Brasov',
      key: 'BV',
    },
    {
      label: 'Buzau',
      key: 'BZ',
    },
    {
      label: 'Cluj',
      key: 'CJ',
    },
    {
      label: 'Calarasi',
      key: 'CL',
    },
    {
      label: 'Caras-Severin',
      key: 'CS',
    },
    {
      label: 'Constanta',
      key: 'CT',
    },
    {
      label: 'Covasna',
      key: 'CV',
    },
    {
      label: 'Dâmbovita',
      key: 'DB',
    },
    {
      label: 'Dolj',
      key: 'DJ',
    },
    {
      label: 'Gorj',
      key: 'GJ',
    },
    {
      label: 'Galati',
      key: 'GL',
    },
    {
      label: 'Giurgiu',
      key: 'GR',
    },
    {
      label: 'Hunedoara',
      key: 'HD',
    },
    {
      label: 'Harghita',
      key: 'HR',
    },
    {
      label: 'Ilfov',
      key: 'IF',
    },
    {
      label: 'Ialomita',
      key: 'IL',
    },
    {
      label: 'Iasi',
      key: 'IS',
    },
    {
      label: 'Mehedinti',
      key: 'MH',
    },
    {
      label: 'Maramures',
      key: 'MM',
    },
    {
      label: 'Mures',
      key: 'MS',
    },
    {
      label: 'Neamt',
      key: 'NT',
    },
    {
      label: 'Olt',
      key: 'OT',
    },
    {
      label: 'Prahova',
      key: 'PH',
    },
    {
      label: 'Sibiu',
      key: 'SB',
    },
    {
      label: 'Salaj',
      key: 'SJ',
    },
    {
      label: 'Satu Mare',
      key: 'SM',
    },
    {
      label: 'Suceava',
      key: 'SV',
    },
    {
      label: 'Tulcea',
      key: 'TL',
    },
    {
      label: 'Timis',
      key: 'TM',
    },
    {
      label: 'Teleorman',
      key: 'TR',
    },
    {
      label: 'Vâlcea',
      key: 'VL',
    },
    {
      label: 'Vrancea',
      key: 'VN',
    },
    {
      label: 'Vaslui',
      key: 'VS',
    },
  ],
  RUS: [
    {
      label: 'Adygeya, Respublika',
      key: 'AD',
    },
    {
      label: 'Altay, Respublika',
      key: 'AL',
    },
    {
      label: 'Altayskiy kray',
      key: 'ALT',
    },
    {
      label: "Amurskaya oblast'",
      key: 'AMU',
    },
    {
      label: "Arkhangel'skaya oblast'",
      key: 'ARK',
    },
    {
      label: "Astrakhanskaya oblast'",
      key: 'AST',
    },
    {
      label: 'Bashkortostan, Respublika',
      key: 'BA',
    },
    {
      label: "Belgorodskaya oblast'",
      key: 'BEL',
    },
    {
      label: "Bryanskaya oblast'",
      key: 'BRY',
    },
    {
      label: 'Buryatiya, Respublika',
      key: 'BU',
    },
    {
      label: 'Chechenskaya Respublika',
      key: 'CE',
    },
    {
      label: "Chelyabinskaya oblast'",
      key: 'CHE',
    },
    {
      label: 'Chukotskiy avtonomnyy okrug',
      key: 'CHU',
    },
    {
      label: 'Chuvashskaya Respublika',
      key: 'CU',
    },
    {
      label: 'Dagestan, Respublika',
      key: 'DA',
    },
    {
      label: 'Ingushskaya Respublika [Respublika Ingushetiya]',
      key: 'IN',
    },
    {
      label: "Irkutskaya oblast'",
      key: 'IRK',
    },
    {
      label: "Ivanovskaya oblast'",
      key: 'IVA',
    },
    {
      label: "Kamchatskaya oblast'",
      key: 'KAM',
    },
    {
      label: 'Kabardino-Balkarskaya Respublika',
      key: 'KB',
    },
    {
      label: 'Karachayevo-Cherkesskaya Respublika',
      key: 'KC',
    },
    {
      label: 'Krasnodarskiy kray',
      key: 'KDA',
    },
    {
      label: "Kemerovskaya oblast'",
      key: 'KEM',
    },
    {
      label: "Kaliningradskaya oblast'",
      key: 'KGD',
    },
    {
      label: "Kurganskaya oblast'",
      key: 'KGN',
    },
    {
      label: 'Khabarovskiy kray',
      key: 'KHA',
    },
    {
      label: 'Khanty-Mansiyskiy avtonomnyy okrug [Yugra]',
      key: 'KHM',
    },
    {
      label: "Kirovskaya oblast'",
      key: 'KIR',
    },
    {
      label: 'Khakasiya, Respublika',
      key: 'KK',
    },
    {
      label: 'Kalmykiya, Respublika',
      key: 'KL',
    },
    {
      label: "Kaluzhskaya oblast'",
      key: 'KLU',
    },
    {
      label: 'Komi, Respublika',
      key: 'KO',
    },
    {
      label: "Kostromskaya oblast'",
      key: 'KOS',
    },
    {
      label: 'Kareliya, Respublika',
      key: 'KR',
    },
    {
      label: "Kurskaya oblast'",
      key: 'KRS',
    },
    {
      label: 'Krasnoyarskiy kray',
      key: 'KYA',
    },
    {
      label: "Leningradskaya oblast'",
      key: 'LEN',
    },
    {
      label: "Lipetskaya oblast'",
      key: 'LIP',
    },
    {
      label: "Magadanskaya oblast'",
      key: 'MAG',
    },
    {
      label: 'Mariy El, Respublika',
      key: 'ME',
    },
    {
      label: 'Mordoviya, Respublika',
      key: 'MO',
    },
    {
      label: "Moskovskaya oblast'",
      key: 'MOS',
    },
    {
      label: 'Moskva',
      key: 'MOW',
    },
    {
      label: "Murmanskaya oblast'",
      key: 'MUR',
    },
    {
      label: 'Nenetskiy avtonomnyy okrug',
      key: 'NEN',
    },
    {
      label: "Novgorodskaya oblast'",
      key: 'NGR',
    },
    {
      label: "Nizhegorodskaya oblast'",
      key: 'NIZ',
    },
    {
      label: "Novosibirskaya oblast'",
      key: 'NVS',
    },
    {
      label: "Omskaya oblast'",
      key: 'OMS',
    },
    {
      label: "Orenburgskaya oblast'",
      key: 'ORE',
    },
    {
      label: "Orlovskaya oblast'",
      key: 'ORL',
    },
    {
      label: 'Perm',
      key: 'PER',
    },
    {
      label: "Penzenskaya oblast'",
      key: 'PNZ',
    },
    {
      label: 'Primorskiy kray',
      key: 'PRI',
    },
    {
      label: "Pskovskaya oblast'",
      key: 'PSK',
    },
    {
      label: "Rostovskaya oblast'",
      key: 'ROS',
    },
    {
      label: "Ryazanskaya oblast'",
      key: 'RYA',
    },
    {
      label: 'Sakha, Respublika [Yakutiya]',
      key: 'SA',
    },
    {
      label: "Sakhalinskaya oblast'",
      key: 'SAK',
    },
    {
      label: "Samarskaya oblast'",
      key: 'SAM',
    },
    {
      label: "Saratovskaya oblast'",
      key: 'SAR',
    },
    {
      label: 'Severnaya Osetiya, Respublika [Alaniya] [Respublika Severnaya Osetiya-Alaniya]',
      key: 'SE',
    },
    {
      label: "Smolenskaya oblast'",
      key: 'SMO',
    },
    {
      label: 'Sankt-Peterburg',
      key: 'SPE',
    },
    {
      label: "Stavropol'skiy kray",
      key: 'STA',
    },
    {
      label: "Sverdlovskaya oblast'",
      key: 'SVE',
    },
    {
      label: 'Tatarstan, Respublika',
      key: 'TA',
    },
    {
      label: "Tambovskaya oblast'",
      key: 'TAM',
    },
    {
      label: "Tomskaya oblast'",
      key: 'TOM',
    },
    {
      label: "Tul'skaya oblast'",
      key: 'TUL',
    },
    {
      label: "Tverskaya oblast'",
      key: 'TVE',
    },
    {
      label: 'Tyva, Respublika [Tuva]',
      key: 'TY',
    },
    {
      label: "Tyumenskaya oblast'",
      key: 'TYU',
    },
    {
      label: 'Udmurtskaya Respublika',
      key: 'UD',
    },
    {
      label: "Ul'yanovskaya oblast'",
      key: 'ULY',
    },
    {
      label: "Volgogradskaya oblast'",
      key: 'VGG',
    },
    {
      label: "Vladimirskaya oblast'",
      key: 'VLA',
    },
    {
      label: "Vologodskaya oblast'",
      key: 'VLG',
    },
    {
      label: "Voronezhskaya oblast'",
      key: 'VOR',
    },
    {
      label: 'Yamalo-Nenetskiy avtonomnyy okrug',
      key: 'YAN',
    },
    {
      label: "Yaroslavskaya oblast'",
      key: 'YAR',
    },
    {
      label: "Yevreyskaya avtonomnaya oblast'",
      key: 'YEV',
    },
    {
      label: "Zabaykal'skij kray",
      key: 'ZAB',
    },
  ],
  RWA: [
    {
      label: 'Ville de Kigali',
      key: '01',
    },
    {
      label: 'Est',
      key: '02',
    },
    {
      label: 'Nord',
      key: '03',
    },
    {
      label: 'Ouest',
      key: '04',
    },
    {
      label: 'Sud',
      key: '05',
    },
  ],
  BLM: [],
  SHN: [
    {
      label: 'Ascension',
      key: 'AC',
    },
    {
      label: 'Saint Helena',
      key: 'HL',
    },
    {
      label: 'Tristan da Cunha',
      key: 'TA',
    },
  ],
  KNA: [
    {
      label: 'Christ Church Nichola Town',
      key: '01',
    },
    {
      label: 'Saint Anne Sandy Point',
      key: '02',
    },
    {
      label: 'Saint George Basseterre',
      key: '03',
    },
    {
      label: 'Saint George Gingerland',
      key: '04',
    },
    {
      label: 'Saint James Windward',
      key: '05',
    },
    {
      label: 'Saint John Capisterre',
      key: '06',
    },
    {
      label: 'Saint John Figtree',
      key: '07',
    },
    {
      label: 'Saint Mary Cayon',
      key: '08',
    },
    {
      label: 'Saint Paul Capisterre',
      key: '09',
    },
    {
      label: 'Saint Paul Charlestown',
      key: '10',
    },
    {
      label: 'Saint Peter Basseterre',
      key: '11',
    },
    {
      label: 'Saint Thomas Lowland',
      key: '12',
    },
    {
      label: 'Saint Thomas Middle Island',
      key: '13',
    },
    {
      label: 'Trinity Palmetto Point',
      key: '15',
    },
    {
      label: 'Saint Kitts',
      key: 'K',
    },
    {
      label: 'Nevis',
      key: 'N',
    },
  ],
  LCA: [
    {
      label: 'Anse la Raye',
      key: '01',
    },
    {
      label: 'Castries',
      key: '02',
    },
    {
      label: 'Choiseul',
      key: '03',
    },
    {
      label: 'Dennery',
      key: '05',
    },
    {
      label: 'Gros Islet',
      key: '06',
    },
    {
      label: 'Laborie',
      key: '07',
    },
    {
      label: 'Micoud',
      key: '08',
    },
    {
      label: 'Soufrière',
      key: '10',
    },
    {
      label: 'Vieux Fort',
      key: '11',
    },
    {
      label: 'Canaries',
      key: '12',
    },
  ],
  MAF: [],
  SPM: [],
  VCT: [
    {
      label: 'Charlotte',
      key: '01',
    },
    {
      label: 'Saint Andrew',
      key: '02',
    },
    {
      label: 'Saint David',
      key: '03',
    },
    {
      label: 'Saint George',
      key: '04',
    },
    {
      label: 'Saint Patrick',
      key: '05',
    },
    {
      label: 'Grenadines',
      key: '06',
    },
  ],
  WSM: [
    {
      label: "A'ana",
      key: 'AA',
    },
    {
      label: 'Aiga-i-le-Tai',
      key: 'AL',
    },
    {
      label: 'Atua',
      key: 'AT',
    },
    {
      label: "Fa'asaleleaga",
      key: 'FA',
    },
    {
      label: "Gaga'emauga",
      key: 'GE',
    },
    {
      label: 'Gagaifomauga',
      key: 'GI',
    },
    {
      label: 'Palauli',
      key: 'PA',
    },
    {
      label: "Satupa'itea",
      key: 'SA',
    },
    {
      label: 'Tuamasaga',
      key: 'TU',
    },
    {
      label: "Va'a-o-Fonoti",
      key: 'VF',
    },
    {
      label: 'Vaisigano',
      key: 'VS',
    },
  ],
  SMR: [
    {
      label: 'Acquaviva',
      key: '01',
    },
    {
      label: 'Chiesanuova',
      key: '02',
    },
    {
      label: 'Domagnano',
      key: '03',
    },
    {
      label: 'Faetano',
      key: '04',
    },
    {
      label: 'Fiorentino',
      key: '05',
    },
    {
      label: 'Borgo Maggiore',
      key: '06',
    },
    {
      label: 'San Marino',
      key: '07',
    },
    {
      label: 'Montegiardino',
      key: '08',
    },
    {
      label: 'Serravalle',
      key: '09',
    },
  ],
  STP: [
    {
      label: 'Água Grande',
      key: '01',
    },
    {
      label: 'Cantagalo',
      key: '02',
    },
    {
      label: 'Caué',
      key: '03',
    },
    {
      label: 'Lembá',
      key: '04',
    },
    {
      label: 'Lobata',
      key: '05',
    },
    {
      label: 'Mé-Zóchi',
      key: '06',
    },
    {
      label: 'Príncipe',
      key: 'P',
    },
  ],
  SAU: [
    {
      label: 'Ar Riyāḑ',
      key: '01',
    },
    {
      label: 'Makkah al Mukarramah',
      key: '02',
    },
    {
      label: 'Al Madinah',
      key: '03',
    },
    {
      label: 'Ash Sharqiyah',
      key: '04',
    },
    {
      label: 'Al Qasim',
      key: '05',
    },
    {
      label: "Ḩā'il",
      key: '06',
    },
    {
      label: 'Tabūk',
      key: '07',
    },
    {
      label: 'Al Ḩudūd ash Shamālīyah',
      key: '08',
    },
    {
      label: 'Jāzān',
      key: '09',
    },
    {
      label: 'Najrān',
      key: '10',
    },
    {
      label: 'Al Bāḩah',
      key: '11',
    },
    {
      label: 'Al Jawf',
      key: '12',
    },
    {
      label: "'Asīr",
      key: '14',
    },
  ],
  SEN: [
    {
      label: 'Diourbel',
      key: 'DB',
    },
    {
      label: 'Dakar',
      key: 'DK',
    },
    {
      label: 'Fatick',
      key: 'FK',
    },
    {
      label: 'Kaffrine',
      key: 'KA',
    },
    {
      label: 'Kolda',
      key: 'KD',
    },
    {
      label: 'Kédougou',
      key: 'KE',
    },
    {
      label: 'Kaolack',
      key: 'KL',
    },
    {
      label: 'Louga',
      key: 'LG',
    },
    {
      label: 'Matam',
      key: 'MT',
    },
    {
      label: 'Sédhiou',
      key: 'SE',
    },
    {
      label: 'Saint-Louis',
      key: 'SL',
    },
    {
      label: 'Tambacounda',
      key: 'TC',
    },
    {
      label: 'Thiès',
      key: 'TH',
    },
    {
      label: 'Ziguinchor',
      key: 'ZG',
    },
  ],
  SRB: [
    {
      label: 'Belgrade',
      key: '00',
    },
    {
      label: 'Severna Backa',
      key: '01',
    },
    {
      label: 'Srednji Banat',
      key: '02',
    },
    {
      label: 'Severni Banat',
      key: '03',
    },
    {
      label: 'Južni Banat',
      key: '04',
    },
    {
      label: 'Zapadna Backa',
      key: '05',
    },
    {
      label: 'Južna Backa',
      key: '06',
    },
    {
      label: 'Srem',
      key: '07',
    },
    {
      label: 'Macva',
      key: '08',
    },
    {
      label: 'Kolubara',
      key: '09',
    },
    {
      label: 'Podunavlje',
      key: '10',
    },
    {
      label: 'Branicevo',
      key: '11',
    },
    {
      label: 'Šumadija',
      key: '12',
    },
    {
      label: 'Pomoravlje',
      key: '13',
    },
    {
      label: 'Bor',
      key: '14',
    },
    {
      label: 'Zajecar',
      key: '15',
    },
    {
      label: 'Zlatibor',
      key: '16',
    },
    {
      label: 'Moravica',
      key: '17',
    },
    {
      label: 'Raška',
      key: '18',
    },
    {
      label: 'Rasina',
      key: '19',
    },
    {
      label: 'Nišava',
      key: '20',
    },
    {
      label: 'Toplica',
      key: '21',
    },
    {
      label: 'Pirot',
      key: '22',
    },
    {
      label: 'Jablanica',
      key: '23',
    },
    {
      label: 'Pcinja',
      key: '24',
    },
    {
      label: 'Kosovo',
      key: '25',
    },
    {
      label: 'Pec´',
      key: '26',
    },
    {
      label: 'Prizren',
      key: '27',
    },
    {
      label: 'Kosovska Mitrovica',
      key: '28',
    },
    {
      label: 'Kosovo-Pomoravlje',
      key: '29',
    },
    {
      label: 'Косово и Метохија',
      key: 'KM',
    },
    {
      label: 'Војводина',
      key: 'VO',
    },
  ],
  SYC: [
    {
      label: 'Anse aux Pins',
      key: '01',
    },
    {
      label: 'Anse Boileau',
      key: '02',
    },
    {
      label: 'Anse Étoile',
      key: '03',
    },
    {
      label: 'Anse Louis',
      key: '04',
    },
    {
      label: 'Anse Royale',
      key: '05',
    },
    {
      label: 'Baie Lazare',
      key: '06',
    },
    {
      label: 'Baie Sainte Anne',
      key: '07',
    },
    {
      label: 'Beau Vallon',
      key: '08',
    },
    {
      label: 'Bel Air',
      key: '09',
    },
    {
      label: 'Bel Ombre',
      key: '10',
    },
    {
      label: 'Cascade',
      key: '11',
    },
    {
      label: 'Glacis',
      key: '12',
    },
    {
      label: "Grand' Anse (Mahé)",
      key: '13',
    },
    {
      label: "Grand' Anse (Praslin)",
      key: '14',
    },
    {
      label: 'La Digue',
      key: '15',
    },
    {
      label: 'La Rivière Anglaise',
      key: '16',
    },
    {
      label: 'Mont Buxton',
      key: '17',
    },
    {
      label: 'Mont Fleuri',
      key: '18',
    },
    {
      label: 'Plaisance',
      key: '19',
    },
    {
      label: 'Pointe La Rue',
      key: '20',
    },
    {
      label: 'Port Glaud',
      key: '21',
    },
    {
      label: 'Saint Louis',
      key: '22',
    },
    {
      label: 'Takamaka',
      key: '23',
    },
    {
      label: 'Les Mamelles',
      key: '24',
    },
    {
      label: 'Roche Caïman',
      key: '25',
    },
    {
      label: 'Ile Perseverance I',
      key: '26',
    },
    {
      label: 'Ile Perseverance II',
      key: '27',
    },
  ],
  SLE: [
    {
      label: 'Eastern',
      key: 'E',
    },
    {
      label: 'Northern',
      key: 'N',
    },
    {
      label: 'North Western',
      key: 'NW',
    },
    {
      label: 'Southern',
      key: 'S',
    },
    {
      label: 'Western Area (Freetown)',
      key: 'W',
    },
  ],
  SGP: [
    {
      label: 'Central Singapore',
      key: '01',
    },
    {
      label: 'North East',
      key: '02',
    },
    {
      label: 'North West',
      key: '03',
    },
    {
      label: 'South East',
      key: '04',
    },
    {
      label: 'South West',
      key: '05',
    },
  ],
  SXM: [],
  SVK: [
    {
      label: 'Banskobystrický kraj',
      key: 'BC',
    },
    {
      label: 'Bratislavský kraj',
      key: 'BL',
    },
    {
      label: 'Košický kraj',
      key: 'KI',
    },
    {
      label: 'Nitriansky kraj',
      key: 'NI',
    },
    {
      label: 'Prešovský kraj',
      key: 'PV',
    },
    {
      label: 'Trnavský kraj',
      key: 'TA',
    },
    {
      label: 'Trenciansky kraj',
      key: 'TC',
    },
    {
      label: 'Žilinský kraj',
      key: 'ZI',
    },
  ],
  SVN: [
    {
      label: 'Ajdovšcina',
      key: '001',
    },
    {
      label: 'Beltinci',
      key: '002',
    },
    {
      label: 'Bled',
      key: '003',
    },
    {
      label: 'Bohinj',
      key: '004',
    },
    {
      label: 'Borovnica',
      key: '005',
    },
    {
      label: 'Bovec',
      key: '006',
    },
    {
      label: 'Brda',
      key: '007',
    },
    {
      label: 'Brezovica',
      key: '008',
    },
    {
      label: 'Brežice',
      key: '009',
    },
    {
      label: 'Tišina',
      key: '010',
    },
    {
      label: 'Celje',
      key: '011',
    },
    {
      label: 'Cerklje na Gorenjskem',
      key: '012',
    },
    {
      label: 'Cerknica',
      key: '013',
    },
    {
      label: 'Cerkno',
      key: '014',
    },
    {
      label: 'Crenšovci',
      key: '015',
    },
    {
      label: 'Crna na Koroškem',
      key: '016',
    },
    {
      label: 'Crnomelj',
      key: '017',
    },
    {
      label: 'Destrnik',
      key: '018',
    },
    {
      label: 'Divaca',
      key: '019',
    },
    {
      label: 'Dobrepolje',
      key: '020',
    },
    {
      label: 'Dobrova-Polhov Gradec',
      key: '021',
    },
    {
      label: 'Dol pri Ljubljani',
      key: '022',
    },
    {
      label: 'Domžale',
      key: '023',
    },
    {
      label: 'Dornava',
      key: '024',
    },
    {
      label: 'Dravograd',
      key: '025',
    },
    {
      label: 'Duplek',
      key: '026',
    },
    {
      label: 'Gorenja vas-Poljane',
      key: '027',
    },
    {
      label: 'Gorišnica',
      key: '028',
    },
    {
      label: 'Gornja Radgona',
      key: '029',
    },
    {
      label: 'Gornji Grad',
      key: '030',
    },
    {
      label: 'Gornji Petrovci',
      key: '031',
    },
    {
      label: 'Grosuplje',
      key: '032',
    },
    {
      label: 'Šalovci',
      key: '033',
    },
    {
      label: 'Hrastnik',
      key: '034',
    },
    {
      label: 'Hrpelje-Kozina',
      key: '035',
    },
    {
      label: 'Idrija',
      key: '036',
    },
    {
      label: 'Ig',
      key: '037',
    },
    {
      label: 'Ilirska Bistrica',
      key: '038',
    },
    {
      label: 'Ivancna Gorica',
      key: '039',
    },
    {
      label: 'Izola/Isola',
      key: '040',
    },
    {
      label: 'Jesenice',
      key: '041',
    },
    {
      label: 'Juršinci',
      key: '042',
    },
    {
      label: 'Kamnik',
      key: '043',
    },
    {
      label: 'Kanal',
      key: '044',
    },
    {
      label: 'Kidricevo',
      key: '045',
    },
    {
      label: 'Kobarid',
      key: '046',
    },
    {
      label: 'Kobilje',
      key: '047',
    },
    {
      label: 'Kocevje',
      key: '048',
    },
    {
      label: 'Komen',
      key: '049',
    },
    {
      label: 'Koper/Capodistria',
      key: '050',
    },
    {
      label: 'Kozje',
      key: '051',
    },
    {
      label: 'Kranj',
      key: '052',
    },
    {
      label: 'Kranjska Gora',
      key: '053',
    },
    {
      label: 'Krško',
      key: '054',
    },
    {
      label: 'Kungota',
      key: '055',
    },
    {
      label: 'Kuzma',
      key: '056',
    },
    {
      label: 'Laško',
      key: '057',
    },
    {
      label: 'Lenart',
      key: '058',
    },
    {
      label: 'Lendava/Lendva',
      key: '059',
    },
    {
      label: 'Litija',
      key: '060',
    },
    {
      label: 'Ljubljana',
      key: '061',
    },
    {
      label: 'Ljubno',
      key: '062',
    },
    {
      label: 'Ljutomer',
      key: '063',
    },
    {
      label: 'Logatec',
      key: '064',
    },
    {
      label: 'Loška dolina',
      key: '065',
    },
    {
      label: 'Loški Potok',
      key: '066',
    },
    {
      label: 'Luce',
      key: '067',
    },
    {
      label: 'Lukovica',
      key: '068',
    },
    {
      label: 'Majšperk',
      key: '069',
    },
    {
      label: 'Maribor',
      key: '070',
    },
    {
      label: 'Medvode',
      key: '071',
    },
    {
      label: 'Mengeš',
      key: '072',
    },
    {
      label: 'Metlika',
      key: '073',
    },
    {
      label: 'Mežica',
      key: '074',
    },
    {
      label: 'Miren-Kostanjevica',
      key: '075',
    },
    {
      label: 'Mislinja',
      key: '076',
    },
    {
      label: 'Moravce',
      key: '077',
    },
    {
      label: 'Moravske Toplice',
      key: '078',
    },
    {
      label: 'Mozirje',
      key: '079',
    },
    {
      label: 'Murska Sobota',
      key: '080',
    },
    {
      label: 'Muta',
      key: '081',
    },
    {
      label: 'Naklo',
      key: '082',
    },
    {
      label: 'Nazarje',
      key: '083',
    },
    {
      label: 'Nova Gorica',
      key: '084',
    },
    {
      label: 'Novo mesto',
      key: '085',
    },
    {
      label: 'Odranci',
      key: '086',
    },
    {
      label: 'Ormož',
      key: '087',
    },
    {
      label: 'Osilnica',
      key: '088',
    },
    {
      label: 'Pesnica',
      key: '089',
    },
    {
      label: 'Piran/Pirano',
      key: '090',
    },
    {
      label: 'Pivka',
      key: '091',
    },
    {
      label: 'Podcetrtek',
      key: '092',
    },
    {
      label: 'Podvelka',
      key: '093',
    },
    {
      label: 'Postojna',
      key: '094',
    },
    {
      label: 'Preddvor',
      key: '095',
    },
    {
      label: 'Ptuj',
      key: '096',
    },
    {
      label: 'Puconci',
      key: '097',
    },
    {
      label: 'Race-Fram',
      key: '098',
    },
    {
      label: 'Radece',
      key: '099',
    },
    {
      label: 'Radenci',
      key: '100',
    },
    {
      label: 'Radlje ob Dravi',
      key: '101',
    },
    {
      label: 'Radovljica',
      key: '102',
    },
    {
      label: 'Ravne na Koroškem',
      key: '103',
    },
    {
      label: 'Ribnica',
      key: '104',
    },
    {
      label: 'Rogašovci',
      key: '105',
    },
    {
      label: 'Rogaška Slatina',
      key: '106',
    },
    {
      label: 'Rogatec',
      key: '107',
    },
    {
      label: 'Ruše',
      key: '108',
    },
    {
      label: 'Semic',
      key: '109',
    },
    {
      label: 'Sevnica',
      key: '110',
    },
    {
      label: 'Sežana',
      key: '111',
    },
    {
      label: 'Slovenj Gradec',
      key: '112',
    },
    {
      label: 'Slovenska Bistrica',
      key: '113',
    },
    {
      label: 'Slovenske Konjice',
      key: '114',
    },
    {
      label: 'Starše',
      key: '115',
    },
    {
      label: 'Sveti Jurij',
      key: '116',
    },
    {
      label: 'Šencur',
      key: '117',
    },
    {
      label: 'Šentilj',
      key: '118',
    },
    {
      label: 'Šentjernej',
      key: '119',
    },
    {
      label: 'Šentjur pri Celju',
      key: '120',
    },
    {
      label: 'Škocjan',
      key: '121',
    },
    {
      label: 'Škofja Loka',
      key: '122',
    },
    {
      label: 'Škofljica',
      key: '123',
    },
    {
      label: 'Šmarje pri Jelšah',
      key: '124',
    },
    {
      label: 'Šmartno ob Paki',
      key: '125',
    },
    {
      label: 'Šoštanj',
      key: '126',
    },
    {
      label: 'Štore',
      key: '127',
    },
    {
      label: 'Tolmin',
      key: '128',
    },
    {
      label: 'Trbovlje',
      key: '129',
    },
    {
      label: 'Trebnje',
      key: '130',
    },
    {
      label: 'Tržic',
      key: '131',
    },
    {
      label: 'Turnišce',
      key: '132',
    },
    {
      label: 'Velenje',
      key: '133',
    },
    {
      label: 'Velike Lašce',
      key: '134',
    },
    {
      label: 'Videm',
      key: '135',
    },
    {
      label: 'Vipava',
      key: '136',
    },
    {
      label: 'Vitanje',
      key: '137',
    },
    {
      label: 'Vodice',
      key: '138',
    },
    {
      label: 'Vojnik',
      key: '139',
    },
    {
      label: 'Vrhnika',
      key: '140',
    },
    {
      label: 'Vuzenica',
      key: '141',
    },
    {
      label: 'Zagorje ob Savi',
      key: '142',
    },
    {
      label: 'Zavrc',
      key: '143',
    },
    {
      label: 'Zrece',
      key: '144',
    },
    {
      label: 'Železniki',
      key: '146',
    },
    {
      label: 'Žiri',
      key: '147',
    },
    {
      label: 'Benedikt',
      key: '148',
    },
    {
      label: 'Bistrica ob Sotli',
      key: '149',
    },
    {
      label: 'Bloke',
      key: '150',
    },
    {
      label: 'Braslovce',
      key: '151',
    },
    {
      label: 'Cankova',
      key: '152',
    },
    {
      label: 'Cerkvenjak',
      key: '153',
    },
    {
      label: 'Dobje',
      key: '154',
    },
    {
      label: 'Dobrna',
      key: '155',
    },
    {
      label: 'Dobrovnik/Dobronak',
      key: '156',
    },
    {
      label: 'Dolenjske Toplice',
      key: '157',
    },
    {
      label: 'Grad',
      key: '158',
    },
    {
      label: 'Hajdina',
      key: '159',
    },
    {
      label: 'Hoce-Slivnica',
      key: '160',
    },
    {
      label: 'Hodoš/Hodos',
      key: '161',
    },
    {
      label: 'Horjul',
      key: '162',
    },
    {
      label: 'Jezersko',
      key: '163',
    },
    {
      label: 'Komenda',
      key: '164',
    },
    {
      label: 'Kostel',
      key: '165',
    },
    {
      label: 'Križevci',
      key: '166',
    },
    {
      label: 'Lovrenc na Pohorju',
      key: '167',
    },
    {
      label: 'Markovci',
      key: '168',
    },
    {
      label: 'Miklavž na Dravskem polju',
      key: '169',
    },
    {
      label: 'Mirna Pec',
      key: '170',
    },
    {
      label: 'Oplotnica',
      key: '171',
    },
    {
      label: 'Podlehnik',
      key: '172',
    },
    {
      label: 'Polzela',
      key: '173',
    },
    {
      label: 'Prebold',
      key: '174',
    },
    {
      label: 'Prevalje',
      key: '175',
    },
    {
      label: 'Razkrižje',
      key: '176',
    },
    {
      label: 'Ribnica na Pohorju',
      key: '177',
    },
    {
      label: 'Selnica ob Dravi',
      key: '178',
    },
    {
      label: 'Sodražica',
      key: '179',
    },
    {
      label: 'Solcava',
      key: '180',
    },
    {
      label: 'Sveta Ana',
      key: '181',
    },
    {
      label: 'Sveti Andraž v Slovenskih goricah',
      key: '182',
    },
    {
      label: 'Šempeter-Vrtojba',
      key: '183',
    },
    {
      label: 'Tabor',
      key: '184',
    },
    {
      label: 'Trnovska vas',
      key: '185',
    },
    {
      label: 'Trzin',
      key: '186',
    },
    {
      label: 'Velika Polana',
      key: '187',
    },
    {
      label: 'Veržej',
      key: '188',
    },
    {
      label: 'Vransko',
      key: '189',
    },
    {
      label: 'Žalec',
      key: '190',
    },
    {
      label: 'Žetale',
      key: '191',
    },
    {
      label: 'Žirovnica',
      key: '192',
    },
    {
      label: 'Žužemberk',
      key: '193',
    },
    {
      label: 'Šmartno pri Litiji',
      key: '194',
    },
    {
      label: 'Občina Apače',
      key: '195',
    },
    {
      label: 'Občina Cirkulane',
      key: '196',
    },
    {
      label: 'Občina Kostanjevica na Krki',
      key: '197',
    },
    {
      label: 'Občina Makole',
      key: '198',
    },
    {
      label: 'Občina Mokronog - Trebelno',
      key: '199',
    },
    {
      label: 'Občina Poljčane',
      key: '200',
    },
    {
      label: 'Občina Renče - Vogrsko',
      key: '201',
    },
    {
      label: 'Občina Središče ob Dravi',
      key: '202',
    },
    {
      label: 'Občina Straža',
      key: '203',
    },
    {
      label: 'Občina Sveta Trojica v Slovenskih goricah',
      key: '204',
    },
    {
      label: 'Občina Sveti Tomaž',
      key: '205',
    },
    {
      label: 'Občina Šmarješke Toplice',
      key: '206',
    },
    {
      label: 'Občina Gorje',
      key: '207',
    },
    {
      label: 'Občina Log - Dragomer',
      key: '208',
    },
    {
      label: 'Občina Rečica ob Savinji',
      key: '209',
    },
    {
      label: 'Občina Sveti Jurij v Slovenskih goricah',
      key: '210',
    },
    {
      label: 'Občina Šentrupert',
      key: '211',
    },
    {
      label: 'Mirna',
      key: '212',
    },
    {
      label: 'Občina Ankaran',
      key: '213',
    },
  ],
  SLB: [
    {
      label: 'Central',
      key: 'CE',
    },
    {
      label: 'Choiseul',
      key: 'CH',
    },
    {
      label: 'Capital Territory (Honiara)',
      key: 'CT',
    },
    {
      label: 'Guadalcanal',
      key: 'GU',
    },
    {
      label: 'Isabel',
      key: 'IS',
    },
    {
      label: 'Makira',
      key: 'MK',
    },
    {
      label: 'Malaita',
      key: 'ML',
    },
    {
      label: 'Rennell and Bellona',
      key: 'RB',
    },
    {
      label: 'Temotu',
      key: 'TE',
    },
    {
      label: 'Western',
      key: 'WE',
    },
  ],
  SOM: [
    {
      label: 'Awdal',
      key: 'AW',
    },
    {
      label: 'Bakool',
      key: 'BK',
    },
    {
      label: 'Banaadir',
      key: 'BN',
    },
    {
      label: 'Bari',
      key: 'BR',
    },
    {
      label: 'Bay',
      key: 'BY',
    },
    {
      label: 'Galguduud',
      key: 'GA',
    },
    {
      label: 'Gedo',
      key: 'GE',
    },
    {
      label: 'Hiiraan',
      key: 'HI',
    },
    {
      label: 'Jubbada Dhexe',
      key: 'JD',
    },
    {
      label: 'Jubbada Hoose',
      key: 'JH',
    },
    {
      label: 'Mudug',
      key: 'MU',
    },
    {
      label: 'Nugaal',
      key: 'NU',
    },
    {
      label: 'Sanaag',
      key: 'SA',
    },
    {
      label: 'Shabeellaha Dhexe',
      key: 'SD',
    },
    {
      label: 'Shabeellaha Hoose',
      key: 'SH',
    },
    {
      label: 'Sool',
      key: 'SO',
    },
    {
      label: 'Togdheer',
      key: 'TO',
    },
    {
      label: 'Woqooyi Galbeed',
      key: 'WO',
    },
  ],
  ZAF: [
    {
      label: 'Eastern Cape',
      key: 'EC',
    },
    {
      label: 'Free State',
      key: 'FS',
    },
    {
      label: 'Gauteng',
      key: 'GP',
    },
    {
      label: 'Limpopo',
      key: 'LP',
    },
    {
      label: 'Mpumalanga',
      key: 'MP',
    },
    {
      label: 'Northern Cape',
      key: 'NC',
    },
    {
      label: 'North-West',
      key: 'NW',
    },
    {
      label: 'Western Cape',
      key: 'WC',
    },
    {
      label: 'KwaZulu-Natal',
      key: 'ZN',
    },
  ],
  SGS: [],
  SSD: [
    {
      label: 'شمال بحر الغزال',
      key: 'BN',
    },
    {
      label: 'غرب بحر الغزال',
      key: 'BW',
    },
    {
      label: 'وسط الاستوائية',
      key: 'EC',
    },
    {
      label: 'شرق الاستوائية',
      key: 'EE',
    },
    {
      label: 'غرب الاستوائية',
      key: 'EW',
    },
    {
      label: 'جونقلي',
      key: 'JG',
    },
    {
      label: 'البحيرات',
      key: 'LK',
    },
    {
      label: 'أعالي النيل',
      key: 'NU',
    },
    {
      label: 'الوحدة',
      key: 'UY',
    },
    {
      label: 'واراب',
      key: 'WR',
    },
  ],
  ESP: [
    {
      label: 'Alicante/Alacant',
      key: 'A',
    },
    {
      label: 'Albacete',
      key: 'AB',
    },
    {
      label: 'Almería',
      key: 'AL',
    },
    {
      label: 'Andalucía',
      key: 'AN',
    },
    {
      label: 'Aragón',
      key: 'AR',
    },
    {
      label: 'Principado de Asturias',
      key: 'AS',
    },
    {
      label: 'Ávila',
      key: 'AV',
    },
    {
      label: 'Barcelona',
      key: 'B',
    },
    {
      label: 'Badajoz',
      key: 'BA',
    },
    {
      label: 'Bizkaia',
      key: 'BI',
    },
    {
      label: 'Burgos',
      key: 'BU',
    },
    {
      label: 'Coruña, A',
      key: 'C',
    },
    {
      label: 'Cádiz',
      key: 'CA',
    },
    {
      label: 'Cantabria',
      key: 'CB',
    },
    {
      label: 'Cáceres',
      key: 'CC',
    },
    {
      label: 'Ceuta',
      key: 'CE',
    },
    {
      label: 'Castilla y León',
      key: 'CL',
    },
    {
      label: 'Castilla-La Mancha',
      key: 'CM',
    },
    {
      label: 'Canary Islands',
      key: 'CN',
    },
    {
      label: 'Córdoba',
      key: 'CO',
    },
    {
      label: 'Ciudad Real',
      key: 'CR',
    },
    {
      label: 'Castellón/Castelló',
      key: 'CS',
    },
    {
      label: 'Cataluña',
      key: 'CT',
    },
    {
      label: 'Cuenca',
      key: 'CU',
    },
    {
      label: 'Extremadura',
      key: 'EX',
    },
    {
      label: 'Galicia',
      key: 'GA',
    },
    {
      label: 'Palmas, Las',
      key: 'GC',
    },
    {
      label: 'Girona',
      key: 'GI',
    },
    {
      label: 'Granada',
      key: 'GR',
    },
    {
      label: 'Guadalajara',
      key: 'GU',
    },
    {
      label: 'Huelva',
      key: 'H',
    },
    {
      label: 'Huesca',
      key: 'HU',
    },
    {
      label: 'Islas Baleares',
      key: 'IB',
    },
    {
      label: 'Jaén',
      key: 'J',
    },
    {
      label: 'Lleida',
      key: 'L',
    },
    {
      label: 'León',
      key: 'LE',
    },
    {
      label: 'Rioja, La',
      key: 'LO',
    },
    {
      label: 'Lugo',
      key: 'LU',
    },
    {
      label: 'Madrid',
      key: 'M',
    },
    {
      label: 'Málaga',
      key: 'MA',
    },
    {
      label: 'Región de Murcia',
      key: 'MC',
    },
    {
      label: 'Comunidad de Madrid',
      key: 'MD',
    },
    {
      label: 'Melilla',
      key: 'ML',
    },
    {
      label: 'Murcia',
      key: 'MU',
    },
    {
      label: 'Navarra',
      key: 'NA',
    },
    {
      label: 'Navarra',
      key: 'NC',
    },
    {
      label: 'Asturias',
      key: 'O',
    },
    {
      label: 'Ourense',
      key: 'OR',
    },
    {
      label: 'Palencia',
      key: 'P',
    },
    {
      label: 'Balears, Illes',
      key: 'PM',
    },
    {
      label: 'Pontevedra',
      key: 'PO',
    },
    {
      label: 'País Vasco',
      key: 'PV',
    },
    {
      label: 'La Rioja',
      key: 'RI',
    },
    {
      label: 'Cantabria',
      key: 'S',
    },
    {
      label: 'Salamanca',
      key: 'SA',
    },
    {
      label: 'Sevilla',
      key: 'SE',
    },
    {
      label: 'Segovia',
      key: 'SG',
    },
    {
      label: 'Soria',
      key: 'SO',
    },
    {
      label: 'Gipuzkoa',
      key: 'SS',
    },
    {
      label: 'Tarragona',
      key: 'T',
    },
    {
      label: 'Teruel',
      key: 'TE',
    },
    {
      label: 'Santa Cruz de Tenerife',
      key: 'TF',
    },
    {
      label: 'Toledo',
      key: 'TO',
    },
    {
      label: 'Valencia/València',
      key: 'V',
    },
    {
      label: 'Valladolid',
      key: 'VA',
    },
    {
      label: 'Comunidad Valenciana',
      key: 'VC',
    },
    {
      label: 'Araba/Álava',
      key: 'VI',
    },
    {
      label: 'Zaragoza',
      key: 'Z',
    },
    {
      label: 'Zamora',
      key: 'ZA',
    },
  ],
  LKA: [
    {
      label: 'බස්නාහිර පළාත, ශ් රී ලංකාව',
      key: '1',
    },
    {
      label: 'Colombo',
      key: '11',
    },
    {
      label: 'Gampaha',
      key: '12',
    },
    {
      label: 'Kalutara',
      key: '13',
    },
    {
      label: 'මධ්‍යම පළාත, ශ්‍රී ලංකාව',
      key: '2',
    },
    {
      label: 'Kandy',
      key: '21',
    },
    {
      label: 'Matale',
      key: '22',
    },
    {
      label: 'Nuwara Eliya',
      key: '23',
    },
    {
      label: 'දකුණු පළාත',
      key: '3',
    },
    {
      label: 'Galle',
      key: '31',
    },
    {
      label: 'Matara',
      key: '32',
    },
    {
      label: 'Hambantota',
      key: '33',
    },
    {
      label: 'උතුරු පළාත',
      key: '4',
    },
    {
      label: 'Jaffna',
      key: '41',
    },
    {
      label: 'Kilinochchi',
      key: '42',
    },
    {
      label: 'Mannar',
      key: '43',
    },
    {
      label: 'Vavuniya',
      key: '44',
    },
    {
      label: 'Mullaittivu',
      key: '45',
    },
    {
      label: 'නැගෙනහිර පළාත, ශ් රී ලංකාව',
      key: '5',
    },
    {
      label: 'Batticaloa',
      key: '51',
    },
    {
      label: 'Ampara',
      key: '52',
    },
    {
      label: 'Trincomalee',
      key: '53',
    },
    {
      label: 'වයඹ පළාත, ශ්‍රී ලංකාව',
      key: '6',
    },
    {
      label: 'Kurunegala',
      key: '61',
    },
    {
      label: 'Puttalam',
      key: '62',
    },
    {
      label: 'උතුරු මැද පළාත',
      key: '7',
    },
    {
      label: 'Anuradhapura',
      key: '71',
    },
    {
      label: 'Polonnaruwa',
      key: '72',
    },
    {
      label: 'ඌව පළාත',
      key: '8',
    },
    {
      label: 'Badulla',
      key: '81',
    },
    {
      label: 'Monaragala',
      key: '82',
    },
    {
      label: 'සබරගමුව පළාත',
      key: '9',
    },
    {
      label: 'Ratnapura',
      key: '91',
    },
    {
      label: 'Kegalla',
      key: '92',
    },
  ],
  SDN: [
    {
      label: 'Wasaţ Dārfūr Zālinjay',
      key: 'DC',
    },
    {
      label: 'Sharq Dārfūr',
      key: 'DE',
    },
    {
      label: 'Shamāl Dārfūr',
      key: 'DN',
    },
    {
      label: 'Janūb Dārfūr',
      key: 'DS',
    },
    {
      label: 'Gharb Dārfūr',
      key: 'DW',
    },
    {
      label: 'Al Qaḑārif',
      key: 'GD',
    },
    {
      label: 'Gharb Kurdufān',
      key: 'GK',
    },
    {
      label: 'Al Jazīrah',
      key: 'GZ',
    },
    {
      label: 'Kassalā',
      key: 'KA',
    },
    {
      label: 'Al Kharţūm',
      key: 'KH',
    },
    {
      label: 'Shiamāl Kurdufān',
      key: 'KN',
    },
    {
      label: 'Janūb Kurdufān',
      key: 'KS',
    },
    {
      label: 'An Nīl al Azraq',
      key: 'NB',
    },
    {
      label: 'Ash Shamālīyah',
      key: 'NO',
    },
    {
      label: 'Nahr an Nīl',
      key: 'NR',
    },
    {
      label: 'An Nīl al Abyaḑ',
      key: 'NW',
    },
    {
      label: 'Al Baḩr al Aḩmar',
      key: 'RS',
    },
    {
      label: 'Sinnār',
      key: 'SI',
    },
  ],
  SUR: [
    {
      label: 'Brokopondo',
      key: 'BR',
    },
    {
      label: 'Commewijne',
      key: 'CM',
    },
    {
      label: 'Coronie',
      key: 'CR',
    },
    {
      label: 'Marowijne',
      key: 'MA',
    },
    {
      label: 'Nickerie',
      key: 'NI',
    },
    {
      label: 'Paramaribo',
      key: 'PM',
    },
    {
      label: 'Para',
      key: 'PR',
    },
    {
      label: 'Saramacca',
      key: 'SA',
    },
    {
      label: 'Sipaliwini',
      key: 'SI',
    },
    {
      label: 'Wanica',
      key: 'WA',
    },
  ],
  SJM: [],
  SWE: [
    {
      label: 'Stockholms län',
      key: 'AB',
    },
    {
      label: 'Västerbottens län',
      key: 'AC',
    },
    {
      label: 'Norrbottens län',
      key: 'BD',
    },
    {
      label: 'Uppsala län',
      key: 'C',
    },
    {
      label: 'Södermanlands län',
      key: 'D',
    },
    {
      label: 'Östergötlands län',
      key: 'E',
    },
    {
      label: 'Jönköpings län',
      key: 'F',
    },
    {
      label: 'Kronobergs län',
      key: 'G',
    },
    {
      label: 'Kalmar län',
      key: 'H',
    },
    {
      label: 'Gotlands län',
      key: 'I',
    },
    {
      label: 'Blekinge län',
      key: 'K',
    },
    {
      label: 'Skåne län',
      key: 'M',
    },
    {
      label: 'Hallands län',
      key: 'N',
    },
    {
      label: 'Västra Götalands län',
      key: 'O',
    },
    {
      label: 'Värmlands län',
      key: 'S',
    },
    {
      label: 'Örebro län',
      key: 'T',
    },
    {
      label: 'Västmanlands län',
      key: 'U',
    },
    {
      label: 'Dalarnas län',
      key: 'W',
    },
    {
      label: 'Gävleborgs län',
      key: 'X',
    },
    {
      label: 'Västernorrlands län',
      key: 'Y',
    },
    {
      label: 'Jämtlands län',
      key: 'Z',
    },
  ],
  CHE: [
    {
      label: 'Aargau (de)',
      key: 'AG',
    },
    {
      label: 'Appenzell Innerrhoden (de)',
      key: 'AI',
    },
    {
      label: 'Appenzell Ausserrhoden (de)',
      key: 'AR',
    },
    {
      label: 'Bern (de)',
      key: 'BE',
    },
    {
      label: 'Basel-Landschaft (de)',
      key: 'BL',
    },
    {
      label: 'Basel-Stadt (de)',
      key: 'BS',
    },
    {
      label: 'Fribourg (fr)',
      key: 'FR',
    },
    {
      label: 'Genève (fr)',
      key: 'GE',
    },
    {
      label: 'Glarus (de)',
      key: 'GL',
    },
    {
      label: 'Graubünden (de)',
      key: 'GR',
    },
    {
      label: 'Jura (fr)',
      key: 'JU',
    },
    {
      label: 'Luzern (de)',
      key: 'LU',
    },
    {
      label: 'Neuchâtel (fr)',
      key: 'NE',
    },
    {
      label: 'Nidwalden (de)',
      key: 'NW',
    },
    {
      label: 'Obwalden (de)',
      key: 'OW',
    },
    {
      label: 'Sankt Gallen (de)',
      key: 'SG',
    },
    {
      label: 'Schaffhausen (de)',
      key: 'SH',
    },
    {
      label: 'Solothurn (de)',
      key: 'SO',
    },
    {
      label: 'Schwyz (de)',
      key: 'SZ',
    },
    {
      label: 'Thurgau (de)',
      key: 'TG',
    },
    {
      label: 'Ticino (it)',
      key: 'TI',
    },
    {
      label: 'Uri (de)',
      key: 'UR',
    },
    {
      label: 'Vaud (fr)',
      key: 'VD',
    },
    {
      label: 'Valais (fr)',
      key: 'VS',
    },
    {
      label: 'Zug (de)',
      key: 'ZG',
    },
    {
      label: 'Zürich (de)',
      key: 'ZH',
    },
  ],
  SYR: [
    {
      label: 'Dimashq',
      key: 'DI',
    },
    {
      label: "Dar'ā",
      key: 'DR',
    },
    {
      label: 'Dayr az Zawr',
      key: 'DY',
    },
    {
      label: 'Al Ḩasakah',
      key: 'HA',
    },
    {
      label: 'Ḩimş',
      key: 'HI',
    },
    {
      label: 'Ḩalab',
      key: 'HL',
    },
    {
      label: 'Ḩamāh',
      key: 'HM',
    },
    {
      label: 'Idlib',
      key: 'ID',
    },
    {
      label: 'Al Ladhiqiyah',
      key: 'LA',
    },
    {
      label: 'Al Qunaytirah',
      key: 'QU',
    },
    {
      label: 'Ar Raqqah',
      key: 'RA',
    },
    {
      label: 'Rif Dimashq',
      key: 'RD',
    },
    {
      label: "As Suwayda'",
      key: 'SU',
    },
    {
      label: 'Tartus',
      key: 'TA',
    },
  ],
  TWN: [
    {
      label: 'Changhua',
      key: 'CHA',
    },
    {
      label: 'Chiayi Municipality',
      key: 'CYI',
    },
    {
      label: 'Chiayi',
      key: 'CYQ',
    },
    {
      label: 'Hsinchu',
      key: 'HSQ',
    },
    {
      label: 'Hsinchu Municipality',
      key: 'HSZ',
    },
    {
      label: 'Hualien',
      key: 'HUA',
    },
    {
      label: 'Ilan',
      key: 'ILA',
    },
    {
      label: 'Keelung Municipality',
      key: 'KEE',
    },
    {
      label: 'Kaohsiung Special Municipality',
      key: 'KHH',
    },
    {
      label: '金門縣',
      key: 'KIN',
    },
    {
      label: '連江縣',
      key: 'LIE',
    },
    {
      label: 'Miaoli',
      key: 'MIA',
    },
    {
      label: 'Nantou',
      key: 'NAN',
    },
    {
      label: 'New Taipei',
      key: 'NWT',
    },
    {
      label: 'Penghu',
      key: 'PEN',
    },
    {
      label: 'Pingtung',
      key: 'PIF',
    },
    {
      label: 'Taoyuan',
      key: 'TAO',
    },
    {
      label: 'Tainan Municipality',
      key: 'TNN',
    },
    {
      label: 'Taipei Special Municipality',
      key: 'TPE',
    },
    {
      label: 'Taitung',
      key: 'TTT',
    },
    {
      label: 'Taichung Municipality',
      key: 'TXG',
    },
    {
      label: 'Yunlin',
      key: 'YUN',
    },
  ],
  TJK: [
    {
      label: 'Dushanbe',
      key: 'DU',
    },
    {
      label: 'Gorno-Badakhshan',
      key: 'GB',
    },
    {
      label: 'Khatlon',
      key: 'KT',
    },
    {
      label: 'Nohiyahoi Tobei Jumhurí',
      key: 'RA',
    },
    {
      label: 'Sughd',
      key: 'SU',
    },
  ],
  TZA: [
    {
      label: 'Arusha',
      key: '01',
    },
    {
      label: 'Dar es Salaam',
      key: '02',
    },
    {
      label: 'Dodoma',
      key: '03',
    },
    {
      label: 'Iringa',
      key: '04',
    },
    {
      label: 'Kagera',
      key: '05',
    },
    {
      label: 'Kaskazini Pemba',
      key: '06',
    },
    {
      label: 'Kaskazini Unguja',
      key: '07',
    },
    {
      label: 'Kigoma',
      key: '08',
    },
    {
      label: 'Kilimanjaro',
      key: '09',
    },
    {
      label: 'Kusini Pemba',
      key: '10',
    },
    {
      label: 'Kusini Unguja',
      key: '11',
    },
    {
      label: 'Lindi',
      key: '12',
    },
    {
      label: 'Mara',
      key: '13',
    },
    {
      label: 'Mbeya',
      key: '14',
    },
    {
      label: 'Mjini Magharibi',
      key: '15',
    },
    {
      label: 'Morogoro',
      key: '16',
    },
    {
      label: 'Mtwara',
      key: '17',
    },
    {
      label: 'Mwanza',
      key: '18',
    },
    {
      label: 'Pwani',
      key: '19',
    },
    {
      label: 'Rukwa',
      key: '20',
    },
    {
      label: 'Ruvuma',
      key: '21',
    },
    {
      label: 'Shinyanga',
      key: '22',
    },
    {
      label: 'Singida',
      key: '23',
    },
    {
      label: 'Tabora',
      key: '24',
    },
    {
      label: 'Tanga',
      key: '25',
    },
    {
      label: 'Manyara',
      key: '26',
    },
    {
      label: 'Mkoa wa Geita',
      key: '27',
    },
    {
      label: 'Mkoa wa Katavi',
      key: '28',
    },
    {
      label: 'Mkoa wa Njombe',
      key: '29',
    },
    {
      label: 'Mkoa wa Simiyu',
      key: '30',
    },
    {
      label: 'Songwe',
      key: '31',
    },
  ],
  THA: [
    {
      label: 'Krung Thep Maha Nakhon [Bangkok]',
      key: '10',
    },
    {
      label: 'Samut Prakan',
      key: '11',
    },
    {
      label: 'Nonthaburi',
      key: '12',
    },
    {
      label: 'Pathum Thani',
      key: '13',
    },
    {
      label: 'Phra Nakhon Si Ayutthaya',
      key: '14',
    },
    {
      label: 'Ang Thong',
      key: '15',
    },
    {
      label: 'Lop Buri',
      key: '16',
    },
    {
      label: 'Sing Buri',
      key: '17',
    },
    {
      label: 'Chai Nat',
      key: '18',
    },
    {
      label: 'Saraburi',
      key: '19',
    },
    {
      label: 'Chon Buri',
      key: '20',
    },
    {
      label: 'Rayong',
      key: '21',
    },
    {
      label: 'Chanthaburi',
      key: '22',
    },
    {
      label: 'Trat',
      key: '23',
    },
    {
      label: 'Chachoengsao',
      key: '24',
    },
    {
      label: 'Prachin Buri',
      key: '25',
    },
    {
      label: 'Nakhon Nayok',
      key: '26',
    },
    {
      label: 'Sa Kaeo',
      key: '27',
    },
    {
      label: 'Nakhon Ratchasima',
      key: '30',
    },
    {
      label: 'Buri Ram',
      key: '31',
    },
    {
      label: 'Surin',
      key: '32',
    },
    {
      label: 'Si Sa Ket',
      key: '33',
    },
    {
      label: 'Ubon Ratchathani',
      key: '34',
    },
    {
      label: 'Yasothon',
      key: '35',
    },
    {
      label: 'Chaiyaphum',
      key: '36',
    },
    {
      label: 'Amnat Charoen',
      key: '37',
    },
    {
      label: 'จังหวัดบึงกาฬ',
      key: '38',
    },
    {
      label: 'Nong Bua Lam Phu',
      key: '39',
    },
    {
      label: 'Khon Kaen',
      key: '40',
    },
    {
      label: 'Udon Thani',
      key: '41',
    },
    {
      label: 'Loei',
      key: '42',
    },
    {
      label: 'Nong Khai',
      key: '43',
    },
    {
      label: 'Maha Sarakham',
      key: '44',
    },
    {
      label: 'Roi Et',
      key: '45',
    },
    {
      label: 'Kalasin',
      key: '46',
    },
    {
      label: 'Sakon Nakhon',
      key: '47',
    },
    {
      label: 'Nakhon Phanom',
      key: '48',
    },
    {
      label: 'Mukdahan',
      key: '49',
    },
    {
      label: 'Chiang Mai',
      key: '50',
    },
    {
      label: 'Lamphun',
      key: '51',
    },
    {
      label: 'Lampang',
      key: '52',
    },
    {
      label: 'Uttaradit',
      key: '53',
    },
    {
      label: 'Phrae',
      key: '54',
    },
    {
      label: 'Nan',
      key: '55',
    },
    {
      label: 'Phayao',
      key: '56',
    },
    {
      label: 'Chiang Rai',
      key: '57',
    },
    {
      label: 'Mae Hong Son',
      key: '58',
    },
    {
      label: 'Nakhon Sawan',
      key: '60',
    },
    {
      label: 'Uthai Thani',
      key: '61',
    },
    {
      label: 'Kamphaeng Phet',
      key: '62',
    },
    {
      label: 'Tak',
      key: '63',
    },
    {
      label: 'Sukhothai',
      key: '64',
    },
    {
      label: 'Phitsanulok',
      key: '65',
    },
    {
      label: 'Phichit',
      key: '66',
    },
    {
      label: 'Phetchabun',
      key: '67',
    },
    {
      label: 'Ratchaburi',
      key: '70',
    },
    {
      label: 'Kanchanaburi',
      key: '71',
    },
    {
      label: 'Suphan Buri',
      key: '72',
    },
    {
      label: 'Nakhon Pathom',
      key: '73',
    },
    {
      label: 'Samut Sakhon',
      key: '74',
    },
    {
      label: 'Samut Songkhram',
      key: '75',
    },
    {
      label: 'Phetchaburi',
      key: '76',
    },
    {
      label: 'Prachuap Khiri Khan',
      key: '77',
    },
    {
      label: 'Nakhon Si Thammarat',
      key: '80',
    },
    {
      label: 'Krabi',
      key: '81',
    },
    {
      label: 'Phangnga',
      key: '82',
    },
    {
      label: 'Phuket',
      key: '83',
    },
    {
      label: 'Surat Thani',
      key: '84',
    },
    {
      label: 'Ranong',
      key: '85',
    },
    {
      label: 'Chumphon',
      key: '86',
    },
    {
      label: 'Songkhla',
      key: '90',
    },
    {
      label: 'Satun',
      key: '91',
    },
    {
      label: 'Trang',
      key: '92',
    },
    {
      label: 'Phatthalung',
      key: '93',
    },
    {
      label: 'Pattani',
      key: '94',
    },
    {
      label: 'Yala',
      key: '95',
    },
    {
      label: 'Narathiwat',
      key: '96',
    },
    {
      label: 'Phatthaya',
      key: 'S',
    },
  ],
  TLS: [
    {
      label: 'Aileu',
      key: 'AL',
    },
    {
      label: 'Ainaro',
      key: 'AN',
    },
    {
      label: 'Baucau',
      key: 'BA',
    },
    {
      label: 'Bobonaro',
      key: 'BO',
    },
    {
      label: 'Cova Lima',
      key: 'CO',
    },
    {
      label: 'Dili',
      key: 'DI',
    },
    {
      label: 'Ermera',
      key: 'ER',
    },
    {
      label: 'Lautem',
      key: 'LA',
    },
    {
      label: 'Liquiça',
      key: 'LI',
    },
    {
      label: 'Manufahi',
      key: 'MF',
    },
    {
      label: 'Manatuto',
      key: 'MT',
    },
    {
      label: 'Oecussi',
      key: 'OE',
    },
    {
      label: 'Viqueque',
      key: 'VI',
    },
  ],
  TGO: [
    {
      label: 'Centre',
      key: 'C',
    },
    {
      label: 'Kara',
      key: 'K',
    },
    {
      label: 'Maritime (Région)',
      key: 'M',
    },
    {
      label: 'Plateaux',
      key: 'P',
    },
    {
      label: 'Savannes',
      key: 'S',
    },
  ],
  TKL: [],
  TON: [
    {
      label: "'Eua",
      key: '01',
    },
    {
      label: "Ha'apai",
      key: '02',
    },
    {
      label: 'Niuas',
      key: '03',
    },
    {
      label: 'Tongatapu',
      key: '04',
    },
    {
      label: "Vava'u",
      key: '05',
    },
  ],
  TTO: [
    {
      label: 'Arima',
      key: 'ARI',
    },
    {
      label: 'Chaguanas',
      key: 'CHA',
    },
    {
      label: 'Couva-Tabaquite-Talparo',
      key: 'CTT',
    },
    {
      label: 'Diego Martin',
      key: 'DMN',
    },
    {
      label: 'Mayaro-Rio Claro',
      key: 'MRC',
    },
    {
      label: 'Penal-Debe',
      key: 'PED',
    },
    {
      label: 'Port of Spain',
      key: 'POS',
    },
    {
      label: 'Princes Town',
      key: 'PRT',
    },
    {
      label: 'Point Fortin',
      key: 'PTF',
    },
    {
      label: 'San Fernando',
      key: 'SFO',
    },
    {
      label: 'Sangre Grande',
      key: 'SGE',
    },
    {
      label: 'Siparia',
      key: 'SIP',
    },
    {
      label: 'San Juan-Laventille',
      key: 'SJL',
    },
    {
      label: 'Tobago',
      key: 'TOB',
    },
    {
      label: 'Tunapuna-Piarco',
      key: 'TUP',
    },
  ],
  TUN: [
    {
      label: 'Tunis',
      key: '11',
    },
    {
      label: "L'Ariana",
      key: '12',
    },
    {
      label: 'Ben Arous',
      key: '13',
    },
    {
      label: 'La Manouba',
      key: '14',
    },
    {
      label: 'Nabeul',
      key: '21',
    },
    {
      label: 'Zaghouan',
      key: '22',
    },
    {
      label: 'Bizerte',
      key: '23',
    },
    {
      label: 'Béja',
      key: '31',
    },
    {
      label: 'Jendouba',
      key: '32',
    },
    {
      label: 'Le Kef',
      key: '33',
    },
    {
      label: 'Siliana',
      key: '34',
    },
    {
      label: 'Kairouan',
      key: '41',
    },
    {
      label: 'Kasserine',
      key: '42',
    },
    {
      label: 'Sidi Bouzid',
      key: '43',
    },
    {
      label: 'Sousse',
      key: '51',
    },
    {
      label: 'Monastir',
      key: '52',
    },
    {
      label: 'Mahdia',
      key: '53',
    },
    {
      label: 'Sfax',
      key: '61',
    },
    {
      label: 'Gafsa',
      key: '71',
    },
    {
      label: 'Tozeur',
      key: '72',
    },
    {
      label: 'Kebili',
      key: '73',
    },
    {
      label: 'Gabès',
      key: '81',
    },
    {
      label: 'Medenine',
      key: '82',
    },
    {
      label: 'Tataouine',
      key: '83',
    },
  ],
  TUR: [
    {
      label: 'Adana',
      key: '01',
    },
    {
      label: 'Adıyaman',
      key: '02',
    },
    {
      label: 'Afyon',
      key: '03',
    },
    {
      label: 'Ağrı',
      key: '04',
    },
    {
      label: 'Amasya',
      key: '05',
    },
    {
      label: 'Ankara',
      key: '06',
    },
    {
      label: 'Antalya',
      key: '07',
    },
    {
      label: 'Artvin',
      key: '08',
    },
    {
      label: 'Aydın',
      key: '09',
    },
    {
      label: 'Balıkesir',
      key: '10',
    },
    {
      label: 'Bilecik',
      key: '11',
    },
    {
      label: 'Bingöl',
      key: '12',
    },
    {
      label: 'Bitlis',
      key: '13',
    },
    {
      label: 'Bolu',
      key: '14',
    },
    {
      label: 'Burdur',
      key: '15',
    },
    {
      label: 'Bursa',
      key: '16',
    },
    {
      label: 'Çanakkale',
      key: '17',
    },
    {
      label: 'Çankırı',
      key: '18',
    },
    {
      label: 'Çorum',
      key: '19',
    },
    {
      label: 'Denizli',
      key: '20',
    },
    {
      label: 'Diyarbakır',
      key: '21',
    },
    {
      label: 'Edirne',
      key: '22',
    },
    {
      label: 'Elazığ',
      key: '23',
    },
    {
      label: 'Erzincan',
      key: '24',
    },
    {
      label: 'Erzurum',
      key: '25',
    },
    {
      label: 'Eskişehir',
      key: '26',
    },
    {
      label: 'Gaziantep',
      key: '27',
    },
    {
      label: 'Giresun',
      key: '28',
    },
    {
      label: 'Gümüşhane',
      key: '29',
    },
    {
      label: 'Hakkâri',
      key: '30',
    },
    {
      label: 'Hatay',
      key: '31',
    },
    {
      label: 'Isparta',
      key: '32',
    },
    {
      label: 'İçel',
      key: '33',
    },
    {
      label: 'İstanbul',
      key: '34',
    },
    {
      label: 'İzmir',
      key: '35',
    },
    {
      label: 'Kars',
      key: '36',
    },
    {
      label: 'Kastamonu',
      key: '37',
    },
    {
      label: 'Kayseri',
      key: '38',
    },
    {
      label: 'Kırklareli',
      key: '39',
    },
    {
      label: 'Kırşehir',
      key: '40',
    },
    {
      label: 'Kocaeli',
      key: '41',
    },
    {
      label: 'Konya',
      key: '42',
    },
    {
      label: 'Kütahya',
      key: '43',
    },
    {
      label: 'Malatya',
      key: '44',
    },
    {
      label: 'Manisa',
      key: '45',
    },
    {
      label: 'Kahramanmaraş',
      key: '46',
    },
    {
      label: 'Mardin',
      key: '47',
    },
    {
      label: 'Muğla',
      key: '48',
    },
    {
      label: 'Muş',
      key: '49',
    },
    {
      label: 'Nevşehir',
      key: '50',
    },
    {
      label: 'Niğde',
      key: '51',
    },
    {
      label: 'Ordu',
      key: '52',
    },
    {
      label: 'Rize',
      key: '53',
    },
    {
      label: 'Sakarya',
      key: '54',
    },
    {
      label: 'Samsun',
      key: '55',
    },
    {
      label: 'Siirt',
      key: '56',
    },
    {
      label: 'Sinop',
      key: '57',
    },
    {
      label: 'Sivas',
      key: '58',
    },
    {
      label: 'Tekirdağ',
      key: '59',
    },
    {
      label: 'Tokat',
      key: '60',
    },
    {
      label: 'Trabzon',
      key: '61',
    },
    {
      label: 'Tunceli',
      key: '62',
    },
    {
      label: 'Şanlıurfa',
      key: '63',
    },
    {
      label: 'Uşak',
      key: '64',
    },
    {
      label: 'Van',
      key: '65',
    },
    {
      label: 'Yozgat',
      key: '66',
    },
    {
      label: 'Zonguldak',
      key: '67',
    },
    {
      label: 'Aksaray',
      key: '68',
    },
    {
      label: 'Bayburt',
      key: '69',
    },
    {
      label: 'Karaman',
      key: '70',
    },
    {
      label: 'Kırıkkale',
      key: '71',
    },
    {
      label: 'Batman',
      key: '72',
    },
    {
      label: 'Şırnak',
      key: '73',
    },
    {
      label: 'Bartın',
      key: '74',
    },
    {
      label: 'Ardahan',
      key: '75',
    },
    {
      label: 'Iğdır',
      key: '76',
    },
    {
      label: 'Yalova',
      key: '77',
    },
    {
      label: 'Karabük',
      key: '78',
    },
    {
      label: 'Kilis',
      key: '79',
    },
    {
      label: 'Osmaniye',
      key: '80',
    },
    {
      label: 'Düzce',
      key: '81',
    },
  ],
  TKM: [
    {
      label: 'Ahal',
      key: 'A',
    },
    {
      label: 'Balkan',
      key: 'B',
    },
    {
      label: 'Dasoguz',
      key: 'D',
    },
    {
      label: 'Lebap',
      key: 'L',
    },
    {
      label: 'Mary',
      key: 'M',
    },
    {
      label: 'Aşgabat',
      key: 'S',
    },
  ],
  TCA: [],
  TUV: [
    {
      label: 'Funafuti',
      key: 'FUN',
    },
    {
      label: 'Niutao',
      key: 'NIT',
    },
    {
      label: 'Nukufetau',
      key: 'NKF',
    },
    {
      label: 'Nukulaelae',
      key: 'NKL',
    },
    {
      label: 'Nanumea',
      key: 'NMA',
    },
    {
      label: 'Nanumanga',
      key: 'NMG',
    },
    {
      label: 'Nui',
      key: 'NUI',
    },
    {
      label: 'Vaitupu',
      key: 'VAI',
    },
  ],
  UGA: [
    {
      label: 'Kalangala',
      key: '101',
    },
    {
      label: 'Kampala',
      key: '102',
    },
    {
      label: 'Kiboga',
      key: '103',
    },
    {
      label: 'Luwero',
      key: '104',
    },
    {
      label: 'Masaka',
      key: '105',
    },
    {
      label: 'Mpigi',
      key: '106',
    },
    {
      label: 'Mubende',
      key: '107',
    },
    {
      label: 'Mukono',
      key: '108',
    },
    {
      label: 'Nakasongola',
      key: '109',
    },
    {
      label: 'Rakai',
      key: '110',
    },
    {
      label: 'Sembabule',
      key: '111',
    },
    {
      label: 'Kayunga',
      key: '112',
    },
    {
      label: 'Wakiso',
      key: '113',
    },
    {
      label: 'Mityana',
      key: '114',
    },
    {
      label: 'Nakaseke',
      key: '115',
    },
    {
      label: 'Lyantonde',
      key: '116',
    },
    {
      label: 'Buikwe',
      key: '117',
    },
    {
      label: 'Bukomansibi',
      key: '118',
    },
    {
      label: 'Butambala',
      key: '119',
    },
    {
      label: 'Buvuma',
      key: '120',
    },
    {
      label: 'Gomba',
      key: '121',
    },
    {
      label: 'Kalungu',
      key: '122',
    },
    {
      label: 'Kyankwanzi',
      key: '123',
    },
    {
      label: 'Lwengo',
      key: '124',
    },
    {
      label: 'Kyotera',
      key: '125',
    },
    {
      label: 'Kasanda',
      key: '126',
    },
    {
      label: 'Bugiri',
      key: '201',
    },
    {
      label: 'Busia',
      key: '202',
    },
    {
      label: 'Iganga',
      key: '203',
    },
    {
      label: 'Jinja',
      key: '204',
    },
    {
      label: 'Kamuli',
      key: '205',
    },
    {
      label: 'Kapchorwa',
      key: '206',
    },
    {
      label: 'Katakwi',
      key: '207',
    },
    {
      label: 'Kumi',
      key: '208',
    },
    {
      label: 'Mbale',
      key: '209',
    },
    {
      label: 'Pallisa',
      key: '210',
    },
    {
      label: 'Soroti',
      key: '211',
    },
    {
      label: 'Tororo',
      key: '212',
    },
    {
      label: 'Kaberamaido',
      key: '213',
    },
    {
      label: 'Mayuge',
      key: '214',
    },
    {
      label: 'Sironko',
      key: '215',
    },
    {
      label: 'Amuria',
      key: '216',
    },
    {
      label: 'Budaka',
      key: '217',
    },
    {
      label: 'Bukwa',
      key: '218',
    },
    {
      label: 'Butaleja',
      key: '219',
    },
    {
      label: 'Kaliro',
      key: '220',
    },
    {
      label: 'Manafwa',
      key: '221',
    },
    {
      label: 'Namutumba',
      key: '222',
    },
    {
      label: 'Bududa',
      key: '223',
    },
    {
      label: 'Bukedea',
      key: '224',
    },
    {
      label: 'Bulambuli',
      key: '225',
    },
    {
      label: 'Buyende',
      key: '226',
    },
    {
      label: 'Kibuku',
      key: '227',
    },
    {
      label: 'Kween',
      key: '228',
    },
    {
      label: 'Luuka',
      key: '229',
    },
    {
      label: 'Namayingo',
      key: '230',
    },
    {
      label: 'Ngora',
      key: '231',
    },
    {
      label: 'Serere',
      key: '232',
    },
    {
      label: 'Butebo',
      key: '233',
    },
    {
      label: 'Namisindwa',
      key: '234',
    },
    {
      label: 'Bugweri',
      key: '235',
    },
    {
      label: 'Kapelebyong',
      key: '236',
    },
    {
      label: 'Kalaki',
      key: '237',
    },
    {
      label: 'Adjumani',
      key: '301',
    },
    {
      label: 'Apac',
      key: '302',
    },
    {
      label: 'Arua',
      key: '303',
    },
    {
      label: 'Gulu',
      key: '304',
    },
    {
      label: 'Kitgum',
      key: '305',
    },
    {
      label: 'Kotido',
      key: '306',
    },
    {
      label: 'Lira',
      key: '307',
    },
    {
      label: 'Moroto',
      key: '308',
    },
    {
      label: 'Moyo',
      key: '309',
    },
    {
      label: 'Nebbi',
      key: '310',
    },
    {
      label: 'Nakapiripirit',
      key: '311',
    },
    {
      label: 'Pader',
      key: '312',
    },
    {
      label: 'Yumbe',
      key: '313',
    },
    {
      label: 'Amolatar',
      key: '314',
    },
    {
      label: 'Kaabong',
      key: '315',
    },
    {
      label: 'Koboko',
      key: '316',
    },
    {
      label: 'Abim',
      key: '317',
    },
    {
      label: 'Dokolo',
      key: '318',
    },
    {
      label: 'Amuru',
      key: '319',
    },
    {
      label: 'Maracha',
      key: '320',
    },
    {
      label: 'Oyam',
      key: '321',
    },
    {
      label: 'Agago',
      key: '322',
    },
    {
      label: 'Alebtong',
      key: '323',
    },
    {
      label: 'Amudat',
      key: '324',
    },
    {
      label: 'Kole',
      key: '325',
    },
    {
      label: 'Lamwo',
      key: '326',
    },
    {
      label: 'Napak',
      key: '327',
    },
    {
      label: 'Nwoya',
      key: '328',
    },
    {
      label: 'Otuke',
      key: '329',
    },
    {
      label: 'Zombo²',
      key: '330',
    },
    {
      label: 'Zombo',
      key: '331',
    },
    {
      label: 'Pakwach',
      key: '332',
    },
    {
      label: 'Kwania',
      key: '333',
    },
    {
      label: 'Nabilatuk',
      key: '334',
    },
    {
      label: 'Karenga',
      key: '335',
    },
    {
      label: 'Madi-Okollo',
      key: '336',
    },
    {
      label: 'Obongi',
      key: '337',
    },
    {
      label: 'Bundibugyo',
      key: '401',
    },
    {
      label: 'Bushenyi',
      key: '402',
    },
    {
      label: 'Hoima',
      key: '403',
    },
    {
      label: 'Kabale',
      key: '404',
    },
    {
      label: 'Kabarole',
      key: '405',
    },
    {
      label: 'Kasese',
      key: '406',
    },
    {
      label: 'Kibaale',
      key: '407',
    },
    {
      label: 'Kisoro',
      key: '408',
    },
    {
      label: 'Masindi',
      key: '409',
    },
    {
      label: 'Mbarara',
      key: '410',
    },
    {
      label: 'Ntungamo',
      key: '411',
    },
    {
      label: 'Rukungiri',
      key: '412',
    },
    {
      label: 'Kamwenge',
      key: '413',
    },
    {
      label: 'Kanungu',
      key: '414',
    },
    {
      label: 'Kyenjojo',
      key: '415',
    },
    {
      label: 'Ibanda',
      key: '416',
    },
    {
      label: 'Isingiro',
      key: '417',
    },
    {
      label: 'Kiruhura',
      key: '418',
    },
    {
      label: 'Buliisa',
      key: '419',
    },
    {
      label: 'Buhweju',
      key: '420',
    },
    {
      label: 'Kiryandongo',
      key: '421',
    },
    {
      label: 'Kyegegwa',
      key: '422',
    },
    {
      label: 'Mitooma',
      key: '423',
    },
    {
      label: 'Ntoroko',
      key: '424',
    },
    {
      label: 'Rubirizi',
      key: '425',
    },
    {
      label: 'Sheema',
      key: '426',
    },
    {
      label: 'Kagadi',
      key: '427',
    },
    {
      label: 'Kakumiro',
      key: '428',
    },
    {
      label: 'Rubanda',
      key: '429',
    },
    {
      label: 'Bunyangabu',
      key: '430',
    },
    {
      label: 'Rukiga',
      key: '431',
    },
    {
      label: 'Kikuube',
      key: '432',
    },
    {
      label: 'Kazo',
      key: '433',
    },
    {
      label: 'Kitagwenda',
      key: '434',
    },
    {
      label: 'Rwampara',
      key: '435',
    },
    {
      label: 'Central',
      key: 'C',
    },
    {
      label: 'Eastern',
      key: 'E',
    },
    {
      label: 'Northern',
      key: 'N',
    },
    {
      label: 'Western',
      key: 'W',
    },
  ],
  UKR: [
    {
      label: "Vinnyts'ka Oblast'",
      key: '05',
    },
    {
      label: "Volyns'ka Oblast'",
      key: '07',
    },
    {
      label: "Luhans'ka Oblast'",
      key: '09',
    },
    {
      label: "Dnipropetrovs'ka Oblast'",
      key: '12',
    },
    {
      label: "Donets'ka Oblast'",
      key: '14',
    },
    {
      label: "Zhytomyrs'ka Oblast'",
      key: '18',
    },
    {
      label: "Zakarpats'ka Oblast'",
      key: '21',
    },
    {
      label: "Zaporiz'ka Oblast'",
      key: '23',
    },
    {
      label: "Ivano-Frankivs'ka Oblast'",
      key: '26',
    },
    {
      label: 'Kyïv',
      key: '30',
    },
    {
      label: "Kyïvs'ka Oblast'",
      key: '32',
    },
    {
      label: "Kirovohrads'ka Oblast'",
      key: '35',
    },
    {
      label: "Sevastopol'",
      key: '40',
    },
    {
      label: 'Respublika Krym',
      key: '43',
    },
    {
      label: "L'vivs'ka Oblast'",
      key: '46',
    },
    {
      label: "Mykolaïvs'ka Oblast'",
      key: '48',
    },
    {
      label: "Odes'ka Oblast'",
      key: '51',
    },
    {
      label: "Poltavs'ka Oblast'",
      key: '53',
    },
    {
      label: "Rivnens'ka Oblast'",
      key: '56',
    },
    {
      label: "Sums'ka Oblast'",
      key: '59',
    },
    {
      label: "Ternopil's'ka Oblast'",
      key: '61',
    },
    {
      label: "Kharkivs'ka Oblast'",
      key: '63',
    },
    {
      label: "Khersons'ka Oblast'",
      key: '65',
    },
    {
      label: "Khmel'nyts'ka Oblast'",
      key: '68',
    },
    {
      label: "Cherkas'ka Oblast'",
      key: '71',
    },
    {
      label: "Chernihivs'ka Oblast'",
      key: '74',
    },
    {
      label: "Chernivets'ka Oblast'",
      key: '77',
    },
  ],
  ARE: [
    {
      label: "'Ajmān",
      key: 'AJ',
    },
    {
      label: 'Abū Z̧aby [Abu Dhabi]',
      key: 'AZ',
    },
    {
      label: 'Dubayy',
      key: 'DU',
    },
    {
      label: 'Al Fujayrah',
      key: 'FU',
    },
    {
      label: "Ra's al Khaymah",
      key: 'RK',
    },
    {
      label: 'Ash Shariqah [Sharjah]',
      key: 'SH',
    },
    {
      label: 'Umm al Qaywayn',
      key: 'UQ',
    },
  ],
  GBR: [
    {
      label: 'Armagh City, Banbridge and Craigavon',
      key: 'ABC',
    },
    {
      label: 'Aberdeenshire',
      key: 'ABD',
    },
    {
      label: 'Aberdeen City',
      key: 'ABE',
    },
    {
      label: 'Argyll and Bute',
      key: 'AGB',
    },
    {
      label: 'Isle of Anglesey [Sir Ynys Môn GB-YNM]',
      key: 'AGY',
    },
    {
      label: 'Ards and North Down',
      key: 'AND',
    },
    {
      label: 'Antrim and Newtownabbey',
      key: 'ANN',
    },
    {
      label: 'Angus',
      key: 'ANS',
    },
    {
      label: 'Bath and North East Somerset',
      key: 'BAS',
    },
    {
      label: 'Blackburn with Darwen',
      key: 'BBD',
    },
    {
      label: 'Bournemouth, Christchurch and Poole',
      key: 'BCP',
    },
    {
      label: 'Bedfordshire',
      key: 'BDF',
    },
    {
      label: 'Barking and Dagenham',
      key: 'BDG',
    },
    {
      label: 'Brent',
      key: 'BEN',
    },
    {
      label: 'Bexley',
      key: 'BEX',
    },
    {
      label: 'Belfast',
      key: 'BFS',
    },
    {
      label: 'Bridgend [Pen-y-bont ar Ogwr GB-POG]',
      key: 'BGE',
    },
    {
      label: 'Blaenau Gwent',
      key: 'BGW',
    },
    {
      label: 'Birmingham',
      key: 'BIR',
    },
    {
      label: 'Buckinghamshire',
      key: 'BKM',
    },
    {
      label: 'Barnet',
      key: 'BNE',
    },
    {
      label: 'Brighton and Hove',
      key: 'BNH',
    },
    {
      label: 'Barnsley',
      key: 'BNS',
    },
    {
      label: 'Bolton',
      key: 'BOL',
    },
    {
      label: 'Blackpool',
      key: 'BPL',
    },
    {
      label: 'Bracknell Forest',
      key: 'BRC',
    },
    {
      label: 'Bradford',
      key: 'BRD',
    },
    {
      label: 'Bromley',
      key: 'BRY',
    },
    {
      label: 'Bristol, City of',
      key: 'BST',
    },
    {
      label: 'Bury',
      key: 'BUR',
    },
    {
      label: 'Cambridgeshire',
      key: 'CAM',
    },
    {
      label: 'Caerphilly [Caerffili GB-CAF]',
      key: 'CAY',
    },
    {
      label: 'Central Bedfordshire',
      key: 'CBF',
    },
    {
      label: 'Causeway Coast and Glens',
      key: 'CCG',
    },
    {
      label: 'Ceredigion [Sir Ceredigion]',
      key: 'CGN',
    },
    {
      label: 'Cheshire East',
      key: 'CHE',
    },
    {
      label: 'Cheshire West and Chester',
      key: 'CHW',
    },
    {
      label: 'Calderdale',
      key: 'CLD',
    },
    {
      label: 'Clackmannanshire',
      key: 'CLK',
    },
    {
      label: 'Cumbria',
      key: 'CMA',
    },
    {
      label: 'Camden',
      key: 'CMD',
    },
    {
      label: 'Carmarthenshire [Sir Gaerfyrddin GB-GFY]',
      key: 'CMN',
    },
    {
      label: 'Cornwall',
      key: 'CON',
    },
    {
      label: 'Coventry',
      key: 'COV',
    },
    {
      label: 'Cardiff [Caerdydd GB-CRD]',
      key: 'CRF',
    },
    {
      label: 'Croydon',
      key: 'CRY',
    },
    {
      label: 'Conwy',
      key: 'CWY',
    },
    {
      label: 'Darlington',
      key: 'DAL',
    },
    {
      label: 'Derbyshire',
      key: 'DBY',
    },
    {
      label: 'Denbighshire [Sir Ddinbych GB-DDB]',
      key: 'DEN',
    },
    {
      label: 'Derby',
      key: 'DER',
    },
    {
      label: 'Devon',
      key: 'DEV',
    },
    {
      label: 'Dumfries and Galloway',
      key: 'DGY',
    },
    {
      label: 'Doncaster',
      key: 'DNC',
    },
    {
      label: 'Dundee City',
      key: 'DND',
    },
    {
      label: 'Dorset',
      key: 'DOR',
    },
    {
      label: 'Derry and Strabane',
      key: 'DRS',
    },
    {
      label: 'Dudley',
      key: 'DUD',
    },
    {
      label: 'Durham',
      key: 'DUR',
    },
    {
      label: 'Ealing',
      key: 'EAL',
    },
    {
      label: 'East Ayrshire',
      key: 'EAY',
    },
    {
      label: 'Edinburgh, City of',
      key: 'EDH',
    },
    {
      label: 'East Dunbartonshire',
      key: 'EDU',
    },
    {
      label: 'East Lothian',
      key: 'ELN',
    },
    {
      label: 'Eilean Siar',
      key: 'ELS',
    },
    {
      label: 'Enfield',
      key: 'ENF',
    },
    {
      label: 'England',
      key: 'ENG',
    },
    {
      label: 'East Renfrewshire',
      key: 'ERW',
    },
    {
      label: 'East Riding of Yorkshire',
      key: 'ERY',
    },
    {
      label: 'Essex',
      key: 'ESS',
    },
    {
      label: 'East Sussex',
      key: 'ESX',
    },
    {
      label: 'Falkirk',
      key: 'FAL',
    },
    {
      label: 'Fife',
      key: 'FIF',
    },
    {
      label: 'Flintshire [Sir y Fflint GB-FFL]',
      key: 'FLN',
    },
    {
      label: 'Fermanagh and Omagh',
      key: 'FMO',
    },
    {
      label: 'Gateshead',
      key: 'GAT',
    },
    {
      label: 'Glasgow City',
      key: 'GLG',
    },
    {
      label: 'Gloucestershire',
      key: 'GLS',
    },
    {
      label: 'Greenwich',
      key: 'GRE',
    },
    {
      label: 'Gwynedd',
      key: 'GWN',
    },
    {
      label: 'Halton',
      key: 'HAL',
    },
    {
      label: 'Hampshire',
      key: 'HAM',
    },
    {
      label: 'Havering',
      key: 'HAV',
    },
    {
      label: 'Hackney',
      key: 'HCK',
    },
    {
      label: 'Herefordshire, County of',
      key: 'HEF',
    },
    {
      label: 'Hillingdon',
      key: 'HIL',
    },
    {
      label: 'Highland',
      key: 'HLD',
    },
    {
      label: 'Hammersmith and Fulham',
      key: 'HMF',
    },
    {
      label: 'Hounslow',
      key: 'HNS',
    },
    {
      label: 'Hartlepool',
      key: 'HPL',
    },
    {
      label: 'Hertfordshire',
      key: 'HRT',
    },
    {
      label: 'Harrow',
      key: 'HRW',
    },
    {
      label: 'Haringey',
      key: 'HRY',
    },
    {
      label: 'Isles of Scilly',
      key: 'IOS',
    },
    {
      label: 'Isle of Wight',
      key: 'IOW',
    },
    {
      label: 'Islington',
      key: 'ISL',
    },
    {
      label: 'Inverclyde',
      key: 'IVC',
    },
    {
      label: 'Kensington and Chelsea',
      key: 'KEC',
    },
    {
      label: 'Kent',
      key: 'KEN',
    },
    {
      label: 'Kingston upon Hull, City of',
      key: 'KHL',
    },
    {
      label: 'Kirklees',
      key: 'KIR',
    },
    {
      label: 'Kingston upon Thames',
      key: 'KTT',
    },
    {
      label: 'Knowsley',
      key: 'KWL',
    },
    {
      label: 'Lancashire',
      key: 'LAN',
    },
    {
      label: 'Lisburn and Castlereagh',
      key: 'LBC',
    },
    {
      label: 'Lambeth',
      key: 'LBH',
    },
    {
      label: 'Leicester',
      key: 'LCE',
    },
    {
      label: 'Leeds',
      key: 'LDS',
    },
    {
      label: 'Leicestershire',
      key: 'LEC',
    },
    {
      label: 'Lewisham',
      key: 'LEW',
    },
    {
      label: 'Lincolnshire',
      key: 'LIN',
    },
    {
      label: 'Liverpool',
      key: 'LIV',
    },
    {
      label: 'London, City of',
      key: 'LND',
    },
    {
      label: 'Luton',
      key: 'LUT',
    },
    {
      label: 'Manchester',
      key: 'MAN',
    },
    {
      label: 'Middlesbrough',
      key: 'MDB',
    },
    {
      label: 'Medway',
      key: 'MDW',
    },
    {
      label: 'Mid and East Antrim',
      key: 'MEA',
    },
    {
      label: 'Milton Keynes',
      key: 'MIK',
    },
    {
      label: 'Midlothian',
      key: 'MLN',
    },
    {
      label: 'Monmouthshire [Sir Fynwy GB-FYN]',
      key: 'MON',
    },
    {
      label: 'Merton',
      key: 'MRT',
    },
    {
      label: 'Moray',
      key: 'MRY',
    },
    {
      label: 'Merthyr Tydfil [Merthyr Tudful GB-MTU]',
      key: 'MTY',
    },
    {
      label: 'Mid Ulster',
      key: 'MUL',
    },
    {
      label: 'North Ayrshire',
      key: 'NAY',
    },
    {
      label: 'Northumberland',
      key: 'NBL',
    },
    {
      label: 'North East Lincolnshire',
      key: 'NEL',
    },
    {
      label: 'Newcastle upon Tyne',
      key: 'NET',
    },
    {
      label: 'Norfolk',
      key: 'NFK',
    },
    {
      label: 'Nottingham',
      key: 'NGM',
    },
    {
      label: 'Northern Ireland',
      key: 'NIR',
    },
    {
      label: 'North Lanarkshire',
      key: 'NLK',
    },
    {
      label: 'North Lincolnshire',
      key: 'NLN',
    },
    {
      label: 'Newry, Mourne and Down',
      key: 'NMD',
    },
    {
      label: 'North Somerset',
      key: 'NSM',
    },
    {
      label: 'Northamptonshire',
      key: 'NTH',
    },
    {
      label: 'Neath Port Talbot [Castell-nedd Port Talbot GB-CTL]',
      key: 'NTL',
    },
    {
      label: 'Nottinghamshire',
      key: 'NTT',
    },
    {
      label: 'North Tyneside',
      key: 'NTY',
    },
    {
      label: 'Newham',
      key: 'NWM',
    },
    {
      label: 'Newport [Casnewydd GB-CNW]',
      key: 'NWP',
    },
    {
      label: 'North Yorkshire',
      key: 'NYK',
    },
    {
      label: 'Oldham',
      key: 'OLD',
    },
    {
      label: 'Orkney Islands',
      key: 'ORK',
    },
    {
      label: 'Oxfordshire',
      key: 'OXF',
    },
    {
      label: 'Pembrokeshire [Sir Benfro GB-BNF]',
      key: 'PEM',
    },
    {
      label: 'Perth and Kinross',
      key: 'PKN',
    },
    {
      label: 'Plymouth',
      key: 'PLY',
    },
    {
      label: 'Portsmouth',
      key: 'POR',
    },
    {
      label: 'Powys',
      key: 'POW',
    },
    {
      label: 'Peterborough',
      key: 'PTE',
    },
    {
      label: 'Redcar and Cleveland',
      key: 'RCC',
    },
    {
      label: 'Rochdale',
      key: 'RCH',
    },
    {
      label: 'Rhondda, Cynon, Taff [Rhondda, Cynon,Taf]',
      key: 'RCT',
    },
    {
      label: 'Redbridge',
      key: 'RDB',
    },
    {
      label: 'Reading',
      key: 'RDG',
    },
    {
      label: 'Renfrewshire',
      key: 'RFW',
    },
    {
      label: 'Richmond upon Thames',
      key: 'RIC',
    },
    {
      label: 'Rotherham',
      key: 'ROT',
    },
    {
      label: 'Rutland',
      key: 'RUT',
    },
    {
      label: 'Sandwell',
      key: 'SAW',
    },
    {
      label: 'South Ayrshire',
      key: 'SAY',
    },
    {
      label: 'Scottish Borders, The',
      key: 'SCB',
    },
    {
      label: 'Scotland',
      key: 'SCT',
    },
    {
      label: 'Suffolk',
      key: 'SFK',
    },
    {
      label: 'Sefton',
      key: 'SFT',
    },
    {
      label: 'South Gloucestershire',
      key: 'SGC',
    },
    {
      label: 'Sheffield',
      key: 'SHF',
    },
    {
      label: 'St. Helens',
      key: 'SHN',
    },
    {
      label: 'Shropshire',
      key: 'SHR',
    },
    {
      label: 'Stockport',
      key: 'SKP',
    },
    {
      label: 'Salford',
      key: 'SLF',
    },
    {
      label: 'Slough',
      key: 'SLG',
    },
    {
      label: 'South Lanarkshire',
      key: 'SLK',
    },
    {
      label: 'Sunderland',
      key: 'SND',
    },
    {
      label: 'Solihull',
      key: 'SOL',
    },
    {
      label: 'Somerset',
      key: 'SOM',
    },
    {
      label: 'Southend-on-Sea',
      key: 'SOS',
    },
    {
      label: 'Surrey',
      key: 'SRY',
    },
    {
      label: 'Stoke-on-Trent',
      key: 'STE',
    },
    {
      label: 'Stirling',
      key: 'STG',
    },
    {
      label: 'Southampton',
      key: 'STH',
    },
    {
      label: 'Sutton',
      key: 'STN',
    },
    {
      label: 'Staffordshire',
      key: 'STS',
    },
    {
      label: 'Stockton-on-Tees',
      key: 'STT',
    },
    {
      label: 'South Tyneside',
      key: 'STY',
    },
    {
      label: 'Swansea [Abertawe GB-ATA]',
      key: 'SWA',
    },
    {
      label: 'Swindon',
      key: 'SWD',
    },
    {
      label: 'Southwark',
      key: 'SWK',
    },
    {
      label: 'Tameside',
      key: 'TAM',
    },
    {
      label: 'Telford and Wrekin',
      key: 'TFW',
    },
    {
      label: 'Thurrock',
      key: 'THR',
    },
    {
      label: 'Torbay',
      key: 'TOB',
    },
    {
      label: 'Torfaen [Tor-faen]',
      key: 'TOF',
    },
    {
      label: 'Trafford',
      key: 'TRF',
    },
    {
      label: 'Tower Hamlets',
      key: 'TWH',
    },
    {
      label: 'Vale of Glamorgan, The [Bro Morgannwg GB-BMG]',
      key: 'VGL',
    },
    {
      label: 'Warwickshire',
      key: 'WAR',
    },
    {
      label: 'West Berkshire',
      key: 'WBK',
    },
    {
      label: 'West Dunbartonshire',
      key: 'WDU',
    },
    {
      label: 'Waltham Forest',
      key: 'WFT',
    },
    {
      label: 'Wigan',
      key: 'WGN',
    },
    {
      label: 'Wiltshire',
      key: 'WIL',
    },
    {
      label: 'Wakefield',
      key: 'WKF',
    },
    {
      label: 'Walsall',
      key: 'WLL',
    },
    {
      label: 'West Lothian',
      key: 'WLN',
    },
    {
      label: 'Wales',
      key: 'WLS',
    },
    {
      label: 'Wolverhampton',
      key: 'WLV',
    },
    {
      label: 'Wandsworth',
      key: 'WND',
    },
    {
      label: 'Windsor and Maidenhead',
      key: 'WNM',
    },
    {
      label: 'Wokingham',
      key: 'WOK',
    },
    {
      label: 'Worcestershire',
      key: 'WOR',
    },
    {
      label: 'Wirral',
      key: 'WRL',
    },
    {
      label: 'Warrington',
      key: 'WRT',
    },
    {
      label: 'Wrexham [Wrecsam GB-WRC]',
      key: 'WRX',
    },
    {
      label: 'Westminster',
      key: 'WSM',
    },
    {
      label: 'West Sussex',
      key: 'WSX',
    },
    {
      label: 'York',
      key: 'YOR',
    },
    {
      label: 'Shetland Islands',
      key: 'ZET',
    },
  ],
  USA: [
    {
      label: 'Alaska',
      key: 'AK',
    },
    {
      label: 'Alabama',
      key: 'AL',
    },
    {
      label: 'Arkansas',
      key: 'AR',
    },
    {
      label: 'American Samoa',
      key: 'AS',
    },
    {
      label: 'Arizona',
      key: 'AZ',
    },
    {
      label: 'California',
      key: 'CA',
    },
    {
      label: 'Colorado',
      key: 'CO',
    },
    {
      label: 'Connecticut',
      key: 'CT',
    },
    {
      label: 'District of Columbia',
      key: 'DC',
    },
    {
      label: 'Delaware',
      key: 'DE',
    },
    {
      label: 'Florida',
      key: 'FL',
    },
    {
      label: 'Georgia',
      key: 'GA',
    },
    {
      label: 'Guam',
      key: 'GU',
    },
    {
      label: 'Hawaii',
      key: 'HI',
    },
    {
      label: 'Iowa',
      key: 'IA',
    },
    {
      label: 'Idaho',
      key: 'ID',
    },
    {
      label: 'Illinois',
      key: 'IL',
    },
    {
      label: 'Indiana',
      key: 'IN',
    },
    {
      label: 'Kansas',
      key: 'KS',
    },
    {
      label: 'Kentucky',
      key: 'KY',
    },
    {
      label: 'Louisiana',
      key: 'LA',
    },
    {
      label: 'Massachusetts',
      key: 'MA',
    },
    {
      label: 'Maryland',
      key: 'MD',
    },
    {
      label: 'Maine',
      key: 'ME',
    },
    {
      label: 'Michigan',
      key: 'MI',
    },
    {
      label: 'Minnesota',
      key: 'MN',
    },
    {
      label: 'Missouri',
      key: 'MO',
    },
    {
      label: 'Northern Mariana Islands',
      key: 'MP',
    },
    {
      label: 'Mississippi',
      key: 'MS',
    },
    {
      label: 'Montana',
      key: 'MT',
    },
    {
      label: 'North Carolina',
      key: 'NC',
    },
    {
      label: 'North Dakota',
      key: 'ND',
    },
    {
      label: 'Nebraska',
      key: 'NE',
    },
    {
      label: 'New Hampshire',
      key: 'NH',
    },
    {
      label: 'New Jersey',
      key: 'NJ',
    },
    {
      label: 'New Mexico',
      key: 'NM',
    },
    {
      label: 'Nevada',
      key: 'NV',
    },
    {
      label: 'New York',
      key: 'NY',
    },
    {
      label: 'Ohio',
      key: 'OH',
    },
    {
      label: 'Oklahoma',
      key: 'OK',
    },
    {
      label: 'Oregon',
      key: 'OR',
    },
    {
      label: 'Pennsylvania',
      key: 'PA',
    },
    {
      label: 'Puerto Rico',
      key: 'PR',
    },
    {
      label: 'Rhode Island',
      key: 'RI',
    },
    {
      label: 'South Carolina',
      key: 'SC',
    },
    {
      label: 'South Dakota',
      key: 'SD',
    },
    {
      label: 'Tennessee',
      key: 'TN',
    },
    {
      label: 'Texas',
      key: 'TX',
    },
    {
      label: 'United States Minor Outlying Islands',
      key: 'UM',
    },
    {
      label: 'Utah',
      key: 'UT',
    },
    {
      label: 'Virginia',
      key: 'VA',
    },
    {
      label: 'Virgin Islands, U.S.',
      key: 'VI',
    },
    {
      label: 'Vermont',
      key: 'VT',
    },
    {
      label: 'Washington',
      key: 'WA',
    },
    {
      label: 'Wisconsin',
      key: 'WI',
    },
    {
      label: 'West Virginia',
      key: 'WV',
    },
    {
      label: 'Wyoming',
      key: 'WY',
    },
  ],
  UMI: [
    {
      label: 'Johnston Atoll',
      key: '67',
    },
    {
      label: 'Midway Islands',
      key: '71',
    },
    {
      label: 'Navassa Island',
      key: '76',
    },
    {
      label: 'Wake Island',
      key: '79',
    },
    {
      label: 'Baker Island',
      key: '81',
    },
    {
      label: 'Howland Island',
      key: '84',
    },
    {
      label: 'Jarvis Island',
      key: '86',
    },
    {
      label: 'Kingman Reef',
      key: '89',
    },
    {
      label: 'Palmyra Atoll',
      key: '95',
    },
  ],
  URY: [
    {
      label: 'Artigas',
      key: 'AR',
    },
    {
      label: 'Canelones',
      key: 'CA',
    },
    {
      label: 'Cerro Lago',
      key: 'CL',
    },
    {
      label: 'Colonia',
      key: 'CO',
    },
    {
      label: 'Durazno',
      key: 'DU',
    },
    {
      label: 'Florida',
      key: 'FD',
    },
    {
      label: 'Flores',
      key: 'FS',
    },
    {
      label: 'Lavalleja',
      key: 'LA',
    },
    {
      label: 'Maldonado',
      key: 'MA',
    },
    {
      label: 'Montevideo',
      key: 'MO',
    },
    {
      label: 'Paysandú',
      key: 'PA',
    },
    {
      label: 'Río Negro',
      key: 'RN',
    },
    {
      label: 'Rocha',
      key: 'RO',
    },
    {
      label: 'Rivera',
      key: 'RV',
    },
    {
      label: 'Salto',
      key: 'SA',
    },
    {
      label: 'San José',
      key: 'SJ',
    },
    {
      label: 'Soriano',
      key: 'SO',
    },
    {
      label: 'Tacuarembó',
      key: 'TA',
    },
    {
      label: 'Treinta y Tres',
      key: 'TT',
    },
  ],
  UZB: [
    {
      label: 'Andijon',
      key: 'AN',
    },
    {
      label: 'Buxoro',
      key: 'BU',
    },
    {
      label: 'Farg‘ona',
      key: 'FA',
    },
    {
      label: 'Jizzax',
      key: 'JI',
    },
    {
      label: 'Namangan',
      key: 'NG',
    },
    {
      label: 'Navoiy',
      key: 'NW',
    },
    {
      label: 'Qashqadaryo',
      key: 'QA',
    },
    {
      label: 'Qoraqalpog‘iston Respublikasi',
      key: 'QR',
    },
    {
      label: 'Samarqand',
      key: 'SA',
    },
    {
      label: 'Sirdaryo',
      key: 'SI',
    },
    {
      label: 'Surxondaryo',
      key: 'SU',
    },
    {
      label: 'Toshkent City',
      key: 'TK',
    },
    {
      label: 'Toshkent',
      key: 'TO',
    },
    {
      label: 'Xorazm',
      key: 'XO',
    },
  ],
  VUT: [
    {
      label: 'Malampa',
      key: 'MAP',
    },
    {
      label: 'Pénama',
      key: 'PAM',
    },
    {
      label: 'Sanma',
      key: 'SAM',
    },
    {
      label: 'Shéfa',
      key: 'SEE',
    },
    {
      label: 'Taféa',
      key: 'TAE',
    },
    {
      label: 'Torba',
      key: 'TOB',
    },
  ],
  VEN: [
    {
      label: 'Distrito Federal',
      key: 'A',
    },
    {
      label: 'Anzoátegui',
      key: 'B',
    },
    {
      label: 'Apure',
      key: 'C',
    },
    {
      label: 'Aragua',
      key: 'D',
    },
    {
      label: 'Barinas',
      key: 'E',
    },
    {
      label: 'Bolívar',
      key: 'F',
    },
    {
      label: 'Carabobo',
      key: 'G',
    },
    {
      label: 'Cojedes',
      key: 'H',
    },
    {
      label: 'Falcón',
      key: 'I',
    },
    {
      label: 'Guárico',
      key: 'J',
    },
    {
      label: 'Lara',
      key: 'K',
    },
    {
      label: 'Mérida',
      key: 'L',
    },
    {
      label: 'Miranda',
      key: 'M',
    },
    {
      label: 'Monagas',
      key: 'N',
    },
    {
      label: 'Nueva Esparta',
      key: 'O',
    },
    {
      label: 'Portuguesa',
      key: 'P',
    },
    {
      label: 'Sucre',
      key: 'R',
    },
    {
      label: 'Táchira',
      key: 'S',
    },
    {
      label: 'Trujillo',
      key: 'T',
    },
    {
      label: 'Yaracuy',
      key: 'U',
    },
    {
      label: 'Zulia',
      key: 'V',
    },
    {
      label: 'Dependencias Federales',
      key: 'W',
    },
    {
      label: 'Vargas',
      key: 'X',
    },
    {
      label: 'Delta Amacuro',
      key: 'Y',
    },
    {
      label: 'Amazonas',
      key: 'Z',
    },
  ],
  VNM: [
    {
      label: 'Lai Chau',
      key: '01',
    },
    {
      label: 'Lao Cai',
      key: '02',
    },
    {
      label: 'Ha Giang',
      key: '03',
    },
    {
      label: 'Cao Bang',
      key: '04',
    },
    {
      label: 'Son La',
      key: '05',
    },
    {
      label: 'Yen Bai',
      key: '06',
    },
    {
      label: 'Tuyen Quang',
      key: '07',
    },
    {
      label: 'Lang Son',
      key: '09',
    },
    {
      label: 'Quang Ninh',
      key: '13',
    },
    {
      label: 'Hoa Binh',
      key: '14',
    },
    {
      label: 'Ninh Binh',
      key: '18',
    },
    {
      label: 'Thai Binh',
      key: '20',
    },
    {
      label: 'Thanh Hoa',
      key: '21',
    },
    {
      label: 'Nghe An',
      key: '22',
    },
    {
      label: 'Ha Tinh',
      key: '23',
    },
    {
      label: 'Quang Binh',
      key: '24',
    },
    {
      label: 'Quang Tri',
      key: '25',
    },
    {
      label: 'Thua Thien-Hue',
      key: '26',
    },
    {
      label: 'Quang Nam',
      key: '27',
    },
    {
      label: 'Kon Tum',
      key: '28',
    },
    {
      label: 'Quang Ngai',
      key: '29',
    },
    {
      label: 'Gia Lai',
      key: '30',
    },
    {
      label: 'Binh Dinh',
      key: '31',
    },
    {
      label: 'Phu Yen',
      key: '32',
    },
    {
      label: 'Dac Lac',
      key: '33',
    },
    {
      label: 'Khanh Hoa',
      key: '34',
    },
    {
      label: 'Lam Dong',
      key: '35',
    },
    {
      label: 'Ninh Thuan',
      key: '36',
    },
    {
      label: 'Tay Ninh',
      key: '37',
    },
    {
      label: 'Dong Nai',
      key: '39',
    },
    {
      label: 'Binh Thuan',
      key: '40',
    },
    {
      label: 'Long An',
      key: '41',
    },
    {
      label: 'Ba Ria - Vung Tau',
      key: '43',
    },
    {
      label: 'An Giang',
      key: '44',
    },
    {
      label: 'Dong Thap',
      key: '45',
    },
    {
      label: 'Tien Giang',
      key: '46',
    },
    {
      label: 'Kien Giang',
      key: '47',
    },
    {
      label: 'Vinh Long',
      key: '49',
    },
    {
      label: 'Ben Tre',
      key: '50',
    },
    {
      label: 'Tra Vinh',
      key: '51',
    },
    {
      label: 'Soc Trang',
      key: '52',
    },
    {
      label: 'Bac Can',
      key: '53',
    },
    {
      label: 'Bac Giang',
      key: '54',
    },
    {
      label: 'Bac Lieu',
      key: '55',
    },
    {
      label: 'Bac Ninh',
      key: '56',
    },
    {
      label: 'Binh Duong',
      key: '57',
    },
    {
      label: 'Binh Phuoc',
      key: '58',
    },
    {
      label: 'Ca Mau',
      key: '59',
    },
    {
      label: 'Hai Duong',
      key: '61',
    },
    {
      label: 'Ha Nam',
      key: '63',
    },
    {
      label: 'Hung Yen',
      key: '66',
    },
    {
      label: 'Nam Dinh',
      key: '67',
    },
    {
      label: 'Phu Tho',
      key: '68',
    },
    {
      label: 'Thai Nguyen',
      key: '69',
    },
    {
      label: 'Vinh Phuc',
      key: '70',
    },
    {
      label: 'Dien Bien',
      key: '71',
    },
    {
      label: 'Dak Nong',
      key: '72',
    },
    {
      label: 'Hau Giang',
      key: '73',
    },
    {
      label: 'Can Tho',
      key: 'CT',
    },
    {
      label: 'Da Nang',
      key: 'DN',
    },
    {
      label: 'Ha Noi',
      key: 'HN',
    },
    {
      label: 'Hai Phong',
      key: 'HP',
    },
    {
      label: 'Ho Chi Minh',
      key: 'SG',
    },
  ],

  VGB: [],
  VIR: [],
  WLF: [
    {
      label: 'Alo',
      key: 'AL',
    },
    {
      label: 'Sigave',
      key: 'SG',
    },
    {
      label: 'Uvea',
      key: 'UV',
    },
  ],
  ESH: [],
  XKX: [],
  YEM: [
    {
      label: 'Abyan',
      key: 'AB',
    },
    {
      label: 'ʿAdan',
      key: 'AD',
    },
    {
      label: "'Amran",
      key: 'AM',
    },
    {
      label: 'Al Bayḑā’',
      key: 'BA',
    },
    {
      label: "Ad¸ D¸ali'",
      key: 'DA',
    },
    {
      label: 'Dhamar',
      key: 'DH',
    },
    {
      label: 'Hadramawt',
      key: 'HD',
    },
    {
      label: 'Hajjah',
      key: 'HJ',
    },
    {
      label: 'Al Ḩudaydah',
      key: 'HU',
    },
    {
      label: 'Ibb',
      key: 'IB',
    },
    {
      label: 'Al Jawf',
      key: 'JA',
    },
    {
      label: 'Laḩij',
      key: 'LA',
    },
    {
      label: "Ma'rib",
      key: 'MA',
    },
    {
      label: 'Al Mahrah',
      key: 'MR',
    },
    {
      label: 'Al Mahwit',
      key: 'MW',
    },
    {
      label: 'محافظة ريمة',
      key: 'RA',
    },
    {
      label: 'صنعاء',
      key: 'SA',
    },
    {
      label: 'Sa`dah',
      key: 'SD',
    },
    {
      label: 'Shabwah',
      key: 'SH',
    },
    {
      label: 'Sanʿā',
      key: 'SN',
    },
    {
      label: 'محافظة أرخبيل سقطرى',
      key: 'SU',
    },
    {
      label: 'Taʿizz',
      key: 'TA',
    },
  ],
  ZMB: [
    {
      label: 'Western',
      key: '01',
    },
    {
      label: 'Central',
      key: '02',
    },
    {
      label: 'Eastern',
      key: '03',
    },
    {
      label: 'Luapula',
      key: '04',
    },
    {
      label: 'Northern',
      key: '05',
    },
    {
      label: 'North-Western',
      key: '06',
    },
    {
      label: 'Southern',
      key: '07',
    },
    {
      label: 'Copperbelt',
      key: '08',
    },
    {
      label: 'Lusaka',
      key: '09',
    },
    {
      label: 'Muchinga',
      key: '10',
    },
  ],
  ZWE: [
    {
      label: 'Bulawayo',
      key: 'BU',
    },
    {
      label: 'Harare',
      key: 'HA',
    },
    {
      label: 'Manicaland',
      key: 'MA',
    },
    {
      label: 'Mashonaland Central',
      key: 'MC',
    },
    {
      label: 'Mashonaland East',
      key: 'ME',
    },
    {
      label: 'Midlands',
      key: 'MI',
    },
    {
      label: 'Matabeleland North',
      key: 'MN',
    },
    {
      label: 'Matabeleland South',
      key: 'MS',
    },
    {
      label: 'Masvingo',
      key: 'MV',
    },
    {
      label: 'Mashonaland West',
      key: 'MW',
    },
  ],
};
