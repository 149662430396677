import { STEP_1_FIELDS, STEP_2_FIELDS, STEP_3_FIELDS, STEP_4_FIELDS } from '../keys';

const STEP_1_FIELDS_SET = new Set(Object.values(STEP_1_FIELDS));
const STEP_2_FIELDS_SET = new Set(Object.values(STEP_2_FIELDS));
const STEP_3_FIELDS_SET = new Set(Object.values(STEP_3_FIELDS));
const STEP_4_FIELDS_SET = new Set(Object.values(STEP_4_FIELDS));

const toCamelCase = (str: string) => str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());

const transformFieldPath = (path: string | string[]) => {
  if (Array.isArray(path)) {
    return path.map(toCamelCase).join('.');
  }

  return path
    .replace(/\.(\d+)\./g, '[$1].')
    .replace(/\.(\d+)$/, '[$1]')
    .split('.')
    .map((part) => toCamelCase(part))
    .join('.');
};

const getBaseKey = (fieldPath: string): string => {
  const match = fieldPath.match(/^([^[]+)/);
  return match ? match[1] : fieldPath;
};

const mapBackendErrorsToFormFields = (backendError: any) => {
  if (!backendError?.data?.meta?.failedAttributes) return {};

  const errors: Record<string, string> = {};
  backendError.data.meta.failedAttributes.forEach(({ path, message }: any) => {
    const fieldPath = transformFieldPath(path);
    errors[fieldPath] = message;
  });

  return errors;
};

export const getStepWithError = (backendError: Record<string, string>) => {
  const lowestStep = Object.keys(backendError).reduce((lowest, keyName) => {
    const formattedKey = getBaseKey(keyName);
    let step = Infinity;
    if (STEP_1_FIELDS_SET.has(formattedKey)) {
      step = 1;
    } else if (STEP_2_FIELDS_SET.has(formattedKey)) {
      step = 2;
    } else if (STEP_3_FIELDS_SET.has(formattedKey)) {
      step = 3;
    } else if (STEP_4_FIELDS_SET.has(formattedKey)) {
      step = 4;
    }

    if (step < lowest) {
      return step;
    }

    return lowest;
  }, Infinity);

  return lowestStep === Infinity ? null : lowestStep;
};

export default mapBackendErrorsToFormFields;
