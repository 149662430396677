import { Dialog } from '@mui/material';
import { OptionallyVisible } from 'components';
import { Form, Formik } from 'formik';
import React, { PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MerchantInfo } from 'services/merchants/types';

import { Button } from 'components/buttons';
import { Panel } from 'components/containers';
import IconCross from 'components/icons/cross';
import { SelectItem } from 'components/inputs/select/select';

import { FORMIK_INITIAL_VALUES, FormType, LABELS, VALIDATION_SCHEMAS } from '../../keys';
import { getStepWithError } from '../../utils/mapBackendErrorsToFormFields';
import Step1 from '../step1/step1';
import Step2 from '../step2/step2';
import Step3 from '../step3/step3';
import Step4 from '../step4/step4';
import { Buttons, Delimeter, ModalControls, ModalRoot, PanelContent } from './styles';
import useStepper, { MAX_STEP, MIN_STEP } from './useStepper';

interface MerchantKybModalProps {
  merchant: MerchantInfo;
  onConfirm: (merchantKybPayload: FormType) => Promise<void>;
  isOpen: boolean;
  closeModal: () => void;
  countries: SelectItem[];
  //  for errors coming from backend
  error?: Record<string, string>;
  isUpdatingMerchantKyb: boolean;
}

export const MerchantKybModal = ({
  onConfirm,
  isOpen,
  closeModal,
  merchant,
  countries,
  error,
  isUpdatingMerchantKyb,
}: PropsWithChildren<MerchantKybModalProps>) => {
  const { t } = useTranslation();
  const { step, next, prev, reset, go } = useStepper();

  useEffect(() => {
    if (error) {
      if (Object.keys(error).length > 0) {
        go(getStepWithError(error) ?? MIN_STEP);
      } else if (Object.keys(error).length === 0) {
        reset();
        closeModal();
      }
    }
  }, [error]);

  const submitForm = async (values: FormType) => {
    await onConfirm(values);
  };

  const handleNextButtonClick = (values: FormType) => {
    if (step < MAX_STEP) {
      next();
    } else {
      submitForm(values);
    }
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={isOpen} scroll="body" disableEscapeKeyDown>
      <ModalRoot>
        <Panel
          label={t(LABELS.MODAL.TITLE)}
          controls={
            <ModalControls>
              <Button flat onClick={closeModal}>
                <IconCross />
              </Button>
            </ModalControls>
          }
        >
          <Formik
            initialValues={{ ...FORMIK_INITIAL_VALUES, name: merchant.name, uuid: merchant.uuid }}
            validationSchema={VALIDATION_SCHEMAS[step - 1]}
            onSubmit={handleNextButtonClick}
          >
            {() => (
              <Form>
                <PanelContent>
                  <OptionallyVisible visible={step === 1}>
                    <Step1 error={error} countries={countries} />
                  </OptionallyVisible>
                  <OptionallyVisible visible={step === 2}>
                    <Step2 error={error} countries={countries} />
                  </OptionallyVisible>
                  <OptionallyVisible visible={step === 3}>
                    <Step3 countries={countries} error={error} />
                  </OptionallyVisible>
                  <OptionallyVisible visible={step === 4}>
                    <Step4 error={error} countries={countries} />
                  </OptionallyVisible>
                  <Delimeter />
                  <Buttons>
                    {step > MIN_STEP ? (
                      <Button onClick={prev}> {t(LABELS.MODAL.BACK)}</Button>
                    ) : (
                      <Button secondary onClick={closeModal}>
                        {t(LABELS.MODAL.CANCEL)}
                      </Button>
                    )}
                    <Button primary onClick={() => {}} type="submit" disabled={isUpdatingMerchantKyb}>
                      {step < MAX_STEP ? t(LABELS.MODAL.CONTINUE) : t(LABELS.MODAL.CONFIRM)}
                    </Button>
                  </Buttons>
                </PanelContent>
              </Form>
            )}
          </Formik>
        </Panel>
      </ModalRoot>
    </Dialog>
  );
};
