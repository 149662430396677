import {
  BridgeAccountPurpose,
  BridgeAssociatedPerson,
  BridgeCustomerAddress,
  BridgeCustomerBusinessType,
  BridgeDocument,
  BridgeHighRiskActivities,
  BridgeIdentityInformation,
  BridgeRegulatedActivity,
  BridgeSourceOfFunds,
} from 'pages/merchant/edit/sections/kyb/types';

import { DateFilterValue } from 'types/filters';
import { Response } from 'types/http';
import { RequestPagination, ServerPagination } from 'types/pagination';
import { RequestSorting } from 'types/sort';

export interface MerchantsListQuery {
  filters?: {
    uuid?: string | string[];
    date?: DateFilterValue;
    status?: string;
  };
  search?: string;
  pagination?: RequestPagination;
  sort?: RequestSorting;
}

export enum MerchantStatus {
  Active = 'active',
  Inactive = 'inactive',
  Deactivated = 'deactivated',
}
export enum AccountOwnerType {
  Individual = 'individual',
  Business = 'business',
}

export enum PwcThemeMode {
  Light = 'light',
  Dark = 'dark',
  SameAsBrowser = 'sameAsBrowser',
}

export interface MerchantInfo extends MerchantCheckoutSettings {
  uuid: string;
  name: string;
  publicKey: string;
  createdAt: string;
  bridgeCustomerId: string;
  mainBankAccountUuid: string | null;
  status: MerchantStatus;
  shift4MerchantId: string | null;
  ownerEmail: string;
  ownerFullName: string;
  processingFee: number;
  webhookUrl: string | null;
  meshSubclientId: string | null;
  payout: {
    balance: string;
    nextPayout: string;
  };
  groupAccount?: {
    uuid: string;
    name: string;
  };
  kyc: MerchantKyc;
}

export interface MerchantKyc {
  uuid?: string;
  merchantUuid?: string;
  tosLink?: string;
  kycStatus?: MerchantKycStatuses;
  tosStatus?: string;
  rejectionReasons?: any[];
  updatedAt?: Date;
}

export enum MerchantKycStatuses {
  NotStarted = 'not_started',
  Pending = 'pending',
  Incomplete = 'incomplete',
  AwaitingUbo = 'awaiting_ubo',
  ManualReview = 'manual_review',
  UnderReview = 'under_review',
  Approved = 'approved',
  Rejected = 'rejected',
}

export interface MerchantCheckoutSettings {
  title: string | null;
  lightModeLogoUrl: File | string;
  darkModeLogoUrl: File | string;
}

interface MerchantBankAddress {
  streetLine1: string;
  streetLine2?: string;
  city: string;
  state?: string;
  postalCode: string;
  country: string;
}

export interface MerchantBankInfo {
  uuid: string;
  merchantUuid: string;
  accountType: string;
  currency: string;
  bankName: string;
  createdAt: string;
  accountOwnerName: string;
  accountOwnerType: AccountOwnerType;
  address?: MerchantBankAddress;
  firstName?: string;
  lastName?: string;
  businessName?: string;
  account?: {
    accountNumber: string;
    routingNumber: string;
  };
  iban?: string;
  bridgeExternalAccountId: string;
  isMain?: boolean;
}

export interface SimulateDeposit {
  uuid: string;
  name: string;
  title: string;
  shift4MerchantId: string;
  webhookUrl: string;
  processingFee: number;
  status: SimulateDepositStatus;
  lightModeLogoUrl: string;
  darkModeLogoUrl: string;
  meshSubclientId: string;
}

export enum SimulateDepositStatus {
  Inactive = 'inactive',
  Active = 'active',
  Deactivated = 'deactivated',
}

export type CreateBankAccountPayload = {
  uuid: string;
  bankName: string;
  accountOwnerName: string;
  accountOwnerType: AccountOwnerType;
  currency: string;
  address: MerchantBankAddress;
  account: {
    accountNumber: string;
    routingNumber: string;
  };
  iban?: {
    accountNumber: string;
    bic: string;
    country: string;
  };
  firstName?: string;
  lastName?: string;
  businessName?: string;
};

export type UpdateMerchantKybPayload = {
  uuid: string;
  name: string;
  description: string;
  businessIndustry: string;
  email: string;
  businessType: BridgeCustomerBusinessType;
  website: string;
  registeredAddress: BridgeCustomerAddress;
  physicalAddress: BridgeCustomerAddress;
  hasMaterialIntermediaryOwnership: boolean;
  identifyingInformation: BridgeIdentityInformation[];
  documents: BridgeDocument[];
  associatedPersons: BridgeAssociatedPerson[];
  isHighRiskActivities: boolean;
  isRegulatedActivity: boolean;
  sourceOfFunds: BridgeSourceOfFunds;
  signedAgreementId: string;
  accountPurpose: BridgeAccountPurpose;

  highRiskActivities?: BridgeHighRiskActivities[];
  regulatedActivity?: BridgeRegulatedActivity;
  sourceOfFundsDescription?: string;
  estimatedAnnualRevenueUsd?: string;
  expectedMonthlyPaymentsUsd?: number;
  operatesInProhibitedCountries?: boolean;
};

export type MerchantsListResponse = { data: MerchantInfo[]; pagination: ServerPagination };
export type MerchantsInfoResponse = { data: MerchantInfo };
export type MerchantsBanksResponse = { data: MerchantBankInfo[] };
export type CreateMerchantBankAccountResponse = Response<{ data: MerchantBankInfo }>;
export type SimulateDepositResponse = Response<{ data: SimulateDeposit }>;
