import { Divider } from '@mui/material';
import { OptionallyVisible } from 'components';
import { FieldArrayRenderProps, getIn, useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormField } from 'components/form/formField/formField';
import IconAdd from 'components/icons/add';
import IconRemove from 'components/icons/dash';
import { SearchableSelect } from 'components/inputs/select/searchableSelect';
import { SelectItem } from 'components/inputs/select/select';

import { FORM_FIELDS, FormType, LABELS } from '../../../../keys';
import { Section } from '../../../../styles';
import { BridgeHighRiskActivities, BusinessHighRiskActivities } from '../../../../types';
import replaceLabelsUnderscore from '../../../../utils/replaceLabelsUnderscore';
import FormikSwitch from '../../../switch/switch';
import { Container, Label, RecordButton } from './styles';

interface Props {
  // path to the field in the formik values, must point to a field of an array type: BusinessHighRiskActivities[]
  fieldPath: string;
  insert: FieldArrayRenderProps['insert'];
  remove: FieldArrayRenderProps['remove'];
  //  for errors coming from backend
  error?: Record<string, string>;
}

const HIGH_RISK_ACTIVITIES_TYPES: SelectItem[] = Object.entries(BridgeHighRiskActivities).map(([key, value]) => ({
  key: value,
  label: key,
}));

const formattedHiRiskActivities = replaceLabelsUnderscore(HIGH_RISK_ACTIVITIES_TYPES);

const HighRiskActivities = ({ fieldPath, error, insert, remove }: Props) => {
  const { t } = useTranslation();
  const { values, touched, errors, setFieldValue } = useFormikContext<FormType>();
  const highRiskActivitiesList = getIn(values, fieldPath) as BusinessHighRiskActivities[];

  const addItem = () => {
    insert(highRiskActivitiesList.length + 1, {});
  };

  const removeItem = (index: number) => {
    return () => {
      remove(index);
    };
  };

  return (
    <>
      <Label>{t(LABELS.SECTIONS.HIGH_RISK_ACTIVITIES.TITLE)}</Label>

      <FormikSwitch fieldPath={FORM_FIELDS.IS_HIGH_RISK_ACTIVITIES} />

      <FormField error={error?.[fieldPath]}>
        {/* showing BE errors at the top, move it? */}
        {null}
      </FormField>

      <Section>
        {highRiskActivitiesList.map((highRiskActivityItem, index) => {
          const hiRiskActivity = `${fieldPath}[${index}]`;

          const hiRiskActivityError = getIn(errors, `${fieldPath}[${index}].key`);
          const hiRiskActivityTouched = getIn(touched, `${fieldPath}[${index}]`);

          return (
            <Container>
              <FormField error={hiRiskActivityError && hiRiskActivityTouched ? hiRiskActivityError : undefined}>
                <SearchableSelect<SelectItem, false>
                  fullWidth
                  value={formattedHiRiskActivities.find((item) => item.key === highRiskActivityItem)}
                  items={formattedHiRiskActivities}
                  placeholder={t(LABELS.SECTIONS.HIGH_RISK_ACTIVITIES.PLACEHOLDER_ACTIVITY)}
                  onChange={(selectItem) => {
                    setFieldValue(hiRiskActivity, selectItem?.key);
                  }}
                  getItemLabel={(item) => item.label}
                  multiple={false}
                  closeDropdownOnSelect
                  disabled={values.isHighRiskActivities === false}
                />
              </FormField>

              <OptionallyVisible visible={index > 0}>
                <div>
                  <RecordButton type="button" inside flat onClick={removeItem(index)}>
                    <IconRemove />
                    {t(LABELS.SECTIONS.HIGH_RISK_ACTIVITIES.REMOVE)}
                  </RecordButton>
                </div>
              </OptionallyVisible>

              <OptionallyVisible visible={index !== highRiskActivitiesList.length - 1}>
                <Divider />
              </OptionallyVisible>
            </Container>
          );
        })}
      </Section>

      <RecordButton type="button" flat onClick={addItem} disabled={values.isHighRiskActivities === false}>
        <IconAdd />
        {t(LABELS.SECTIONS.HIGH_RISK_ACTIVITIES.ADD)}
      </RecordButton>
    </>
  );
};

export default HighRiskActivities;
