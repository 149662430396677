export const KEYS = {
  BASE_CURRENCY_UNIT: 1,
  APPROX_EQUAL_SYMBOL: '≈',
  DASH: '–',
};

export const LABELS = {
  BREADCRUMBS: [
    ['page.pwcOrders.view.breadcrumbs.orders', 'Orders'],
    ['page.pwcOrders.view.breadcrumbs.orderDetails', 'Order details'],
  ],
  PAGE_TITLE: ['page.pwcOrders.view.title', 'Order details'],
  SECTIONS: {
    ORDER: {
      TITLE: ['page.pwcOrders.view.sections.order.title', 'Order'],
      ORDER_ID: ['page.pwcOrders.view.sections.order.orderId', 'Order ID'],
      EXTERNAL_ORDER_ID: ['page.pwcOrders.view.sections.order.externalOrderId', 'External Order ID'],
      MERCHANT_NAME: ['page.pwcOrders.view.sections.order.merchantName', 'Merchant Name'],
      GROUP_ACCOUNT_NAME: ['page.pwcOrders.view.sections.order.groupAccount', 'Group Account'],
      CREATION_TIME: ['page.pwcOrders.view.sections.order.creationTime', 'Creation Time'],
      EXPIRATION_TIME: ['page.pwcOrders.view.sections.order.expirationTime', 'Expiration Time'],
      DATE: ['page.pwcOrders.view.sections.order.date', 'Date'],
      STATUS: ['page.pwcOrders.view.sections.order.status', 'Status'],
      ORDER_AMOUNT: ['page.pwcOrders.view.sections.order.orderAmount', 'Order Amount'],
      PAID_AMOUNT: ['page.pwcOrders.view.sections.order.paidAmount', 'Paid Amount'],
      PROCESSING_FEE_AMOUNT: ['page.pwcOrders.view.sections.order.processingFeeAmount', 'Processing Fee Amount'],
      PROCESSING_FEE_PERCENT: ['page.pwcOrders.view.sections.order.processingFeePercent', 'Processing Fee %'],
      NETWORK_FEE: ['page.pwcOrders.view.sections.order.networkFee', 'Network Fee'],
      CRYPTO_PRICE: ['page.pwcOrders.view.sections.order.cryptoPrice', 'Crypto Price'],
      UNMATCHED_DEPOSIT_POLICY: ['page.pwcOrders.view.sections.order.unmatchedDepositPolicy', 'Unmatched Deposit Policy'],
      FLEXIBLE_AMOUNT: ['page.pwcOrders.view.sections.order.flexibleAmount', 'Flexible Amount'],
      AUTOMATIC_REFUND: ['page.pwcOrders.view.sections.order.automaticRefund', 'Automatic Refund'],
      INCOMPLETE_PAYMENT: ['page.pwcOrders.view.sections.order.incompletePayment', 'Incomplete Payment'],
      LOCKED: ['page.pwcOrders.view.sections.order.locked', 'Locked'],
      ACTION_ORDER_SUCCESS: ['page.pwcOrders.view.sections.order.actionOrderSuccess', 'Successfully {{action}}ed order'],
      ACTION_ORDER_ERROR: ['page.pwcOrders.view.sections.order.actionOrderError', 'Failed to {{action}} order'],
    },
    CANCEL_ORDER: {
      MODAL_TITLE: ['page.pwcOrders.view.sections.cancelOrder.modalTitle', 'Cancel Order'],
      TITLE: ['page.pwcOrders.view.sections.cancelOrder.title', 'Are you sure?'],
      MESSAGE: ['page.pwcOrders.view.sections.cancelOrder.message', 'This order of <0>{{code}} {{amount}}</0> will be canceled.'],
      CANCEL: ['page.pwcOrders.view.sections.cancelOrder.cancel', 'Cancel'],
      CONFIRM: ['page.pwcOrders.view.sections.cancelOrder.confirm', 'Confirm'],
    },
    ORDER_SUMMARY: {
      TITLE: ['page.pwcOrders.view.sections.orderSummary.title', 'Order Summary'],
      ITEMS: ['page.pwcOrders.view.sections.orderSummary.items', 'Items'],
      TAX: ['page.pwcOrders.view.sections.orderSummary.tax', 'Tax'],
      DELIVERY_FEE: ['page.pwcOrders.view.sections.orderSummary.deliveryFee', 'Delivery Fee'],
      TIP: ['page.pwcOrders.view.sections.orderSummary.tip', 'Tip'],
      GRATUITY: ['page.pwcOrders.view.sections.orderSummary.gratuity', 'Gratuity'],
      REDIRECT_URL: ['page.pwcOrders.view.sections.orderSummary.redirectUrl', 'Redirect URL'],
    },
    EXCHANGE_DETAILS: {
      TITLE: ['page.pwcOrders.view.sections.exchangeDetails.title', 'Crypto Exchange'],
      LIQUIDITY_PROVIDER: ['page.pwcOrders.view.sections.exchangeDetails.liquidityProvider', 'Liquidity Provider'],
      DEPOSIT_ADDRESS: ['page.pwcOrders.view.sections.exchangeDetails.depositAddress', 'Deposit Address'],
      DEPOSIT_MEMO: ['page.pwcOrders.view.sections.exchangeDetails.depositMemo', 'Deposit Memo'],
      DEPOSIT_RECEIVED_TIME: ['page.pwcOrders.view.sections.exchangeDetails.depositReceivedTime', 'Deposit Received Time'],
      EXCHANGE_STATUS: ['page.pwcOrders.view.sections.exchangeDetails.settlementStatus', 'Exchange Status'],
      SETTLEMENT_PAYMENT_RAIL: ['page.pwcOrders.view.sections.exchangeDetails.settlementPaymentRail', 'Settlement Payment Rail'],
      SETTLEMENT_CURRENCY: ['page.pwcOrders.view.sections.exchangeDetails.settlementCurrency', 'Settlement Currency'],
      BANK_ACCOUNT_NAME: ['page.pwcOrders.view.sections.exchangeDetails.bankAccountName', 'Bank Account Name'],
      DESTINATION_WALLET: ['page.pwcOrders.view.sections.exchangeDetails.destinationWallet', 'Destination Wallet'],
      SETTLEMENT_TRANSACTION_HASH: [
        'page.pwcOrders.view.sections.exchangeDetails.settlementTransactionHash',
        'Settlement Transaction Hash',
      ],
    },
    SETTLEMENT_DETAILS: {
      TITLE: ['page.pwcOrders.view.sections.settlementDetails.title', 'Crypto Settlement Details'],
      SETTLEMENT_STATUS: ['page.pwcOrders.view.sections.settlementDetails.settlementStatus', 'Settlement Status'],
      SETTLEMENT_PAYMENT_RAIL: ['page.pwcOrders.view.sections.settlementDetails.settlementPaymentRail', 'Settlement Payment Rail'],
      SETTLEMENT_CURRENCY: ['page.pwcOrders.view.sections.settlementDetails.settlementCurrency', 'Settlement Currency'],
      DESTINATION_WALLET: ['page.pwcOrders.view.sections.settlementDetails.destinationWallet', 'Destination Wallet'],
      SETTLEMENT_TRANSACTION_HASH: [
        'page.pwcOrders.view.sections.settlementDetails.settlementTransactionHash',
        'Settlement Transaction Hash',
      ],
    },
    CUSTOMER: {
      TITLE: ['page.pwcOrders.view.sections.customer.title', 'Customer'],
      CUSTOMER_EMAIL: ['page.pwcOrders.view.sections.customer.userEmail', 'Customer Email'],
      CUSTOMER_ID: ['page.pwcOrders.view.sections.customer.userId', 'Customer ID'],
      EXTERNAL_CUSTOMER_ID: ['page.pwcOrders.view.sections.customer.externalUserId', 'External Customer ID'],
    },
    PAYOUT: {
      TITLE: ['page.pwcOrders.view.sections.payout.title', 'Settlement'],
      PAYOUT_DATE: ['page.pwcOrders.view.sections.payout.date', 'Payout Date'],
      PAYOUT_ID: ['page.pwcOrders.view.sections.payout.id', 'Payout ID'],
    },
    PAYMENT: {
      TITLE: ['page.pwcOrders.view.sections.payment.title', 'Crypto Payment'],
      CUSTOMER_ID: ['page.pwcOrders.view.sections.payment.customerId', 'Customer ID'],
      EXTERNAL_CUSTOMER_ID: ['page.pwcOrders.view.sections.payment.externalCustomerId', 'External Customer ID'],
      NETWORK: ['page.pwcOrders.view.sections.payment.network', 'Network'],
      PAYMENT_PROVIDER: ['page.pwcOrders.view.sections.payment.paymentProvider', 'Payment Provider'],
      PAYMENT_CONNECTION_TYPE: ['page.pwcOrders.view.sections.payment.paymentProviderConnectionType', 'Connection Type'],
      PAYMENT_CONNECTION_NAME: ['page.pwcOrders.view.sections.payment.paymentProviderConnectionName', 'Connection Name'],
      PAYMENT_STATUS: ['page.pwcOrders.view.sections.payment.paymentProviderStatus', 'Status'],
      PAYMENT_SUBMITTED_AT: ['page.pwcOrders.view.sections.payment.paymentProviderStatusUpdatedAt', 'Payment Submitted At'],
      DEPOSIT_ADDRESS: ['page.pwcOrders.view.sections.payment.paymentProviderStatusUpdatedAt.walletAddress', 'From Wallet Address'],
      DEPOSIT_MEMO: ['page.pwcOrders.view.sections.payment.paymentProviderStatusUpdatedAt.walletMemo', 'From Wallet Memo'],
      TRANSACTION_HASH: ['page.pwcOrders.view.sections.payment.transactionHash', 'Transaction Hash'],
    },
    TOP_AREA: {
      ORDER_ID: ['page.pwcOrders.view.sections.statusBar.orderId', 'Order ID'],
      ORDER_TOTAL: ['page.pwcOrders.view.sections.statusBar.orderTotal', 'Order total'],
      STATUS: ['page.pwcOrders.view.sections.statusBar.status', 'Status'],
    },
    CHARGES_LIST: {
      TITLE: ['page.pwcOrders.view.sections.chargesList.title', 'Crypto Transactions'],
    },
  },
  BOOLEAN: {
    YES: ['page.pwcOrders.view.sections.boolean.yes', 'Yes'],
    NO: ['page.pwcOrders.view.sections.boolean.no', 'No'],
  },
  UNLOCK_ORDER: ['page.pwcOrders.view.unlockOrder', 'Unlock'],
  CANCEL_ORDER: ['page.pwcOrders.view.cancelOrder', 'Cancel Order'],
};
