import { FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';

import { SelectItem } from 'components/inputs/select/select';

import { FORM_FIELDS, LABELS } from '../../keys';
import { Section } from '../../styles';
import Checkbox from '../checkbox/checkbox';
import FormikInput from '../formikTextInput/formikTextInput';
import AccountPurpose from './components/accountPurpose/accountPurpose';
import EstimatedAnnualRevenue from './components/estimatedAnnualRevenue/estimatedAnnualRevenue';
import HighRiskActivities from './components/hiRiskActivities/hiRiskActivities';
import RegulatedActivity from './components/regulatedActivity/regulatedActivity';
import SourceOfFunds from './components/sourceOfFunds/sourceOfFunds';
import { Container, Delimeter } from './styles';

interface StepFormProps {
  error?: Record<string, string>;
  countries: SelectItem[];
}

export default ({ error, countries }: StepFormProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <FieldArray
        render={({ insert, remove }) => (
          <HighRiskActivities error={error} insert={insert} remove={remove} fieldPath={FORM_FIELDS.HIGH_RISK_ACTIVITIES} />
        )}
        name={FORM_FIELDS.HIGH_RISK_ACTIVITIES}
      />

      <Delimeter />

      <Section>
        <RegulatedActivity
          title={LABELS.SECTIONS.REGULATED_ACTIVITY.TITLE}
          fieldPath={FORM_FIELDS.REGULATED_ACTIVITY}
          countries={countries}
          error={error}
        />
      </Section>

      <Delimeter />

      <Section>
        <SourceOfFunds fieldPath={FORM_FIELDS.SOURCE_OF_FUNDS} error={error} />
      </Section>

      <Section>
        <FormikInput
          label={t(LABELS.SECTIONS.SOURCE_OF_FUNDS_DESCRIPTION.TITLE)}
          fieldPath={FORM_FIELDS.SOURCE_OF_FUNDS_DESCRIPTION}
          placeholder={t(LABELS.SECTIONS.SOURCE_OF_FUNDS_DESCRIPTION.PLACEHOLDER)}
          error={error}
          isOptional
        />
      </Section>

      <Section>
        <AccountPurpose fieldPath={FORM_FIELDS.ACCOUNT_PURPOSE} error={error} />
      </Section>

      <Section>
        <EstimatedAnnualRevenue fieldPath={FORM_FIELDS.ESTIMATED_ANNUAL_REVENUE_USD} error={error} />
      </Section>

      <Section>
        <FormikInput
          label={t(LABELS.SECTIONS.EXPECTED_MONTHLY_PAYMENTS_USD.TITLE)}
          fieldPath={FORM_FIELDS.EXPECTED_MONTHLY_PAYMENTS_USD}
          placeholder={t(LABELS.SECTIONS.EXPECTED_MONTHLY_PAYMENTS_USD.PLACEHOLDER)}
          error={error}
          isOptional
        />
      </Section>

      <Checkbox
        text={t(LABELS.SECTIONS.OPERATES_IN_PROHIBITED_COUNTRIES.TEXT)}
        label={t(LABELS.SECTIONS.OPERATES_IN_PROHIBITED_COUNTRIES.LABEL)}
        fieldPath={FORM_FIELDS.OPERATES_IN_PROHIBITED_COUNTRIES}
        isOptional
      />
    </Container>
  );
};
