import { getIn, useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormField } from 'components/form/formField/formField';
import { SearchableSelect } from 'components/inputs/select/searchableSelect';
import { SelectItem } from 'components/inputs/select/select';

import { FORMIK_INITIAL_VALUES, LABELS } from '../../../../keys';
import { NAICS } from './NAICS_codes';
import { Container, Label } from './styles';

interface Props {
  // path to the field in the formik values, must point to a field of an array type: SelectItem
  fieldPath: string;
  //  for errors coming from backend
  error?: Record<string, string>;
}

const BusinessIndustry = ({ fieldPath, error }: Props) => {
  const { t } = useTranslation();
  const { values, touched, errors, setFieldValue } = useFormikContext<typeof FORMIK_INITIAL_VALUES>();

  const industry = `${fieldPath}`;
  const industryError = getIn(errors, fieldPath);
  const industryTouched = getIn(touched, fieldPath);

  const industryInformationValue = getIn(values, fieldPath) as SelectItem['key'];

  return (
    <Container>
      <Label>{t(LABELS.SECTIONS.BUSINESS_INDUSTRY.TITLE)}</Label>

      <FormField error={error?.[fieldPath]}>
        {/* showing BE errors at the top, move it? */}
        {null}
      </FormField>

      <FormField error={industryError && industryTouched ? industryError : undefined}>
        <SearchableSelect<SelectItem, false>
          fullWidth
          value={NAICS.find((item) => item.key === industryInformationValue)}
          items={NAICS}
          placeholder={t(LABELS.SECTIONS.BUSINESS_INDUSTRY.PLACEHOLDER)}
          onChange={(selectItem) => {
            setFieldValue(industry, selectItem?.key);
          }}
          getItemLabel={(item) => item.label}
          closeDropdownOnSelect
          error={error?.[fieldPath] || (industryError && industryTouched) ? industryError : undefined}
        />
      </FormField>
    </Container>
  );
};

export default BusinessIndustry;
