import { getIn, useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormField } from 'components/form/formField/formField';
import { SearchableSelect } from 'components/inputs/select/searchableSelect';
import { SelectItem } from 'components/inputs/select/select';

import { FORMIK_INITIAL_VALUES, LABELS } from '../../../../keys';
import { BridgeCustomerBusinessType } from '../../../../types';
import replaceLabelsUnderscore from '../../../../utils/replaceLabelsUnderscore';
import { Container, Label } from './styles';

interface Props {
  // path to the field in the formik values, must point to a field of an array type: BridgeCustomerBusinessType
  fieldPath: string;
  //  for errors coming from backend
  error?: Record<string, string>;
}

const BUSINESS_TYPES: SelectItem[] = Object.entries(BridgeCustomerBusinessType).map(([key, value]) => ({
  key: value,
  label: key,
}));

const formattedBusinessTypes = replaceLabelsUnderscore(BUSINESS_TYPES);

const BusinessType = ({ fieldPath, error }: Props) => {
  const { t } = useTranslation();
  const { values, touched, errors, setFieldValue } = useFormikContext<typeof FORMIK_INITIAL_VALUES>();

  const businessType = `${fieldPath}`;
  const businessTypeError = getIn(errors, fieldPath);
  const businessTypeTouched = getIn(touched, fieldPath);

  const businessTypeInformationValue = getIn(values, fieldPath) as BridgeCustomerBusinessType;

  return (
    <Container>
      <Label>{t(LABELS.SECTIONS.BUSINESS_TYPE.TITLE)}</Label>

      <FormField error={error?.[fieldPath]}>
        {/* showing BE errors at the top, move it? */}
        {null}
      </FormField>

      <FormField error={businessTypeError && businessTypeTouched ? businessTypeError : undefined}>
        <SearchableSelect<SelectItem, false>
          fullWidth
          value={formattedBusinessTypes.find((item) => item.key === businessTypeInformationValue)}
          items={formattedBusinessTypes}
          placeholder={t(LABELS.SECTIONS.BUSINESS_TYPE.PLACEHOLDER)}
          onChange={(selectItem) => {
            setFieldValue(businessType, selectItem?.key);
          }}
          getItemLabel={(item) => item.label}
          multiple={false}
          closeDropdownOnSelect
          error={error?.[fieldPath] || (businessTypeError && businessTypeTouched) ? businessTypeError : undefined}
        />
      </FormField>
    </Container>
  );
};

export default BusinessType;
