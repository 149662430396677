import { ClickAwayListener } from '@mui/base';
import { PopperProps } from '@mui/base/Popper';
import debounce from 'lodash/debounce';
import React, { MouseEvent, ReactNode, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import IconDropdownArrow from 'components/icons/dropdownArrow';
import { Input } from 'components/inputs/input/input';
import OptionallyVisible from 'components/optionallyVisible';

import IconLoading from '../../icons/loading';
import IconSearch from '../../icons/search';
import { Checkbox } from '../checkbox/checkbox';
import { ITEM_DELIMETER, KEYS } from './keys';
import {
  Button,
  DropdownContainer,
  DropdownIconContainer,
  DropdownList,
  DropdownSearch,
  DropdownSelectedContainer,
  Item,
  ItemIcon,
  ItemLabel,
  PopperRoot,
  SearchAdornment,
  SearchLabel,
  SelectableItem,
  Value,
} from './styles';

export type SelectValue<T extends SelectItem, M extends boolean = false> = M extends true ? T[] : T;
export type SelectProps<T extends SelectItem, M extends boolean = false> = {
  items: T[];
  withSearch?: boolean;
  placeholder?: string;
  fullWidth?: boolean;
  getItemLabel: (item: T) => string | ReactNode | ReactNode[];
  renderListItem?: (item: T, selectItem: (event: MouseEvent<HTMLElement>) => void) => string | ReactNode | ReactNode[];
  nothingFoundMessage?: string | ReactNode | ReactNode[];
  onSearch?: (search: string) => void;
  disabled?: boolean;
  searchResultsLabel?: string;
  closeDropdownOnSelect?: boolean;
  multiple?: M;
  value?: SelectValue<T, M>;
  renderSelectedItem?: (items: SelectValue<T, M>) => string | ReactNode | ReactNode[];
  onChange?: (items: SelectValue<T, M>, search?: string) => void;
  popperProps?: Partial<PopperProps>;
  paginationProps?: {
    hasNextPage: boolean;
    loadNextPage: () => void;
  };
  leaveSearchValueOnClose?: boolean;
  error?: boolean;
};
export interface SelectItem {
  key: string;
  icon?: string;
  label?: string;
}
export const Select = <T extends SelectItem, M extends boolean = false>({
  items,
  value,
  withSearch,
  paginationProps,
  placeholder,
  fullWidth,
  getItemLabel,
  onSearch,
  onChange,
  disabled,
  multiple,
  renderSelectedItem: passedRenderSelectedItem,
  renderListItem: passedRenderListItem,
  nothingFoundMessage,
  searchResultsLabel,
  closeDropdownOnSelect,
  popperProps = {},
  leaveSearchValueOnClose = false,
  error = false,
}: SelectProps<T, M>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (disabled) {
      return;
    }
    setAnchorEl(anchorEl ? null : event.currentTarget);
    if (anchorEl) {
      handleClose();
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
    if (!leaveSearchValueOnClose) {
      setSearchValue('');
      onSearch?.('');
    }
  };
  const open = Boolean(anchorEl);
  const handleSearch = (value: string) => {
    setSearchValue(value);
    onSearch?.(value);
  };
  const handleSelectItem = (item: T) => (event: MouseEvent<HTMLElement>) => {
    if (multiple) {
      const multipleValue = value as T[] | undefined;
      const isItemSelected = Boolean(multipleValue?.find((selectedItem: T) => selectedItem.key === item.key));
      const nextValues = isItemSelected
        ? multipleValue!.filter((selectedItem: T) => selectedItem.key !== item.key)
        : [...(multipleValue || []), item];
      onChange?.(nextValues as SelectValue<T, M>, searchValue);
    } else {
      onChange?.(item as SelectValue<T, M>, searchValue);
    }
    if (closeDropdownOnSelect) {
      handleClose();
    }
  };
  const defaultRenderSelectedItem = (selection: SelectValue<T, M>) => {
    if (multiple) {
      const items = selection as T[];
      if (!items?.length) {
        return null;
      }
      const isNotLast = (index: number) => index < items.length - 1;
      return (
        <>
          {items.map((item, index) => (
            <Item>
              <OptionallyVisible visible={Boolean(item.icon)}>
                <ItemIcon src={item.icon} />
              </OptionallyVisible>
              <ItemLabel>
                {getItemLabel(item)}
                {isNotLast(index) && ITEM_DELIMETER}
              </ItemLabel>
            </Item>
          ))}
        </>
      );
    }
    const item = selection as T;
    if (!item) {
      return null;
    }
    return (
      <Item>
        <OptionallyVisible visible={Boolean(item.icon)}>
          <ItemIcon src={item.icon} />
        </OptionallyVisible>
        <ItemLabel>{getItemLabel(item)}</ItemLabel>
      </Item>
    );
  };
  const defaultRenderListItem = (item: T, selectItem: (event: MouseEvent<HTMLElement>) => void) => {
    if (!item) {
      return null;
    }
    if (multiple) {
      return (
        <SelectableItem onClick={selectItem} key={item.key}>
          <Checkbox value={(value as T[])?.includes(item)} />
          <OptionallyVisible visible={Boolean(item.icon)}>
            <ItemIcon src={item.icon} />
          </OptionallyVisible>
          <ItemLabel>{getItemLabel(item)}</ItemLabel>
        </SelectableItem>
      );
    }
    return (
      <SelectableItem onClick={selectItem} key={item.key}>
        <OptionallyVisible visible={Boolean(item.icon)}>
          <ItemIcon src={item.icon} />
        </OptionallyVisible>
        <ItemLabel>{getItemLabel(item)}</ItemLabel>
      </SelectableItem>
    );
  };
  const renderSelectedItems = (items: T | T[]) => {
    if (multiple) {
      const renderer = (passedRenderSelectedItem || defaultRenderSelectedItem) as (items: T[]) => ReactNode | ReactNode[];
      return renderer(items as T[]);
    }
    const renderer = (passedRenderSelectedItem || defaultRenderSelectedItem) as (items: T) => ReactNode | ReactNode[];
    return renderer(items as T);
  };
  const handleLoadNextPage = debounce((isVisible: boolean) => {
    if (isVisible) {
      paginationProps?.loadNextPage();
    }
  }, KEYS.PAGE_LOAD_DEBOUNCE);
  const renderListItem = passedRenderListItem || defaultRenderListItem;
  const isEmptySearch = Boolean(searchValue && !items.length);
  const isEmptyValue = multiple ? !(value as T[])?.length : !value;
  return (
    <>
      <Button type="button" fullWidth={fullWidth} onClick={handleClick} disabled={disabled} error={error}>
        <Value>
          <OptionallyVisible visible={Boolean(value)}>{renderSelectedItems(value!)}</OptionallyVisible>
          <OptionallyVisible visible={isEmptyValue}>
            <ItemLabel>{placeholder}</ItemLabel>
          </OptionallyVisible>
        </Value>
        <DropdownIconContainer>
          <IconDropdownArrow />
        </DropdownIconContainer>
      </Button>
      <PopperRoot open={open} anchorEl={anchorEl} {...popperProps}>
        <ClickAwayListener onClickAway={handleClose}>
          <DropdownContainer parentWidth={anchorEl?.offsetWidth} parentHeight={anchorEl?.offsetHeight}>
            <DropdownSelectedContainer>
              <Value>
                <OptionallyVisible visible={Boolean(value)}>{renderSelectedItems(value!)}</OptionallyVisible>
                <OptionallyVisible visible={isEmptyValue}>
                  <ItemLabel>{placeholder}</ItemLabel>
                </OptionallyVisible>
              </Value>
              <DropdownIconContainer rotated>
                <IconDropdownArrow />
              </DropdownIconContainer>
            </DropdownSelectedContainer>
            <OptionallyVisible visible={withSearch}>
              <DropdownSearch>
                <Input
                  value={searchValue}
                  onChange={handleSearch}
                  fullWidth
                  startAdornment={
                    <SearchAdornment>
                      <IconSearch width={16} height={16} />
                    </SearchAdornment>
                  }
                />
              </DropdownSearch>
            </OptionallyVisible>
            <DropdownList>
              <OptionallyVisible visible={Boolean(searchValue && searchResultsLabel)}>
                <SearchLabel>{searchResultsLabel}</SearchLabel>
              </OptionallyVisible>
              {items.map((item) => renderListItem(item, handleSelectItem(item)))}
              <OptionallyVisible visible={isEmptySearch}>{nothingFoundMessage}</OptionallyVisible>
              <OptionallyVisible visible={Boolean(paginationProps?.hasNextPage)}>
                <VisibilitySensor active={Boolean(paginationProps?.hasNextPage)} onChange={handleLoadNextPage}>
                  <IconLoading />
                </VisibilitySensor>
              </OptionallyVisible>
            </DropdownList>
          </DropdownContainer>
        </ClickAwayListener>
      </PopperRoot>
    </>
  );
};
