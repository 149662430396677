import { OptionallyVisible } from 'components';
import { Field, getIn, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormField } from 'components/form/formField/formField';
import { Input, InputType } from 'components/inputs';

import { FormType, LABELS } from '../../keys';
import { Label, Optional } from './styles';

interface Props {
  //  path to the field in the formik values
  fieldPath: string;
  placeholder?: string;
  label?: string;
  type?: InputType;
  //  for errors coming from backend
  error?: Record<string, string>;
  errorPath?: string;
  disabled?: boolean;
  isOptional?: boolean;
}

const FormikInput = ({
  fieldPath,
  placeholder = '',
  error,
  errorPath = fieldPath,
  label,
  type = InputType.Text,
  disabled = false,
  isOptional = false,
}: Props) => {
  const { values, touched, errors, setFieldValue } = useFormikContext<FormType>();

  const inputValue = getIn(values, fieldPath);
  const inputError = getIn(errors, fieldPath);
  const inputTouched = getIn(touched, fieldPath);

  const [errorText, setErrorText] = useState<string | undefined>(undefined);

  const { t } = useTranslation();

  useEffect(() => {
    if (error?.[fieldPath]) {
      setErrorText(error[fieldPath]);
    }

    if (inputError && inputTouched) {
      setErrorText(inputError);
    }

    if (!error?.[fieldPath] && !inputError) {
      setErrorText(undefined);
    }
  }, [error, errorPath, inputError, inputTouched]);

  return (
    <>
      <OptionallyVisible visible={Boolean(label)}>
        <Label>
          {label}
          <OptionallyVisible visible={isOptional}>
            <Optional> {t(LABELS.SECTIONS.FIELD_OPTIONAL)}</Optional>
          </OptionallyVisible>
        </Label>
      </OptionallyVisible>

      <FormField error={errorText}>
        <Field name={fieldPath}>
          {({ field }: any) => (
            <Input
              {...field}
              type={type}
              placeholder={placeholder}
              value={inputValue}
              disabled={disabled}
              onChange={(value: string) => {
                setFieldValue(fieldPath, value);
              }}
            />
          )}
        </Field>
      </FormField>
    </>
  );
};

export default FormikInput;
