import { getIn, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FormField } from 'components/form/formField/formField';
import { SearchableSelect } from 'components/inputs/select/searchableSelect';
import { SelectItem } from 'components/inputs/select/select';

import { FORMIK_INITIAL_VALUES, FORM_FIELDS, LABELS } from '../../../../keys';
import { RegulatedActivityType } from '../../../../types';
import FormikInput from '../../../formikTextInput/formikTextInput';
import FormikSwitch from '../../../switch/switch';
import { Container, Label } from './styles';

interface Props {
  title: string[];
  // path to the field in the formik values, must point to a field of type: AddressType
  fieldPath: string;
  countries: SelectItem[];
  //  for errors coming from backend
  error?: Record<string, string>;
}

const regulatedActivityInitialValues = {
  regulatedActivitiesDescription: '',
  primaryRegulatoryAuthorityCountry: '',
  primaryRegulatoryAuthorityName: '',
  licenseNumber: '',
};

const RegulatedActivity = ({ title, fieldPath, countries, error }: Props) => {
  const { t } = useTranslation();

  const description = `${fieldPath}.regulatedActivitiesDescription`;
  const authorityCountry = `${fieldPath}.primaryRegulatoryAuthorityCountry`;
  const authorityName = `${fieldPath}.primaryRegulatoryAuthorityName`;
  const licenseNumber = `${fieldPath}.licenseNumber`;

  const { values, touched, errors, setFieldValue } = useFormikContext<typeof FORMIK_INITIAL_VALUES>();

  useEffect(() => {
    if (values.isRegulatedActivity) {
      setFieldValue(fieldPath, regulatedActivityInitialValues);
    } else {
      setFieldValue(fieldPath, undefined);
    }
  }, [values.isRegulatedActivity, setFieldValue, fieldPath]);

  const regulatedActivityValue = getIn(values, fieldPath) as RegulatedActivityType;
  const authorityCountryError = getIn(errors, authorityCountry);
  const authorityCountryTouched = getIn(touched, authorityCountry);

  return (
    <Container>
      <Label>{t(title)}</Label>

      <FormikSwitch fieldPath={FORM_FIELDS.IS_REGULATED_ACTIVITY} />

      <FormField error={error?.[fieldPath]}>
        {/* showing BE errors at the top, move it? */}
        {null}
      </FormField>

      <FormikInput
        fieldPath={description}
        placeholder={t(LABELS.SECTIONS.REGULATED_ACTIVITY.PLACEHOLDER_DESCRIPTION)}
        error={error}
        disabled={values.isRegulatedActivity === false}
      />
      <FormField error={authorityCountryError && authorityCountryTouched ? authorityCountryError : undefined}>
        <SearchableSelect<SelectItem, false>
          fullWidth
          value={countries.find((item) => item.key === regulatedActivityValue?.primaryRegulatoryAuthorityCountry)}
          items={countries}
          placeholder={t(LABELS.SECTIONS.REGULATED_ACTIVITY.PLACEHOLDER_AUTHORITY_COUNTRY)}
          onChange={(selectItem) => {
            setFieldValue(authorityCountry, selectItem?.key);
          }}
          getItemLabel={(item) => item.label}
          multiple={false}
          closeDropdownOnSelect
          disabled={values.isRegulatedActivity === false}
          error={error?.[authorityCountry] || (authorityCountryError && authorityCountryTouched) ? authorityCountryError : undefined}
        />
      </FormField>
      <FormikInput
        fieldPath={authorityName}
        placeholder={t(LABELS.SECTIONS.REGULATED_ACTIVITY.PLACEHOLDER_AUTHORITY_NAME)}
        error={error}
        disabled={values.isRegulatedActivity === false}
      />
      <FormikInput
        fieldPath={licenseNumber}
        placeholder={t(LABELS.SECTIONS.REGULATED_ACTIVITY.PLACEHOLDER_LICENSE_NUMBER)}
        error={error}
        disabled={values.isRegulatedActivity === false}
      />
    </Container>
  );
};

export default RegulatedActivity;
