import { t } from 'i18next';
import * as Yup from 'yup';

import RoutePath from 'router/path';

import {
  AddressType,
  AssociatedPersonType,
  BridgeDocumentPurpose,
  BridgeHighRiskActivities,
  BridgeRegulatedActivity,
  DocumentType,
  IdentifyingInformationType,
} from './types';

// separate fields will be needed for each step of the form to traverse the form based on validation later
export const STEP_1_FIELDS = {
  NAME: 'name',
  DESCRIPTION: 'description',
  BUSINESS_INDUSTRY: 'businessIndustry',
  EMAIL: 'email',
  BUSINESS_TYPE: 'businessType',
  WEBSITE: 'website',
  REGISTERED_ADDRESS: 'registeredAddress',
  PHYSICAL_ADDRESS: 'physicalAddress',
};

export const STEP_2_FIELDS = {
  IDENTIFYING_INFORMATION: 'identifyingInformation',
  DOCUMENTS: 'documents',
  HAS_MATERIAL_INTERMEDIARY_OWNERSHIP: 'hasMaterialIntermediaryOwnership',
};

export const STEP_3_FIELDS = {
  ASSOCIATED_PERSONS: 'associatedPersons',
};

export const STEP_4_FIELDS = {
  IS_HIGH_RISK_ACTIVITIES: 'isHighRiskActivities',
  HIGH_RISK_ACTIVITIES: 'highRiskActivities',
  IS_REGULATED_ACTIVITY: 'isRegulatedActivity',
  REGULATED_ACTIVITY: 'regulatedActivity',
  SOURCE_OF_FUNDS: 'sourceOfFunds',
  SOURCE_OF_FUNDS_DESCRIPTION: 'sourceOfFundsDescription',
  ACCOUNT_PURPOSE: 'accountPurpose',
  ESTIMATED_ANNUAL_REVENUE_USD: 'estimatedAnnualRevenueUsd',
  EXPECTED_MONTHLY_PAYMENTS_USD: 'expectedMonthlyPaymentsUsd',
  OPERATES_IN_PROHIBITED_COUNTRIES: 'operatesInProhibitedCountries',
};

export const FORM_FIELDS = {
  ...STEP_1_FIELDS,
  ...STEP_2_FIELDS,
  ...STEP_3_FIELDS,
  ...STEP_4_FIELDS,
};

export type FormType = {
  // TODO: Added for testing purposes, remove when done.
  signedAgreementId: string;
  uuid?: string;
  name: string;
  description: string;
  businessIndustry: string;
  email: string;
  businessType: string;
  website: string;
  identifyingInformation: IdentifyingInformationType[];
  documents: DocumentType[];
  hasMaterialIntermediaryOwnership: boolean;
  isHighRiskActivities: boolean;
  highRiskActivities: BridgeHighRiskActivities[];
  isRegulatedActivity: boolean;
  regulatedActivity?: BridgeRegulatedActivity;
  sourceOfFunds: string;
  sourceOfFundsDescription?: string;
  accountPurpose: string;
  estimatedAnnualRevenueUsd?: string;
  expectedMonthlyPaymentsUsd?: string;
  operatesInProhibitedCountries: boolean;
  registeredAddress: AddressType;
  physicalAddress: AddressType;
  associatedPersons: AssociatedPersonType[];
};

export const FORMIK_INITIAL_VALUES: FormType = {
  signedAgreementId: '1234567890',
  name: '',
  description: '',
  businessIndustry: '',
  email: '',
  businessType: '',
  website: '',
  identifyingInformation: [{ type: '', issuingCountry: '', number: '' }],
  documents: [
    {
      purposes: [BridgeDocumentPurpose.BUSINESS_FORMATION, BridgeDocumentPurpose.OWNERSHIP_INFORMATION],
      file: undefined,
      description: undefined,
    },
  ],
  hasMaterialIntermediaryOwnership: false,
  registeredAddress: {
    streetLine1: '',
    streetLine2: undefined,
    city: '',
    subdivision: undefined,
    postalCode: '',
    country: '',
  },
  physicalAddress: {
    streetLine1: '',
    streetLine2: undefined,
    city: '',
    subdivision: undefined,
    postalCode: '',
    country: '',
  },
  isHighRiskActivities: false,
  highRiskActivities: [BridgeHighRiskActivities.NONE_OF_THE_ABOVE],
  isRegulatedActivity: false,
  regulatedActivity: undefined,
  sourceOfFunds: '',
  sourceOfFundsDescription: undefined,
  accountPurpose: '',
  estimatedAnnualRevenueUsd: undefined,
  expectedMonthlyPaymentsUsd: undefined,
  operatesInProhibitedCountries: false,
  associatedPersons: [
    {
      firstName: '',
      lastName: '',
      email: '',
      residentialAddress: {
        streetLine1: '',
        streetLine2: undefined,
        city: '',
        subdivision: undefined,
        postalCode: '',
        country: '',
      },
      birthDate: null,
      hasOwnership: false,
      hasControl: false,
      isSigner: false,
      identifyingInformation: [{ type: '', issuingCountry: '', number: '' }],
      documents: [
        {
          purposes: [BridgeDocumentPurpose.BUSINESS_FORMATION, BridgeDocumentPurpose.OWNERSHIP_INFORMATION],
          file: undefined,
          description: undefined,
        },
      ],
      phone: '',
      isDirector: false,
      title: '',
      ownershipPercentage: 0,
      relationshipEstablishedAt: '',
    },
  ],
};

export const KEYS = {
  ROUTE_MATCH: `${RoutePath.Pwc.Accounts.ById}/:route`,
  ROUTE_MATCH_ID: `${RoutePath.Pwc.Accounts.ById}/:route`,
  DEFAULT_DATE_FORMAT: 'YYYY-MM-DD',
  DEFAULT_DATE_MASK: '9999-99-99',
};

export const imgAcceptRules = {
  'image/jpg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
};

export const RADIO_GROUP_OPTIONS = [
  { key: 'Yes', label: 'Yes', value: true },
  { key: 'No', label: 'No', value: false },
];

export const ESTIMATED_ANNUAL_REVENUE_USD_OPTIONS = [
  { key: '0_99999', label: '$0 - $99,999' },
  { key: '100000_999999', label: '$100,000 - $999,999' },
  { key: '1000000_9999999', label: '$1,000,000 - $9,999,999' },
  { key: '10000000_49999999', label: '$10,000,000 - $49,999,999' },
  { key: '50000000_249999999', label: '$50,000,000 - $249,999,999' },
  { key: '250000000_plus', label: '$250,000,000+' },
];

export const LABELS = {
  PANEL_TITLE: ['page.merchantSettings.general.title', 'Bridge KYB'],
  PANEL_FIELDS: {
    INFORMATION: ['page.merchantSettings.general.form.information', 'Required Information'],
    BUTTON: ['page.merchantSettings.general.form.button', 'Fill Manually'],
  },
  PANEL_VALUES: {
    INFORMATION: [
      'page.merchantSettings.general.formValues.information',
      'Merchant Name, Description, Business Industry, Email, Source of Funds,' +
        ' Business Type, Website, Address, Tax ID Number, Ultimate Beneficial Owner, Ownership Document',
    ],
    BUTTON: ['page.merchantSettings.general.formValues.button', 'Bridge KYB'],
  },
  SECTIONS: {
    FIELD_REQUIRED: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.required', 'This field is required'],
    FIELD_OPTIONAL: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.optional', 'Optional'],
    MERCHANT_NAME: {
      TITLE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.merchantName', 'Merchant Name'],
      PLACEHOLDER: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.merchantNamePlaceholder', 'Merchant Name'],
    },
    MERCHANT_DESCRIPTION: {
      TITLE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.merchantDescription', 'Description'],
      PLACEHOLDER: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.merchantDescriptionPlaceholder', 'Merchant Description'],
    },
    BUSINESS_INDUSTRY: {
      TITLE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.businessIndustry', 'Business Industry'],
      PLACEHOLDER: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.businessIndustryPlaceholder', 'Select Industry'],
    },
    MERCHANT_EMAIL: {
      TITLE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.merchantEmail', 'Email'],
      PLACEHOLDER: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.merchantEmailPlaceholder', 'Email'],
    },
    BUSINESS_TYPE: {
      TITLE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.businessType', 'Business Type'],
      PLACEHOLDER: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.businessTypePlaceholder', 'Business Type'],
    },
    WEBSITE: {
      TITLE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.website', 'Website'],
      PLACEHOLDER: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.websitePlaceholder', 'Website'],
    },
    ADDRESS: {
      REGISTERED_ADDRESS_TITLE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.registeredAddress', 'Registered Address'],
      PHYSICAL_ADDRESS_TITLE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.physicalAddress', 'Physical Address'],
      RESIDENTIAL_ADDRESS_TITLE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.residentialAddress', 'Residential Address'],
      STREET_LINE_1: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.streetLine1Placeholder', 'Street Line 1'],
      STREET_LINE_2: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.streetLine2Placeholder', 'Street Line 2'],
      CITY: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.cityPlaceholder', 'City'],
      COUNTRY: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.countryPlaceholder', 'Country'],
      POSTAL_CODE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.postalCodePlaceholder', 'Postal Code'],
      SUBDIVISION: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.subdivisionPlaceholder', 'State/Region'],
    },
    TAX_ID: {
      TITLE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.taxId', 'Tax ID Number'],
      PLACEHOLDER: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.taxIdPlaceholder', '1234567890123'],
    },
    FIRST_NAME: {
      TITLE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.firstName', 'First Name'],
      PLACEHOLDER: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.firstNamePlaceholder', 'Enter First Name'],
    },
    LAST_NAME: {
      TITLE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.lastName', 'Last Name'],
      PLACEHOLDER: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.lastNamePlaceholder', 'Enter Last Name'],
    },
    EMAIL: {
      TITLE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.email', 'Email'],
      PLACEHOLDER: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.emailPlaceholder', 'example@mail.com'],
    },
    BIRTH_DATE: {
      TITLE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.birthDate', 'Date of Birth'],
      PLACEHOLDER: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.birthDatePlaceholder', 'Date'],
    },
    PHONE: {
      TITLE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.phone', 'Phone'],
      PLACEHOLDER: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.phonePlaceholder', 'Enter Phone Number'],
    },
    TITLE: {
      TITLE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.title', 'Title'],
      PLACEHOLDER: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.titlePlaceholder', 'Enter Title'],
    },
    OWNERSHIP_PERCENTAGE: {
      TITLE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.ownPercentage', 'Ownership Percentage'],
      PLACEHOLDER: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.ownPercentagePlaceholder', '%'],
    },
    RELATIONSHIP_AT: {
      TITLE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.relationshipAt', 'Relationship Established At'],
      PLACEHOLDER: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.relationshipAtPlaceholder', 'Date'],
    },
    HAS_OWNERSHIP: {
      LABEL: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.hasOwnership', 'Has Ownership?'],
    },
    HAS_CONTROL: {
      LABEL: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.hasControl', 'Has Control?'],
    },
    IS_SIGNER: {
      LABEL: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.isSigner', 'Is Signer?'],
    },
    IS_DIRECTOR: {
      LABEL: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.isDirector', 'Is Director? Required if the person resides in EEA.'],
    },
    DESCRIPTION: {
      TITLE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.description', 'Description'],
      PLACEHOLDER: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.descriptionPlaceholder', 'Description'],
    },
    IDENTIFYING_INFORMATION: {
      TITLE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.identifyingInformation', 'Identifying Information'],
      ADD: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.addIdentifyingInformation', 'ADD IDENTIFYING INFORMATION'],
      REMOVE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.removeIdentifyingInformation', 'REMOVE IDENTIFYING INFORMATION'],
      PLACEHOLDER_TYPE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.identifyingInformationPlaceholderType', 'Type'],
      PLACEHOLDER_COUNTRY: [
        'page.merchantSettings.kybMerchantModal.sections.kyb.form.identifyingInformationPlaceholderCountry',
        'Issuing Country',
      ],
      PLACEHOLDER_NUMBER: [
        'page.merchantSettings.kybMerchantModal.sections.kyb.form.identifyingInformationPlaceholderNumber',
        'Identification Number',
      ],
    },
    HAS_MATERIAL_INTERMEDIARY_OWNERSHIP: {
      LABEL: [
        'page.merchantSettings.kybMerchantModal.sections.kyb.form.hasMaterialIntermediaryOwnership',
        'Has Material Intermediary Ownership',
      ],
      TEXT: [
        'page.merchantSettings.kybMerchantModal.sections.kyb.form.hasMaterialIntermediaryOwnershipText',
        'The business has at least one intermediate legal entity owner with 25% or more ownership',
      ],
    },
    ASSOCIATED_PERSON: {
      TITLE: [
        'page.merchantSettings.kybMerchantModal.sections.kyb.form.associatedPerson',
        'Associated Person such as Ultimate Beneficial Owner',
      ],
      ADD: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.addAssociatedPerson', 'ADD ULTIMATE BENEFICIARY OWNER'],
      REMOVE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.removeAssociatedPerson', 'REMOVE ULTIMATE BENEFICIARY OWNER'],
    },
    DOCUMENTS: {
      TITLE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.documents', 'Documents'],
      ADD: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.addDocuments', 'ADD Documents'],
      REMOVE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.removeDocuments', 'REMOVE Documents'],
      LOGO: {
        LABEL: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.documentsLogo', 'Document'],
        UPLOAD: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.documentsUpload', 'Upload Document'],
        SUPPORTED_FORMAT: [
          'page.merchantSettings.kybMerchantModal.sections.kyb.form.documentsSupportedFormat',
          'Supported Formats: JPG, PNG',
        ],
        MAX_FILE_SIZE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.documentsMaxFileSize', 'Maximum Size: 1MB'],
      },
      PLACEHOLDER_PURPOSE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.documentsPlaceholderPurpose', 'Purpose'],
      PLACEHOLDER_UPLOAD: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.documentsPlaceholderUpload', 'Choose File'],
      PLACEHOLDER_DESCRIPTION: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.documentsPlaceholderDescription', 'Description'],
    },
    HIGH_RISK_ACTIVITIES: {
      TITLE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.hiRiskActivities', 'High Risk Activities'],
      ADD: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.hiRiskActivities', 'ADD ACTIVITY'],
      REMOVE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.hiRiskActivities', 'REMOVE ACTIVITY'],
      PLACEHOLDER_ACTIVITY: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.hiRiskActivitiesPlaceholder', 'Hi Risk Activity'],
    },
    REGULATED_ACTIVITY: {
      TITLE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.regulatedActivity', 'Regulated Activity'],
      PLACEHOLDER_DESCRIPTION: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.regulatedActivityPlaceholder', 'Description'],
      PLACEHOLDER_AUTHORITY_COUNTRY: [
        'page.merchantSettings.kybMerchantModal.sections.kyb.form.regulatedActivityAuthorityCountry',
        'Authority Country',
      ],
      PLACEHOLDER_AUTHORITY_NAME: [
        'page.merchantSettings.kybMerchantModal.sections.kyb.form.regulatedActivityAuthorityName',
        'Authority Name',
      ],
      PLACEHOLDER_LICENSE_NUMBER: [
        'page.merchantSettings.kybMerchantModal.sections.kyb.form.regulatedActivityLicenseNumber',
        'License Number',
      ],
    },
    SOURCE_OF_FUNDS: {
      TITLE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.sourceOfFunds', 'Source of Funds'],
      PLACEHOLDER: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.sourceOfFundsPlaceholder', 'Source of Funds'],
    },
    SOURCE_OF_FUNDS_DESCRIPTION: {
      TITLE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.sourceOfFundsDescription', 'Source of Funds Description'],
      PLACEHOLDER: [
        'page.merchantSettings.kybMerchantModal.sections.kyb.form.sourceOfFundsDescriptionPlaceholder',
        'Enter the Source of Funds Description',
      ],
    },
    ACCOUNT_PURPOSE: {
      TITLE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.accountPurpose', 'Business Primary Purpose'],
      PLACEHOLDER: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.accountPurposePlaceholder', 'Other'],
    },
    ESTIMATED_ANNUAL_REVENUE_USD: {
      TITLE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.estimatedAnnualRevenueUsd', 'Estimated Annual Revenue (USD)'],
      PLACEHOLDER: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.estimatedAnnualRevenueUsdPlaceholder', '100,000'],
    },
    EXPECTED_MONTHLY_PAYMENTS_USD: {
      TITLE: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.expectedMonthlyPaymentsUsd', 'Estimated Monthly Payment (USD)'],
      PLACEHOLDER: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.expectedMonthlyPaymentsUsdPlaceholder', '10,000'],
    },
    OPERATES_IN_PROHIBITED_COUNTRIES: {
      LABEL: ['page.merchantSettings.kybMerchantModal.sections.kyb.form.operatesInProhibitedCountries', 'Operates in Prohibited Countries'],
      TEXT: [
        'page.merchantSettings.kybMerchantModal.sections.kyb.form.operatesInProhibitedCountriesText',
        'Cuba, Iran, Myanmar, North Korea, and Syria',
      ],
    },
  },
  ADORNMENTS: {
    PERCENT: '%',
    HTTPS: 'https://',
  },
  MODAL: {
    TITLE: ['page.merchantSettings.general.modal.title', 'Bridge KYB'],
    CONTINUE: ['page.merchantSettings.general.modal.continue', 'Continue'],
    CONFIRM: ['page.merchantSettings.general.modal.save', 'Save'],
    CANCEL: ['page.merchantSettings.general.modal.cancel', 'Cancel'],
    BACK: ['page.merchantSettings.general.modal.back', 'Back'],
  },
};

const dateSchema = Yup.string()
  .trim()
  .matches(/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/, 'Is not in correct format,\n ex. : "1980-03-24" (March 24, 1980)')
  .required(t(LABELS.SECTIONS.FIELD_REQUIRED));

const documentsSchema = Yup.array()
  .of(
    Yup.object({
      purposes: Yup.array()
        .of(Yup.string().oneOf(Object.values(BridgeDocumentPurpose), t(LABELS.SECTIONS.FIELD_REQUIRED)))
        .min(1, t(LABELS.SECTIONS.FIELD_REQUIRED))
        .required(t(LABELS.SECTIONS.FIELD_REQUIRED)),

      file: Yup.string().min(1).required(t(LABELS.SECTIONS.FIELD_REQUIRED)),

      description: Yup.string().when('purposes', {
        is: (purposes: string[] | undefined): boolean => Array.isArray(purposes) && purposes.includes(BridgeDocumentPurpose.OTHER),
        then: (schema) => schema.min(1, 'Description is required').required('Description is required'),
        otherwise: (schema) => schema.optional(),
      }),
    }),
  )
  .min(1);

const identifyingInformationSchema = Yup.array()
  .of(
    Yup.object({
      type: Yup.string().required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
      issuingCountry: Yup.string().required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
      number: Yup.string().min(1).required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
    }),
  )
  .min(1);

const addressSchema = Yup.object().shape({
  streetLine1: Yup.string().min(1).required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
  streetLine2: Yup.string().min(1).optional(),
  city: Yup.string().min(1).required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
  subdivision: Yup.string().min(1).max(3).optional(),
  postalCode: Yup.string().min(1).required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
  country: Yup.string().required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
});

const associatedPersonSchema = Yup.array()
  .of(
    Yup.object({
      firstName: Yup.string().min(1).max(1024).required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
      lastName: Yup.string().min(1).max(1024).required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
      email: Yup.string().email().required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
      residentialAddress: addressSchema,
      documents: documentsSchema,
      identifyingInformation: identifyingInformationSchema,
      birthDate: dateSchema,
      relationshipEstablishedAt: dateSchema.required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
      phone: Yup.string().min(1).max(1024).required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
      hasOwnership: Yup.boolean().required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
      hasControl: Yup.boolean().required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
      isSigner: Yup.boolean().required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
      isDirector: Yup.boolean().required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
      title: Yup.string().required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
      ownershipPercentage: Yup.number()
        .typeError('Ownership percentage must be a number')
        .required('Ownership percentage is required')
        .when('hasOwnership', {
          is: true,
          then: (schema) => schema.min(25, 'Associated person with ownership does not meet the business 25% ownership threshold'),
          otherwise: (schema) => schema.notRequired().min(0, 'Ownership percentage must be greater than 0'),
        }),
      ownershipValidation: Yup.boolean().test(
        'at-least-one-true',
        'At least one of hasOwnership, hasControl, or isSigner must be true',
        // eslint-disable-next-line func-names
        function () {
          const { hasOwnership, hasControl, isSigner } = this.parent;
          return hasOwnership || hasControl || isSigner;
        },
      ),
    }),
  )
  .min(1);

const highRiskActivitiesSchema = Yup.array()
  .of(Yup.string().oneOf(Object.values(BridgeHighRiskActivities)))
  .when('isHighRiskActivities', {
    is: true,
    then: (schema) =>
      schema.required('At least one high-risk activity is required').min(1, 'At least one high-risk activity must be selected'),
    otherwise: (schema) => schema.notRequired(),
  });

const regulatedActivitySchema = Yup.object()
  .shape({
    regulatedActivitiesDescription: Yup.string().min(1),
    primaryRegulatoryAuthorityCountry: Yup.string().min(1),
    primaryRegulatoryAuthorityName: Yup.string().min(1),
    licenseNumber: Yup.string().min(1),
  })
  .when('isRegulatedActivity', {
    is: (val: any) => Boolean(val),
    then: (schema) =>
      schema.shape({
        regulatedActivitiesDescription: Yup.string().min(1).required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
        primaryRegulatoryAuthorityCountry: Yup.string().min(1).required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
        primaryRegulatoryAuthorityName: Yup.string().min(1).required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
        licenseNumber: Yup.string().min(1).required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
      }),
    otherwise: () => Yup.object().strip(),
  });

// each element in the array corresponds to a step, 0th = step 1, 1st = step 2, etc.
export const VALIDATION_SCHEMAS = [
  Yup.object().shape({
    [STEP_1_FIELDS.NAME]: Yup.string().min(1).max(1024).required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
    [STEP_1_FIELDS.DESCRIPTION]: Yup.string().min(1).max(1024).required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
    [STEP_1_FIELDS.BUSINESS_INDUSTRY]: Yup.string().required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
    [STEP_1_FIELDS.BUSINESS_TYPE]: Yup.string().required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
    [STEP_1_FIELDS.EMAIL]: Yup.string().email().required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
    [STEP_1_FIELDS.WEBSITE]: Yup.string().min(1).max(1024).url().required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
    [STEP_1_FIELDS.REGISTERED_ADDRESS]: addressSchema,
    [STEP_1_FIELDS.PHYSICAL_ADDRESS]: addressSchema,
  }),
  Yup.object().shape({
    [STEP_2_FIELDS.HAS_MATERIAL_INTERMEDIARY_OWNERSHIP]: Yup.boolean().required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
    [STEP_2_FIELDS.IDENTIFYING_INFORMATION]: identifyingInformationSchema,
    [STEP_2_FIELDS.DOCUMENTS]: documentsSchema,
  }),
  Yup.object().shape({
    [STEP_3_FIELDS.ASSOCIATED_PERSONS]: associatedPersonSchema,
  }),
  Yup.object().shape({
    [STEP_4_FIELDS.IS_HIGH_RISK_ACTIVITIES]: Yup.boolean().required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
    [STEP_4_FIELDS.HIGH_RISK_ACTIVITIES]: highRiskActivitiesSchema,
    [STEP_4_FIELDS.IS_REGULATED_ACTIVITY]: Yup.boolean().required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
    [STEP_4_FIELDS.REGULATED_ACTIVITY]: regulatedActivitySchema,
    [STEP_4_FIELDS.SOURCE_OF_FUNDS]: Yup.string().required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
    [STEP_4_FIELDS.SOURCE_OF_FUNDS_DESCRIPTION]: Yup.string().optional(),
    [STEP_4_FIELDS.ACCOUNT_PURPOSE]: Yup.string().required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
    [STEP_4_FIELDS.ESTIMATED_ANNUAL_REVENUE_USD]: Yup.string().optional(),
    [STEP_4_FIELDS.EXPECTED_MONTHLY_PAYMENTS_USD]: Yup.string().optional(),
    [STEP_4_FIELDS.OPERATES_IN_PROHIBITED_COUNTRIES]: Yup.boolean().required(t(LABELS.SECTIONS.FIELD_REQUIRED)),
  }),
];
