export interface PwcOrder extends CryptoBase, GeneralOrderBase, CryptoPaymentBase {
  merchantUuid: string;
  paymentProviderId: string;
  onChainTransactionHash: string;
  fromWallet: string;
  network: string;
  summary: string;
  customerUuid: string;
  customerEmail: string;
  assetUuid: string;
  depositUuid: string;
  settlementDestination: string;
  settlementOnchainTransactionHash: string;
  externalCustomerId: string;
}

export enum PaymentProvider {
  Manual = 'manual',
  Mesh = 'mesh',
  WalletConnect = 'walletConnect',
}

export enum LiquidityProvider {
  Bridge = 'bridge',
}

export enum PwcOrderStatus {
  Initiated = 'INITIATED',
  Pending = 'PENDING',
  Confirmed = 'CONFIRMED',
  Completed = 'COMPLETED',
  Expired = 'EXPIRED',
  Canceled = 'CANCELED',
  Incomplete = 'INCOMPLETE',
}

export enum Network {
  Base = 'base',
  Ethereum = 'ethereum',
}

export interface CryptoBase {
  liquidityProvider: LiquidityProvider | null;
  toWalletAddress: string | null;
  toWalletMemo: string | null;
  exchangeStatus: string | null;
  settlementPaymentRail: string | null;
  settlementCurrency: string | null;
  bankAccount?: {
    uuid: string;
    bankName: string;
  };
  crypto?: {
    wallet: string | null;
    onChain: {
      transactionHash: string;
      explorerUrl: string;
    } | null;
  };
  cryptoPrice: string;
}

export interface CryptoExchange extends CryptoBase {
  depositReceivedAt: string | null;
}

export interface OnChainDetails {
  onChain?: {
    transactionHash: string;
    explorerUrl: string;
  };
}

export interface OrderItem {
  uuid: string;
  name: string;
  imageUrl: string | null;
  fiatAmount: number;
  orderUuid: string;
  type: OrderItemType;
}

export enum OrderItemType {
  Product = 'product',
  Tax = 'tax',
  Delivery = 'delivery',
  Tip = 'tip',
  ProcessingFee = 'processingFee',
  Gratuity = 'gratuity',
  Custom = 'custom',
}

export interface GeneralOrderBase {
  uuid: string;
  createdAt: string;
  expiredAt: string;
  status: PwcOrderStatus;
  cryptoCurrencyCode: string | null;
  cryptoAmount: string | null;
  fiatCurrencyCode: string | null; // can't be null?
  fiatAmount: string | number | null; // can't be null?
  processingFeePercent: string | null;
  processingFeeFiatAmount: string | null;
  redirectUrl: string | null;
  cancelRedirectUrl: string | null;
  paidFiatAmount: string | number | null; // can't be null?
  unmatchedDepositAmountPolicy: UnmatchedDepositAmountPolicy;
  locked: boolean;
}

export enum UnmatchedDepositAmountPolicy {
  FLEXIBLE_AMOUNT = 'flexibleAmount',
  INCOMPLETE_PAYMENT = 'incompletePayment',
  AUTOMATIC_REFUND = 'automaticRefund',
}

export interface GeneralOrder extends GeneralOrderBase {
  externalOrderId: string | null;
  items?: OrderItem[];
}

export interface CryptoPaymentBase {
  paymentProvider: PaymentProvider | null;
  paymentProviderConnectionName: string | null;
  paymentProviderConnectionType: string | null;
  paymentProviderStatus: string | null;
  paymentProviderStatusUpdatedAt: string | null;
  paymentProviderId: string | null;
}

export interface CryptoPayment extends CryptoPaymentBase, OnChainDetails {
  cryptoCurrencyCode: string;
  network?: Network;
  fromWalletAddress: string;
}

export type CryptoAsset = {
  uuid: string;
  name: string;
  network: string;
  cryptoCurrencyCode: string;
  meshNetworkId: string;
  minAmount: number;
  uniqueDepositAddressEnabled: boolean;
  isEvm: boolean;
  iconUrl: string;
  cryptoNetworkIconUrl: string;
};

export type MerchantOrder = {
  uuid: string;
  name: string;
};

export type MerchantCustomer = {
  uuid: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string | null;
  externalCustomerId: string | null;
};

export interface CreateOrderParams {
  fiatAmount: number;
  fiatCurrencyCode: string;
  items: OrderItem[];
  externalOrderId?: string;
  externalCustomerId?: string;
  redirectUrl?: string;
  cancelRedirectUrl?: string;
  orderTtl?: number;
  summary?: string;
  customer?: Pick<MerchantCustomer, 'email' | 'firstName' | 'lastName'>;
  webhookUrl?: string;
  unmatchedDepositAmountPolicy?: UnmatchedDepositAmountPolicy;
  passProcessingFeeToCustomer?: boolean;
  merchantUuid: string;
}

export interface Charges {
  cryptoAsset: CryptoAsset;
  cryptoExchange: ChargesCryptoExchange;
  cryptoPayment: ChargesCryptoPayment;
  payout: Payout;
  updatedAt: string;
  uuid: string;
}

export interface ChargesCryptoExchange extends CryptoExchange {
  fiatAmount: number;
  processingFeeAmount: number;
  cryptoPrice: string;
}

export interface ChargesCryptoPayment extends CryptoPayment {
  cryptoAmount: string;
  feeAmount: string;
  paymentProviderTransactionInitiatedAt: string;
}
export interface Payout {
  uuid: string;
  submittedAt: string;
}
