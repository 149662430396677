import React, { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { MerchantInfo } from 'services/merchants/types';

import { Panel } from 'components/containers';
import { SelectItem } from 'components/inputs/select/select';

import { MerchantKybModal } from './components/modal/merchantKybModal';
import { FormType, LABELS } from './keys';
import { KybPanel } from './panel/panel';
import { FormContainer } from './styles';

interface GeneralSectionProps extends WithTranslation {
  merchant: MerchantInfo;
  countries: SelectItem[];
  //  for errors coming from backend
  error?: Record<string, string>;
  onConfirm: (merchantKybPayload: FormType) => Promise<void>;
  isUpdatingMerchantKyb: boolean;
}

export const KybComponent = ({ t, merchant, countries, error, onConfirm, isUpdatingMerchantKyb }: GeneralSectionProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <Panel label={t(LABELS.PANEL_TITLE)}>
      <FormContainer>
        <KybPanel openModal={() => setIsModalVisible(true)} />
      </FormContainer>
      <MerchantKybModal
        error={error}
        countries={countries}
        merchant={merchant}
        closeModal={() => setIsModalVisible(false)}
        onConfirm={onConfirm}
        isOpen={isModalVisible}
        isUpdatingMerchantKyb={isUpdatingMerchantKyb}
      />
    </Panel>
  );
};

export const KYB = withTranslation()(KybComponent);
