import { PageWrapper } from 'components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { pwcOrdersApi, useGetPwcOrderQuery } from 'services/pwcOrders';

import LoadingIndicator from 'components/loadingIndicator';

import RoutePath from 'router/path';

import { selectAccountAccess } from 'state/selectors/accounts/accountSelector';
import { selectUserLevel } from 'state/slices/userSlice';

import { AccountAccessLevel } from 'types/account';
import { APIError } from 'types/error';

import { useRedirectOnAccountSwitch } from '../../utils/useRedirectOnAccountSwitch';
import { LABELS } from './keys';
import { OrderDetails } from './orderDetails';
import { hasUserS4cRole } from './utils';

export const PwcOrderViewContainer = () => {
  const { t } = useTranslation();
  useRedirectOnAccountSwitch(RoutePath.Pwc.Orders.Root);
  const [unlockOrderMutation, { isLoading: isUnlocking }] = pwcOrdersApi.useUnlockMutation();
  const [cancelOrderMutation, { isLoading: isCancelling }] = pwcOrdersApi.useCancelMutation();

  const params = useParams();
  const { uuid } = params;

  const { data: orderPwc, isLoading } = useGetPwcOrderQuery(uuid!, {
    skip: !uuid,
  });

  const accountAccessLevel = useSelector(selectAccountAccess);
  const userLevel = useSelector(selectUserLevel);
  const isS4cUser = hasUserS4cRole(userLevel);
  const isPartnerAdmin = [AccountAccessLevel.Owner, AccountAccessLevel.Administrator].includes(accountAccessLevel!);

  const shouldShowExchangeDetailsSection = isS4cUser;
  // TODO: Change condition to show settlement details section when it's implemented
  const shouldShowSettlementDetailsSection = false;
  const shouldShowPayoutSection = Boolean(orderPwc?.data?.payout.uuid);
  const shouldShowOrderDetailsSection = Boolean(orderPwc?.data?.general.items?.length);
  const shouldShowChargesListSection = Boolean(orderPwc?.data?.charges.length);

  const hasExtendedAccess = isS4cUser;

  const breadcrumbs = LABELS.BREADCRUMBS.map((item) => t(item));

  if (isLoading) {
    return (
      <PageWrapper pageTitle={t(LABELS.PAGE_TITLE)} breadcrumbs={breadcrumbs}>
        <LoadingIndicator padded />
      </PageWrapper>
    );
  }

  if (!orderPwc?.data) {
    return null;
  }

  const unlockOrder = async () => {
    if (!uuid) {
      throw new Error("Can't unlock order without order uuid");
    }

    return unlockOrderMutation({ uuid });
  };

  const cancelOrder = async () => {
    if (!uuid) {
      throw new Error("Can't unlock order without order uuid");
    }

    return cancelOrderMutation({ uuid });
  };

  return (
    <PageWrapper breadcrumbs={breadcrumbs} pageTitle={t(LABELS.PAGE_TITLE)}>
      <OrderDetails
        orderPwcData={orderPwc?.data}
        shouldShowExchangeDetailsSection={shouldShowExchangeDetailsSection}
        hasExtendedAccess={hasExtendedAccess}
        shouldShowSettlementDetailsSection={shouldShowSettlementDetailsSection}
        shouldShowPayoutSection={shouldShowPayoutSection}
        shouldShowOrderDetailsSection={shouldShowOrderDetailsSection}
        shouldShowChargesListSection={shouldShowChargesListSection}
        isS4cUser={isS4cUser}
        isPartnerAdmin={isPartnerAdmin}
        unlock={{
          trigger: unlockOrder as () => Promise<{ error?: APIError }>,
          isLoading: isUnlocking,
        }}
        cancel={{
          trigger: cancelOrder as () => Promise<{ error?: APIError }>,
          isLoading: isCancelling,
        }}
      />
    </PageWrapper>
  );
};
