import styled from '@emotion/styled/macro';
import { Popper } from '@mui/base/Popper';

import { Path } from '../../icons/styles';

export const PopperRoot = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.modal,
}));

export const Button = styled.button<{ fullWidth?: boolean; disabled?: boolean; error?: boolean }>((props) => ({
  width: props.fullWidth ? '100%' : 'auto',
  boxSizing: 'border-box',
  backgroundColor: props.theme.colors.container.primary,
  border: props.error ? `1px solid ${props.theme.colors.error.main}` : 0,
  padding: '8px 12px',
  borderRadius: 5,
  outline: 'none',
  cursor: props.disabled ? 'not-allowed' : 'pointer',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const DropdownContainer = styled.div<{ parentWidth?: number; parentHeight?: number }>((props) => ({
  minWidth: Math.max(props.parentWidth || 0, 240),
  maxWidth: props.parentWidth || 290,
  marginTop: -(props.parentHeight || 32),
  overflow: 'hidden',
  padding: 12,
  borderRadius: 5,
  border: `1px solid ${props.theme.colors.accent}`,
  backgroundColor: props.theme.colors.background,
}));

export const Item = styled.div((props) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  lineHeight: '16px',
  display: 'flex',
  gap: 5,
  alignItems: 'center',
}));

export const SelectableItem = styled.button((props) => ({
  textOverflow: 'ellipsis',
  border: 0,
  borderRadius: 5,
  backgroundColor: props.theme.colors.background,
  textAlign: 'left',
  padding: 6,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 12,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: props.theme.colors.highlight,
    // @ts-ignore
    [ItemLabel]: {
      color: props.theme.colors.darkAccent,
    },
  },
}));

export const ItemIcon = styled.img((props) => ({
  display: props.src ? 'flex' : 'none',
  width: 20,
  height: 20,
}));

export const ItemLabel = styled.span((props) => ({
  fontSize: 13,
  lineHeight: '16px',
  fontFamily: props.theme.fonts.primary,
  color: props.theme.colors.inactive,
  fontWeight: 500,
  display: 'inline',
}));

export const Value = styled.div((props) => ({
  maxWidth: 'calc(100% - 16px)',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'flex',
  justifyContent: 'flex-start',
  textAlign: 'left',
  flexWrap: 'wrap',
  gap: 5,
}));

export const DropdownIconContainer = styled.div<{ rotated?: boolean }>((props) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 16,
  width: 8,
  transform: props.rotated ? `rotate(180deg)` : undefined,
  path: {
    fill: props.theme.colors.inactive,
  },
}));

export const DropdownSelectedContainer = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const DropdownSearch = styled.div((props) => ({
  marginTop: 20,
}));

export const DropdownList = styled.div((props) => ({
  marginTop: 10,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'stretch',
  maxHeight: 300,
  overflowY: 'auto',
  gap: 10,
}));

export const SearchLabel = styled.h5((props) => ({
  margin: 0,
  fontSize: 12,
  fontWeight: 500,
  fontFamily: props.theme.fonts.primary,
  color: props.theme.colors.inactive,
}));

export const SearchAdornment = styled.div((props) => ({
  fontSize: 13,
  lineHeight: '16px',
  fontFamily: props.theme.fonts.primary,
  fontWeight: 500,
  color: props.theme.colors.inactive,
  marginRight: 8,
  width: 16,
  height: 16,
  // @ts-ignore
  [Path]: {
    fill: props.theme.colors.inactive,
  },
}));
