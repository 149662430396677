import { getIn, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormField } from 'components/form/formField/formField';
import { SearchableSelect } from 'components/inputs/select/searchableSelect';
import { SelectItem } from 'components/inputs/select/select';

import { FORMIK_INITIAL_VALUES, LABELS } from '../../keys';
import { subdivisions } from '../../subdivisions';
import { AddressType } from '../../types';
import FormikInput from '../formikTextInput/formikTextInput';
import { Container, Label } from './styles';

interface Props {
  title: string[];
  // path to the field in the formik values, must point to a field of type: AddressType
  fieldPath: string;
  countries: SelectItem[];
  //  for errors coming from backend
  error?: Record<string, string>;
}

const Address = ({ title, fieldPath, countries, error }: Props) => {
  const { t } = useTranslation();
  const [chosenCountrySubdivisions, setChosenCountrySubdivisions] = useState<SelectItem[]>([]);

  const streetLine1 = `${fieldPath}.streetLine1`;
  const streetLine2 = `${fieldPath}.streetLine2`;
  const city = `${fieldPath}.city`;
  const country = `${fieldPath}.country`;
  const postalCode = `${fieldPath}.postalCode`;
  const subdivision = `${fieldPath}.subdivision`;

  const { values, touched, errors, setFieldValue } = useFormikContext<typeof FORMIK_INITIAL_VALUES>();
  const addressValue = getIn(values, fieldPath) as AddressType;
  const countryError = getIn(errors, country);
  const countryTouched = getIn(touched, country);
  const subdivisionError = getIn(errors, subdivision);
  const subdivisionTouched = getIn(touched, subdivision);

  useEffect(() => {
    if (addressValue.country) {
      setChosenCountrySubdivisions(subdivisions[addressValue.country] || []);
    }
  }, [addressValue.country, setFieldValue, country]);

  return (
    <Container>
      <Label>{t(title)}</Label>

      <FormField error={error?.[fieldPath]}>
        {/* showing BE errors at the top, move it? */}
        {null}
      </FormField>

      <FormikInput fieldPath={streetLine1} placeholder={t(LABELS.SECTIONS.ADDRESS.STREET_LINE_1)} error={error} />
      <FormikInput fieldPath={streetLine2} placeholder={t(LABELS.SECTIONS.ADDRESS.STREET_LINE_2)} error={error} />
      <FormikInput fieldPath={city} placeholder={t(LABELS.SECTIONS.ADDRESS.CITY)} error={error} />
      <FormField error={subdivisionError && subdivisionTouched ? subdivisionError : undefined}>
        <SearchableSelect<SelectItem, false>
          fullWidth
          value={chosenCountrySubdivisions.find((item) => item.key === addressValue.subdivision)}
          items={chosenCountrySubdivisions}
          placeholder={t(LABELS.SECTIONS.ADDRESS.SUBDIVISION)}
          onChange={(selectItem) => {
            setFieldValue(subdivision, selectItem?.key);
          }}
          getItemLabel={(item) => item.label}
          multiple={false}
          closeDropdownOnSelect
          error={error?.[fieldPath] || (subdivisionError && subdivisionTouched) ? subdivisionError : undefined}
          disabled={!chosenCountrySubdivisions.length}
        />
      </FormField>
      <FormikInput fieldPath={postalCode} placeholder={t(LABELS.SECTIONS.ADDRESS.POSTAL_CODE)} error={error} />
      <FormField error={countryError && countryTouched ? countryError : undefined}>
        <SearchableSelect<SelectItem, false>
          fullWidth
          value={countries.find((item) => item.key === addressValue.country)}
          items={countries}
          placeholder={t(LABELS.SECTIONS.ADDRESS.COUNTRY)}
          onChange={(selectItem) => {
            setFieldValue(country, selectItem?.key);
            setFieldValue(subdivision, undefined);
          }}
          getItemLabel={(item) => item.label}
          multiple={false}
          closeDropdownOnSelect
          error={error?.[fieldPath] || (countryError && countryTouched) ? countryError : undefined}
        />
      </FormField>
    </Container>
  );
};

export default Address;
